import { Types } from "../types/types";

function validateTimeWindow(field: string, regexValue: any): boolean {
  const regex = regexValue;
  return regex.test(field);
}

export const validateField = (column: string, type: string, value: any) => {
  let message: string = '';

  if(Number.isInteger(value) && type === Types.DOUBLE) {
    message = `Campo ${column} inválido - ${value}. Ex.: 22.0`;

    return message;
  }

  
  if(column === "DRIVER_TIME_WINDOW" && value.length > 0 && !validateTimeWindow(value, /^([01]\d|2[0-3]):[0-5]\d,([01]\d|2[0-3]):[0-5]\d$/)) {
    message = `Campo ${column} inválido - ${value}. Ex.: 08:00,18:00`;

    return message;
  }

  if(column === "VERSAO_MINIMA" && value.length > 0 && !validateTimeWindow(value, /^\d+\.\d+\.\d+$/)) {
    message = `Campo ${column} inválido - ${value}. Ex.: 1.0.0`;

    return message;
  }
  
  return message;
  
}