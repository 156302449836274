import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import InputCustom from 'components/Input';
import ModalDelete from 'components/ModalDelete';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import {
  Col,
  Label,
  Row
} from 'reactstrap';
import api from 'services/api';
import { updateGroupsUserId } from 'store/modules/map/actions';
import { Group, Permission } from './interfaces/types';

interface FormValues {
  name: string;
}

interface Props {
  onUpdateGroup: (isUpdate?: boolean) => void;
}

const GroupsRegister: React.FC<Props> = (props: Props) => {
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'nome',
      headerName: 'Nome',
      filter: true,
      checkboxSelection: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: true,
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<Group[]>([]);

  const [permission, setPermission] = useState([]);
  const [multiplePermission, setMultiplePermission] = useState<any[]>([]);

  const [isRemove, setIsRemove] = useState(false);

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const tooglePermission = useCallback(selectOptions => {
    setMultiplePermission(selectOptions);
  }, []);

  const getPermissions = useCallback(async () => {
    try {
      const permissionData = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/acesso/permissoes/findByFilters',
        permissionData
      );

      const mappingPermission = response.data.map((item: Permission) => {
        return {
          value: item.id,
          label: item.descricao,
        };
      });

      setPermission(mappingPermission);
    } catch {}
  }, []);

  const deleteGroup = useCallback(async (groupId: number) => {
    try {
      const response = await api.delete(`acesso/grupos/${groupId}`);
      if (response.status === 204) {
        FrontendNotification(
          t('groups.messageRemove'),
          NotificationType.SUCCESS
        );
      } else {
        FrontendNotification(
          t('groups.messageErroRemoved'),
          NotificationType.ERROR
        );
      }

      setIsRemove(!isRemove);
      props.onUpdateGroup();
    } catch {}
  }, []);

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      multiplePermission: any[],
      resetForm: any,
      isEdit: boolean,
      groupId: number
    ) => {
      try {
        if (multiplePermission.length > 0 && values.name.length > 0) {
          const mappingPermission = multiplePermission.map(item => {
            return {
              id: item.value,
            };
          });
          const body = {
            nome: values.name,
            permissoes: mappingPermission,
          };

          if (isEdit) {
            const response = await api.patch(`/acesso/grupos/${groupId}`, body);

            if (response.status === 204) {

              FrontendNotification(
                t('groups.messageUpdated'),
                NotificationType.SUCCESS
              );

              props.onUpdateGroup(true);
            } else {
              FrontendNotification(
                t('groups.messageErroUpdated'),
                NotificationType.ERROR
              );
            }
            setIsEdit(false);
          } else {
            const response = await api.post('/acesso/grupos', body);

            if (response.status === 201) {
              FrontendNotification(
                t('groups.messageCreated'),
                NotificationType.SUCCESS
              );
              dispatch(updateGroupsUserId(response.data.id));

              props.onUpdateGroup();
            } else {
              FrontendNotification(
                t('groups.messageErroCreated'),
                NotificationType.ERROR
              );
            }
          }
        }
      } catch {}
    },
    []
  );

  const initialValues: FormValues = {
    name: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) =>
      handleSubmit(
        values,
        multiplePermission,
        resetForm,
        isEdit,
        selectedRows[0]?.id
      ),
  });

  const onUpdate = () => {
    if (selectedRows.length > 0) {
      formik.setFieldValue('name', selectedRows[0]?.nome);

      if (
        selectedRows[0]?.permissoes !== null &&
        selectedRows[0]?.permissoes.length > 0
      ) {
        const mappingPermission = selectedRows[0]?.permissoes.map(
          (item: Permission) => {
            return {
              value: item.id,
              label: item.descricao,
            };
          }
        );

        setMultiplePermission(mappingPermission);
      }

      setIsEdit(!isEdit);

      // handleSubmit(formik.values, selectedRows[0].permissoes, formik.resetForm, true);
    }
  };

  const onDelete = () => {
    if (selectedRows.length > 0) {
      setIsRemove(!isRemove);
    }
  };

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  return (
    <div className="tw-flex tw-w-full tw-flex-row">
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => deleteGroup(selectedRows[0]?.id)}
      />
      
      <div className="tw-w-[30%]">
        <Row>
          <Col md={12}>
            <InputCustom
              title={t('groups.fields.name')}
              placeholder={t('groups.fields.name')}
              onChange={formik.handleChange('name')}
              error={formik.errors.name}
              touched={formik.touched.name}
              value={formik.values.name}
            />
          </Col>
        </Row>
        <Row className="tw-mt-3">
          <Col md={12}>
            <Label className="tw-text-[#003049] tw-opacity-60 tw-text-sm tw-mb-2">
              {t('groups.fields.permissions')}
            </Label>
            <Select
              isMulti
              name="subsidiary"
              value={multiplePermission}
              options={permission}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={tooglePermission}
            />
          </Col>
        </Row>
        <div className="tw-flex tw-justify-end tw-mt-6">
          <button
            type="button"
            className="button tw-w-3/4 tw-mr-0"
            onClick={() => formik.handleSubmit()}
          >
            <Trans i18nKey={isEdit ? "groups.buttons.update" : "groups.buttons.save"} />
          </button>
        </div>
      </div>
      <div className="tw-w-[70%] tw-mb-4 tw-h-[400px] tw-ml-4">
        <Grid<Group>
          columns={columns}
          pagination={true}
          rowSelection={RowSelection.SINGLE}
          path="acesso/grupos/pageable"
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          filters={[]}
          showCrudButtons={true}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      </div>
    </div>
  );
};

export default GroupsRegister;
