import { CustomCellRendererProps } from 'ag-grid-react';
import Grid from 'components/Grid/Grid';
import { ColumnDef } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import NewTypePointInterest from './components/NewTypePointInterest';
import { renderIcons } from './icons';
import { ITypePointInterest } from './types/types';

// import { Container } from './styles';

interface Props {
  onClear: () => void;
}

const TypePointInterest: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();


  const [columns] = useState<ColumnDef[]>([
    {
      field: 'description',
      headerName: t('typePointInterest.form.description'),
    },
    {
      field: 'icon',
      headerName: t('typePointInterest.form.icon'),
      flex: 1,
      cellRenderer: (params: CustomCellRendererProps) => {
        if (params.value) {
          return renderIcons(params.value, false);
        }
      },
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<ITypePointInterest[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);

  const [tabs, setTabs] = useState<number>(0);



  const onHandleDelete = useCallback(async (data: ITypePointInterest[]) => {
    try {
      const response = await api.delete(`/delivery/typePointInterest/${data[0].id}`);

      if (response.status === 204) {
        FrontendNotification(
          'Tipo Ponto de Interesse removida com sucesso!',
          NotificationType.SUCCESS
        );
      }

      setIsRemove(false);
      setSelectedRows([]);
      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  return (
    <CustomModal
      isOpen
      label={t('typePointInterest.title')}
      styles={{ minWidth: '800px' }}
      isClose={props.onClear}
    >
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => onHandleDelete(selectedRows)}
      />
      <Tabs
        selectedIndex={tabs}
        onSelect={(index: number) => {
          setTabs(index);
        }}
      >
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
        </TabList>
        <TabPanel>
          <div className="tw-w-full tw-h-96">
            <Grid<ITypePointInterest>
              columns={columns}
              filters={[]}
              pagination
              path="/delivery/typePointInterest/pageable"
              showCrudButtons={false}
              customCrudButtons
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onUpdate={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setTabs(2);
              }}
              onDelete={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setIsRemove(!isRemove)
              }}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <NewTypePointInterest onClear={() => setTabs(0)} />
        </TabPanel>
        <TabPanel>
          <NewTypePointInterest onClear={() => setTabs(0)} isEdit data={selectedRows[0]} />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default TypePointInterest;
