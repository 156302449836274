import { GroupedRow, People, Team } from 'components/CreateCargo/types';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';

// import { Container } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  data: GroupedRow[];
  onUpdateRow: () => void;
  dataVehicles: any[];
};

const ReleaseLoadingDialog: React.FC<Props> = ({
  open,
  onClose,
  data,
  onUpdateRow,
  dataVehicles,
}: Props) => {
  const [routeCargo, setRouteCargo] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  // Function to remove duplicate elements from an array of objects
  function removeDuplicates(array: any[], key: keyof any): any[] {
    const seenKeys = new Set();

    // Use the filter method to create a new array without duplicate elements
    return array.filter(item => {
      // Get the value of the specified key from the object
      const keyValue = item[key];

      // Check if the key has been seen already
      if (!seenKeys.has(keyValue)) {
        // If it hasn't been seen, add it to the set and return true (keeping the item in the new array)
        seenKeys.add(keyValue);
        return true;
      }

      // If the key has been seen, return false (ignoring the item in the new array)
      return false;
    });
  }

  const onCreateCargo = useCallback(
    async (
      groupedRows: GroupedRow[],
      dataRoute: any[],
      vehiclesData: any[]
    ) => {
      try {
        setLoading(true);

        let selectedRows = removeDuplicates(groupedRows, 'simulation');

        for (const row of selectedRows) {
          const getListDriver = row?.teamList
            .filter((driver: Team) => driver.type === People.DRIVER)
            .map((item: Team) => item.id);
          const getListCollaborator = row?.teamList
            .filter(
              (collaborator: Team) => collaborator.type === People.COLLABORATOR
            )
            .map((item: Team) => item.id);

          console.log(getListDriver);
          console.log(getListCollaborator);

          const getListRouteOptmi: any[] = await getRouteOptmi(row.simulation);

          const dataCreateRoute = getListRouteOptmi.map((route: any) => {
            return {
              optmization: {
                id: route.id,
              },
            };
          });

          const mappingRoutes = getListRouteOptmi.map((route: any) => {
            const findVehicleByRoute = vehiclesData.find(
              (item: any) => item.id === route.vehicle.id
            );

            const getCollaborators = findVehicleByRoute?.colaboradores.map(
              (item: any) => item.id
            );

            return {
              routes: [
                {
                  optmization: {
                    id: route.id,
                  },
                },
              ],
              collaborators: getCollaborators ? getCollaborators : null,
              driver: findVehicleByRoute?.motorista.id,
            };
          });

          const data = {
            routes: dataCreateRoute,
            collaborators: getListCollaborator,
            driver:
              getListDriver && getListDriver.length > 0
                ? getListDriver[0]
                : null,
          };

          let response;

          if (dataVehicles.length > 0) {
            response = await api.post('/route/cargo/create/multiple', mappingRoutes);
          } else {
            response = await api.post('/route/cargo/create', data);
          }

          if (response.status === 204) {

            setLoading(false);
            FrontendNotification(
              'Rota liberada pra carregamento!',
              NotificationType.SUCCESS
            );
            onUpdateRow();
          }
        }
        window.open('/dashboard', '_blank');

        setLoading(false);
      } catch {}
    },
    []
  );

  const getRouteOptmi = useCallback(async (simulation: string) => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'simulation.id',
            value: simulation,
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/route/optmis/findByFilters', body);
      return response.data;
      // setRouteCargo(response.data);

      setLoading(false);
    } catch {}
  }, []);

  const getVehicles = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'filial.id',
            value: sessionStorage.getItem('subsidiary'),
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/delivery/veiculos/findByFilters', body);
      setVehicles(response.data);
      setLoading(false);
    } catch {}
  }, []);

  useEffect(() => {
    getVehicles();
  }, [getVehicles]);

  // useEffect(() => {
  //   getRouteOptmi();
  // }, [getRouteOptmi]);

  return (
    <div>
      <Loading loading={loading} />
      <Modal isOpen={open} toggle={onClose}>
        <ModalBody>
          <Trans i18nKey="common.questionReleaseLoading" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => onCreateCargo(data, routeCargo, dataVehicles)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={onClose} id="buttonCancelRemove">
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReleaseLoadingDialog;
