import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../services/api';
import TopBar from '../../util/TopBar';

import { useTranslation } from 'react-i18next';
import CustomFilterDialog from '../../components/CustomFilterDialog';
import CustomFilter from '../../components/CustomFilterDialog/CustomFilter';
import CancelCargo from './CancelCargo';
import ChangeDriver from './ChangeDriver';
import GetRoutes from './GetRoutes';
import ListAttendances from './ListAttendances';
import Alert from './components/Alert';
import CustomComponent from './components/CustomComponent';
import DetailsCTE from './components/DetailsCTE';
import KpiDataCargo from './components/KpiData/KpiDataCargo';
import KpiDataVehicle from './components/KpiData/KpiDataVehicle';
import Table from './components/Table';
import CargoAnimation from './components/Table/Animation';
import UploadCTE from './components/UploadCTE';
import componentShipment from './data/component.json';
import './styles.scss';

export interface FormFieldShipment {
  dataInicial?: string;
  dataFinal?: string;
  nomeCliente?: string;
  nomeMotorista?: string;
  placa?: string;
  numeroCte?: string;
  idReferencia?: string;
  romaneiosPendentes?: string;
}

const ControlTowerNew: React.FC = () => {
  const [subsidiaryList, setSubsidiaryList] = useState([]);
  const [currentSubsidiary, setCurrentSubsidiary] = useState<any>(null);
  const [visibleViewMap, setVisibleViewMap] = useState(false);
  const [visibleChangeDriver, setVisibleChangeDriver] = useState(false);
  const [visibleCancelCargo, setVisibleCancelCargo] = useState(false);
  const [visibleListKnowledge, setVisibleListKnowledge] = useState(false);
  const [visibleUploadCte, setVisibleUploadCte] = useState(false);
  const [visibleDetailsCTE, setVisibleDetailsCTE] = useState(false);
  const [motoristaIdMap, setMotoristaIdMap] = useState<any>();
  const [alertUpdate, setAlertUpdate] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>();

  const [subsidiaryPosition, setSubsidiaryPosition] = useState({
    lat: 0,
    lng: 0,
  });
  const [kpis, setKpis] = useState({
    totalVeiculos: 0,
    totalEntregas: 0,
    totalVeiculosBase: 0,
    totalVeiculosBasePerc: 0,
    totalVeiculosRetornandoBase: 0,
    totalVeiculosRetornandoBasePerc: 0,
    totalCanceladas: 0,
    totalCanceladasPerc: 0,
    totalPendentes: 0,
    totalPendentesPerc: 0,
    totalEfetivadas: 0,
    totalEfetivadasPerc: 0,
    totalEmAtendimento: 0,
    totalInsucessos: 0,
    totalInsucessosPerc: 0,
    totalEmAtendimentoPerc: 0,
    totalVeiculosSemComunicacao: 0,
    totalVeiculosSemComunicacaoPerc: 0,
    totalAtendimentosValidos: 0,
    totalAtendimentosValiosPerc: 0,
    totalAtendimentosRealizados: 0,
  });
  const [totalElements, setTotalElements] = useState(0);
  const [idRomaneioMap, setIdRomaneio] = useState<any>();

  const [cargo, setCargo] = useState<any[]>([]);
  const dateFormat = format(new Date(), 'yyyy-MM-dd', {
    locale: pt,
  });
  const [initialDate, setInitialDate] = useState(`${dateFormat}`);
  const [finalDate, setFinalDate] = useState(`${dateFormat}`);
  const [modalFilters, setModalFilters] = useState<boolean>(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadingDevices, setLoadingDevices] = useState(false);
  const [permission, setPermission] = useState();

  const { t } = useTranslation();

  const userData = useSelector((state: any) => state.auth.user);

  const kpi = sessionStorage.getItem('kpis');

  let dataKpi;

  if (kpi) {
    dataKpi = kpi;
  }

  const getSubsidiaryId = useSelector((state: any) => state.map.subsidiaryId);

  const getSubsidiaryList = useCallback(async () => {
    try {
      const data = {
        filters: [{ field: 'status', value: 'ACTIVE', operation: 'EQUAL' }],
      };

      const response = await api.post('/cadastro/filiais/findByFilters', data);
      setSubsidiaryList(response.data);
    } catch (error) {}
  }, []);

  const getLoadDriverInfo = useCallback(
    async (idDriver: any, data: any[], indexCargo: number) => {
      try {
        const subsidiary = sessionStorage.getItem('subsidiary');

        const response = await api.get(
          `/delivery/motoristasInfos/redis/${idDriver}`
        );

        setCargo((prevState: any) => {
          prevState[indexCargo].romaneio.motorista.sinalOperadora =
            response.data.sinalOperadora;
          prevState[indexCargo].romaneio.motorista.bateria =
            response.data.bateria;
          prevState[indexCargo].romaneio.motorista.gps = response.data.gps;
          prevState[indexCargo].romaneio.motorista.statusComunicacao =
            response.data.semComunicacao;
          prevState[indexCargo].romaneio.motorista.dataComunicacao =
            response.data.data;
          prevState[indexCargo].romaneio.motorista.appVersion =
            response.data.appVersion;
          prevState[indexCargo].romaneio.motorista.charging =
            response.data.charging;

          return [...prevState];
        });

        setCargo(data);

        return response.data;
      } catch {}
    },
    []
  );

  async function getLoadDataCargo(idCargo: any, data: any, indexCargo: number) {
    try {
      const subsidiary = sessionStorage.getItem('subsidiary');

      const response = await api.post(
        `/delivery/kpis/tc/${subsidiary}/conhecimentos/${idCargo}`
      );

      setCargo((prevState: any) => {
        prevState[indexCargo].romaneio.conhecimentoAtual =
          response.data.conhecimentoAtual;
        prevState[indexCargo].romaneio.conhecimentoSeguinte =
          response.data.conhecimentoSeguinte;
        prevState[indexCargo].romaneio.possuiAlertasNaoLidos =
          response.data.possuiAlertasNaoLidos;
        return [...prevState];
      });

      return response.data;
    } catch {}
  }

  const getCargoData = useCallback(async (page?: number, perPage?: number) => {
    try {
      setLoading(true);

      const filters = sessionStorage.getItem('data::shipment');

      let dataFilters: any = null;

      if (filters) {
        dataFilters = JSON.parse(filters);
      }

      if (dataFilters['dtInicial'].length > 0) {
        dataFilters['dtInicial'] = `${dataFilters['dtInicial']}T00:00:00`;
      }

      if (dataFilters['dtFinal'].length > 0) {
        dataFilters['dtFinal'] = `${dataFilters['dtFinal']}T23:59:59`;
      }

      const subsidiary = sessionStorage.getItem('subsidiary');

      const filtersCurrent = {
        dtInicial: `${initialDate}T00:00:00`,
        dtFinal: `${finalDate}T23:59:59`,
        romaneiosPendentes: dataFilters['romaneiosPendentes'],
        romaneiosFinalizados: dataFilters['romaneiosFinalizados'],
        nomeCliente: dataFilters['nomeCliente'],
        placa: dataFilters['placa'],
        nomeMotorista: dataFilters['nomeMotorista'],
        numeroCte: dataFilters['numeroCte'],
        idReferencia: dataFilters['idReferencia'],
        operadorLogistico: dataFilters['operadorLogistico'],
      };

      const dataCargo = {
        filter:
          dataFilters['dtInicial'].length > 0 ||
          dataFilters['dtFinal'].length > 0
            ? dataFilters
            : filtersCurrent,
        page: page ? page : currentPage ? currentPage : 0,
        orders: [
          {
            field: 'data',
            direction: 'ASC',
          },
        ],
        size: perPage || 10,
      };

      const response = await api.post(
        `/delivery/kpis/tc/${subsidiary}`,
        dataCargo
      );

      setTotalElements(response.data.totalPages);

      let cargoData: any = [];

      if (response.data.content.length > 0) {
        cargoData = response.data.content.map((c: any, index: number) => {
          //
          return {
            romaneio: {
              ...c.romaneio,
              conhecimentoAtual: null,
              conhecimentoSeguinte: null,
              possuiAlertasNaoLidos: false,
            },
          };
        });
      }
      setCargo(cargoData);
      sessionStorage.setItem(
        'dataTable',
        JSON.stringify(response.data.content)
      );

      cargoData.map(async (itemCargo: any, index: number) => {
        const getLoadCargo = await getLoadDataCargo(
          itemCargo.romaneio.id,
          cargoData,
          index
        );
        const getLoadDriverInfoData = await getLoadDriverInfo(
          itemCargo.romaneio.motorista.id,
          cargoData,
          index
        );
      });

      // getLoadDataCargo(cargoData);

      // await getLoadDataCargo(response.data.content);

      setLoading(false);

      if (response.data.content.length >= 1) {
        setSubsidiaryPosition({
          lat: response.data.content[0].filial.endereco.latitude,
          lng: response.data.content[0].filial.endereco.longitude,
        });
      }

      setCurrentSubsidiary(null);

      setCurrentSubsidiary(subsidiary);
      setCurrentPage(0);
    } catch (err) {}
  }, []);

  const getKpisData = useCallback(async () => {
    try {
      const filters = sessionStorage.getItem('data::shipment');

      let dataFiltersKpi: any = null;

      if (filters) {
        dataFiltersKpi = JSON.parse(filters);
      }

      if (dataFiltersKpi['dtInicial'].length > 0) {
        dataFiltersKpi['dtInicial'] = `${dataFiltersKpi['dtInicial']}T00:00:00`;
      }

      if (dataFiltersKpi['dtFinal'].length > 0) {
        dataFiltersKpi['dtFinal'] = `${dataFiltersKpi['dtFinal']}T23:59:59`;
      } else if (dataFiltersKpi['dtInicial'].length > 0) {
        dataFiltersKpi['dtFinal'] = `${finalDate}T23:59:59`;
      }

      const subsidiary = sessionStorage.getItem('subsidiary');

      const filtersCurrent = {
        dtInicial: `${initialDate}T00:00:00`,
        dtFinal: `${finalDate}T23:59:59`,
      };

      const dataCargo = {
        filter:
          dataFiltersKpi['dtInicial'].length > 0 ||
          dataFiltersKpi['dtFinal'].length > 0
            ? dataFiltersKpi
            : filtersCurrent,
        page: currentPage || 0,
        size: perPage,
      };

      const response = await api.post(
        `/delivery/kpis/findByFilters/${subsidiary}`,
        dataCargo
      );

      if (response.data) {
        setKpis(response.data);
        sessionStorage.setItem('kpis', JSON.stringify(response.data));
      }
    } catch (err) {}
  }, []);

  const getUser = useCallback(async () => {
    try {
      setPermission(userData.grupo.permissoes);
    } catch (err) {}
  }, []);

  useEffect(() => {
    getCargoData();
    getKpisData();
  }, [getCargoData, getKpisData]);


  useEffect(() => {
    if (!sessionStorage.getItem('dataTable')) {
      sessionStorage.setItem('dataTable', JSON.stringify([]));
    }
    if (!sessionStorage.getItem('component::shipment')) {
      sessionStorage.setItem(
        'component::shipment',
        JSON.stringify(componentShipment)
      );
    }
    if (!sessionStorage.getItem('kpis')) {
      sessionStorage.setItem(
        'kpis',
        JSON.stringify({
          totalVeiculos: 0,
          totalEntregas: 0,
          totalVeiculosBase: 0,
          totalVeiculosBasePerc: 0,
          totalVeiculosRetornandoBase: 0,
          totalVeiculosRetornandoBasePerc: 0,
          totalCanceladas: 0,
          totalCanceladasPerc: 0,
          totalPendentes: 0,
          totalPendentesPerc: 0,
          totalEfetivadas: 0,
          totalEfetivadasPerc: 0,
          totalEmAtendimento: 0,
          totalInsucessos: 0,
          totalInsucessosPerc: 0,
          totalEmAtendimentoPerc: 0,
          totalVeiculosSemComunicacao: 0,
          totalVeiculosSemComunicacaoPerc: 0,
          totalAtendimentosValidos: 0,
          totalAtendimentosValiosPerc: 0,
          totalAtendimentosRealizados: 0,
        })
      );
    }
  }, []);

  useEffect(() => {
    getSubsidiaryList();

    getUser();
  }, [getSubsidiaryList, getUser]);

  return (
    <React.Fragment>
      <TopBar
        listSubsidiary={subsidiaryList}
        onChangeSubsidiary={() => {
          getCargoData(currentPage);
          getKpisData();
          setAlertUpdate(!alertUpdate);
        }}
      />

      {/* <DetailsCTE /> */}

      {visibleDetailsCTE && (
        <DetailsCTE
          onClear={() => setVisibleDetailsCTE(!visibleDetailsCTE)}
          data={selectedRow}
        />
      )}

      {visibleViewMap && (
        <GetRoutes
          onClear={() => setVisibleViewMap(!visibleViewMap)}
          driverId={motoristaIdMap}
          cargoId={idRomaneioMap}
        />
      )}
      {visibleChangeDriver && (
        <ChangeDriver
          onClear={() => setVisibleChangeDriver(!visibleChangeDriver)}
          idRomaneio={idRomaneioMap}
          onChangeCargo={() => {
            getCargoData(currentPage);
          }}
        />
      )}
      {visibleCancelCargo && (
        <CancelCargo
          onClear={() => setVisibleCancelCargo(!visibleCancelCargo)}
          idRomaneio={idRomaneioMap}
          onLoadCargo={() => {
            getCargoData(currentPage);
          }}
        />
      )}
      {visibleListKnowledge && (
        <ListAttendances
          onClear={() => setVisibleListKnowledge(!visibleListKnowledge)}
          idRomaneio={idRomaneioMap}
          isAlert={false}
          onPrintCargo={(cargoId: any) => {
            // setCargoPrint(cargoId);
            // setIsPrintCargo(!isPrintCargo);
          }}
          alertCargo={false}
        />
      )}
      {visibleUploadCte && (
        <UploadCTE
          idRomaneio={idRomaneioMap}
          onClear={() => setVisibleUploadCte(!visibleUploadCte)}
        />
      )}
      {/* <PieChart /> */}

      <CustomFilterDialog
        open={modalFilters}
        onClose={() => setModalFilters(!modalFilters)}
        data={sessionStorage.getItem('component::shipment')}
        dataComponent={sessionStorage.getItem('data::shipment')}
        component="data::shipment"
        onSendFilter={() => {
          setModalFilters(!modalFilters);
          getCargoData(currentPage);
          getKpisData();
        }}
        onCancel={() => {}}
        onFilter={() => {}}
      >
        <React.Fragment>
          <></>
        </React.Fragment>
      </CustomFilterDialog>

      <div className="headerTower">
        <div style={{ marginRight: '0.612rem' }}>
          <CustomComponent
            title="Veículos"
            disabledOptions
            onFilter={() => {}}
            onUpdate={() => {}}
            color="#FC8800"
            styles={{
              width: '32rem',
              height: '230px',
            }}
          >
            <div style={{ display: 'flex', height: '12rem' }}>
              <KpiDataVehicle data={kpis} />
            </div>
          </CustomComponent>
        </div>

        <div style={{ marginRight: '0.612rem' }}>
          <CustomComponent
            title="Atendimentos"
            disabledOptions
            onFilter={() => {}}
            onUpdate={() => {}}
            styles={{
              width: '27rem',
              height: '230px',
            }}
            color="#6369D1"
          >
            <div style={{ display: 'flex', height: '12rem' }}>
              <KpiDataCargo data={kpis} />
            </div>
          </CustomComponent>
        </div>
        {/* <Kpi /> */}
        <div>
          <Alert updateAlert={alertUpdate} />
        </div>
      </div>
      <div className="cargoContainer">
        <CustomComponent
          title="Romaneios"
          onFilter={() => setModalFilters(!modalFilters)}
          onUpdate={() => getCargoData(currentPage)}
          styles={{
            marginTop: '1.214rem',
            marginRight: '1rem',
            position: 'relative',
          }}
          loading={loading}
          animationResource={<CargoAnimation />}
          color="#EA004C"
        >
          <>
            <div style={{ position: 'absolute', top: 20, marginLeft: '1rem' }}>
              <CustomFilter
                data={sessionStorage.getItem('component::shipment')}
                dataComponent={sessionStorage.getItem('data::shipment')}
                component="data::shipment"
                onSendFilter={() => {
                  // setModalFilters(!modalFilters);
                  getCargoData(currentPage);
                  getKpisData();
                }}
                onCancel={() => {}}
                onFilter={() => {}}
              >
                <></>
              </CustomFilter>
            </div>

            <Table
              dataTable={cargo}
              totalPages={totalElements}
              onChangeTotalPages={(numPages: number) => {
                setCurrentPage(0);
                getCargoData(0, numPages);
                setPerPage(numPages);
              }}
              onPagination={(page: number) => {
                setCurrentPage(page);
                getCargoData(page, perPage);
              }}
              currentPage={currentPage}
              onViewRoute={(
                romaneioId: number,
                idDriver: number,
                cargo: any
              ) => {
                // setSubsidiaryPosition({
                //   lat: cargo.filial.endereco.latitude,
                //   lng: cargo.filial.endereco.longitude,
                // });
                setIdRomaneio(romaneioId);
                setMotoristaIdMap(idDriver);
                setVisibleViewMap(!visibleViewMap);
              }}
              onChangeDriverRoute={(romaneioId: number) => {
                setIdRomaneio(romaneioId);
                setVisibleChangeDriver(!visibleChangeDriver);
              }}
              onCancelCargoRoute={(romaneioId: number) => {
                setIdRomaneio(romaneioId);
                setVisibleCancelCargo(!visibleCancelCargo);
              }}
              onListKnowledge={(romaneioId: number) => {
                setIdRomaneio(romaneioId);
                setVisibleListKnowledge(!visibleListKnowledge);
              }}
              onUpload={(romaneioId: number) => {
                setIdRomaneio(romaneioId);
                setVisibleUploadCte(!visibleUploadCte);
              }}
              onDetails={(data: any) => {
                setSelectedRow(data);

                setVisibleDetailsCTE(!visibleDetailsCTE);
              }}
            />
          </>
        </CustomComponent>
      </div>
    </React.Fragment>
  );
};

export default ControlTowerNew;
