import { Tooltip } from '@mui/material';
import IconLocalization from 'assets/images/assinatura.svg';
import IconDeliveryCancel from 'assets/images/cancelIcon.svg';
import IconDeliveryNoPrazo from 'assets/images/deliveryPrazo.svg';
import IconRegistraAtendimentos from 'assets/images/iconRegistraAtendimentos.svg';
import IconArrowDown from 'assets/images/knowledge/arrowDown.svg';
import IconArrowUp from 'assets/images/knowledge/arrowUp.svg';
import IconDeliveryDone from 'assets/images/knowledge/deliveryDone.svg';
import IconDeliveryOverdue from 'assets/images/knowledge/deliveryOverdue.svg';
import IconDocument from 'assets/images/knowledge/document.svg';
import IconPhoto from 'assets/images/knowledge/photo.svg';
import maps from 'assets/images/mapIcon.svg';
import skipServiceIcon from 'assets/images/skipServiceIcon.svg';
import DetailsDelivery from 'components/DetailsDelivery';
import { format } from 'date-fns';
import { pt, ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ModalReceiver from './components/ModalReceiver';
import './styles.scss';

import ModalOccurrence from 'components/CardKnowledge/ModalOccurrence';
import { FrontendNotification } from 'components/Notification';
import { NotificationType } from 'core/common/Notification';
import GetRoutes from 'pages/ControlTower/GetRoutes';
import { Button, Modal, ModalFooter, ModalHeader } from 'reactstrap';
import api from 'services/api';
import permissionMenu from 'shared/Permission';
import { numberToCurrencyBRL } from 'util/format';
import { Cargo, CargoStatus } from '../../interfaces/types';
import ModalSignature from './components/ModalSignature';

interface Props {
  data: Cargo;
  index?: number;
  onReloadCargo: () => void;
}

const CardKnowledge: React.FC<Props> = (props: Props) => {
  const [isViewStage, setIsViewStage] = useState<boolean>(false);
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [collapseId, setCollapseId] = useState(0);
  const [isCollapse, setIsCollapse] = useState(true);
  const [isOpenReceiver, setIsOpenReceiver] = useState<boolean>(false);
  const [isOpenPhoto, setIsOpenPhoto] = useState<boolean>(false);
  const [isOpenSignature, setIsOpenSignature] = useState<boolean>(false);
  const [isEmptyMedia, setIsEmptyMedia] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [linkTracking, setLinkTracking] = useState<string>('');
  const [occurrenceModal, setOccurrenceModal] = useState<boolean>(false);
  const [confirmFinishCargo, setConfirmFinishCargo] = useState<boolean>(false);
  const [isViewRoute, setIsViewRoute] = useState<boolean>(false);
  const [selectedOccurrences, setSelectedOccurrences] = useState();
  const [permission, setPermission] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [positionMap, setPositionMap] = useState({
    romaneioId: '',
    lat: '',
    lng: '',
  });

  const { t } = useTranslation();

  const userData = useSelector((state: any) => state.auth.user);

  const renderIconStatus = (status: string) => {
    switch (status) {
      case CargoStatus.ENTREGUE:
        return <img src={IconDeliveryDone} alt="" className="tw-w-4 tw-h-4" />;
        break;
      case CargoStatus.ATRASADO:
        return (
          <img src={IconDeliveryOverdue} alt="" className="tw-w-4 tw-h-4" />
        );
        break;
      case CargoStatus.NO_PRAZO:
        return (
          <img src={IconDeliveryNoPrazo} alt="" className="tw-w-4 tw-h-4" />
        );
        break;
      case CargoStatus.CANCELADO:
        return (
          <img src={IconDeliveryCancel} alt="" className="tw-w-4 tw-h-4" />
        );
        break;

      default:
        return <img src={IconDeliveryDone} alt="" className="tw-w-4 tw-h-4" />;
        break;
    }
  };

  const renderColorStatus = (status: CargoStatus) => {
    console.log(status);
    console.log(props.data.numero);
    let colorStatus = '#D62828';
    switch (status) {
      case CargoStatus.ATRASADO:
        colorStatus = '#F77F00';
        break;
      case CargoStatus.ENTREGUE:
        colorStatus = '#08B639';
        break;
      case CargoStatus.NO_PRAZO:
        colorStatus = '#6369D1';
      default:
        colorStatus = '#D62828';
        break;
    }
    return colorStatus;
  };

  const renderTextStatus = (status: CargoStatus | string) => {
    let statusEntrega = status;

    switch (status) {
      case CargoStatus.ATRASADO:
        statusEntrega = `${t('knowledge.delayed')}`;
        break;
      case CargoStatus.NO_PRAZO:
        statusEntrega = `${t('knowledge.onTime')}`;
        break;
      case CargoStatus.CANCELADO:
        statusEntrega = status;
        break;

      default:
        statusEntrega = status;
        break;
    }

    return statusEntrega;
  };

  const toggle = () => setTooltipOpen(!tooltipOpen);

  const requestModal = useCallback(async (cargoId: number) => {
    const response = await api.post(
      '/delivery/conhecimentosMidias/findByFilters',
      {
        filters: [
          {
            field: 'conhecimento.id',
            value: cargoId,
            operation: 'EQUAL',
          },
          {
            field: 'etapa',
            operation: 'IS_NULL',
          },
          {
            field: 'ocorrencia',
            operation: 'IS_NULL',
          },
        ],
      }
    );
    const getSignatures = response.data.filter(
      (item: any) => item.tipo === 'ASSINATURA'
    );

    if (getSignatures.length > 0 && getSignatures[0].url.length > 0) {
      setIsOpenSignature(!isOpenSignature);
      setImageUrl(getSignatures[0].url);
      // setTypePhoto('Assinatura');
      setIsEmptyMedia(false);
    } else {
      setIsOpenSignature(!isOpenSignature);
      setIsEmptyMedia(true);

      // setTypePhoto('Assinatura');
    }
  }, []);

  const requestModalPhoto = useCallback(async (cargoId: number) => {
    const response = await api.post(
      '/delivery/conhecimentosMidias/findByFilters',
      {
        filters: [
          {
            field: 'conhecimento.id',
            value: cargoId,
            operation: 'EQUAL',
          },
          {
            field: 'etapa',
            operation: 'IS_NULL',
          },
          {
            field: 'ocorrencia',
            operation: 'IS_NULL',
          },
        ],
      }
    );

    const getPhoto = response.data.filter((item: any) => item.tipo === 'FOTO');

    if (getPhoto.length > 0 && getPhoto[0].url.length > 0) {
      const photo = response.data.find((item: any) => item.tipo === 'FOTO');

      setImageUrl(getPhoto[0].url);
      setIsEmptyMedia(false);
      // setTypePhoto('Foto');
      setIsOpenPhoto(!isOpenPhoto);
    } else {
      // setTypePhoto('Foto');
      setIsEmptyMedia(true);
    }
  }, []);

  const onFinishCargo = useCallback(async (selectedOccurrence, baixaLote) => {
    try {
      setLoading(true);
      const date = format(new Date(), 'yyyy-MM-dd', {
        locale: ptBR,
      });
      const hour = format(new Date(), 'HH:mm:ss', {
        locale: ptBR,
      });
      const data = {
        dataHora: `${date}T${hour}`,
        numeroOc: selectedOccurrence.numero,
        baixaLote: baixaLote,
      };

      const response = await api.patch(
        `/delivery/conhecimentos/registraAtendimentoManual/${
          baixaLote && baixaLote === true
            ? selectedOccurrence.cargoId
            : selectedOccurrence.id
        }`,
        data
      );

      props.onReloadCargo();
      setLoading(false);

      FrontendNotification(
        t('listAttendances.finishKnowledgeSuccess'),
        NotificationType.SUCCESS
      );
    } catch {
      setLoading(false);
    }
  }, []);

  const getParameters = useCallback(async () => {
    try {
      const data = {
        filters: [],
      };
      const response = await api.post(
        '/cadastro/parametrosWeb/findByFilters',
        data
      );

      setParameters(response.data);
    } catch (err) {}
  }, []);

  const getUser = useCallback(async () => {
    try {
      setPermission(userData.grupo.permissoes);
    } catch (err) {}
  }, []);

  useEffect(() => {
    getUser();
    getParameters();
  }, [getUser, getParameters]);



  return (
    <div
      className="tw-mt-4 tw-max-w-sm tw-mx-auto tw-shadow-md tw-bg-white rounded-lg shadow-md overflow-hidden tw-mr-3"
      style={{
        borderTop: `0.75rem solid ${renderColorStatus(
          props.data.statusEntrega
        )}`,
      }}
    >
      <Modal
        isOpen={confirmFinishCargo}
        toggle={() => setConfirmFinishCargo(!confirmFinishCargo)}
        style={{ minWidth: '50%' }}
      >
        <ModalHeader>
          <div className="tw-flex tw-items-center tw-justify-center tw-w-full">
            <span>Baixa Portal</span>
          </div>
        </ModalHeader>

        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              setOccurrenceModal(!occurrenceModal);
              onFinishCargo(selectedOccurrences, true);
              setConfirmFinishCargo(!confirmFinishCargo);
            }}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="knowledge.confirmCloseAllCargo" />
          </Button>{' '}
          <Button
            style={{ background: 'rgb(16, 132, 28)' }}
            onClick={() => {
              setOccurrenceModal(!occurrenceModal);
              onFinishCargo(selectedOccurrences, false);
              setConfirmFinishCargo(!confirmFinishCargo);
            }}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="knowledge.confirmCloseCargo" />
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setConfirmFinishCargo(!confirmFinishCargo);
            }}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="knowledge.cancelCloseCargo" />
          </Button>
        </ModalFooter>
      </Modal>
      {occurrenceModal && (
        <ModalOccurrence
          onClear={() => setOccurrenceModal(!occurrenceModal)}
          onConfirm={(selectedOccurrence: any) => {
            setSelectedOccurrences({
              ...selectedOccurrence,
              id: props.data.id,
              cargoId: props.data.romaneio.id,
            });
            setConfirmFinishCargo(true);
          }}
          typeOcorrence={props.data.tipo}
        />
      )}

      {isViewRoute && (
        <GetRoutes
          onClear={() => setIsViewRoute(!isViewRoute)}
          idRomaneio={positionMap.romaneioId}
          motoristaId={String(props.data.romaneio.motorista.id)}
          subsidiaryPosition={{
            lat: positionMap.lat,
            lng: positionMap.lng,
          }}
          // longitude={positionMap.longitude}
        />
      )}
      {isViewStage && (
        <DetailsDelivery
          cargoType={props.data.tipo}
          cargoNumber={props.data.numero}
          cargoId={props.data.id}
          onClear={() => setIsViewStage(!isViewStage)}
        />
      )}

      {isOpenReceiver && (
        <ModalReceiver
          title={t('knowledge.receiver')}
          data={props.data}
          onClear={() => setIsOpenReceiver(!isOpenReceiver)}
        />
      )}

      {isOpenSignature && (
        <ModalSignature
          isEmpty={isEmptyMedia}
          title="Assinatura"
          type="ASSINATURA"
          urlImage={imageUrl}
          onClear={() => setIsOpenSignature(!isOpenSignature)}
        />
      )}

      {isOpenPhoto && (
        <ModalSignature
          isEmpty={isEmptyMedia}
          title="Foto"
          type="FOTO"
          urlImage={imageUrl}
          onClear={() => setIsOpenPhoto(!isOpenPhoto)}
        />
      )}

      {/* <ModalOccurrence onClear={() => {}} onConfirm={() => {}} typeOcorrence={props.data.tipo} /> */}

      <div className="tw-w-full tw-p-2">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center">
            <div className="tw-w-6 tw-h-6 tw-bg-[#6369D1] tw-rounded-full tw-flex tw-justify-center tw-text-sm tw-text-[#fff] tw-pt-[2px]">
              {props.data.ordemEntrega}
            </div>
            <div className="tw-flex tw-flex-col tw-ml-2">
              <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                {props.data.tipo === 'ENTREGA' ? (
                  <Trans i18nKey="knowledge.deliveryNumber" />
                ) : (
                  <Trans i18nKey="knowledge.collectionNumber" />
                )}
              </span>
              <div className="tw-flex tw-items-center tw-z-50">
                {props.data.dataBaixaApp === null && (
                  <Tooltip
                    title={t('listAttendances.messageTooltipFinishKnowledge')}
                    placement="top"
                  >
                    <img
                      src={IconRegistraAtendimentos}
                      className="tw-cursor-pointer"
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'controlTower.baixa-manual'
                          )
                        ) {
                          setOccurrenceModal(true);
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                    />
                  </Tooltip>
                )}
                {/* <CustomTooltip
                message={t('listAttendances.messageTooltipFinishKnowledge')}
                icon={IconRegistraAtendimentos}
                onClick={() => {
                  //
                }}
              /> */}
                <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer tw-ml-1">
                  <Tooltip title={props.data.numero} placement="top">
                    <span>{props.data.numero}</span>
                  </Tooltip>
                </span>
              </div>
            </div>
          </div>
          <div className="tw-flex tw-flex-col tw-ml-2">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              <Trans i18nKey="knowledge.status" />
            </span>
            <span className="tw-flex tw-items-center tw-text-sm">
              {renderIconStatus(props.data.statusEntrega)}
              {/* <img src={IconDeliveryDone} alt="" className="tw-w-4 tw-h-4" /> */}
              <span
                className={`tw-max-w-[8ch] tw-text-sm  tw-ml-1 tw-font-bold tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap`}
              >
                {props.data.ocorrencia !== null ? (
                  <Tooltip
                    placement="top"
                    title={`${
                      props.data.ocorrencia.numero
                    } - ${props.data.ocorrencia.descricao.toLowerCase()}`}
                  >
                    <span
                      className="tw-cursor-pointer"
                      style={{
                        color: renderColorStatus(props.data.statusEntrega),
                      }}
                    >
                      {`${props.data.ocorrencia.numero} -
                        ${props.data.ocorrencia.descricao.toLowerCase()}`}
                    </span>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={renderTextStatus(props.data.statusEntrega)}
                    placement="top"
                  >
                    <span
                      className={`tw-text-sm tw-font-bold tw-cursor-pointer`}
                      style={{
                        color: `${renderColorStatus(props.data.statusEntrega)}`,
                      }}
                    >
                      {renderTextStatus(props.data.statusEntrega)}
                    </span>
                  </Tooltip>
                )}
                {/* <Trans i18nKey="knowledge.delayed" /> */}
              </span>
            </span>
          </div>
          

          <div className="tw-flex tw-flex-col tw-ml-2">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              <Trans i18nKey="knowledge.eta" />
            </span>
            <Tooltip
              title={
                props.data.eta !== null && props.data.eta.length > 0
                  ? format(new Date(props.data.eta), 'dd/MM/yyyy HH:mm', {
                      locale: pt,
                    })
                  : '----'
              }
              placement="top"
            >
              <span className="tw-max-w-[12ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.eta !== null && props.data.eta.length > 0
                  ? format(new Date(props.data.eta), 'dd/MM/yyyy HH:mm', {
                      locale: pt,
                    })
                  : '----'}
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-ml-1">
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              <Trans i18nKey="knowledge.client" />
            </span>
            <Tooltip title={props.data.cliente.nome}>
              <span className="tw-max-w-[25ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.cliente.nome}
              </span>
            </Tooltip>
          </div>
          <div className="tw-flex tw-flex-col tw-ml-4">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              Pulou Atend.
            </span>
            <Tooltip
              title={
                props.data.pulaAtendimento ? (
                  <img src={skipServiceIcon} className="tw-w-6 tw-h-6" />
                ) : (
                  '----'
                )
              }
              placement="top"
            >
              <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.pulaAtendimento ? (
                  <img src={skipServiceIcon} className="tw-w-6 tw-h-6" />
                ) : (
                  '----'
                )}
              </span>
            </Tooltip>
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-ml-1">
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              Etapa Atual
            </span>
            <Tooltip
              title={
                props.data.etapa !== null ? props.data.etapa.descricao : '----'
              }
              placement="top"
            >
              <span className="tw-max-w-[15ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.etapa !== null
                  ? props.data.etapa.descricao
                  : '----'}
              </span>
            </Tooltip>

            <span
              className="tw-text-sm tw-font-bold tw-text-[#EA004C] tw-cursor-pointer"
              onClick={() => setIsViewStage(!isViewStage)}
            >
              Ver etapas
            </span>
          </div>
          <div className="tw-flex tw-flex-col tw-ml-4 tw-mb-4">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              Check-in
            </span>
            <Tooltip
              title={props.data.checkIn !== null ? props.data.checkIn : '----'}
              placement="top"
            >
              <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.checkIn !== null ? props.data.checkIn : '----'}
              </span>
            </Tooltip>
          </div>
          <div className="tw-flex tw-flex-col tw-ml-4 tw-mb-4">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              Check-out
            </span>
            <Tooltip
              title={
                props.data.checkOut !== null ? props.data.checkOut : '----'
              }
              placement="top"
            >
              <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.checkOut !== null ? props.data.checkOut : '----'}
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-between tw-mt-3">
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              <Trans i18nKey="knowledge.deliveryTime" />
            </span>
            <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
              {props.data.dataBaixaApp !== null
                ? format(
                    new Date(props.data.dataBaixaApp),
                    'dd/MM/yyyy HH:mm',
                    {
                      locale: pt,
                    }
                  )
                : '----'}
            </span>
          </div>

          <div className="tw-flex tw-flex-col tw-ml-4">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              Rota
            </span>
            <Tooltip
              title={
                props.data.rota !== null && props.data.rota.length > 0
                  ? props.data.rota
                  : '----'
              }
              placement="top"
            >
              <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                {props.data.rota !== null && props.data.rota.length > 0
                  ? props.data.rota
                  : '----'}
              </span>
            </Tooltip>
          </div>
          
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              <Trans i18nKey="knowledge.idReference" />
            </span>
            <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
              {props.data.idReferencia}
            </span>
          </div>
        </div>
        <div className="tw-bg-[#D2D2D2] tw-w-full tw-h-[1px] tw-mt-2" />
        <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-ml-1">
          <div className="tw-flex tw-flex-col">
            <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
              Endereço de atendimento
            </span>
            <div className="tw-flex tw-items-center tw-mt-2">
              <img
                src={maps}
                width="20"
                alt=""
                className="tw-cursor-pointer"
                onClick={() => {
                  if (
                    props.data.latitude !== null &&
                    props.data.longitude !== null
                  ) {
                    setPositionMap({
                      romaneioId: String(props.data.romaneio.id),
                      lat: props.data.latitude,
                      lng: props.data.longitude,
                    });
                  } else {
                    setPositionMap({
                      romaneioId: String(props.data.romaneio.id),
                      lat: String(props.data.endereco.latitude),
                      lng: String(props.data.endereco.longitude),
                    });
                  }
                  setIsViewRoute(!isViewRoute);
                }}
              />
              <div className="tw-w-[1px] tw-h-6 tw-ml-2 tw-bg-[#D2D2D2]" />
              <span className="tw-ml-4 tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                <Tooltip
                  title={`${props.data.endereco.logradouro}, ${props.data.endereco.numero} ${props.data.endereco.bairro}, ${props.data.endereco.cidade}, ${props.data.endereco.uf}`}
                  placement="top"
                >
                  <span>
                    {`${props.data.endereco.logradouro}, ${props.data.endereco.numero} ${props.data.endereco.bairro}, ${props.data.endereco.cidade}, ${props.data.endereco.uf}`}
                  </span>
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
        <div className="tw-bg-[#D2D2D2] tw-w-full tw-h-[1px] tw-mt-3" />
        {props.data.id !== collapseId && isCollapse && (
          <div
            className="tw-flex tw-w-full tw-justify-center tw-items-center tw-mt-2 tw-cursor-pointer"
            onClick={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              setCollapseId(props.data.id);
              setIsCollapse(!isCollapse);
            }}
          >
            <span className="tw-text-sm tw-text-[#EA004C] tw-font-bold">
              <Trans i18nKey="knowledge.moreDetails" />
            </span>
            <img
              src={IconArrowDown}
              alt=""
              className="icon-custom fa left-margin"
            />
          </div>
        )}

        {props.data.id == collapseId && isCollapse == false && (
          <>
            <div className="tw-flex tw-justify-between tw-mt-3">
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.cityFrom" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.filialOrigem.endereco.uf} -{' '}
                  {props.data.filialOrigem.endereco.cidade}
                </span>
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.cityTo" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.filialDestino.endereco.uf} -{' '}
                  {props.data.filialDestino.endereco.cidade}
                </span>
              </div>
            </div>

            <div className="tw-flex tw-justify-between tw-mt-3">
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.typeTransport" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.tipoTransporte}
                </span>
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.weight" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.peso}KG
                </span>
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.volume" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.qtdVolumes}
                </span>
              </div>
            </div>

            <div className="tw-flex tw-justify-between tw-mt-3">
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.tracking" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  <Trans i18nKey="common.no" />
                </span>
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.dangerGood" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.dangerGood === true ? (
                    <Trans i18nKey="common.yes" />
                  ) : (
                    <Trans i18nKey="common.no" />
                  )}
                </span>
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  Baixa Manual
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.ocorrencia !== null &&
                  props.data.ocorrencia.baixaManual
                    ? 'Sim'
                    : 'Não'}
                  &nbsp;
                  {props.data.ocorrencia !== null &&
                    props.data.ocorrencia.baixaManual && <>-</>}
                  &nbsp;
                  {props.data.ocorrencia !== null &&
                    props.data.ocorrencia.baixaManual &&
                    'Usuário:'}
                  &nbsp;
                  {props.data.ocorrencia !== null &&
                    props.data.ocorrencia.baixaManual &&
                    userData.nome}
                </span>
              </div>
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.value" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {numberToCurrencyBRL(props.data.valor)}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-mt-3">
              <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-ml-1">
                <div className="tw-flex tw-flex-col">
                  <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                    Contratante
                  </span>
                  <Tooltip
                    title={
                      props.data.contratante !== null
                        ? props.data.contratante.pessoaJuridica?.nomeFantasia
                        : '----'
                    }
                    placement="top"
                  >
                    <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                      {props.data.contratante !== null
                        ? props.data.contratante.pessoaJuridica?.nomeFantasia
                        : '----'}
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className="tw-flex tw-flex-col tw-mt-3">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  Tipo Atividade
                </span>
                <Tooltip
                  title={
                    props.data.tipoAtividade !== null
                      ? props.data.tipoAtividade.nome
                      : '----'
                  }
                  placement="top"
                >
                  <span className="tw-max-w-[9ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                    {props.data.tipoAtividade !== null
                      ? props.data.tipoAtividade.nome
                      : '----'}
                  </span>
                </Tooltip>
              </div>
            </div>

            <div className="tw-flex tw-justify-between tw-mt-3"></div>

            <div className="tw-bg-[#D2D2D2] tw-w-full tw-h-[1px] tw-mt-3" />

            <div className="tw-w-full tw-flex tw-justify-between tw-items-center tw-mt-3">
              <div className="tw-w-full tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  Exigências
                </span>
                <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
                  <div>
                    <span
                      className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer"
                      onClick={() => {
                        setIsOpenReceiver(!isOpenReceiver);
                      }}
                    >
                      <img
                        src={IconDocument}
                        style={{
                          cursor: 'pointer',
                          marginRight: 10,
                        }}
                        alt=""
                        className="icon-custom fa"
                      />
                      <Trans i18nKey="knowledge.receiver" />
                    </span>
                  </div>
                  <div>
                    <span
                      className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer"
                      onClick={() => requestModal(props.data.id)}
                    >
                      <img
                        src={IconLocalization}
                        style={{
                          cursor: 'pointer',
                          marginRight: 10,
                        }}
                        alt=""
                        className="icon-custom fa"
                      />
                      <Trans i18nKey="knowledge.requirements.localization" />
                    </span>
                  </div>
                  <div>
                    <span
                      className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer"
                      onClick={() => requestModalPhoto(props.data.id)}
                    >
                      <img
                        src={IconPhoto}
                        style={{
                          cursor: 'pointer',
                          marginRight: 10,
                        }}
                        alt=""
                        className="icon-custom fa"
                      />
                      <Trans i18nKey="knowledge.requirements.photo" />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="tw-bg-[#D2D2D2] tw-w-full tw-h-[1px] tw-mt-3" />

            <div className="tw-flex tw-justify-between tw-mt-3">
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                  <Trans i18nKey="knowledge.linkTracking" />
                </span>
                <span className="tw-max-w-[30ch] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-text-sm tw-text-[#003049] tw-cursor-pointer">
                  {props.data.etapa !== null ? (
                    <>
                      {props.data.urlTracklink !== null ? (
                        <a
                          className="tw-cursor-pointer tw-text-[#0000ff]"
                          target="_blank"
                          href={linkTracking}
                          onClick={() => {
                            setLinkTracking(
                              `${props.data.urlTracklink}?lang=pt-BR`
                            );
                          }}
                          rel="noreferrer"
                        >
                          {props.data.urlTracklink}
                        </a>
                      ) : (
                        '----'
                      )}
                    </>
                  ) : (
                    '----'
                  )}
                </span>
              </div>
            </div>

            <div className="tw-bg-[#D2D2D2] tw-w-full tw-h-[1px] tw-mt-3" />

            <div
              className="tw-flex tw-w-full tw-justify-center tw-items-center tw-mt-2 tw-cursor-pointer"
              onClick={(e: any) => {
                e.preventDefault();
                e.stopPropagation();
                setCollapseId(0);
                setIsCollapse(true);
              }}
            >
              <span className="tw-text-sm tw-text-[#EA004C] tw-font-bold">
                <Trans i18nKey="knowledge.lessDetail" />
              </span>
              <img
                src={IconArrowUp}
                alt=""
                className="icon-custom fa left-margin"
              />
            </div>
          </>
        )}

        {/* <h2 className="text-xl font-bold text-gray-800">{title}</h2> */}
        {/* <p className="mt-2 text-gray-600">{description}</p> */}
      </div>
    </div>
  );
};

export default CardKnowledge;
