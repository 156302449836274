import polyline from '@mapbox/polyline';
import Pagination from '@material-ui/lab/Pagination';
import TruckIcon from 'assets/images/map/truck.svg';
import pointFilial from 'assets/images/pointFilial.svg';
import { Direction, Filter, SearchOperation } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import { IPointInterest } from 'components/PointInterest/types/types';
import Loading from 'core/common/LoadingRoutering';
import Leaflet, { LatLngExpression } from 'leaflet';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { createCustomMarkerIcon } from 'components/TypePointInterest/icons';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Map, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import api from 'services/api';
import getIconVehicle from 'shared/IconVehicles';
import { Cargo } from '../ListAttendances/interfaces/types';
import Carousel from './components/Carousel';
import DetailsPointsInterest from './components/DetailsPointsInterest';
import Switch from './components/Switch';
import { ICheckinCargo, IDriverPositions } from './types/types';
import formValidator from './validators/formValidator';

interface Props {
  onClear: () => void;
  driverId: number;
  cargoId: number | string;
}

const GetRoutes: React.FC<Props> = (props: Props) => {
  const [checkedPlanning, setCheckedPlanning] = useState<boolean>(true);
  const [checkedCargoPosition, setCheckedCargoPosition] = useState<boolean>(
    true
  );
  const [checkedFinishedRoute, setCheckedFinishedRoute] = useState<boolean>(
    true
  );
  const [checkedFinishedPosition, setCheckedFinishedPosition] = useState<
    boolean
  >(true);
  const [checkedPosition, setCheckedPosition] = useState<boolean>(false);
  const [checkedPoints, setCheckedPoints] = useState<boolean>(false);

  const [listCheckinCargo, setListCheckinCargo] = useState<ICheckinCargo[]>([]);
  const [filters, setFilters] = useState<Filter[]>([]);
  const [positions, setPositions] = useState<IDriverPositions[]>([]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [radiusPointInterest, setRadiusPointInterest] = useState<string>(
    '100000'
  );
  // const [initialDate, setInitialDate] = useState<string>('');
  // const [finalDate, setFinalDate] = useState<string>('');

  const [shipment, setShipment] = useState<any>([]);
  const [cargo, setCargo] = useState([]);
  const [branchPosition, setBranchPosition] = useState<any>([]);
  const [driverPosition, setDriverPosition] = useState<any>([]);

  const [pointsPlanning, setPointsPlanning] = useState<LatLngExpression[]>([]);
  const [pointsCoarse, setPointsCoarse] = useState<string[]>([]);
  const [points, setPoints] = useState<IPointInterest[]>([]);

  const [detailsCargo, setDetailsCargo] = useState<Cargo[]>([]);
  const [detailsPointsInterest, setDetailsPointsInterest] = useState<
    IPointInterest[]
  >([]);

  const [initialPosition, setInitialPosition] = useState<any>([]);
  const [zoom, setZoom] = useState<number>(14);

  const [loading, setLoading] = useState<boolean>();

  let mapRef = useRef();

  const { t } = useTranslation();

  const handleSubmit = useCallback((startDate: any, endDate: any) => {
    const initialDate = format(startDate, "yyyy-MM-dd'T'HH:mm:ss", {
      locale: pt,
    });

    const finalDate = format(endDate, "yyyy-MM-dd'T'HH:mm:ss", {
      locale: pt,
    });

    const body = [
      {
        field: 'motorista.id',
        value: props.driverId,
        operation: SearchOperation.EQUAL,
      },
      {
        field: 'dataHora',
        value: initialDate,
        operation: SearchOperation.GREATHER_THAN_EQUAL,
      },
      {
        field: 'dataHora',
        value: finalDate,
        operation: SearchOperation.LESS_THAN_EQUAL,
      },
    ];

    setFilters(body);

    getPositions(body, currentPage);
  }, []);

  const getPolyline = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get(
        `/route/polylines/shipment/${props.cargoId}`
      );

      if (response.status === 200) {
        if (response.data && response.data.polyline !== null) {
          const routePolyline: any = polyline.decode(response.data.polyline);

          setPointsPlanning(routePolyline);
          if (response.data.polylineRealized !== null) {
            setPointsCoarse(response.data.polylineRealized);
          }
        }
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getPositionDriver = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get(
        `/delivery/motoristasPosicoes/redis/${props.driverId}`
      );

      if (response.status === 200) {
        if (response.data) {
          setDriverPosition([response.data.latitude, response.data.longitude]);
        }
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getPointInterest = useCallback(async (dataShipment, radius?: any) => {
    try {
      setLoading(true);

      const response = await api.get(
        `/delivery/pointInterest/findLocationsWithinRadius?latitude=${
          dataShipment[0].filial.endereco.latitude
        }&longitude=${
          dataShipment[0].filial.endereco.longitude
        }&radius=${radius || radiusPointInterest}`
      );

      setPoints(response.data);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getPositionCargo = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'id',
            value: props.cargoId,
            operation: SearchOperation.EQUAL,
          },
        ],
      };

      const response = await api.post(
        '/delivery/romaneios/findByFilters',
        body
      );

      if (response.status === 200) {
        if (response.data.length > 0) {
          console.log('entrou', response.data);
          setShipment(response.data);
          setBranchPosition([
            response.data[0].filial.endereco.latitude,
            response.data[0].filial.endereco.longitude,
          ]);
          if (
            response.data[0].motorista.latitude !== null &&
            response.data[0].motorista.longitude !== null &&
            response.data[0].motorista.latitude !== 0 &&
            response.data[0].motorista.longitude !== 0
          ) {
            setInitialPosition([
              response.data[0].motorista.latitude,
              response.data[0].motorista.longitude,
            ]);
          } else {
            setInitialPosition([
              response.data[0].filial.endereco.latitude,
              response.data[0].filial.endereco.longitude,
            ]);
          }
          setZoom(30);
          setCargo(response.data[0].conhecimentos);

          getPointInterest(response.data);
        }
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getPositions = useCallback(async (filters: Filter[], page?: number) => {
    try {
      setLoading(true);

      const body = {
        filters: filters,
        orders: [
          {
            field: 'dataHora',
            direction: Direction.DESC,
          },
        ],
        page: page || 0,
        size: 100,
      };

      const response = await api.post(
        '/delivery/motoristasPosicoes/pageable',
        body
      );

      setTotalPages(response.data.totalPages);
      setPositions(response.data.content);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getCheckinCargo = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'romaneio.id',
            value: props.cargoId,
            operation: SearchOperation.EQUAL,
          },
        ],
      };

      const response = await api.post(
        '/delivery/romaneiosCheckins/findByFilters',
        body
      );

      setListCheckinCargo(response.data);

      if (response.data.length > 0) {
        const body = [
          {
            field: 'motorista.id',
            value: props.driverId,
            operation: SearchOperation.EQUAL,
          },
          {
            field: 'dataHora',
            value: response.data[0].horarioInicial,
            operation: SearchOperation.GREATHER_THAN_EQUAL,
          },
        ];

        formik.setFieldValue(
          'startDate',
          format(
            new Date(response.data[0].horarioInicial),
            'yyyy-MM-dd HH:mm:ss',
            {
              locale: pt,
            }
          )
        );

        setFilters(body);
        getPositions(body, 0);
      }

      setTimeout(() => {
        setLoading(false);
      }, 2000);
    } catch {
      setLoading(false);
    }
  }, []);

  const findPointInterestById = useCallback(async (id: number) => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'id',
            value: id,
            operation: SearchOperation.EQUAL,
          },
        ],
      };

      const response = await api.post(
        '/delivery/pointInterest/findByFilters',
        body
      );

      if (response.status === 200) {
        setDetailsPointsInterest(response.data);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  function findPositionCargo(latitude: any, longitude: any) {
    let positionMultiple = cargo.filter(
      (itemPosition: Cargo) =>
        itemPosition.endereco.latitude === latitude &&
        itemPosition.endereco.longitude === longitude
    );

    return positionMultiple;
  }

  const formik = useFormik({
    initialValues: {
      startDate: '',
      endDate: '',
    },
    validationSchema: formValidator,
    onSubmit: (values: any) => {
      const startDate = new Date(values.startDate);
      const endDate = new Date(values.endDate);


      handleSubmit(startDate, endDate);
    },
  });

  const getPositionIcon = () => {
    const positionIcon = `<div style="background-color: #006CEA; width: 20px; height: 20px; border-radius: 100%;"></div>`;

    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: positionIcon,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  };

  const getPositionIconCargo = (
    number: any,
    foraRaio: boolean,
    finishedPosition: boolean
  ) => {
    let iconPosition;

    if (finishedPosition) {
      if (foraRaio) {
        iconPosition = `<div class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i class='marker-text-current-raio'>${number}º</i></div></div>`;
      } else {
        iconPosition = `<div style="border: 5px solid #08B639;" class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i style="color: #08B639;" class='marker-text-current-raio'>${number}º</i></div></div>`;
      }
    } else {
      iconPosition = `<div style="border: 5px solid #6369d1;" class=${
        number < 99
          ? 'minSizeCurrent'
          : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
      }><div class='marker-multiple-content'><i style="color: #6369d1;" class='marker-text-current'>${number}º</i></div></div>`;
    }

    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: iconPosition,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  };

  const getPositionIconCargoMultiple = (
    number: number,
    foraRaio: boolean,
    finishedPosition: boolean
  ) => {
    let iconPositionMultiple;

    if (finishedPosition) {
      if (foraRaio) {
        iconPositionMultiple = `<div class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i class='marker-text-current-raio'>+${number}</i></div></div>`;
      } else {
        iconPositionMultiple = `<div style="border: 5px solid #08B639;" class=${
          number < 99
            ? 'minSizeCurrentRaio'
            : number < 1000
            ? 'mediumSizeCurrentRaio'
            : 'maxSizeCurrentRaio'
        } style=''><div class='marker-multiple-content'><i style="color: #08B639;" class='marker-text-current-raio'>+${number}</i></div></div>`;
      }
    } else {
      iconPositionMultiple = `<div style="border: 5px solid #FC8800;" class=${
        number < 99
          ? 'minSizeCurrent'
          : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
      }><div class='marker-multiple-content'><i style="color: #FC8800;" class='marker-text-current'>+${number}</i></div></div>`;
    }

    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: iconPositionMultiple,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  };

  const pinIcon = new Leaflet.Icon({
    iconUrl:
      shipment.length === 0
        ? TruckIcon
        : getIconVehicle(
            shipment[0].veiculo.tipo,
            false,
            false,
            shipment[0].statusEntrega
          ),
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  const PointBranch = new Leaflet.Icon({
    iconUrl: pointFilial,
    iconSize: [90, 90],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  useEffect(() => {
    getPositionCargo();
    getPositionDriver();
    getPolyline();
  }, [getPositionCargo, getPositionDriver, getPolyline]);

  return (
    <CustomModal
      isOpen
      label={t('controlTower.cargoMap.title')}
      isClose={props.onClear}
      styles={{ minWidth: '90%' }}
    >
      <div className="tw-w-full tw-h-full">
        <Loading loading={loading} />
        <div className="tw-flex tw-items-center tw-w-full">
          <Switch
            onChecked={() => setCheckedPlanning(!checkedPlanning)}
            checked={checkedPlanning}
            type=""
            title={t('controlTower.cargoMap.switch.planning')}
          />
          <Switch
            onChecked={() => setCheckedCargoPosition(!checkedCargoPosition)}
            checked={checkedCargoPosition}
            type=""
            title={t('controlTower.cargoMap.switch.cargoPosition')}
          />
          <Switch
            onChecked={() => setCheckedFinishedRoute(!checkedFinishedRoute)}
            checked={checkedFinishedRoute}
            type="coarse"
            title={t('controlTower.cargoMap.switch.finishedRoute')}
          />
          <Switch
            onChecked={() =>
              setCheckedFinishedPosition(!checkedFinishedPosition)
            }
            checked={checkedFinishedPosition}
            type="coarse"
            title={t('controlTower.cargoMap.switch.finishedPosition')}
          />
          <Switch
            onChecked={() => {
              if (!checkedPosition) {
                getCheckinCargo();
              }

              setCheckedPosition(!checkedPosition);
            }}
            checked={checkedPosition}
            type="position"
            title={t('controlTower.cargoMap.switch.position')}
          />
          <Switch
            onChecked={() => setCheckedPoints(!checkedPoints)}
            checked={checkedPoints}
            type="fence"
            title={t('controlTower.cargoMap.switch.points')}
          />
        </div>
        <div className="tw-w-full tw-h-full tw-flex">
          <div className="tw-w-full tw-h-full">
            {initialPosition.length > 0 && (
              <Map
                ref={(m: any) => {
                  mapRef = m;
                }}
                center={initialPosition}
                zoom={zoom}
                zoomControl
                className="tw-max-h-[32rem] tw-cursor-pointer"
              >
                <TileLayer
                  attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {driverPosition.length > 0 && (
                  <Marker position={driverPosition} icon={pinIcon} />
                )}

                {checkedPosition && (
                  <Fragment>
                    {positions.length > 0 &&
                      positions.map((item: IDriverPositions) => (
                        <Marker
                          key={`marker-${item.id}`}
                          position={[item.latitude, item.longitude]}
                          icon={getPositionIcon()}
                        />
                      ))}
                  </Fragment>
                )}

                {cargo.length > 0 &&
                  cargo.map((item: any) => (
                    <Fragment>
                      {checkedCargoPosition && (
                        <Marker
                          key={`marker-${item.id}`}
                          position={[item.endereco.latitude, item.endereco.longitude]}
                          icon={
                            findPositionCargo(item.endereco.latitude, item.endereco.longitude)
                              .length > 1
                              ? getPositionIconCargoMultiple(
                                  findPositionCargo(
                                    item.endereco.latitude,
                                    item.endereco.longitude
                                  ).length,
                                  false,
                                  false
                                )
                              : getPositionIconCargo(
                                  item.ordemEntrega,
                                  false,
                                  false
                                )
                          }
                          onClick={(e: any) => {
                            setDetailsCargo(
                              findPositionCargo(
                                e.target.options.position[0],
                                e.target.options.position[1]
                              )
                            );
                          }}
                        >
                          <Popup className="tw-w-[300px] tw-top-[-210px] tw-min-h-28">
                            <Carousel data={detailsCargo} />
                          </Popup>
                        </Marker>
                      )}

                      {checkedFinishedPosition && item.latitude !== null && item.longitude !== null && (
                        <Marker
                          key={`marker2-${item.id}`}
                          position={[
                            item.latitude,
                            item.longitude,
                          ]}
                          icon={
                            findPositionCargo(
                              item.latitude,
                              item.longitude
                            ).length > 1
                              ? getPositionIconCargoMultiple(
                                  findPositionCargo(
                                    item.latitude,
                                    item.longitude
                                  ).length,
                                  item.baixaForaRaio,
                                  item.dataBaixaApp !== null ? true : false
                                )
                              : getPositionIconCargo(
                                  item.ordemEntrega,
                                  item.baixaForaRaio,
                                  item.dataBaixaApp !== null ? true : false
                                )
                          }
                          onClick={(e: any) => {
                            setDetailsCargo(
                              findPositionCargo(
                                e.target.options.position[0],
                                e.target.options.position[1]
                              )
                            );
                          }}
                        >
                          <Popup className="tw-w-[300px] tw-top-[-210px] tw-min-h-28">
                            <Carousel data={detailsCargo} />
                          </Popup>
                        </Marker>
                      )}
                    </Fragment>
                  ))}

                {checkedPoints && (
                  <Fragment>
                    {points.map((item: any) => (
                      <Marker
                        position={[item.latitude, item.longitude]}
                        icon={createCustomMarkerIcon(
                          item.typePointInterest.icon
                        )}
                        onClick={(e: any) => {
                          findPointInterestById(item.id);
                        }}
                      >
                        <Popup className="tw-w-[300px] tw-top-[-190px] tw-min-h-28">
                          <DetailsPointsInterest data={detailsPointsInterest} />
                        </Popup>
                      </Marker>
                    ))}
                  </Fragment>
                )}

                {branchPosition.length > 0 && (
                  <Marker position={branchPosition} icon={PointBranch} />
                )}

                {/* <Polyline
                weight={22}
                color="#2c0ddf40"
                positions={[pointsPlanning]}
              /> */}

                {checkedPlanning && (
                  <Polyline
                    lineCap="square"
                    dashArray="5, 10"
                    color="#6369D1"
                    positions={pointsPlanning}
                  />
                )}

                {checkedFinishedRoute && (
                  <Fragment>
                    {pointsCoarse.map((item: any) => (
                      <Polyline
                        color="#08B639"
                        positions={polyline.decode(item)}
                      />
                    ))}
                  </Fragment>
                )}
              </Map>
            )}
          </div>
          <div className="tw-w-[25%] tw-h-full tw-ml-2 tw-flex-nowrap">
            {checkedPoints && (
              <div className="tw-flex tw-w-full tw-h-full tw-mb-2">
                <div className="tw-flex tw-flex-col tw-w-full tw-h-full">
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    Raio P.O.I
                  </span>
                  <select
                    className="tw-w-1/3 tw-h-8 tw-rounded-xl tw-bg-[#fff] tw-mt-2"
                    style={{ border: '1px solid rgba(0, 48, 73, 0.2)' }}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                      setRadiusPointInterest(e.target.value);
                      getPointInterest(shipment, e.target.value);
                    }}
                    value={radiusPointInterest}
                  >
                    <option value="50000">50Km</option>
                    <option value="100000">100Km</option>
                    <option value="200000">200Km</option>
                  </select>
                </div>
              </div>
            )}

            <div className="tw-flex tw-flex-col tw-mb-2">
              <span className="tw-text-sm tw-font-bold tw-text-[#003049]">
                {t('controlTower.cargoMap.filter.label')}
              </span>

              <div className="tw-flex tw-w-full tw-mt-2">
                <div className="tw-mr-2 tw-w-1/3">
                  <input
                    type="datetime-local"
                    className="tw-bg-[#FFF] tw-w-full tw-h-8 tw-rounded-xl tw-pl-2"
                    style={{ border: '1px solid rgba(0, 48, 73, 0.2)' }}
                    onChange={formik.handleChange('startDate')}
                    value={formik.values.startDate}
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <p className="tw-text-xs tw-text-[#EA004C] tw-font-bold">
                      {formik.errors.startDate}
                    </p>
                  )}
                </div>
                <div className="tw-w-1/3">
                  <input
                    type="datetime-local"
                    className="tw-bg-[#FFF] tw-w-full tw-h-8 tw-rounded-xl tw-pl-2"
                    style={{ border: '1px solid rgba(0, 48, 73, 0.2)' }}
                    onChange={formik.handleChange('endDate')}
                    value={formik.values.endDate}
                  />
                  {formik.touched.endDate && formik.errors.endDate && (
                    <p className="tw-text-xs tw-text-[#EA004C] tw-font-bold tw-text-start">
                      {formik.errors.endDate}
                    </p>
                  )}
                </div>
                <div className="tw-w-full tw-ml-2">
                  <button
                    className="tw-flex tw-items-center tw-w-full tw-justify-center tw-border-none tw-bg-[#EA004C] tw-text-sm tw-font-medium tw-text-[#FFF] tw-rounded-xl tw-p-2 tw-h-8"
                    type="button"
                    onClick={() => formik.handleSubmit()}
                  >
                    Filtrar
                  </button>
                </div>
              </div>
              {checkedPosition && (
                <div className="tw-w-full tw-flex tw-flex-col tw-h-full">
                  <div
                    className="tw-flex tw-flex-col tw-w-full tw-bg-[#FFF] tw-shadow-xl tw-p-2 tw-rounded-xl tw-mt-2"
                    style={{ border: '2px solid #edf2f4' }}
                  >
                    <div className="tw-max-h-80 tw-overflow-scroll">
                      {positions.map((item: IDriverPositions) => (
                        <div
                          className="tw-flex tw-w-full tw-h-full tw-mb-3 tw-cursor-pointer"
                          onClick={() => {
                            setInitialPosition([item.latitude, item.longitude]);
                            setZoom(20);
                          }}
                        >
                          <div className="tw-flex tw-flex-col tw-w-full">
                            <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                              Data/Hora
                            </span>
                            <p className="tw-text-sm tw-text-[#003049] tw-font-normal">
                              {format(
                                new Date(item.dataHora),
                                'dd/MM/yyyy HH:mm:ss'
                              )}
                            </p>
                            <div className="tw-w-full tw-h-[1px] tw-bg-[#ccd6db]" />
                          </div>
                          <div className="tw-w-3 tw-h-3 tw-bg-[#006CEA] tw-rounded-full tw-mr-2" />
                        </div>
                      ))}
                    </div>
                    <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-mt-3">
                      <Pagination
                        count={totalPages}
                        variant="outlined"
                        shape="rounded"
                        onChange={(e: any, page: number) => {
                          setCurrentPage(page - 1);
                          getPositions(filters, page - 1);
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default GetRoutes;
