export enum AlertType {
  TEMPO_ETAPA_ULTRAPASSADO = "TEMPO_ETAPA_ULTRAPASSADO",
  REGISTRO_OCORRENCIA_INFO_INSUCESSO = "REGISTRO_OCORRENCIA_INFO_INSUCESSO",
  ATEND_REALIZADO_FORA_RAIO = "ATEND_REALIZADO_FORA_RAIO",
  PARADA_NAO_PROGRAMADA = "PARADA_NAO_PROGRAMADA"
}

export interface Alert {
  id: number;
  descricao: string;
  tipo: string;
  dataCriacao: string;
  dataModificacao: string;
}