import { ValueFormatterParams } from 'ag-grid-community';
import LockedOpen from 'assets/images/lockedOpenCustom.svg';
import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import ModalDelete from 'components/ModalDelete';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import i18n from 'i18n';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import history from 'services/history';
import {
  deleteGroupsEdit,
  deleteGroupsUserId,
} from 'store/modules/map/actions';
import validateCNPJ from 'util/validCNPJ';
import EditUsers from './EditUsers';
import GroupsRegister from './GroupsRegister';
import NewUsers, { FormValues } from './NewUsers';
import { User } from './interfaces/types';

interface Props {
  onClear: () => void;
}

const RegisterUsers: React.FC<Props> = (props: Props) => {
  const [tabs, setTabs] = useState<number>(0);
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'nome',
      headerName: 'Nome',
      filter: true,
      checkboxSelection: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      filter: true,
    },
    {
      field: 'bloqueado',
      headerName: 'Bloqueado',
      filter: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value === 'ACTIVE' ? 'ATIVO' : 'INATIVO';
        }
      },
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<User[]>([]);

  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [blockedConfirmation, setBlockedConfirmation] = useState<boolean>(
    false
  );
  const [unlockConfirmation, setUnlockConfirmation] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [updatePermission, setUpdatePermission] = useState<boolean>(false);


  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onCreate = () => {
    setTabs(1);
  };

  const onUpdate = () => {
    if (selectedRows.length > 0) {
      setTabs(2);
    }
  };

  const onDelete = () => {
    if (selectedRows.length > 0) {
      setIsRemove(!isRemove);
    }
  };

  const onBlocked = () => {
    if (selectedRows.length > 0) {
      if (selectedRows[0].bloqueado) {
        setUnlockConfirmation(!unlockConfirmation);
      } else {
        setBlockedConfirmation(!blockedConfirmation);
      }
    }
  };

  const getLanguage = i18n.language;

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      subsdiaryList: any[],
      resetForm: any,
      isEdit: boolean,
      row: User | null
    ) => {
      try {
        setLoading(true);
        if (
          validateCNPJ(values.cnpj) ||
          values.cnpj == '' ||
          values.cnpj == null
        ) {
          if (subsdiaryList.length > 0) {
            const mappingFilial = subsdiaryList.map((item: any) => {
              return {
                id: item.value,
              };
            });

            const logisticOperator = {
              id: values.operadorLogistico,
            };

            const body = {
              nome: values.name,
              login: values.email,
              email: values.email,
              senha: values.senha,
              filiais: mappingFilial,
              grupo: {
                id: values.group,
              },
              operadorLogistico:
                values.operadorLogistico !== null &&
                values.operadorLogistico.length > 0
                  ? logisticOperator
                  : null,
              cnpj: values.cnpj && values.cnpj.length > 0 ? values.cnpj : null,
            };

            if (isEdit && row !== null) {
              const response = await api.patch(
                `/acesso/usuarios/${row.id}`,
                body
              );

              if (response.status === 204) {
                FrontendNotification(
                  t('users.messageUpdated'),
                  NotificationType.SUCCESS
                );
              } else {
                FrontendNotification(
                  t('users.messageErroUpdated'),
                  NotificationType.ERROR
                );
              }
              setTabs(0);
            } else {
              const response = await api.post('/acesso/usuarios', body);

              if (response.status === 201) {
                FrontendNotification(
                  t('users.messageCreated'),
                  NotificationType.SUCCESS
                );
                FrontendNotification(
                  t('users.messageEmailReceived'),
                  NotificationType.SUCCESS
                );
                dispatch(deleteGroupsUserId());
                // onLoadUsers();
              } else {
                FrontendNotification(
                  t('users.messageErroCreated'),
                  NotificationType.ERROR
                );
              }
              resetForm({});
              setTabs(0);
            }
          } else {
            FrontendNotification(
              '* Informe a filial!',
              NotificationType.WARNING
            );
          }
        } else {
          const message =
            getLanguage === 'pt' || getLanguage === 'pt-BR'
              ? 'CNPJ Inválido'
              : 'Invalid CNPJ';
          FrontendNotification(message, NotificationType.WARNING);
        }

        setLoading(false);
      } catch {}
    },
    []
  );

  const deleteUser = useCallback(async (userId: number) => {
    try {
      const response = await api.delete(`acesso/usuarios/${userId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('users.messageRemove'),
          NotificationType.SUCCESS
        );
      } else {
        FrontendNotification(
          t('users.messageErroRemoved'),
          NotificationType.ERROR
        );
      }

      setIsRemove(!isRemove);

      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  const blockedUser = useCallback(async (data: User, isBlocked: boolean) => {
    try {
      const body = {
        nome: data.nome,
        email: data.email,
        senha: data.senha,
        grupo: {
          id: data.grupo.id,
        },
        operadorLogistico: null,
        cnpj: data.cnpj,
        filiais: data.filiais,
        bloqueado: isBlocked,
      };

      const response = await api.patch(`/acesso/usuarios/${data.id}`, body);

      if (response.status === 204) {
        FrontendNotification(
          t('driver.message.blockedUser'),
          NotificationType.SUCCESS
        );
      }

      setBlockedConfirmation(false);
      setUnlockConfirmation(false);

      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  return (
    <CustomModal
      isOpen
      label={t('users.title')}
      isClose={() => props.onClear()}
      styles={{ minWidth: '70%' }}
    >
      <Loading loading={loading} />
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => deleteUser(selectedRows[0].id)}
      />

<Modal isOpen={updatePermission}>
        <ModalBody>
          <Trans i18nKey="users.messageUpdatePermissions" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              history.push('/');
              window.location.reload();
            }}
          >
            <Trans i18nKey="common.ok" />
          </Button>{' '}
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={blockedConfirmation}
        toggle={() => setBlockedConfirmation(!blockedConfirmation)}
      >
        <ModalBody>
          <Trans i18nKey="common.questionBlockedUser" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => blockedUser(selectedRows[0], true)}
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setBlockedConfirmation(!blockedConfirmation)}
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={unlockConfirmation}
        toggle={() => setUnlockConfirmation(!unlockConfirmation)}
      >
        <ModalBody>
          <Trans i18nKey="common.questionUnlockUser" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => blockedUser(selectedRows[0], false)}
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>
          <Button
            color="secondary"
            onClick={() => setUnlockConfirmation(!unlockConfirmation)}
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <Tabs
        selectedIndex={tabs}
        onSelect={(index: number) => {
          dispatch(deleteGroupsUserId());
          dispatch(deleteGroupsEdit());
          setTabs(index);
        }}
      >
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
          <Tab>
            <Trans i18nKey="tabs.groups" />
          </Tab>
        </TabList>

        <TabPanel>
          <div className="tw-w-full tw-h-[400px] tw-mb-14">
            <Grid<User>
              columns={columns}
              pagination={true}
              rowSelection={RowSelection.SINGLE}
              path="acesso/usuarios/pageable"
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              filters={[]}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onCreate={onCreate}
              showCrudButtons={true}
              customButtons={[
                {
                  label: 'Bloquear/Desbloquear',
                  action: () => onBlocked(),
                  icon: LockedOpen,
                },
              ]}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <NewUsers
            onSubmit={(
              values: FormValues,
              subsidiaryList: any[],
              resetForm: any
            ) => handleSubmit(values, subsidiaryList, resetForm, false, null)}
            onGroupUser={() => setTabs(3)}
          />
        </TabPanel>
        <TabPanel>
          <EditUsers
            onSubmit={(
              values: FormValues,
              subsidiaryList: any[],
              resetForm: any
            ) => {
              if(selectedRows.length > 0) {

                handleSubmit(values, subsidiaryList, resetForm, true, selectedRows[0]);
              }
            }}
            onGroupUser={() => {}}
            userId={selectedRows[0]?.id}
          />
        </TabPanel>
        <TabPanel>
          <GroupsRegister
            onUpdateGroup={() => {
              setTabs(1);
              setTabs(3);
              setUpdatePermission(true);
            }}
          />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default RegisterUsers;
