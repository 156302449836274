import InputCustom from 'components/Input';
import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  width: 100%;
`;



export const ShowSortOptions = styled.div`
 display: flex;
 align-items: center;
`;

export const SectionFilter = styled.div`
  display: flex;
  align-items: center;
  margin-top: 0.615rem;
  position: relative;
`;

export const SectionText = styled.div`
  font-size: 0.813rem;
  color: #535669;
  margin-left: 0.400rem;
`;

export const ClearFilters = styled.div`
  /* position: absolute; */
  margin-left: 1rem;
  cursor: pointer;
  /* right: 0; */
`;

export const ClearFiltersText = styled.span`
  font-family: 'Lato';
  font-weight: bold;
  font-size: 0.813rem;
  color: #EA004C;
`;

export const WrapperFilters = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WrapperFields = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`;

export const FilterLabel = styled.span`
  font-size: 1rem;
  color: #535669;
  font-weight: bold;
  margin-left: 0.400rem;
`;

export const CustomInput = styled(InputCustom)`
  height: 1.875rem !important;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.875rem;
  flex-wrap: wrap;
`;

export const ButtonCancel = styled.div`
  cursor: pointer;
`;

export const TextButtonCancel = styled.span`
  font-family: 'Lato';
  font-size: 1rem;
  font-weight: bold;
  color: #535669;
`;

export const ButtonConfirm = styled.div`
display: flex;
align-items: center;
justify-content: center;
  background-color: #EA004C;
  width: 4.75rem;
  height: 2.5rem;
  padding: 0.814rem;
  border-radius: 0.313rem;
  cursor: pointer;
`;

export const TextButtonConfirm = styled.span`
  font-size: 0.873rem;
  font-family: 'Lato';
  color: #fff;
`;