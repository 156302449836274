import CustomModal from 'components/ModalCustom';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import ListParameters from './components/List';

interface Props {
  onClear: () => void;
}

const Parameters: React.FC<Props> = (props: Props) => {
  const [tabs, setTabs] = useState<number>(0);

  const { t } = useTranslation();

  return (
    <CustomModal label={t('parameters.title')} isOpen styles={{ minWidth: '80%' }} isClose={props.onClear}>
      <Tabs
        selectedIndex={tabs}
        onSelect={(index: number) => {
          setTabs(index);
        }}
      >
        <TabList>
          <Tab>
            <Trans i18nKey="parameters.tabs.routing" />
          </Tab>
          <Tab>
            <Trans i18nKey="parameters.tabs.mobile" />
          </Tab>
          {/* <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab> */}
        </TabList>
        <TabPanel>
          <ListParameters baseURL='/parameters/routing' />
        </TabPanel>
        <TabPanel>
          <ListParameters baseURL='/parameters/mobile' />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default Parameters;
