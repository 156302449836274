import InputCustom from 'components/Input';
import { icons, renderIcons } from 'components/TypePointInterest/icons';
import { ITypePointInterest } from 'components/TypePointInterest/types/types';
import Loading from 'core/common/LoadingRoutering';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Label } from 'reactstrap';
import api from 'services/api';
import formValidator from './validators/formValidator';

interface Props {
  onClear: () => void;
  isEdit?: boolean;
  data?: ITypePointInterest;
}

interface FormValues {
  description: string;
  icon: string;
}

const NewTypePointInterest: React.FC<Props> = (props: Props) => {
  const [defaultValue, setDefaultValue] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);


  const { t } = useTranslation();

  const handleSubmit = useCallback(async (values: FormValues, isEdit?: boolean, data?: ITypePointInterest, resetForm?: any) => {
    try {

      setLoading(true);

      const body = {
        description: values.description,
        icon: values.icon
      };

      if(isEdit) {
        const response = await api.patch(`/delivery/typePointInterest/${data?.id}`, body);

        if(response.status === 204) {
          FrontendNotification(t('typePointInterest.messageUpdatedSuccess'), NotificationType.SUCCESS);
        }
      } else {
        const response = await api.post('/delivery/typePointInterest', body);

        if(response.status === 204) {
          FrontendNotification(t('typePointInterest.messageSuccess'), NotificationType.SUCCESS);
        }
      }

      resetForm({});
      setDefaultValue([]);
      props.onClear();

      setLoading(false);
      
      
    }catch{
      FrontendNotification(t('typePointInterest.messageError'), NotificationType.ERROR);
    }
  }, []);

  const onLoadFormValues = useCallback((row: ITypePointInterest) => {
    if(row) {
      formik.setFieldValue('description', row.description);

      // Resgata o valor do select
      const findIconByName = icons.find((item: any) => item.id === row.icon);
      let rows = [];

      rows.push(findIconByName);
      setDefaultValue(rows);

      formik.setFieldValue('icon', row.icon);
    }
  }, []);

  const initialValues: FormValues = {
    description: '',
    icon: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues, { resetForm }) => handleSubmit(values, props.isEdit, props.data, resetForm)
  });

  const customStyles = {
    option: (provided: any) => ({
      ...provided,
      background: '#FFF',
      color: '#000'
    }),
   
  };

  useEffect(() => {
    if(props.isEdit && props.data) {
      onLoadFormValues(props.data);
    }
  }, [])

  return (
    <div className="tw-w-full tw-h-full tw-mt-4">
      <Loading loading={loading} />
      <div className="tw-flex tw-items-center">
        <div className="tw-flex-col tw-w-full tw-mr-4">
          <InputCustom
            title={t('typePointInterest.form.description')}
            onChange={formik.handleChange('description')}
            placeholder={t('typePointInterest.form.placeholder')}
            error={formik.errors.description}
            touched={formik.touched.description}
            value={formik.values.description}
          />
        </div>
        <div className="tw-flex-col tw-w-full tw-mb-3">
          <Label className="tw-text-sm tw-text-[rgb(0,48,73)] tw-opacity-60">
            {t('typePointInterest.form.icon')}
          </Label>
          <Select
            id="typeAlert"
            name="subsidiary"
            options={icons}
            placeholder="Selecione"
            // value={formik.values.icon}
            styles={customStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption: any) => {
              setDefaultValue(selectedOption);
              formik.setFieldValue('icon', selectedOption.id);
            }}
            value={defaultValue}
            formatOptionLabel={(item: any) => {
              return (
                  <div
                    style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                  >
                    {renderIcons(item.id, false)}
                    {item.label}
                  </div>
              );
            }}
          />
          {formik.touched.icon && formik.errors.icon && (
            <span className='tw-text-sm tw-text-[#EA004C] tw-font-bold'>{formik.errors.icon}</span>
          )}
        </div>
      </div>
      <div className='tw-flex tw-w-full tw-justify-end'>
        <button className='tw-w-1/3 tw-h-9 tw-bg-[#EA004C] tw-text-sm tw-text-[#FFF] tw-border-none tw-rounded-md tw-mt-4' onClick={() => formik.handleSubmit()}>
          Salvar
        </button>
      </div>
    </div>
  );
};

export default NewTypePointInterest;
