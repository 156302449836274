import React, { useCallback, useEffect, useRef, useState } from 'react';

import TruckIcon from 'assets/images/map/truck.svg';
import Leaflet from 'leaflet';
import { Map, Marker, TileLayer } from 'react-leaflet';

import Loading from 'core/common/Loading';
import api from 'services/api';

interface TrackingDelivery {
  customer: {
    latitude: number;
    longitude: number;
  };
  driver: {
    latitude: number;
    longitude: number;
  };
}

const MapDeliveryTracking: React.FC = () => {
  const [initialPosition, setInitialPosition] = useState<any>([
    -8.0248893,
    -34.8917043,
  ]);
  const [zoom, setZoom] = useState<number>(14);

  const [driverPosition, setDriverPosition] = useState<any>([]);
  const [cargoPosition, setCargoPosition] = useState<any>([]);

  const [loading, setLoading] = useState<boolean>(false);

  let mapRef: any = useRef();

  const getCargoByUuid = useCallback(async () => {
    try {
      setLoading(true);

      const urlParams = new URLSearchParams(window.location.search);

      const uuid = urlParams.get('uuid');

      if (uuid) {
        const { data } = await api.get(`middleware/tracking/${uuid}`);

        setDriverPosition([data.driver.latitude, data.driver.longitude]);
        setCargoPosition([data.customer.latitude, data.customer.longitude]);

        setInitialPosition([data.driver.latitude, data.driver.longitude]);
        setZoom(30);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  function getHtmlPositionIcon() {
    const number = 1;

    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: `<div style="border: 5px solid #6369d1;" class=${
        number < 99
          ? 'minSizeCurrent'
          : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
      }><div class='marker-multiple-content'><i style="color: #6369d1;" class='marker-text-current'>${number}º</i></div></div>`,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  const truckIcon = new Leaflet.Icon({
    iconUrl: TruckIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  useEffect(() => {
    getCargoByUuid();
  }, [getCargoByUuid]);

  return (
    <div className="tw-w-full tw-h-full">
      <Loading loading={loading} />
      <Map
        ref={m => {
          mapRef = m;
        }}
        center={initialPosition}
        zoom={zoom}
        zoomControl
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        {driverPosition.length > 0 && (
          <Marker position={driverPosition} icon={truckIcon} />
        )}

        {cargoPosition.length > 0 && (
          <Marker position={cargoPosition} icon={getHtmlPositionIcon()} />
        )}
      </Map>
    </div>
  );
};

export default MapDeliveryTracking;
