import React, { useCallback, useEffect, useState } from 'react';

import polyline from '@mapbox/polyline';
import ChangeVehiclesLoads from 'components/ChangeVehiclesLoads';
import Loading from 'core/common/LoadingRoutering';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import Leaflet from 'leaflet';
import { Trans, useTranslation } from 'react-i18next';
import { Marker, Polyline, Popup } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import {
  DataRouteringCargas,
  DataStepsRoutering,
  RouteringCargasPayload,
  clearDataRouteSelected,
  clearDataStepSelected,
  clearDetailRouteringCarga,
  setDataRouteringCargas,
  setDataStepsSelected,
  updateDataRoute,
} from 'store/modules/routering/actions';
import { saveStepsId } from 'store/modules/routeringSimulation/actions';
import { colors } from 'util/colors';
import pointFilial from '../../assets/images/pointFilial.svg';
import Carousel from './Carousel';
import RouteringConfirm from './RouteringConfirm';
import CargasRouting from './components/CargasRouting';
import KnowledgeRouting from './components/KnowledgeRouting';
import ConfirmationDriver from './components/KnowledgeRouting/components/Card/components/ConfirmationDriver';
import SearchAddress from './components/KnowledgeRouting/components/SearchAddress';
import RouteringMap from './components/RouteringMap';
import SideBarRouting from './components/SidebarRoutes';
import './styles.scss';

interface Props {
  onUpdateCargo: () => void;
}

// import { Container } from './styles';

const RouterCargo: React.FC<Props> = ({ onUpdateCargo }: Props) => {
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState<number>(10);
  const [initialPosition, setInitialPosition] = useState<number[]>([
    -8.0527,
    -34.9099,
  ]);
  const [changeVehiclesLoads, setChangeVehiclesLoads] = useState<boolean>(
    false
  );
  const [menuSelected, setMenuSelected] = useState<string>('cargas');
  const [menuSelectedCargo, setMenuSelectedCargo] = useState<string>('');
  const [stepsActual, setStepsActual] = useState<number>(0);
  const [multiplePoints, setMultiplePoints] = useState<any>([]);
  const [isAddressChanged, setIsAddressChanged] = useState<boolean>(false);
  const [stepsAddress, setStepsAddress] = useState<any>();
  const [knowledgeAddress, setKnowledgeAddress] = useState<any>([]);
  const [updateStep, setUpdateStep] = useState<boolean>(false);
  const [deleteSteps, setDeleteSteps] = useState<boolean>(false);
  const [stepId, setStepId] = useState<any>();
  const [indexLoad, setIndexLoad] = useState<any>();
  const [routeRemoveStep, setRouteRemoveStep] = useState<any>();
  const [idRoute, setIdRoute] = useState<any>();
  const [detailMapRoutering, setDetailMapRoutering] = useState<any>();

  const dispatch = useDispatch();

  const dataStepsRoutering = useSelector((state: any) => state.routering.data);
  const dataCarga: RouteringCargasPayload = useSelector(
    (state: any) => state.routering
  );
  const dataCargoRoutering: DataRouteringCargas[] = useSelector(
    (state: any) => state.routering.data.dataOptmi
  );
  const dataCargoRouteringSelected: DataRouteringCargas[] = useSelector(
    (state: any) => state.routering.data.dataRouteSelected
  );

  const getDataCargoRoutering = dataCargoRouteringSelected;


  const { t } = useTranslation();

  const onReloadCargo = useCallback(async (arrOptmis: any) => {
    try {
      setLoading(true);

      let getCargas = sessionStorage.getItem('cargas');

      let getCargaDateScheduling;

      if (getCargas) {
        getCargaDateScheduling = JSON.parse(getCargas);
      }

      const mappingKnowledge = arrOptmis.steps.map(
        (itemStep: DataStepsRoutering, indexStep: number) => {
          return {
            id: itemStep.conhecimentoPre.id,
            priority: 0,
          };
        }
      );

      const dateScheduling = getCargaDateScheduling.find(
        (item: any) => item.id === arrOptmis.id
      ).dataAgendamento;

      const getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
        locale: pt,
      });

      const getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
        locale: pt,
      });

      const dataSubmit = {
        conhecimentos: mappingKnowledge,
        rota: arrOptmis.id,
        veiculos: [
          {
            id: arrOptmis.vehicle.id,
            roadtrip: arrOptmis.roadtrip,
            outputForecast: `${getDateScheduling}T${getHoursScheduling}`,
          },
        ],
        filial: arrOptmis.subsidiary.id,
        alteraAtendimentosRota: false,
        idStep: null,
      };

      const response = await api.post(
        '/delivery/conhecimentosPre/alteraRotas',
        dataSubmit
      );

      sessionStorage.setItem(
        'idSimulacao',
        JSON.stringify(response.data.id_route_simulation)
      );
      setLoading(false);

      getCargasRoutering('');
    } catch (err) {}
  }, []);

  function getColors(cargaId: number) {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colorsData = [];

    if (getColorsByCarga) {
      colorsData = JSON.parse(getColorsByCarga);
    }

    const findColors = colorsData.find((color: any) => color.id === cargaId);

    return findColors;
  }

  function loadColors(
    cargaItem: DataRouteringCargas,
    data: any,
    index: number
  ) {
    const getColorsByCarga = sessionStorage.getItem('@carga/colors');

    let colorsData = [];

    if (getColorsByCarga) {
      colorsData = JSON.parse(getColorsByCarga);
    }

    const random = Math.floor(Math.random() * colors.length);

    const dataColors = {
      id: cargaItem.id,
      color: index > colors.length - 1 ? colors[random] : colors[index],
    };

    let colorsList = colorsData;

    colorsList.push(dataColors);

    if (data.length > colorsData) {
      sessionStorage.setItem('@carga/colors', JSON.stringify(colorsList));
    } else {
      sessionStorage.setItem('@carga/colors', JSON.stringify(colorsList));
    }

    return dataColors;
  }

  const getCargasRoutering = useCallback(
    async (cargoType?: string) => {
      try {
        setLoading(true);

        let dataRouteChanged: any = null;

        const idRouteChanged = sessionStorage.getItem('idRouteChangedAddress');

        if (idRouteChanged) {
          dataRouteChanged = JSON.parse(idRouteChanged);
        }

        const data = {
          filters: [
            {
              field: 'simulation.id',
              value: sessionStorage.getItem('idSimulacao'),
              operation: 'EQUAL',
            },
            {
              field: 'inactivated',
              operation: 'EQUAL',
              value: false
            }
          ],
          orders: [],
          page: 0,
          size: 1000,
        };
        const response = await api.post('/route/optmis/pageable', data);

        const orderById = response.data.content.sort(
          (a: any, b: any) => a.id - b.id
        );

        const mappingCargaRoutering = orderById.map(
          (cargaItem: DataRouteringCargas, indexCarga: number) => {
            return {
              ...cargaItem,
              fillColor:
                getColors(indexCarga) !== undefined
                  ? getColors(cargaItem.id).color
                  : loadColors(cargaItem, orderById, indexCarga).color,
              active: false,
              steps: cargaItem.steps.map(stepItem => {
                return {
                  ...stepItem,
                  isCompacted: false,
                  active: false,
                };
              }),
            };
          }
        );

        const mappingCarga = mappingCargaRoutering.map(
          (cargaItem: DataRouteringCargas, indexCarga: number) => {
            return {
              dataAgendamento: cargaItem.dataAgendamento,
              id: cargaItem.id,
            };
          }
        );

        if (response.data.content.length > 0) {
          sessionStorage.setItem('cargas', JSON.stringify(mappingCarga));
          dispatch(setDataRouteringCargas(mappingCargaRoutering));
        } else {
          history.push('/uploadCSV');
          window.location.reload();
        }

        setInitialPosition([
          mappingCargaRoutering[0].subsidiary.endereco.latitude,
          mappingCargaRoutering[0].subsidiary.endereco.longitude,
        ]);

        dispatch(clearDetailRouteringCarga());
        dispatch(clearDataRouteSelected());
        if (cargoType === 'updateRoute') {
          if (dataRouteChanged !== null) {
            const findRouteById = response.data.content.find(
              (item: any) => item.id === dataRouteChanged
            );
            onReloadSteps(findRouteById);
          } else {
            onReloadSteps(response.data.content[0]);
          }
          // getCargasRoutering();
        }
        setLoading(false);
      } catch (err) {}
    },
    [updateStep]
  );

  function findPositionKnowledge(
    latitude: any,
    longitude: any,
    stepsData: DataStepsRoutering[]
  ) {
    let positionMultiple = stepsData.filter(
      (itemPosition: DataStepsRoutering) =>
        itemPosition.latitude === latitude &&
        itemPosition.longitude === longitude
    );

    return positionMultiple;
  }

  const PointFilial = new Leaflet.Icon({
    iconUrl: pointFilial,
    iconSize: [80, 80],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [15, 42],
  });

  function getHtmlPositionIcon(number: number, fillColor: any) {
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: `<div style='border: 5px solid ${fillColor}' class=${
        number < 99
          ? 'minSizeCurrent'
          : number < 1000
          ? 'mediumSizeCurrent'
          : 'maxSizeCurrent'
      }><div class='marker-multiple-content'><i class='marker-text-current' style='color: ${fillColor}'>${number}º</i></div></div>`,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }


  function getHtmlPositionIconMultiple(number: number, fillColor: string, order: number) {
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html: `
        <div style="border: 5px solid ${fillColor};" class="fullSize">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <p class="marker-text-multiple" style="color: ${fillColor};">+${number}</p>
            <span class="marker-text-multiple" style="color: ${fillColor};">${order}º</span>
          </div>
        </div>
      `,
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  const onReloadSteps = useCallback(async (arrOptmis: any) => {
    try {
      setLoading(true);

      const mappingKnowledge = arrOptmis.steps.map(
        (itemStep: DataStepsRoutering, indexStep: number) => {
          return {
            id: itemStep.conhecimentoPre.id,
            priority: 0,
          };
        }
      );

      let cargas = sessionStorage.getItem('cargas');

      let getCargaDateScheduling;

      if (cargas) {
        getCargaDateScheduling = JSON.parse(cargas);
      }

      const dateScheduling = getCargaDateScheduling.find(
        (item: any) => item.id === arrOptmis.id
      ).dataAgendamento;

      const getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
        locale: pt,
      });

      const getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
        locale: pt,
      });

      const dataSubmit = {
        conhecimentos: mappingKnowledge,
        rota: arrOptmis.id,
        veiculos: [
          {
            id: arrOptmis.vehicle.id,
            roadtrip: arrOptmis.roadtrip,
            outputForecast: `${getDateScheduling}T${getHoursScheduling}`,
          },
        ],
        filial: arrOptmis.subsidiary.id,
        alteraAtendimentosRota: false,
        idStep: null,
      };

      const response = await api.post(
        '/delivery/conhecimentosPre/alteraOrdemRota',
        dataSubmit
      );

      sessionStorage.setItem(
        'idSimulacao',
        JSON.stringify(response.data.id_route_simulation)
      );

      getCargas('');

      setLoading(false);
    } catch (err) {}
  }, []);

  const getCargas = useCallback(async (cargoType?: string) => {
    try {
      setLoading(true);

      let dataRouteChanged: any = null;

      const idRouteChanged = sessionStorage.getItem('idRouteChangedAddress');

      if (idRouteChanged) {
        dataRouteChanged = JSON.parse(idRouteChanged);
      }

      const data = {
        filters: [
          {
            field: 'simulation.id',
            value: sessionStorage.getItem('idSimulacao'),
            operation: 'EQUAL',
          },
          {
            field: 'inactivated',
            operation: 'EQUAL',
            value: false
          }
        ],
        orders: [],
        page: 0,
        size: 1000,
      };
      const response = await api.post('/route/optmis/pageable', data);

      const orderById = response.data.content.sort(
        (a: any, b: any) => a.id - b.id
      );

      const mappingCargaRoutering = orderById.map(
        (cargaItem: DataRouteringCargas, indexCarga: number) => {
          return {
            ...cargaItem,
            fillColor:
              getColors(indexCarga) !== undefined
                ? getColors(cargaItem.id).color
                : loadColors(cargaItem, orderById, indexCarga).color,
            active: false,
            isCompacted: false,
            steps: cargaItem.steps.map(stepItem => {
              return {
                ...stepItem,
                isCompacted: false,
                active: false,
              };
            }),
          };
        }
      );

      const mappingCarga = mappingCargaRoutering.map(
        (cargaItem: DataRouteringCargas, indexCarga: number) => {
          return {
            dataAgendamento: cargaItem.dataAgendamento,
            id: cargaItem.id,
          };
        }
      );

      if (response.data.content.length > 0) {
        sessionStorage.setItem('cargas', JSON.stringify(mappingCarga));
        dispatch(setDataRouteringCargas(mappingCargaRoutering));
      } else {
        history.push('/uploadCSV');
        window.location.reload();
      }

      setInitialPosition([
        mappingCargaRoutering[0].subsidiary.endereco.latitude,
        mappingCargaRoutering[0].subsidiary.endereco.longitude,
      ]);

      dispatch(clearDetailRouteringCarga());
      dispatch(clearDataRouteSelected());
      if (cargoType === 'updateRoute') {
        if (dataRouteChanged !== null) {
          const findRouteById = response.data.content.find(
            (item: any) => item.id === dataRouteChanged
          );
          onReloadSteps(findRouteById);
        } else {
          onReloadSteps(response.data.content[0]);
        }
        // getCargasRoutering();
      }
      setLoading(false);
    } catch (err) {}
  }, []);

  const onUpdateAddress = useCallback(async (dataSteps, data, valueAddress) => {
    try {
      const findKnowledgeById = dataSteps
        .map((itemKnowledge: DataStepsRoutering, i: number) =>
          itemKnowledge.conhecimentoPre.id === data.id ? i : -1
        )
        .filter((item: any) => item !== -1);

      let dataKnowledgeAddress;

      if (dataSteps[findKnowledgeById[0]].conhecimentoPre.tipo === 'Entrega') {
        dataKnowledgeAddress = {
          ...dataSteps[findKnowledgeById[0]].conhecimentoPre,
          enderecoLogradouro: valueAddress.logradouro,
          enderecoBairro: valueAddress.bairro,
          enderecoCidade: valueAddress.cidade,
          enderecoUf: valueAddress.estado,
          enderecoCep: valueAddress.cep,
          enderecoNumero: valueAddress.numero,
          enderecoLatitude: valueAddress.latitude,
          enderecoLongitude: valueAddress.longitude,
        };
      } else {
        dataKnowledgeAddress = {
          ...dataSteps[findKnowledgeById[0]].conhecimentoPre,
          enderecoLogradouroColeta: valueAddress.logradouro,
          enderecoBairroColeta: valueAddress.bairro,
          enderecoCidadeColeta: valueAddress.cidade,
          enderecoUfColeta: valueAddress.estado,
          enderecoCepColeta: valueAddress.cep,
          enderecoNumeroColeta: valueAddress.numero,
          enderecoLatitudeColeta: valueAddress.latitude,
          enderecoLongitudeColeta: valueAddress.longitude,
        };
      }

      const response = await api.patch(
        `/delivery/conhecimentosPre/${
          dataSteps[findKnowledgeById[0]].conhecimentoPre.id
        }`,
        dataKnowledgeAddress
      );
    } catch (err) {}
  }, []);

  const setRouteReorderRemoveKnowledge = useCallback(
    async (
      optmisData: any,
      dataIndex: number,
      idRoute: number,
      idStep: number,
      optmiIdOrder: number
    ) => {
      try {
        setLoading(true);

        const removeSteps = optmisData.steps
          .map((item: any, i: number) => (item.id === idStep ? i : -1))
          .filter((index: number) => index !== -1);
        const filterSteps = optmisData.steps.filter(
          (item: any, index: any) => index !== removeSteps[0]
        );

        let mappingKnowledge: any;

        if (optmisData.steps.length === 1) {
          mappingKnowledge = [];
        } else {
          mappingKnowledge = filterSteps.map(
            (itemKnowledge: any, indexKnowledge: number) => {
              return {
                id: itemKnowledge.conhecimentoPre.id,
                priority: 0,
              };
            }
          );
        }

        let getCargasRouting = sessionStorage.getItem('cargas');

        let getCargaDateScheduling;

        if (getCargasRouting) {
          getCargaDateScheduling = JSON.parse(getCargasRouting);
        }

        const dateScheduling = getCargaDateScheduling.find(
          (item: any) => item.id === optmisData.id
        ).dataAgendamento;

        const getDateScheduling = format(
          new Date(dateScheduling),
          'yyyy-MM-dd',
          {
            locale: pt,
          }
        );

        const getHoursScheduling = format(
          new Date(dateScheduling),
          'HH:mm:ss',
          {
            locale: pt,
          }
        );

        const dataSubmit = {
          conhecimentos: mappingKnowledge,
          rota: optmiIdOrder,
          veiculos: [
            {
              id: optmisData.vehicle.id,
              roadtrip: optmisData.roadtrip,
              outputForecast: `${getDateScheduling}T${getHoursScheduling}`,
            },
          ],
          filial: optmisData.subsidiary.id,
          alteraAtendimentosRota: false,
          idStep: null,
        };

        const response = await api.post(
          '/delivery/conhecimentosPre/alteraOrdemRota',
          dataSubmit
        );

        if (response.data.id_route_simulation !== null) {
          sessionStorage.setItem(
            'idSimulacao',
            JSON.stringify(response.data.id_route_simulation)
          );
        }

        setLoading(false);

        getCargas();
      } catch (err) {}
    },
    []
  );

  useEffect(() => {
    const getColorByCarga = dataCarga.data.dataOptmi.map(
      (cargaItem: DataRouteringCargas) => {
        return {
          id: cargaItem.id,
          color: cargaItem.fillColor,
        };
      }
    );

    sessionStorage.setItem('@carga/colors', JSON.stringify(getColorByCarga));

    getCargas();
  }, [getCargas]);

  return (
    <div className="tw-flex">
      <Loading loading={loading} />
      <SideBarRouting onChange={(value: string) => {
          setMenuSelected(value);
          setMenuSelectedCargo(value);
        }}
        showMenu
        />

      {changeVehiclesLoads && (
        <ChangeVehiclesLoads
          dataOptmi={detailMapRoutering}
          onUpdate={() => getCargas()}
          onClear={() => setChangeVehiclesLoads(!changeVehiclesLoads)}
        />
      )}
      {deleteSteps && (
        <ConfirmationDriver
          idRomaneio={stepId}
          indexLoad={indexLoad}
          idRoute={stepId}
          onClear={() => setDeleteSteps(!deleteSteps)}
          onLoad={(index: any, routeId: any) =>
            setRouteReorderRemoveKnowledge(
              routeRemoveStep,
              index,
              routeId,
              stepId,
              idRoute
            )
          }
          cpfDriver={undefined}
          onConfirm={undefined}
        />
      )}

      {isAddressChanged && (
        <SearchAddress
          onClear={() => setIsAddressChanged(!isAddressChanged)}
          // accuracy={accuracyAddress}
          data={stepsAddress}
          columns={stepsAddress}
          onEditColumns={(
            data: any,
            valueColumn: any,
            dataValueAddress: any,
            isMultipleRows: any
          ) => {
            onUpdateAddress(knowledgeAddress, data, dataValueAddress);
            // setTimeout(() => getCargasRoutering("updateRoute"), 5000)
            getCargas('updateRoute');
          }}
          accuracy={undefined}
          onInitial={undefined}
          dataColumns={undefined}
        />
      )}

      {menuSelected === 'cargas' || menuSelected === 'route' ? (
        <CargasRouting
          onSelectCarga={(menu: string) => {
            setMenuSelectedCargo(menu);
          }}
          onReloadRoute={(dataRoute: DataRouteringCargas) =>
            onReloadCargo(dataRoute)
          }
          selectedMenuCarga={menuSelected}
          onClose={() => onUpdateCargo()}
        />
      ) : null}
      {/* <KnowledgeRouting /> */}

      {menuSelectedCargo === 'knowledge' &&
        dataStepsRoutering.detail !== null &&
        dataStepsRoutering.detail.steps.length > 0 && (
          <KnowledgeRouting
            data={dataStepsRoutering.detail.steps}
            dataOptmi={dataStepsRoutering.detail}
            onMap={(latitude: number, longitude: number) => {
              setInitialPosition([latitude, longitude]);
              setZoom(20);
            }}
            onGrouped={(dataOptmi: DataRouteringCargas, dataSteps: DataStepsRoutering[]) => {
              if(getDataCargoRoutering.length > 0) {
                const findIndexOptmiById = getDataCargoRoutering.map((data: DataRouteringCargas, index: number) => data.id === dataOptmi.id ? index : -1).filter((optmiFilter) => optmiFilter !== -1);
                if(findIndexOptmiById.length > 0) {
                  dispatch(updateDataRoute(findIndexOptmiById[0], dataSteps));
                }
              }
            }}    
          />
          // <KnowledgeRouting
          // data={dataStepsRoutering.detail.steps}
          // dataOptmi={dataStepsRoutering.detail}
          // onMap={(latitudeValue: number, longitudeValue: number) => {
          //   setInitialPosition([latitudeValue, longitudeValue]);
          //   setZoom(20);
          // }}
          // />
        )}

      {menuSelected === 'check' && dataCargoRoutering.length > 0 && (
        <RouteringConfirm
          activeCargo={menuSelected}
          dataCargoConfirm={dataCargoRoutering}
        />
      )}

      <RouteringMap position={initialPosition} zoom={zoom} zoomControl>
        <>
          {dataCargoRoutering && dataCargoRoutering.length > 0 ? (
            <Marker
              position={[
                dataCargoRoutering[0].subsidiary.endereco.latitude,
                dataCargoRoutering[0].subsidiary.endereco.longitude,
              ]}
              icon={PointFilial}
            >
              <Popup
                className="popupFilial"
                style={{ top: '-80px', left: '-62px' }}
              >
                <b>
                  <Trans i18nKey="routeOptmization.simulation.subsidiaryName" />
                  :
                </b>{' '}
                {dataCargoRoutering[0].subsidiary.nome} <br />
              </Popup>
            </Marker>
          ) : (
            <></>
          )}
          {getDataCargoRoutering.length > 0 && (
            <React.Fragment>
              {getDataCargoRoutering.map((dataRoute: DataRouteringCargas) => (
                <>
                  {dataRoute.steps.map((stepRoute: DataStepsRoutering) => (
                    <Marker
                      key={`marker-${stepRoute.id}`}
                      position={[stepRoute.latitude, stepRoute.longitude]}
                      icon={
                        findPositionKnowledge(
                          stepRoute.latitude,
                          stepRoute.longitude,
                          dataRoute.steps
                        ).length > 1
                          ? getHtmlPositionIconMultiple(
                              findPositionKnowledge(
                                stepRoute.latitude,
                                stepRoute.longitude,
                                dataRoute.steps
                              ).length,
                              dataRoute.fillColor,
                              findPositionKnowledge(
                                stepRoute.latitude,
                                stepRoute.longitude,
                                dataRoute.steps
                              )[0].order
                            )
                          : getHtmlPositionIcon(
                              stepRoute.order,
                              dataRoute.fillColor
                            )
                      }
                      onClick={(e: any) => {
                        setStepsActual(0);
                        setMultiplePoints([]);

                        setMultiplePoints(
                          findPositionKnowledge(
                            e.target.options.position[0],
                            e.target.options.position[1],
                            dataRoute.steps
                          )
                        );
                      }}
                    >
                      <Popup className="popupRoutering">
                        <Carousel
                          data={multiplePoints}
                          onChangeRoute={(stepId: number, data: any) => {
                            setDetailMapRoutering(dataRoute);
                            setChangeVehiclesLoads(!changeVehiclesLoads);
                            dispatch(saveStepsId(stepId));
                            dispatch(clearDataStepSelected());
                            dispatch(setDataStepsSelected(data));
                          }}
                          onDeleteStep={(stepId: number, indexStep: number) => {
                            setDeleteSteps(!deleteSteps);
                            setStepId(stepId);
                            setIndexLoad(indexStep);
                            setIdRoute(dataRoute.id);
                            setRouteRemoveStep(dataRoute);
                          }}
                          onChangeAddress={() => {
                            setStepsAddress(stepRoute.conhecimentoPre);
                            setKnowledgeAddress(dataRoute.steps);
                            setIsAddressChanged(!isAddressChanged);
                            sessionStorage.setItem(
                              'idRouteChangedAddress',
                              JSON.stringify(dataRoute.id)
                            );
                            // setUpdateStep(!updateStep);
                          }}
                        />
                      </Popup>
                    </Marker>
                  ))}
                </>
              ))}
            </React.Fragment>
          )}
          {getDataCargoRoutering.length > 0 && (
            <React.Fragment>
              {getDataCargoRoutering.map((dataCargo: DataRouteringCargas) => (
                <Polyline
                  color={dataCargo.fillColor}
                  positions={polyline.decode(dataCargo.polyline)}
                />
              ))}
            </React.Fragment>
          )}
        </>
      </RouteringMap>
    </div>
  );
};

export default RouterCargo;
