import airplaneOrder from 'assets/images/confirmacaoEntrega.png';
import shipOrder from 'assets/images/finalizacaoEntrega.png';
import iconSMS from 'assets/images/iconSMS.svg';
import truckOrder from 'assets/images/initialDelivery.png';
import location from 'assets/images/knowledge/localization.svg';
import photo from 'assets/images/knowledge/photo.svg';
import signatures from 'assets/images/knowledge/signatures.png';
import packageIcon from 'assets/images/packageIcon.svg';
import saindoEntrega from 'assets/images/saindoEntrega.png';
import GetPositionDriver from './GetPositionDriver';

import CustomModal from 'components/ModalCustom';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import ModalSignature from 'pages/ControlTower/ListAttendances/components/CardKnowledge/components/ModalSignature';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import api from 'services/api';
import '../../index.css';
import {
  CargoOccurrence,
  CargoOccurrenceData,
  CargoPackage,
  CargoSMS,
  CargoStage,
  CargoStageMedia,
} from './interfaces/types';
import ListPackages from './ListPackages';
import OcorrencesDelivery from './OcorrencesDelivery';
import SMSMessage from './SMSMessage';
// import { Container } from './styles';

interface Props {
  cargoId: number;
  cargoType: string;
  cargoNumber: string;
  onClear: () => void;
}

const DetailsDelivery: React.FC<Props> = (props: Props) => {
  const [details, setDetails] = useState<CargoStage[]>([]);
  const [cargoSMS, setCargoSMS] = useState<CargoSMS[]>([]);
  const [cargoPackage, setCargoPackage] = useState<CargoPackage[]>([]);
  const [occurrence, setOccurrences] = useState<CargoOccurrence[]>([]);
  const [occurrenceData, setOccurrencesData] = useState<CargoOccurrenceData[]>(
    []
  );

  const [isOccurrence, setIsOccurrence] = useState(false);
  const [occurrenceList, setOccurrenceList] = useState<CargoOccurrenceData[]>(
    []
  );
  const [isOpenSMS, setIsOpenSMS] = useState<boolean>(false);
  const [isOpenPackage, setIsOpenPackage] = useState<boolean>(false);
  const [isOpenPositionDriver, setIsOpenPositionDriver] = useState<boolean>(
    false
  );
  const [isOpenPhoto, setIsOpenPhoto] = useState<boolean>(false);
  const [isOpenSignature, setIsOpenSignature] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('')

  const [positions, setPositions] = useState({
    latitude: 0,
    longitude: 0,
  });
  const [stageId, setStageId] = useState<number>();

  function getSMS(etapaId: number) {
    const data = cargoSMS.find((row: CargoSMS) => row.etapa.id === etapaId);

    return data;
  }

  function getPackages(etapaId: number) {
    const data = cargoPackage.find(
      (row: CargoPackage) => row.conhecimento.etapa.id === etapaId
    );
    return data;
  }

  function getOcorrences(etapaId: number) {
    const data = occurrence.find(
      (row: CargoOccurrence) => row.etapa !== null && row.etapa.id === etapaId
    );

    return data;
  }

  const loadPhoto = useCallback(async photoLoad => {
    try {
      const getPhoto = photoLoad.filter((item: CargoStageMedia) => item.tipo === 'FOTO');

      if (getPhoto.length > 0 && getPhoto[0].url.length > 0) {
        setImageUrl(getPhoto[0].url);
        setIsOpenPhoto(!isOpenPhoto);
      }
    } catch (err) {}
  }, []);

  const loadSignatures = useCallback(async signatures => {
    try {
      const getSignatures = signatures.filter(
        (item: CargoStageMedia) => item.tipo === 'ASSINATURA'
      );

      if (getSignatures.length > 0 && getSignatures[0].url.length > 0) {
        setImageUrl(getSignatures[0].url);
        setIsOpenSignature(!isOpenSignature);
      }
    } catch (err) {}
  }, []);

  const getCargoStage = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'conhecimento.id',
            value: props.cargoId,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/conhecimentosEtapas/findByFilters',
        data
      );

      const orderEtapas = response.data.sort(
        (a: CargoStage, b: CargoStage) => a.etapa.sequencia - b.etapa.sequencia
      );

      setDetails(orderEtapas);
    } catch (err) {}
  }, []);
  const getSmsPhases = useCallback(async () => {
    try {
      const dataSms = {
        filters: [
          {
            field: 'conhecimento.id',
            value: props.cargoId,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/conhecimentosSms/findByFilters',
        dataSms
      );

      setCargoSMS(response.data);
    } catch (err) {}
  }, []);

  const getCargoPackages = useCallback(async () => {
    try {
      const dataOcorrences = {
        filters: [
          {
            field: 'conhecimento.id',
            value: props.cargoId,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/conhecimentosPacotes/findByFilters',
        dataOcorrences
      );

      setCargoPackage(response.data);
    } catch (err) {}
  }, []);

  const getOccurrencesData = useCallback(async () => {
    try {
      const dataOcorrences = {
        filters: [
          {
            field: 'conhecimento.id',
            value: props.cargoId,
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/conhecimentosOcorrencias/findByFilters',
        dataOcorrences
      );

      setOccurrences(response.data);
    } catch (err) {}
  }, []);

  const getOccurrencesCargo = useCallback(async () => {
    try {
      const response = await api.get(
        `/delivery/conhecimentosOcorrencias/medias/oc/info/cargo/${props.cargoId}`
      );

      setOccurrencesData(response.data);
    } catch {}
  }, []);

  const getOcorrencesStage = useCallback(async (stageId: number) => {
    try {
      const response = await api.get(
        `/delivery/conhecimentosOcorrencias/medias/oc/info/cargo/${props.cargoId}/stage/${stageId}`
      );

      setOccurrenceList(response.data);
      setIsOccurrence(!isOccurrence);

      // setOccurrences(response.data);
    } catch (err) {}
  }, []);

  const getPositions = useCallback(async (data: any) => {
    try {
      // const response = await api.get(
      //   `/delivery/motoristasPosicoes/redis/${motoristaId}`
      // );

      setPositions({
        latitude: data.endereco.latitude,
        longitude: data.endereco.longitude,
      });

      setIsOpenPositionDriver(!isOpenPositionDriver);
    } catch {}
  }, []);

  useEffect(() => {
    getCargoStage();
    getSmsPhases();
    getCargoPackages();
    getOccurrencesData();
    getOccurrencesCargo();
    // getOcorrencesStage();
  }, [
    getCargoStage,
    getSmsPhases,
    getCargoPackages,
    getOccurrencesData,
    getOccurrencesCargo,
  ]);

  return (
    <CustomModal
      isOpen
      label={`Nº da
    ${String(props.cargoType).includes('COLETA') ? 'Coleta' : 'Entrega'}:
    ${props.cargoNumber}`}
      isClose={() => props.onClear()}
      styles={{ minWidth: '800px' }}
    >
      <>
      {isOpenPhoto && (
        <ModalSignature title="Foto" isEmpty={false} urlImage={imageUrl} onClear={() => setIsOpenPhoto(!isOpenPhoto)} type="FOTO" />
      )}
      {isOpenSignature && (
        <ModalSignature title="Assinatura" isEmpty={false} urlImage={imageUrl} onClear={() => setIsOpenSignature(!isOpenSignature)} type="ASSINATURA" />
      )}
        {isOccurrence && (
          <OcorrencesDelivery
            cargoId={props.cargoId}
            data={occurrenceList}
            onClear={() => setIsOccurrence(!isOccurrence)}
          />
        )}

        {isOpenPositionDriver && (
          <GetPositionDriver
            latitude={positions.latitude}
            longitude={positions.longitude}
            onClear={() => {
              setPositions({
                latitude: 0,
                longitude: 0,
              });
              setIsOpenPositionDriver(!isOpenPositionDriver);
            }}
          />
        )}

        {isOpenSMS && (
          <SMSMessage
            onClear={() => setIsOpenSMS(!isOpenSMS)}
            data={cargoSMS}
            etapaId={stageId}
            knowledgeId={props.cargoId}
          />
        )}
        {isOpenPackage && (
          <ListPackages
            onClear={() => setIsOpenPackage(!isOpenPackage)}
            knowledgePackageId={props.cargoId}
            phasesPackageId={stageId}
          />
        )}

        <div className="tw-w-full tw-h-[2px] tw-bg-[#ccc] tw-mb-4" />
        {occurrenceData.length > 0 && (
          <div className="tw-bg-[#EDF2F4] tw-rounded-r-xl tw-rounded-t-xl tw-h-24 tw-flex tw-mb-4 tw-p-1 tw-pl-3 tw-flex-col tw-w-[550px] tw-ml-[12%]">
            <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
              <div className="tw-flex tw-flex-col">
                <p className="tw-w-fit tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60 tw-mb-1">
                  <Trans i18nKey="detailsDelivery.phases" />
                </p>
                <p className="tw-w-fit tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60">
                  Em trânsito
                </p>
              </div>
            </div>
            <div
              className="tw-flex tw-items-center tw-mr-4 tw-ml-0 tw-cursor-pointer"
              onClick={() => {
                setIsOccurrence(!isOccurrence);

                setOccurrenceList(occurrenceData);
              }}
            >
              <p className="tw-text-sm tw-text-[#EA004C] tw-font-bold tw-mt-0 tw-mb-0">
                Ocorrências Informativas
              </p>
            </div>
          </div>
        )}
        {details.map((row: CargoStage, index: number) => (
          <>
            <div className="tw-flex tw-relative">
              <div className="tw-flex tw-flex-col">
                <div
                  className="tw-bg-[#fff] tw-w-[74px] tw-h-[74px] tw-rounded-[50%] tw-flex tw-items-center tw-justify-center"
                  style={{
                    border: '12px solid #6369D1',
                    boxShadow: '5px 5px 12px #6369D1',
                  }}
                >
                  {row.etapa.tipo === 'INICIO_ENTREGA' ? (
                    <img src={truckOrder} alt="" />
                  ) : row.etapa.tipo === 'SAINDO_ENTREGA' ? (
                    <img src={saindoEntrega} alt="" />
                  ) : row.etapa.tipo === 'CONFIRMACAO_ENTREGA' ? (
                    <img src={airplaneOrder} alt="" />
                  ) : (
                    <img src={shipOrder} alt="" />
                  )}
                </div>
                {index + 1 === details.length ? (
                  <></>
                ) : (
                  <div className="tw-bg-[#6369D1] tw-h-10 tw-w-[4px] tw-relative tw-left-9 tw-z-[4px]" />
                )}
              </div>
              <div className="tw-absolute tw-top-0 tw-ml-10">
                <div className="tw-flex tw-flex-col">
                  <div className="tw-flex tw-flex-col">
                    <div className="tw-flex tw-flex-row tw-z-[4px]">
                      <div className="tw-bg-[#EDF2F4] tw-rounded-r-xl tw-rounded-t-xl tw-h-24 tw-flex tw-ml-14 tw-mb-11 tw-p-1 tw-pl-3 tw-flex-col tw-w-[550px]">
                        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
                          <div className="tw-flex tw-flex-col tw-mr-4">
                            <p className="tw-w-fit tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60 tw-mb-1">
                              <Trans i18nKey="detailsDelivery.phases" />
                            </p>
                            <p className="tw-w-fit tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60">
                              {row.etapa.descricao}
                            </p>
                          </div>
                          <div className="tw-flex tw-flex-col tw-mr-4">
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60 tw-mb-1">
                              <Trans i18nKey="detailsDelivery.initialTime" />
                            </p>
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60">
                              {format(new Date(row.horarioInicial), 'HH:mm', {
                                locale: pt,
                              })}
                            </p>
                          </div>
                          <div className="tw-flex tw-flex-col tw-mr-4">
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60 tw-mb-1">
                              <Trans i18nKey="detailsDelivery.finalTime" />
                            </p>
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60">
                              {format(new Date(row.horarioFinal), 'HH:mm', {
                                locale: pt,
                              })}
                            </p>
                          </div>
                          <div className="tw-flex tw-flex-col tw-mr-4">
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60 tw-mb-1">
                              <Trans i18nKey="detailsDelivery.expectedDuration" />
                            </p>
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60">
                              {row.etapa.tempo !== null
                                ? `${row.etapa.tempo}min`
                                : '----'}
                            </p>
                          </div>
                          <div className="tw-flex tw-flex-col tw-mr-4">
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60 tw-mb-1">
                              <Trans i18nKey="detailsDelivery.duration" />
                            </p>
                            <p className="tw-text-sm tw-font-bold tw-text-[#003049] tw-opacity-60">
                              {row.duracao !== null
                                ? `${row.duracao}min`
                                : '----'}
                            </p>
                          </div>
                        </div>

                        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mt-0">
                          {row.etapa.posicao && (
                            <div className="tw-flex tw-items-center tw-mr-4 tw-cursor-pointer"  onClick={() =>
                              getPositions(
                                row
                              )
                            }>
                              <img src={location} alt="" />
                              <p className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-3 tw-mt-0 tw-mb-0">
                                <Trans i18nKey="detailsDelivery.posicao" />
                              </p>
                            </div>
                          )}
                          {row.etapa.foto && (
                            <div className="tw-flex tw-items-center tw-mr-4 tw-cursor-pointer" onClick={() => loadPhoto(row.midias)}>
                              <img src={photo} alt="" />
                              <p className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-3 tw-mt-0 tw-mb-0">
                                <Trans i18nKey="detailsDelivery.photo" />
                              </p>
                            </div>
                          )}
                          {row.etapa.assinatura && (
                            <div className="tw-flex tw-items-center tw-mr-4 tw-cursor-pointer" onClick={() => loadSignatures(row.midias)}>
                              <img src={signatures} alt="" />
                              <p className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-3 tw-mt-0 tw-mb-0">
                                <Trans i18nKey="detailsDelivery.signatures" />
                              </p>
                            </div>
                          )}
                          {getSMS(row.etapa.id) && (
                            <div className="tw-flex tw-items-center tw-mr-4 tw-cursor-pointer" onClick={() => {
                              setStageId(row.etapa.id);
                              setIsOpenSMS(!isOpenSMS);
                            }}>
                              <img src={iconSMS} alt="" />
                              <p className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-3 tw-mt-0 tw-mb-0">
                                <Trans i18nKey="detailsDelivery.sms" />
                              </p>
                            </div>
                          )}
                          {getPackages(row.etapa.id) && (
                            <div className="tw-flex tw-items-center tw-mr-4 tw-cursor-pointer" onClick={() => {
                              setStageId(row.etapa.id);
                              setIsOpenPackage(!isOpenPackage);
                            }}>
                              <img src={packageIcon} alt="" />
                              <p className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-3 tw-mt-0 tw-mb-0">
                                <Trans i18nKey="packages.title" />
                              </p>
                            </div>
                          )}

                          {row.etapa !== null && getOcorrences(row.etapa.id) && (
                            <div
                              className="tw-flex tw-items-center tw-mr-4 tw-cursor-pointer"
                              onClick={() => {
                                getOcorrencesStage(row.etapa.id);
                              }}
                            >
                              <p className="tw-text-sm tw-text-[#EA004C] tw-font-bold tw-ml-3 tw-mt-0 tw-mb-0">
                                Ocorrências Informativas
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
        <div className="tw-mb-4" />
      </>
    </CustomModal>
  );
};

export default DetailsDelivery;
