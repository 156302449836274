import { ColDef, ICellRendererParams, SelectionChangedEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { RowSelection } from 'components/Grid/types';
import React, { useCallback, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import ParameterValue from './components/ParameterValue';
// import './styles.css';

// import { Container } from './styles';

interface Props {
  baseURL: string;
}

const ListParameters: React.FC<Props> = (props: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);

  const [columns] = useState<ColDef[]>([
    {
      field: 'key',
      headerName: 'Parâmetros',
    },
    {
      field: 'value',
      headerName: 'Valor',
      valueGetter: (params: any) => params.data.value, // Pegamos o dia Monday
      valueSetter: (params: any) => {
        params.data.value = params.newValue;
        return true;
      },
      cellRenderer: (params: ICellRendererParams) => {
        return <ParameterValue data={params} baseURL={props.baseURL} />
      }
    },
    {
      field: 'description',
      headerName: 'Descrição',
      width: 450
    },
  ]);
  const [rowData, setRowData] = useState([]);

  const onGridReady = useCallback(async () => {
    try {

      const response = await api.get(props.baseURL + '/custom');

      setRowData(response.data);
    } catch {}
  }, []);

  return (
    <Row className="tw-h-96 ag-theme-quartz">
      <Col>
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          getRowId={(params: any) => params.data.id}
          columnDefs={columns}
          pagination
          onSelectionChanged={(params: SelectionChangedEvent) => {
            // const rows: any[] = params.api.getSelectedRows();
            // setSelectedRows(rows);
            // params.api.refreshCells({
            //   force: true,
            // });
          }}
          rowSelection={RowSelection.MULTIPLE}
          onGridReady={onGridReady}
          suppressRowClickSelection
        />
      </Col>
    </Row>
  );
};

export default ListParameters;
