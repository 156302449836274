import {
    FrontendNotification,
    NotificationType,
} from 'components/Notification';
import { GroupedRow } from './types';

// Verifica se o campo released está preenchido no agrupamento.
export const checkIfExistsReleased = (rows: GroupedRow[]): boolean => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].released) {
      FrontendNotification(
        'O agrupamento ' + rows[i].grouper + ' consta como liberado',
        NotificationType.WARNING
      );
      return false;
    }
  }

  return true;
};

// Verifica se o campo created está preenchido no agrupamento.
export const checkIfExistsCreated = (rows: GroupedRow[]): boolean => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].created) {
      FrontendNotification(
        'O agrupamento ' + rows[i].grouper + ' consta como criado',
        NotificationType.WARNING
      );
      return false;
    }
  }

  return true;
};

// Verifica se o campo customer está preenchido no agrupamento.
export const checkIfTotalNumberOfCustomersIsNonZero = (
  rows: GroupedRow[]
): boolean => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].customer === 0) {
      FrontendNotification(
        'O agrupamento ' +
          rows[i].grouper +
          ' possui o total de clientes zerado',
        NotificationType.WARNING
      );
      return false;
    }
  }
  return true;
};

// Verifica se a data de previsão foi informada no agrupamento.
export const checkIfExistsEstimateDate = (rows: GroupedRow[]): boolean => {
  for (let i = 0; i < rows.length; i++) {
    if (!rows[i].estimateDate) {
      FrontendNotification(
        'O agrupamento ' + rows[i].grouper + ' não consta a data de previsão',
        NotificationType.WARNING
      );
      return false;
    }
  }

  return true;
};

// Verifica se a data de previsão foi informada no agrupamento.
export const checkIfGrouperDetailed = (rows: GroupedRow[]): boolean => {
  for (let i = 0; i < rows.length; i++) {
    console.log(rows);
    if (!rows[i].licensePlate) {
      FrontendNotification(
        'O agrupamento ' + rows[i].grouper + ' não está detalhado.',
        NotificationType.WARNING
      );
      return false;
    }
  }

  return true;
};

// Verifica se a placa foi informada no agrupamento.
export const checkIfExistsRouting = (rows: GroupedRow[]): boolean => {
  for (let i = 0; i < rows.length; i++) {
    if (!rows[i].routing) {
      FrontendNotification(
        'O agrupamento ' + rows[i].grouper + ' não está roteirizado',
        NotificationType.WARNING
      );
      return false;
    }
  }

  return true;
};

// Verifica se a placa foi informada no agrupamento.
export const checkIfExistsLicensePlate = (rows: GroupedRow[]): boolean => {
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].created) {
      FrontendNotification(
        'O agrupamento ' + rows[i].grouper + ' não consta a placa',
        NotificationType.WARNING
      );
      return false;
    }
  }

  return true;
};

// Regras de negócio básicas para qualquer operação.
export const checkBasicRn = (rows: GroupedRow[]): boolean => {
  if (!checkIfExistsReleased(rows)) {
    return false;
  } else if (!checkIfExistsCreated(rows)) {
    return false;
  } else if (!checkIfTotalNumberOfCustomersIsNonZero(rows)) {
    return false;
  }

  return true;
};

// Regras de negócio referente a operação de roteirização.
export const checkRoutingRn = (rows: GroupedRow[]): boolean => {
  if (!checkIfGrouperDetailed(rows)) {
    return false;
  } else if (!checkIfExistsParentIdInChildInGroupedRow(rows)) {
    return false;
  }

  return true;
};

// Verifica se existe algum registro com o id do pai e se esse id pai está presente no mesmo agrupamento.
// Caso não esteja, não será possível realizar a operação.
const checkIfExistsParentIdInChildInGroupedRow = (
  groupedRows: GroupedRow[]
): boolean => {
  // Percorre os agrupamentos selecionados e verifica se
  // se existe algum registro, do agrupamento, que tenha uma ligação com um pai.
  // Essa ligação é feita através do id de referência.
  for (let i = 0; i < groupedRows.length; i++) {
    let rows = groupedRows[i].rows;
    let parentIdFound = null;

    for (let x = 0; x < rows.length; x++) {
      if (
        rows[x].parentReferenceId !== '' &&
        rows[x].parentReferenceId !== null
      ) {
        parentIdFound = rows[x].parentReferenceId;
      }

      // Só realiza a verificação caso encontre um parent id.
      if (parentIdFound !== null) {
        let referenceIdFound = null;

        if (parentIdFound != null) {
          for (let y = 0; y < rows.length; y++) {
            if (parentIdFound === rows[y].idReferencia) {
              referenceIdFound = rows[y].idReferencia;
              break;
            }
          }
        }

        if (referenceIdFound === null) {
          FrontendNotification(
            'A carga [' +
              parentIdFound +
              '] não foi encontrada no agrupamento [' +
              groupedRows[i].grouper +
              ']',
            NotificationType.WARNING
          );
          return false;
        }
      }
    }
  }

  return true;
};

// Verifica se há mais de um registro selecionado
export const hasMoreThanOneSelected = (rows: GroupedRow[]) => {
  if (rows != null && rows.length > 1) {
    FrontendNotification(
      'É necessário selecionar apenas um registro.',
      NotificationType.WARNING
    );

    return true;
  }
  return false;
};
