import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import EditIcon from 'assets/images/editIcon.svg';
import DriverShift from 'components/Driver/DriverShift';
import formValidatorEN from 'components/Driver/formValidatorEN';
import formValidatorENEdit from 'components/Driver/formValidatorEN-Edit';
import formValidatorPT from 'components/Driver/formValidatorPT';
import formValidatorPTEdit from 'components/Driver/formValidatorPTEdit';
import InputCustom from 'components/Input';
import CustomModal from 'components/ModalCustom';
import SearchFilter from 'components/SearchFilter';
import SelectCustom from 'components/SelectCustom';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Input,
  Label, Modal, ModalBody, ModalFooter, Row
} from 'reactstrap';
import api from 'services/api';
import { responsiveSize } from 'styles/responsive';
import hidePasswordIcon from '../../assets/images/hidePasswordIcon.png';
import lockedClose from '../../assets/images/lockedClose.svg';
import lockedOpen from '../../assets/images/lockedOpen.svg';
import showPasswordIcon from '../../assets/images/showPasswordIcon.png';
import { BoxForm, Content, Footer, IconColumn } from './styles';
import './styles.scss';

interface Props {
  onClear?: any;
}

interface ILogisticOperator {
  cnpj?: string;
  id?: number;
  nomeFantasia?: string;
  razaoSocial?: string;
}

interface ISubsidiaryData {
  id: number;
  nome: string;
}

interface FormValues {
  cpf: string;
  ctps: string;
  nome: string;
  cargo: string;
  tipo: string;
  operadorLogistico: string;
  telefone: string;
  password: string;
  confirmarSenha: string;
  filial: string;
}

const Driver: React.FC<Props> = ({ onClear }: Props) => {
  const { t } = useTranslation();

  const [updateId, setUpdateId] = React.useState(null);
  const [initialPosition, setInitialPosition] = useState([-8.0527, -34.9099]);
  const [edit, setEdit] = useState(false);
  const [driver, setDriver] = useState<any>([]);
  const [filters, setFilters] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [optionsFilter, setOptionsFilter] = useState<string>('nome');
  const [valueSearch, setValueSearch] = useState<string>('');
  const [passwordDisabled, setPasswordDisabled] = useState<boolean>(true);
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);
  const [isVisibleConfirmPassword, setIsVisibleConfirmPassword] = useState<
    boolean
  >(false);
  const [operatorLogistic, setOperatorLogistic] = useState<any>([]);
  const [filiais, setFiliais] = useState<any>([]);
  const [driverShift, setDriverShift] = useState(false);
  const [blockedConfirmation, setBlockedConfirmation] = useState(false);
  const [unlockConfirmation, setUnlockConfirmation] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [blockedId, setBlockedId] = useState('');
  const [removeConfirmation, setRemoveConfirmation] = useState(false);

  const getDriver = useCallback(async () => {
    try {
      const languageFormat = navigator.language;
      const emailSendLanguage =
        languageFormat === 'pt' || languageFormat === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';
      const currentFilters = [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ];
      const data = {
        filters: filters.length > 0 ? filters : currentFilters,
        page: currentPage,
        size: 10,
        orders: [
          {
            field: 'id',
            direction: 'DESC',
          },
        ],
      };

      const response = await api.post(
        `/delivery/motoristas/pageable?lang=${emailSendLanguage}`,
        data
      );
      setTotalPages(Math.ceil(response.data.totalElements / 10));
      setDriver(response.data.content);
    } catch (err) {}
  }, [filters, currentPage]);

  function validaCpfCnpj(cpfCnpj: any) {
    const valorCpfCnpj = cpfCnpj.replace(/[^0-9]/g, '');

    let SomaFirst = 0;
    let SomaSecond = 0;
    let RestoFirst;
    let RestoSecond;
    let i;

    switch (valorCpfCnpj) {
      case '12345678909':
        return false;

      case '00000000000':
        return false;

      case '11111111111':
        return false;

      case '22222222222':
        return false;

      case '33333333333':
        return false;

      case '44444444444':
        return false;

      case '55555555555':
        return false;

      case '66666666666':
        return false;

      case '77777777777':
        return false;

      case '88888888888':
        return false;

      case '99999999999':
        return false;

      default:
        for (i = 0; i < 9; i++) {
          SomaFirst += parseInt(valorCpfCnpj[i], 10) * (11 - (i + 1));
          RestoFirst = (SomaFirst * 10) % 11;
        }

        if (RestoFirst === 10 || RestoFirst === 11) RestoFirst = 0;
        if (RestoFirst !== parseInt(valorCpfCnpj[9], 10)) return false;

        for (i = 0; i < 10; i++) {
          SomaSecond += parseInt(valorCpfCnpj[i], 10) * (12 - (i + 1));
          RestoSecond = (SomaSecond * 10) % 11;
        }

        if (RestoSecond === 10 || RestoSecond === 11) RestoSecond = 0;
        if (RestoSecond !== parseInt(valorCpfCnpj[10], 10)) return false;

        return true;
    }
  }

  function validatePhone(phone: any) {
    const brazilianPhoneRegex = /^\(\d{2}\) \d{4,5}-\d{4}$/gi;
    return brazilianPhoneRegex.test(phone);
  }

  const getOperatorLogistic = useCallback(async () => {
    try {
      const response = await api.get('/delivery/operadoresLogisticos');
      setOperatorLogistic(response.data);
    } catch (err) {}
  }, []);

  const getFiliais = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      setFiliais(response.data);
    } catch (err) {}
  }, []);

  const getCurrentPosition = useCallback(() => {
    const position = navigator.geolocation.getCurrentPosition(location => {
      setInitialPosition([location.coords.latitude, location.coords.longitude]);
    });
  }, []);

  const handleSubmit = useCallback(
    async (
      values,
      driverUpdate,
      resetForm,
      isEditPassword,
      isEdit,
      dataShiftDriver
    ) => {
      try {
        const shiftData = {
          horarioInicial: dataShiftDriver.horaInicial,
          horarioFinal: dataShiftDriver.horaFinal,
        };

        const isJourney = {
          id: parseInt(dataShiftDriver)
        }
        let data;
        if (values.operadorLogistico !== '') {
          data = {
            idReferencia: 4435543,
            filial: {
              id: values.filial,
            },
            cpf: values.cpf,
            nome: values.nome,
            // cpf: values.cpf,
            latitude: initialPosition[0],
            longitude: initialPosition[1],
            telefone: values.telefone,
            tipo: values.tipo,
            operadorLogistico: {
              id: values.operadorLogistico,
            },
            senha:
            values.password.length <= 0 ? null : isEdit && !isEditPassword
                ? values.password
                : isEdit === false
                ? values.password
                : !isEdit && isEditPassword ? values.password : null,
            journey: dataShiftDriver.length > 0 ? isJourney : null
          };
        } else {
          data = {
            idReferencia: 21421,
            filial: {
              id: values.filial,
            },
            cpf: values.cpf,

            nome: values.nome,
            // cpf: values.cpf,
            latitude: initialPosition[0],
            longitude: initialPosition[1],
            telefone: values.telefone,
            tipo: values.tipo,
            senha:
              values.password.length <= 0 ? null : isEdit && !isEditPassword
                ? values.password
                : isEdit === false
                ? values.password
                : !isEdit && isEditPassword ? values.password : null,
            journey: dataShiftDriver.length > 0 ? isJourney : null

            // horariosPausa: JSON.parse(sessionStorage.getItem('horariosPausa')),
          };
        }
        const languageFormat = navigator.language;
        const emailSendLanguage =
          languageFormat === 'pt' || languageFormat === 'pt-BR'
            ? 'pt_BR'
            : 'en_US';
        if (validatePhone(values.telefone)) {
          if (validaCpfCnpj(values.cpf)) {
            if (values.telefone.length < 15) {
              FrontendNotification(
                'Telefone Inválido!',
                NotificationType.ERROR
              );
            } else if (driverUpdate) {
              const response = await api.patch(
                `/delivery/motoristas/${driverUpdate}?lang=${emailSendLanguage}`,
                data
              );

              if (response.status) {
                FrontendNotification(
                  t('driver.message.updatedDriver'),
                  NotificationType.SUCCESS
                );
              }

              setEdit(false);
              setUpdateId(null);
            } else {
              const response = await api.post(
                `/delivery/motoristas?lang=${emailSendLanguage}`,
                data
              );

              if (response.data) {
                FrontendNotification(
                  t('driver.message.createdDriver'),
                  NotificationType.SUCCESS
                );
              }
            }

            resetForm({});
          } else {
            FrontendNotification(
              t('form.field.message.driverCpfInvalid'),
              NotificationType.ERROR
            );
          }
        } else {
          FrontendNotification(
            t('form.field.message.driverPhoneInvalid'),
            NotificationType.ERROR
          );
        }

        getDriver();
        sessionStorage.setItem('journeyId', '');
      } catch (err) {}
    },
    []
  );

  function selectValidation() {
    let form;
    if (
      (edit && navigator.language === 'pt') ||
      (edit && navigator.language === 'pt-BR')
    ) {
      form = formValidatorPTEdit;
    } else if (
      (edit && navigator.language === 'en') ||
      (edit && navigator.language === 'en-US')
    ) {
      form = formValidatorENEdit;
    } else if (navigator.language === 'pt' || navigator.language === 'pt-BR') {
      form = formValidatorPT;
    } else if (navigator.language === 'en' || navigator.language === 'en-US') {
      form = formValidatorEN;
    } else {
      form = formValidatorEN;
    }
    return form;
  }

  const cpfMask = (value: any) => {
    return value
      .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
  };

  const initialValues: FormValues = {
    cpf: '',
    ctps: '',
    cargo: '',
    nome: '',
    telefone: '',
    tipo: '',
    operadorLogistico: '',
    password: '',
    confirmarSenha: '',
    filial: '',
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: selectValidation(),
    onSubmit: (values, { resetForm }) =>
      handleSubmit(
        values,
        updateId,
        resetForm,
        passwordDisabled,
        edit,
        sessionStorage.getItem('journeyId') || ''
      ),
  });

  const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);


  const deleteDriver = useCallback(async (driverId: any) => {
    try {
      // setRemoveConfirmation(true);
      const language  = navigator.language;

      const erroLanguage =
        language === 'pt' || language === 'pt-BR' ? 'pt_BR' : 'en_US';
      const response = await api.delete(
        `/delivery/motoristas/${driverId}?lang=${erroLanguage}`
      );
      if (response.status === 204) {
        FrontendNotification(
          t('Motorista removido com sucesso!'),
          NotificationType.SUCCESS
        );
      }
      setRemoveConfirmation(false);
      getDriver();
    } catch (err) {
    }
  }, []);

  useEffect(() => {
    getDriver();

    getCurrentPosition();
    getOperatorLogistic();
    getFiliais();
    formik.resetForm({});
  }, [getDriver, getOperatorLogistic, getFiliais]);

  return (
    <CustomModal
      isOpen
      label={t('driver.title')}
      isClose={onClear}
      styles={{ maxWidth: '95%' }}
    >
      <Modal isOpen={removeConfirmation} toggle={toggleRemove}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => deleteDriver(removeId)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={toggleRemove}
            id="buttonCancelRemove"
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      {driverShift ? (
        <DriverShift onClear={() => setDriverShift(!driverShift)} journeySelected={sessionStorage.getItem('journeyId')} />
      ) : (
        <></>
      )}
      <SearchFilter
        isName
        isType
        isCPF
        isService
        isSubsidiary
        isTypeSubsidiary={optionsFilter === 'subsidiary'}
        // isTypeDriver={optionsFilter === 'tipo'}
        options={filiais}
        valueSearch={valueSearch}
        optionValue={optionsFilter}
        handleChange={(e: any) => setValueSearch(e.target.value)}
        handleSelect={(e: any) => setOptionsFilter(e.target.value)}
        handleSelectDriver={(e: any) => setValueSearch(e.target.value)}
        onExecuteFilter={() => {
          setCurrentPage(0);
          if (optionsFilter === 'nome' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'nome',
                value: valueSearch,
                operation: 'MATCH',
              },
            ]);
          } else if (optionsFilter === 'tipo' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'tipo',
                value: valueSearch,
                operation: 'EQUAL',
              },
            ]);
          } else if (optionsFilter === 'cpf' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'cpf',
                value: valueSearch,
                operation: 'MATCH',
              },
            ]);
          } else if (optionsFilter === 'subsidiary' && valueSearch.length > 0) {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
              {
                field: 'filial.id',
                value: valueSearch,
                operation: 'EQUAL',
              },
            ]);
          } else {
            setFilters([
              {
                field: 'status',
                value: 'ACTIVE',
                operation: 'EQUAL',
              },
            ]);
          }
        }}
      />
      <div className="d-flex">
        <BoxForm>
          <Row>
            <Col md="12">
              <InputCustom
                title="CPF"
                typeInput="mask"
                mask="999.999.999-99"
                placeholder={t('driver.placeholder.cpf')}
                onChange={formik.handleChange('cpf')}
                value={formik.values.cpf}
                touched={formik.touched.cpf}
                error={formik.errors.cpf}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <InputCustom
                title={t('driver.fields.name')}
                placeholder={t('driver.placeholder.name')}
                onChange={formik.handleChange('nome')}
                value={formik.values.nome}
                touched={formik.touched.nome}
                error={formik.errors.nome}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <SelectCustom
                title={t('driver.fields.type')}
                onChange={formik.handleChange('tipo')}
                value={formik.values.tipo}
                touched={formik.touched.tipo}
                error={formik.errors.tipo}
                id='typeDriver'
              >
                <option label={t('common.select')} />
                <option
                  id="typeP"
                  label={t('driver.typeDriver.company')}
                  value="PROPRIO"
                />
                <option
                  id="type3P"
                  label={t('driver.typeDriver.contractor')}
                  value="AUTONOMO"
                />
                <option
                  id="type3PL"
                  label={t('driver.typeDriver.logistic')}
                  value="TERCEIRIZADO"
                />
              </SelectCustom>
            </Col>
          </Row>
          {formik.values.tipo === 'TERCEIRIZADO' && (
            <Row>
              <Col md={12}>
                <SelectCustom
                  title={t('driver.fields.operatorLogistic')}
                  onChange={formik.handleChange('operadorLogistico')}
                  value={formik.values.operadorLogistico}
                  touched={formik.touched.operadorLogistico}
                  error={formik.errors.operadorLogistico}
                  id='operatorLogistic'
                >
                  <option label={t('common.select')} />
                  {operatorLogistic.map((item: ILogisticOperator) => (
                    <option
                      key={item.id}
                      label={item.nomeFantasia}
                      value={item.id}
                    >
                      {item.nomeFantasia}
                    </option>
                  ))}
                </SelectCustom>
              </Col>
            </Row>
          )}

          <Row>
            <Col md={6}>
              <InputCustom
                title={t('driver.fields.phone')}
                typeInput="mask"
                mask="(99) 99999-9999"
                placeholder={t('driver.placeholder.phone')}
                onChange={formik.handleChange('telefone')}
                value={formik.values.telefone}
                touched={formik.touched.telefone}
                error={formik.errors.telefone}
              />
            </Col>
            <Col md={6}>
              <button
                className="button journey"
                id="journey"
                type="button"
                style={{ width: '100%', marginTop: responsiveSize(44) }}
                onClick={() => {
                  setDriverShift(!driverShift);
                }}
              >
                Jornada
              </button>
            </Col>
          </Row>
          <>
            <Label>
              <div style={{ display: 'flex', position: 'relative' }}>
                <span style={{ fontSize: responsiveSize(14) }}>
                  <Trans i18nKey="users.fields.password" />
                </span>
                {edit && (
                  <div style={{ position: 'absolute', right: 0 }}>
                    <span
                      onClick={() => {
                        setPasswordDisabled(!passwordDisabled);
                        formik.setFieldValue('password', '');
                        formik.setFieldValue('confirmarSenha', '');
                      }}
                      style={{
                        fontFamily: 'Lato',
                        fontSize: responsiveSize(14),
                        fontWeight: 'bold',
                        color: '#EA004C',
                        cursor: 'pointer',
                      }}
                    >
                      Alterar Senha
                    </span>
                  </div>
                )}
              </div>
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', right: 14, top: 5 }}>
                  {isVisiblePassword ? (
                    <img
                      src={hidePasswordIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                    />
                  ) : (
                    <img
                      src={showPasswordIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                    />
                  )}
                </div>
                <Input
                  type={isVisiblePassword ? 'text' : 'password'}
                  disabled={edit ? passwordDisabled : false}
                  onChange={formik.handleChange('password')}
                  placeholder={t('form.field.placeholder.password')}
                  autocomplete="new-password"
                  style={{
                    // maxWidth: 350,
                    height: responsiveSize(35),
                    background: edit && passwordDisabled ? '#ccc' : '#FFFFFF',
                    border: `${responsiveSize(2)} solid rgba(0, 48, 73, 0.2)`,
                    boxSizing: 'border-box',
                    borderRadius: responsiveSize(10),
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: responsiveSize(14),
                    lineHeight: responsiveSize(19),
                    color: '#003049',
                    marginTop: responsiveSize(8),
                  }}
                  value={formik.values.password}
                />
              </div>
            </Label>

            {formik.touched.password && formik.errors.password && (
              <p style={{ fontSize: 14, color: 'red' }}>
                {formik.errors.password}
              </p>
            )}
            {navigator.language === 'pt' || navigator.language === 'pt-BR' ? (
              <span
                style={{
                  fontFamily: 'Lato',
                  fontSize: responsiveSize(14),
                  fontWeight: 300,
                  color: '#003049',
                  marginBottom: responsiveSize(10),
                }}
              >
                A senha deve possuir pelo menos 6 caracteres, contendo: <br />
                uma letra e um número
              </span>
            ) : (
              <span
                style={{
                  fontFamily: 'Lato',
                  fontSize: responsiveSize(14),
                  fontWeight: 300,
                  color: '#003049',
                  marginBottom: responsiveSize(10),
                }}
              >
                The password must be at least 6 characters long, containing:
                <br />a letter and a number
              </span>
            )}

            <Label>
              <span style={{ fontSize: responsiveSize(14) }}>
                <Trans i18nKey="users.fields.confirmPassword" />
              </span>
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    right: responsiveSize(14),
                    top: responsiveSize(5),
                  }}
                >
                  {isVisibleConfirmPassword ? (
                    <img
                      src={hidePasswordIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setIsVisibleConfirmPassword(!isVisibleConfirmPassword)
                      }
                    />
                  ) : (
                    <img
                      src={showPasswordIcon}
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        setIsVisibleConfirmPassword(!isVisibleConfirmPassword)
                      }
                    />
                  )}
                </div>

                <Input
                  type={isVisibleConfirmPassword ? 'text' : 'password'}
                  disabled={edit ? passwordDisabled : false}
                  onChange={formik.handleChange('confirmarSenha')}
                  placeholder={t('driver.placeholder.confirmPassword')}
                  style={{
                    // maxWidth: 350,
                    height: responsiveSize(35),
                    background: edit && passwordDisabled ? '#ccc' : '#FFFFFF',
                    border: `${responsiveSize(2)} solid rgba(0, 48, 73, 0.2)`,
                    boxSizing: 'border-box',
                    borderRadius: responsiveSize(10),
                    fontFamily: 'Lato',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: responsiveSize(14),
                    lineHeight: responsiveSize(19),
                    color: '#003049',
                    marginTop: responsiveSize(8),
                  }}
                  value={formik.values.confirmarSenha}
                />
              </div>
            </Label>

            {formik.touched.confirmarSenha && formik.errors.confirmarSenha && (
              <p style={{ fontSize: 14, color: 'red' }}>
                {formik.errors.confirmarSenha}
              </p>
            )}
          </>
          <Row>
            <Col md={12}>
              <SelectCustom
                title={t('filiais.driverTitle')}
                onChange={formik.handleChange('filial')}
                value={formik.values.filial}
                touched={formik.touched.filial}
                error={formik.errors.filial}
                id='subsidiaryList'
              >
                <option
                  style={{ fontSize: responsiveSize(9) }}
                  label={t('common.select')}
                />
                {filiais.map((item: ISubsidiaryData) => (
                  <option value={item.id} label={item.nome} />
                ))}
              </SelectCustom>
            </Col>
          </Row>
          <Footer>
            <Row>
              {edit ? (
                <>
                  <Col md={6}>
                    <button
                      onClick={() => {
                        formik.resetForm({});
                        setEdit(false);
                        setUpdateId(null);
                        sessionStorage.setItem('journeyId', '')
                      }}
                      id="cancel"
                      className="button"
                      type="button"
                      style={{
                        width: '100%',
                        background: '#ccc',
                        color: '#000',
                      }}
                    >
                      <Trans i18nKey="common.cancel" />
                    </button>
                  </Col>

                  <Col md={6}>
                    <button
                      onClick={() => formik.handleSubmit()}
                      id="update"
                      className="button"
                      type="button"
                      style={{ width: '100%' }}
                    >
                      <Trans i18nKey="driver.update" />
                    </button>
                  </Col>
                </>
              ) : (
                <Col md={12} xs={12}>
                  <button
                    onClick={() => formik.handleSubmit()}
                    className="button"
                    id="save"
                    type="button"
                    style={{ width: '100%' }}
                  >
                    <Trans i18nKey="driver.title" />
                  </button>
                </Col>
              )}
            </Row>
          </Footer>
        </BoxForm>
        <Content
          style={{
            height: '50%',
            marginLeft: responsiveSize(20),
            padding: responsiveSize(40),
            paddingLeft: responsiveSize(50),
            marginTop: '50px !important',
          }}
        >
          <div className="table-responsive">
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="driver.fields.cpf" />
                  </th>
                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="driver.fields.name" />
                  </th>

                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="driver.fields.type" />
                  </th>
                  <th
                    style={{
                      fontSize: responsiveSize(18),
                      color: '#003049',
                      fontWeight: 'bold',
                    }}
                  >
                    <Trans i18nKey="driver.fields.phone" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {driver.map((item: any, index: number) => (
                  <tr className="lineGroup">
                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {item.cpf !== null ? cpfMask(item.cpf) : ''}
                    </td>
                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {item.nome}
                    </td>

                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {item.tipo === 'PROPRIO'
                        ? 'Próprio'
                        : item.tipo === 'AUTONOMO'
                        ? 'Autônomo'
                        : 'Terceirizado'}
                    </td>
                    <td
                      style={{ fontSize: responsiveSize(16), color: '#003049' }}
                    >
                      {item.telefone}
                    </td>
                    <IconColumn>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          if (item.bloqueado === true) {
                            setUnlockConfirmation(!blockedConfirmation);
                            setBlockedId(item);
                          } else {
                            setBlockedConfirmation(!unlockConfirmation);
                            setBlockedId(item);
                          }
                        }}
                      >
                        {item.bloqueado ? (
                          <img src={lockedClose} />
                        ) : (
                          <img src={lockedOpen} />
                        )}
                      </Button>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          // setPasswordDisabled(true);

                          formik.setFieldValue('nome', item.nome);
                          formik.setFieldValue('tipo', item.tipo);
                          formik.setFieldValue('telefone', item.telefone);
                          formik.setFieldValue('cpf', item.cpf);
                          formik.setFieldValue('filial', item.filial.id);
                          formik.setFieldValue('senha', '');
                          formik.setFieldValue('confirmarSenha', '');
                          if (item.journey !== null) {
                            sessionStorage.setItem('journeyId', item.journey.id);
                          }
                          if (item.filial && item.filial !== null) {
                            formik.setFieldValue('filial', item.filial.id);
                          }

                          if (
                            item.operadorLogistico &&
                            item.operadorLogistico !== null
                          ) {
                            formik.setFieldValue(
                              'operadorLogistico',
                              item.operadorLogistico.id
                            );
                          }

                          setEdit(true);
                          setUpdateId(item.id);
                        }}
                      >
                        <img
                          src={EditIcon}
                          alt=""
                        />
                      </Button>
                      <Button
                        color="link"
                        className="noMargin"
                        onClick={() => {
                          setRemoveId(item.id);
                          setRemoveConfirmation(!removeConfirmation);
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                        />
                      </Button>
                    </IconColumn>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: 10,
              }}
            >
              <Pagination
                count={totalPages}
                variant="outlined"
                shape="rounded"
                onChange={(e: any, page: number) => {
                  setCurrentPage(page - 1)
                }}
              />
            </div>
          </div>
        </Content>
      </div>
    </CustomModal>
  );
};

export default Driver;
