import AddButton from 'assets/images/addButtonTimeWindow.svg';
import RemoveButton from 'assets/images/removeButtonTimeWindow.svg';
import InputCustom from 'components/Input';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import formValidator from './validators/formValidator';

// import { Container } from './styles';

interface FormValues {
  clienteNome: string;
  idReferencia: string;
}

interface Props {
  isEdit?: boolean;
  data?: any[];
  onConfirm: () => void;
}

const Create: React.FC<Props> = (props: Props) => {
  const [timeWindows, setTimeWindows] = useState<any[]>([
    {
      initialTime: '',
      finalTime: '',
    },
  ]);
  const [textValue, setTextValue] = useState('');

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: FormValues, timeWindows: any[]) => {
      try {
        const findTimeWindows = timeWindows.find(
          (item: any) =>
            item.initialTime.length === 0 || item.finalTime.length === 0
        );

        if (findTimeWindows) {
          FrontendNotification(
            'Preencha corretamente os campos do formulário!',
            NotificationType.WARNING
          );
        } else {
          const windowsDelivery = timeWindows.map((item: any) => {
            return {
              intervalo: {
                horarioInicial: item.initialTime,
                horarioFinal: item.finalTime,
              },
            };
          });
          const body = {
            nome: values.clienteNome,
            idReferencia: values.idReferencia,
            horariosEntregas: windowsDelivery,
          };

          if (props.isEdit && props.data && props.data.length > 0) {
            const response = await api.patch(
              `/delivery/clientes/${props.data[0].id}`,
              body
            );
            if (response.status === 204) {
              FrontendNotification(
                t('windowDelivery.messageUpdated'),
                NotificationType.SUCCESS
              );

              props.onConfirm();
            }
          } else {
            const response = await api.post('/delivery/clientes', body);

            if (response.status === 201) {
              FrontendNotification(
                t('windowDelivery.messageCreate'),
                NotificationType.SUCCESS
              );
              props.onConfirm();
            }
          }
        }
      } catch {}
    },
    []
  );

  const onLoadFormValues = useCallback(() => {
    const data = props.data;

    if (data) {
      formik.setFieldValue('clienteNome', data[0].nome);
      formik.setFieldValue('idReferencia', data[0].idReferencia);

      if (
        data[0].horariosEntregas !== null &&
        data[0].horariosEntregas.length > 0
      ) {
        const deliveryTimes = data[0].horariosEntregas.map((item: any) => {
          return {
            initialTime: item.intervalo.horarioInicial,
            finalTime: item.intervalo.horarioFinal,
          };
        });

        setTimeWindows([]);

        setTimeWindows(deliveryTimes);
      }
    }
  }, []);

  const initialValues: FormValues = {
    clienteNome: '',
    idReferencia: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues) => handleSubmit(values, timeWindows),
  });

  useEffect(() => {
    if (props.isEdit) {
      onLoadFormValues();
    }
  }, [onLoadFormValues]);

  return (
    <>
    <div className="tw-w-full tw-flex-col">
      <div className="tw-flex tw-w-full">
        <div className="tw-w-full tw-mr-2">
          <InputCustom
            title="Cliente"
            id="nameClient"
            onChange={formik.handleChange('clienteNome')}
            placeholder="Digite o nome do cliente"
            value={formik.values.clienteNome}
            touched={formik.touched.clienteNome}
            error={formik.errors.clienteNome}
          />
        </div>
        <div className="tw-w-full">
          <InputCustom
            title="Id de Referencia"
            id="referenceId"
            onChange={formik.handleChange('idReferencia')}
            placeholder="Digite o id ref. do cliente"
            value={formik.values.idReferencia}
            touched={formik.touched.idReferencia}
            error={formik.errors.idReferencia}
          />
        </div>
      </div>
      <div className='tw-max-h-[350px] tw-overflow-y-scroll'>
        {timeWindows.map((time: any, index: number) => {
          return (
            <div>
              {index === 0 ? (
                <div className="tw-flex tw-w-full tw-mt-2 tw-items-center">
                  <div className="tw-flex tw-flex-col tw-mr-3">
                    <label
                      htmlFor=""
                      className="tw-text-sm tw-text-[#003049] tw-font-bold"
                    >
                      Horário Inicial
                    </label>
                    <input
                      type="time"
                      id={`initialTime${index}`}
                      className="tw-w-14 tw-h-6"
                      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                        timeWindows[index].initialTime = e.target.value;
                      }}
                      value={time.initialTime}
                    />
                  </div>
                  <div className="tw-flex tw-flex-col">
                    <label
                      htmlFor=""
                      className="tw-text-sm tw-text-[#003049] tw-font-bold"
                    >
                      Horário Final
                    </label>
                    <input
                      type="time"
                      className="tw-w-14 tw-h-6"
                      id={`finalTime${index}`}
                      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                        timeWindows[index].finalTime = e.target.value;
                      }}
                      value={time.finalTime}
                    />
                  </div>
                  <div className="tw-mt-7">
                    {timeWindows.length > 1 ? (
                      <button
                        className="tw-bg-[#fff] tw-border-none"
                        onClick={() => {
                          console.log(timeWindows);

                          // const filter = timeWindows.filter((_, indexRemove) => indexRemove !== index);

                          setTimeWindows(
                            timeWindows.filter(
                              (_, indexRemove) => indexRemove !== index
                            )
                          );
                        }}
                      >
                        <img src={RemoveButton} />
                      </button>
                    ) : (
                      <button
                        className="tw-bg-[#fff] tw-border-none"
                        id="saveTimeWindows"
                        onClick={() => {
                          let windows = {
                            initialTime: '',
                            finalTime: '',
                          };

                          setTimeWindows([...timeWindows, windows]);
                        }}
                      >
                        <img src={AddButton} />
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="tw-flex tw-w-full tw-mt-2 tw-items-center">
                  <div className="tw-flex tw-flex-col tw-mr-11">
                    <input
                      type="time"
                      id={`initialTime${index}`}
                      className="tw-w-14 tw-h-6"
                      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                        timeWindows[index].initialTime = e.target.value;
                      }}
                      value={time.initialTime}
                    />
                  </div>
                  <div className="tw-flex tw-flex-col">
                    <input
                      type="time"
                      className="tw-w-14 tw-h-6"
                      id={`finalTime${index}`}
                      onFocus={(e: React.FocusEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                      }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setTextValue(e.target.value);
                        timeWindows[index].finalTime = e.target.value;
                      }}
                      value={time.finalTime}
                    />
                  </div>
                  <div className="tw-mt-1 tw-ml-7">
                    {index === timeWindows.length - 1 ? (
                      <button
                        className="tw-bg-[#fff] tw-border-none"
                        id="saveTimeWindows"
                        onClick={() => {
                          let windows = {
                            initialTime: '',
                            finalTime: '',
                          };

                          setTimeWindows([...timeWindows, windows]);
                        }}
                      >
                        <img src={AddButton} />
                      </button>
                    ) : (
                        <button
                          className="tw-bg-[#fff] tw-border-none"
                          onClick={() => {
                            
                            
                            setTimeWindows(timeWindows.filter((_, indexRemove) => indexRemove !== index));
                            }}
                            >
                            <img src={RemoveButton} />
                            </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
      <div className="tw-w-full tw-flex tw-justify-end tw-items-center tw-mt-4">
        <button
          className="tw-w-36 tw-h-9 tw-bg-[#10841C] tw-rounded-md tw-text-sm tw-text-[#fff] tw-font-bold tw-border-none"
          id="saveForm"
          onClick={() => formik.handleSubmit()}
        >
          Confirmar
        </button>
      </div>
      </>
  );
};

export default Create;
