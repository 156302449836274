import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import Create from './components/Create';
import { ICargoTypes } from './types/types';

interface Props {
  onClear: () => void;
}

const CargoTypes: React.FC<Props> = (props: Props) => {
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'nome',
      headerName: 'Tipo da Carga',
      flex: 1,
    },
  ]);
  const [rowData, setRowData] = useState<ICargoTypes[]>([]);
  const [selectedRow, setSelectedRow] = useState<ICargoTypes[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [tabs, setTabs] = useState<number>(0);

  const { t } = useTranslation();

  const onDelete = useCallback(async (id: number) => {
    try {
      const response = await api.delete(`/delivery/tipos/cargas/${id}`);

      if (response.status === 204) {
        FrontendNotification(
          t('cargoTypes.messageDeleteSuccess'),
          NotificationType.SUCCESS
        );
      }
      setIsRemove(false);

      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  return (
    <CustomModal
      isOpen
      label={t('cargoTypes.title')}
      isClose={() => props.onClear()}
      styles={{ minWidth: '40%' }}
    >
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => onDelete(selectedRow[0]?.id)}
      />
      <div className="tw-w-full tw-h-full tw-mt-2">
        <Tabs selectedIndex={tabs} onSelect={(index: number) => setTabs(index)}>
          <TabList>
            <Tab>
              <Trans i18nKey="tabs.list" />
            </Tab>
            <Tab>
              <Trans i18nKey="tabs.new" />
            </Tab>
            <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
          </TabList>
          <TabPanel>
            <div className="tw-w-full tw-h-96 tw-mt-4">
              <Grid<ICargoTypes>
                columns={columns}
                filters={[]}
                pagination
                path="/delivery/tipos/cargas/pageable"
                rowSelection={RowSelection.SINGLE}
                showCrudButtons={false}
                setRowData={setRowData}
                customCrudButtons
                selectedRows={selectedRow}
                setSelectedRows={setSelectedRow}
                onUpdate={(data?: ICargoTypes) => {
                  let rows: any = [];
                  rows.push(data);
                  setSelectedRow(rows);
                  setTabs(2);
                }}
                onDelete={(data?: any) => {
                  let rows = [];
                  rows.push(data);
                  setSelectedRow(rows);
                  setIsRemove(!isRemove)
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <Create onClose={() => setTabs(0)} />
          </TabPanel>
          <TabPanel>
            <Create row={selectedRow[0]} isEdit onClose={() => setTabs(0)} />
          </TabPanel>
        </Tabs>
      </div>
    </CustomModal>
  );
};

export default CargoTypes;
