import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  styled
} from '@mui/material';
import { GroupedRow, Team } from 'components/CreateCargo/types';
import React from 'react';
import DetailsForm from './DetailsForm';
import {
  CloseModal,
  Container,
  Title
} from './styles';

interface DetailsCargosProps {
  open: boolean;
  onClose: () => void;
  selectedRow: GroupedRow | null;
  update: (licensePlate: string, estimateDate: Date | null, teamList: Team[], roundtrip: boolean, destiny: string) => void
  updateRow: (roteirizacao: boolean, id_simulation: string, licensePlate: string, estimatedDate: Date, destiny: string, teamList: Team[], roundtrip: boolean) => void;
}

const DetailsCargos: React.FC<DetailsCargosProps> = ({ open, onClose, selectedRow, update, updateRow }: DetailsCargosProps) => {

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiPaper-root': {
      borderRadius: '0.313rem',
      width: '800px !important',
    },
    '& .MuiDialogTitle-root': {
      background: '#EA004C',
      height: '2rem',
      padding: '0.313rem',
      borderRadius: '0.313rem 0.313rem 0 0',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));


  console.log(selectedRow);

  return (
    <BootstrapDialog
      onClose={() => onClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title">
        <Container>         
          <Title>Detalhes da Carga</Title>
          <CloseModal onClick={() => onClose()}>
            <CloseIcon style={{ color: 'white' }} />
          </CloseModal>
        </Container>
      </DialogTitle>
      <DialogContent>
        <DetailsForm data={selectedRow} onUpdate={(licensePlate: string, estimatedDate: Date | null, destiny: string, teamList: Team[], roundtrip: boolean) => update(licensePlate, estimatedDate, teamList, roundtrip, destiny)} onUpdateRow={updateRow} />
        <div className='tw-mb-4' />
      </DialogContent>
    </BootstrapDialog>
  );
}

export default DetailsCargos;