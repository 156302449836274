import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.css';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { QueryClient } from 'react-query';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes';
import history from './services/history';
import { persistor, store } from './store';
import './styles/global.scss';

function App() {
  const [app, setApp] = React.useState();
  const { t } = useTranslation();

  const message = t('common.metaTag');

  // const description = document.createElement('meta');
  // description.setAttribute('name', 'description');
  // description.setAttribute('content', message);

  // document.head.insertAdjacentElement('beforeend', description);

  const queryClient = new QueryClient();

  return (
    <>
      <div className="App">
        <Provider store={store}>
            <PersistGate persistor={persistor}>
              <Router history={history}>
                <Routes />
                <NotificationContainer />
              </Router>
            </PersistGate>
        </Provider>
      </div>
    </>
  );
}

export default App;
