import React from 'react';
import { InputCustom, Title } from './styles';

interface InputProps {
  title: string;
  type?: string;
  placeholder: string;
  value?: string;
  custom?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = ({ title, type, placeholder, onChange, onFocus, value }) => {
  return (
    <React.Fragment>
      <Title>{title}</Title>
      <InputCustom placeholder={placeholder} onChange={onChange} type={type} value={value} onFocus={onFocus} />
    </React.Fragment>
  );
};

export default Input;
