import CustomTabs from 'components/CustomTabs';
import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import api from 'services/api';
import EditRoute from './EditRoute';
import NewRoute from './NewRoute';

// import { Container } from './styles';

type Region = {
  id: number;
  description: string;
  referenceId: string;
};

export type Route = {
  id: number;
  description: string;
  referenceId: string;
  region: Region;
};

interface Props {
  isClose: () => void;
}

const Route: React.FC<Props> = ({ isClose }: Props) => {
  const [tabs, setTabs] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<Route[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'description',
      headerName: 'Descrição',
      filter: true,
    },
    {
      field: 'referenceId',
      headerName: 'Id de referência',
    },
    {
      field: "region.description",
      headerName: "Região",
      filter: true
    }
  ]);

  const onHandleDelete = useCallback(async (data: Route[]) => {
    try {
      const response = await api.delete(`/delivery/route/${data[0].id}`);

      if(response.status === 204) {
        FrontendNotification('Rota removida com sucesso!', NotificationType.SUCCESS);
      }

      setIsRemove(false);
      setSelectedRows([]);
      setTabs(1);
      setTabs(0);
    }catch{}
  }, []);

  function onUpdate() {
    if(selectedRows.length > 0) {
      setTabs(2);
    }
  }

  function onDelete() {
    setIsRemove(!isRemove);
  }

  function onCreate() {
    setTabs(1);
  }

  return (
    <CustomModal isOpen isClose={isClose} label="Rota" styles={{ minWidth: '600px' }}>
      <ModalDelete isOpen={isRemove} onClose={() => setIsRemove(!isRemove)} onConfirm={() =>  onHandleDelete(selectedRows)} />
      <CustomTabs
        onChange={(selectedTab: number) => setTabs(selectedTab)}
        tabSelected={tabs}
        renderTab1={(
          <>
          <div style={{ height: '400px', maxHeight: 'max-content' }}>
            <Grid<Route>
              columns={columns}
              pagination={true}
              rowSelection={RowSelection.SINGLE}
              path="/delivery/route/pageable"
              filters={[]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onUpdate={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setTabs(2);
              }}
              onDelete={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setIsRemove(!isRemove)
              }}
              onCreate={onCreate}
              showCrudButtons={false}
              customCrudButtons
              />
          </div>
          <div className='tw-mb-20' />
              </>
        )}
        renderTab2={(
          <NewRoute onClear={() => setTabs(0)} />
        )}
        renderTab3={(
          <EditRoute onClear={() => setTabs(0)} data={selectedRows[0]} />
        )}
      />
    </CustomModal>
  );
};

export default Route;
