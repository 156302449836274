/* eslint-disable prefer-const */
import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import './styles.scss';

import { FrontendNotification } from 'components/Notification';
import {
  NotificationType
} from 'core/common/Notification';
import { useFormationCargas } from 'hook/FormationCargas';
import api from 'services/api';
import closeModalIcon from '../../../../../../../assets/images/closeIcon.svg';

export default function ConfirmationDriver({
  idRomaneio,
  description,
  cpfDriver,
  onClear,
  onConfirm,
  onLoad,
}) {
  const [vehicleData, setVehicleData] = useState([]);
  const [modal, setModal] = useState(true);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);
  const { groupedCargoData, onChangeVehicle } = useFormationCargas();
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const handleSubmit = useCallback(async (data) => {
    try {
      const dataSubmit = {
        id: idRomaneio,
        idVeiculo: cpfDriver,
      };

      const response = await api.post('/route/optmis/trocaVeiculo', dataSubmit);
      if (response.status === 204) {
        setModal(!modal);
        onConfirm();
        onLoad();

        let initialIndex = -1;
        let rowIndex = -1;

        console.log(description);
        initialIndex = groupedCargoData.findIndex(item => item.destiny === description.replaceAll('.', ''));

        console.log(initialIndex);


        const teamList = [
          {
            id: data[0].motorista.id,
            type: "DRIVER",
            nome: data[0].motorista.nome
          }
        ];

        onChangeVehicle(initialIndex, data[0].placa, teamList);

        FrontendNotification(
          t('changeVehicles.messageSuccess'),
          NotificationType.SUCCESS
        );
      }
    } catch (err) {}
  }, []);

  const getVehicleById = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: "id",
            value: cpfDriver,
            operation: 'EQUAL',
          }
        ]
      };

      const response = await api.post('/delivery/veiculos/findByFilters', data);

      setVehicleData(response.data);
    }catch{}
  }, [])

  useEffect(() => {
    getVehicleById();
  }, [getVehicleById])

  return (
    <>
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%', padding: '10px' }}
      >
        <div className="modalWrapperVehicles" style={{ padding: '20px' }}>
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="changeVehicles.title" />
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="changeVehicles.messageConfirmChanged" />
          </span>
          <Row>
            <Col md={6} mt="4">
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  onClear();
                  setModal(!modal);
                }}
              >
                <Trans i18nKey="changeVehicles.buttons.cancel" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => 
                  handleSubmit(vehicleData)}
              >
                <Trans i18nKey="changeVehicles.buttons.confirm" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
