import React from 'react';

// import { Container } from './styles';

interface Props {
  dataCargos: any;
  dataVehicle: any;
  porcentage: any;
  title: string;
}

const TotalizatorValue: React.FC<Props> = (props: Props) => {
  console.log(props.porcentage > 100 ? 'entrou' : 'nao entro')
  return (
    <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-z-50">
      <div className="tw-flex tw-w-full tw-flex-col tw-justify-start tw-mr-2">
        <div
          className={`tw-flex tw-items-center tw-justify-center tw-w-auto tw-h-6 tw-rounded-3xl tw-p-2 tw-text-sm tw-text-[#fff]`}
          style={{
            backgroundColor: props.porcentage > 100 ? '#EA004C' : '#08B369'
          }}
        >
          <span>{props.title}</span>
        </div>
        <div className="tw-flex tw-mt-2">
          <span className="tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60 tw-mr-2">
            Veiculos
          </span>
          <span className="tw-text-sm tw-text-[rgba(0, 48, 73, 0.2)] tw-font-bold">
            X
          </span>
          <span className="tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60 tw-ml-2">
            Cargas
          </span>
        </div>
        <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
          <div>
            <span
              className={`tw-text-sm tw-text-[${
                props.porcentage > 100 ? '#EA004C' : '#08B369'
              }] tw-font-bold`}
              style={{
                color: props.porcentage > 100 ? '#EA004C' : '#08B369'
              }}
            >
              {props.dataVehicle}
            </span>
          </div>
          <div />
          <div>
            <span
              className={`tw-text-sm tw-font-bold`}
              style={{
                color: props.porcentage > 100 ? '#EA004C' : '#08B369'
              }}
            >
              {props.dataCargos}
            </span>
          </div>
        </div>
        <div className='tw-flex tw-w-full tw-items-center tw-justify-center'>
            <span className='tw-text-lg tw-font-bold' style={{ color: props.porcentage > 100 ? '#EA004C' : '#08B369' }}>{props.dataVehicle > 0 ? props.porcentage : 0}%</span>
        </div>
      </div>
      
    </div>
  );
};

export default TotalizatorValue;
