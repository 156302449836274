import { Filter, SearchOperation } from 'components/Grid/types';
import Loading from 'core/common/Loading';
import { format, isAfter } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import api from 'services/api';
import Filters from '../Filters';
import './styles.css';

interface GroupedCargoResult {
  dataBaixaApp: string;
  dataCriacao: string;
  percentual: number;
  total: number;
  onTime: number;
}

interface RenderedBarChartData {
  name: string;
  Percentual: number | string;
  amt: number;
  fill: string;
}

interface Props {
  filters: Filter[];
  isOpenFilters: boolean;
  onClear: () => void;
}

const ReportPunctiality: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>([]);
  const [width, setWidth] = useState<number | undefined>(0);
  const initialFilters = [
    {
      field: 'ocorrencia',
      operation: 'IS_NOT_NULL',
    },
    {
      field: 'step.conhecimentoPre.clienteHorarioAbertura',
      value: '',
      operation: 'NOT_EQUAL',
    },
    {
      field: 'step.conhecimentoPre.clienteHorarioFechamento',
      value: '',
      operation: 'NOT_EQUAL',
    },

    {
      field: 'step.conhecimentoPre.clienteHorarioAbertura',
      operation: 'IS_NOT_NULL',
    },
    {
      field: 'step.conhecimentoPre.clienteHorarioFechamento',
      operation: 'IS_NOT_NULL',
    },
  ];

  const onRenderGraphData = useCallback((data: any[]) => {
    const result = data;

    const mappingResult = result
      .map((rs: any) => {
        let shipmentCheckin: any = new Date(rs.romaneio.romaneioCheckin.horarioInicial);

        shipmentCheckin = shipmentCheckin.setMinutes(shipmentCheckin.getMinutes() - 5);

        const dateShipmentCheckin = format(
          new Date(shipmentCheckin),
          'yyyy-MM-dd HH:mm',
          {
            locale: ptBR,
          }
        );

        const dateClienteHorarioFechamento = format(
          new Date(rs.dataBaixaApp),
          `yyyy-MM-dd ${rs.step.conhecimentoPre.clienteHorarioFechamento}`,
          {
            locale: ptBR,
          }
        );

        return {
          ...rs,
          onTime: isAfter(
            new Date(dateShipmentCheckin),
            new Date(dateClienteHorarioFechamento)
          )
            ? 0
            : 1,
        };
      })
      .sort((a, b) => a.romaneio.data.localeCompare(b.romaneio.data));

    const groupedCargo = mappingResult.reduce<
      Record<string, { onTime: number; total: number, dataCriacao: string }>
    >((acc, item) => {
      const date = format(new Date(item.romaneio.data), 'dd/MM/yy', {
        locale: ptBR,
      });

      if (!acc[date]) {
        acc[date] = { onTime: 0, total: 0, dataCriacao: '' };
      }

      acc[date].onTime += item.onTime;
      acc[date].total += 1;

      acc[date].dataCriacao = item.romaneio.data;

      return acc;
    }, {});

    const groupedCargoResult: GroupedCargoResult[] = Object.entries(
      groupedCargo
    ).map(([dataBaixaApp, { onTime, total, dataCriacao }]) => ({
      dataBaixaApp,
      percentual: (onTime / total) * 100,
      total: total,
      onTime: onTime,
      dataCriacao: dataCriacao
    }));

    let total = 0;

    const renderResultGraphBarChart: RenderedBarChartData[] = groupedCargoResult.map(
      item => {
        total += item.onTime;

        return {
          name: item.dataCriacao,
          Percentual: Number(item.percentual.toFixed(2)),
          amt: item.percentual,
          fill:
            item.percentual >= 0 && item.percentual <= 91.66
              ? '#EA004C'
              : item.percentual >= 91.67 && item.percentual <= 92.68
              ? '#FFF000'
              : item.percentual > 92.69 && item.percentual <= 93.7
              ? '#008000'
              : '#0000FF',
        };
      }
    );

    const totalPercentualCargo: any = (total / result.length) * 100;

    renderResultGraphBarChart.push({
      name: 'Total',
      Percentual:
        result.length > 0 ? Number(totalPercentualCargo).toFixed(2) : '---',
      amt: totalPercentualCargo,
      fill:
        totalPercentualCargo >= 0 && totalPercentualCargo <= 91.66
          ? '#EA004C'
          : totalPercentualCargo >= 91.67 && totalPercentualCargo <= 92.68
          ? '#FFF000'
          : totalPercentualCargo > 92.69 && totalPercentualCargo <= 93.7
          ? '#008000'
          : '#0000FF',
    });

    setData(renderResultGraphBarChart);
  }, []);

  const getReports = useCallback(async (filters?: any[]) => {
    try {
      setLoading(true);

      const getElement = document.getElementById('reportsSection');

      const getWidthElement = getElement?.offsetWidth;

      if (getWidthElement) {
        setWidth(getWidthElement - 50);
      }

      const initialDayOfMonth = new Date();

      let currentFilters = [
        {
          field: 'romaneio.data',
          value: format(
            initialDayOfMonth.setDate(initialDayOfMonth.getDate() - 30),
            'yyyy-MM-dd',
            {
              locale: ptBR,
            }
          ),
          operation: 'GREATER_THAN_EQUAL',
        },
        {
          field: 'romaneio.data',
          value: format(new Date(), 'yyyy-MM-dd', {
            locale: ptBR,
          }),
          operation: 'LESS_THAN_EQUAL',
        },
        {
          field: 'romaneio.romaneioCheckin',
          operation: 'IS_NOT_NULL'
        },
        {
          field: 'ocorrencia',
          operation: 'IS_NOT_NULL',
        },
        {
          field: 'step.conhecimentoPre.clienteHorarioAbertura',
          value: '',
          operation: 'NOT_EQUAL',
        },
        {
          field: 'step.conhecimentoPre.clienteHorarioFechamento',
          value: '',
          operation: 'NOT_EQUAL',
        },

        {
          field: 'step.conhecimentoPre.clienteHorarioAbertura',
          operation: 'IS_NOT_NULL',
        },
        {
          field: 'step.conhecimentoPre.clienteHorarioFechamento',
          operation: 'IS_NOT_NULL',
        },
      ];

      const dataFilters = filters || currentFilters;

      const buildFilters = (filters: any) =>
        filters
          .map(
            (item: any) => `{
              field: "${item.field}",
              value: "${item.value || ''}",
              operation: ${item.operation}
            }`
          )
          .join(',');

      const query = `
        {
          findByFilters_Conhecimento(
            filters: [${buildFilters(dataFilters)}]
          ) {
            id,
            numero,
            dataBaixaApp,
            ocorrencia {
              id
            }
            romaneio {
              id,
              data,
              veiculo {
                id,
                placa
              },
              romaneioCheckin {
                id,
                horarioInicial,
                horarioFinal
              }
            },
            step {
              id,
              conhecimentoPre {
                id,
                clienteHorarioAbertura,
                clienteHorarioFechamento,
                rota
              }
            }
          }
        }
      `;

      const body = query;

      const response = await api.post(
        '/graphql',
        { query: body },
        {
          headers: {
            'Content-Type': 'application/json', // Ensure correct content type
          },
        }
      );

      const data = response.data.data.findByFilters_Conhecimento;

      onRenderGraphData(data);

      setLoading(false);
    } catch {}
  }, []);

  const CustomizedLabel = (props: any) => {
    const { x, y, fill, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize="11"
        fontWeight="bold"
        fontFamily="sans-serif"
        fill="#000"
        textAnchor="top"
        style={{ transform: 'rotate(-40%)' }}
      >
        {value}%
      </text>
    );
  };

  useEffect(() => {
    getReports();
  }, [getReports, props.filters]);

  return (
    <>
      <Loading loading={loading} />

      {props.isOpenFilters && (
        <Filters
          onClear={props.onClear}
          filtersData={sessionStorage.getItem('reports::punctualityComponent')}
          data={sessionStorage.getItem('reports::punctualityData')}
          onFilter={(data: any) => {
            let filtersData = [];

            filtersData = initialFilters;

            if (data.initialDate.length > 0) {
              const getFilterByField = filtersData
                .map((item: any, index: number) =>
                  item.field === 'romaneio.data' &&
                  item.operation === SearchOperation.GREATHER_THAN_EQUAL
                    ? index
                    : -1
                )
                .filter((itemFilter: any) => itemFilter !== -1);

              if (getFilterByField.length > 0) {
                filtersData[getFilterByField[0]].value = data.initialDate;
              } else {
                filtersData.push({
                  field: 'romaneio.data',
                  value: data.initialDate,
                  operation: SearchOperation.GREATHER_THAN_EQUAL,
                });
              }
            } else {
              let dateInitial = new Date();
              filtersData.push({
                field: 'romaneio.data',
                value: `${format(
                  dateInitial.setDate(dateInitial.getDate() - 30),
                  'yyyy-MM-dd',
                  {
                    locale: ptBR,
                  }
                )}`,
                operation: SearchOperation.GREATHER_THAN_EQUAL,
              });
            }
            if (data.finalDate.length > 0) {
              const getFilterByField = filtersData
                .map((item: any, index: number) =>
                  item.field === 'romaneio.data' &&
                  item.operation === SearchOperation.LESS_THAN_EQUAL
                    ? index
                    : -1
                )
                .filter((itemFilter: any) => itemFilter !== -1);

              if (getFilterByField.length > 0) {
                filtersData[getFilterByField[0]].value = data.finalDate;
              } else {
                filtersData.push({
                  field: 'romaneio.data',
                  value: data.finalDate,
                  operation: SearchOperation.LESS_THAN_EQUAL,
                });
              }
            } else {
              let finalDate = new Date();
              filtersData.push({
                field: 'romaneio.data',
                value: `${format(finalDate, 'yyyy-MM-dd', {
                  locale: ptBR,
                })}`,
                operation: SearchOperation.LESS_THAN_EQUAL,
              });
            }
            if (data.placa.length > 0) {
              const getFilterByField = filtersData
                .map((item: any, index: number) =>
                  item.field === 'romaneio.veiculo.placa' ? index : -1
                )
                .filter((itemFilter: any) => itemFilter !== -1);

              if (getFilterByField.length > 0) {
                filtersData[getFilterByField[0]].value = data.placa;
              } else {
                filtersData.push({
                  field: 'romaneio.veiculo.placa',
                  value: data.placa,
                  operation: SearchOperation.MATCH,
                });
              }
            } else {
              const getFilterByField = filtersData
                .map((item: any, index: number) =>
                  item.field === 'romaneio.veiculo.rota' ? index : -1
                )
                .filter((itemFilter: any) => itemFilter !== -1);

              if (getFilterByField) {
                filtersData.slice(getFilterByField[0], getFilterByField[0]);
              }
            }

            if (data.rota.length > 0) {
              const getFilterByField = filtersData
                .map((item: any, index: number) =>
                  item.field === 'step.conhecimentoPre.rota' ? index : -1
                )
                .filter((itemFilter: any) => itemFilter !== -1);

              if (getFilterByField.length > 0) {
                filtersData[getFilterByField[0]].value = data.rota;
              } else {
                filtersData.push({
                  field: 'step.conhecimentoPre.rota',
                  value: data.rota,
                  operation: SearchOperation.MATCH,
                });
              }
            } else {
              const getFilterByField = filtersData
                .map((item: any, index: number) =>
                  item.field === 'step.conhecimentoPre.rota' ? index : -1
                )
                .filter((itemFilter: any) => itemFilter !== -1);

              if (getFilterByField) {
                filtersData.slice(getFilterByField[0], getFilterByField[0]);
              }
            }

            props.onClear();

            getReports(filtersData);
          }}
        />
      )}
      <ResponsiveContainer width="100%" height="100%">
        <div className="tw-w-full tw-mb-5">
          <BarChart
            width={900}
            height={400}
            data={data}
            margin={{
              top: 15,
              // right: 30,
              left: -5,
              bottom: 45,
            }}
            style={{ marginTop: 10 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              angle={-90}
              textAnchor="end"
              style={{ fontSize: '12px', fontWeight: 'bold' }}
            />
            <YAxis
              dataKey="amt"
              style={{ fontSize: '12px', fontWeight: 'bold' }}
            >
              <Label
                style={{
                  textAnchor: 'middle',
                  fontSize: '18px',
                  fill: '#000',
                  marginRight: '10px',
                }}
                angle={270}
                dx={-10}
                value={'Pontualidade %'}
              />
            </YAxis>
            <Tooltip />
            <Bar
              dataKey="Percentual"
              fill="#8884d8"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
              style={{ bottom: '-40px !important' }}
              label={<CustomizedLabel />}
            >
              {data.map((item: any, index: number) => (
                <Cell key={`cell-${index}`} fill={item.fill} />
              ))}
            </Bar>
          </BarChart>
        </div>
      </ResponsiveContainer>
    </>
  );
};

export default ReportPunctiality;
