import {
    ValueFormatterParams
} from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';

export type GridProps<T> = {
    columns: ColumnDef[];
    pagination: boolean;
    rowSelection?: RowSelection;
    path: string;
    selectedRows?: T[];
    setSelectedRows?: (rows: T[]) => void;
    onUpdate?: () => void;
    onDelete?: () => void;
    onCreate?: () => void;
    showCrudButtons: boolean;
    customCrudButtons?: boolean;
    customButtons?: CustomButtons[];
    filters: Filter[];    
    setRowData?: (rows: T[]) => void;
    perPage?: number;
    orders?: Order[];
    server?: boolean;
    refresh?: string;
    force?: boolean
    onGridApi?: (api: any) => void;
}

export type CustomButtons = {
    label: string;
    icon?: string;
    action: () => void;
}

export type ChildrenColumnDef = {
    field: string;
    width: number;
    enableRowGroupd: boolean;
}

export type ColumnDef = {
    field: string;
    headerName?: string;
    width?: number;
    checkboxSelection?: boolean;
    editable?: boolean;
    filter?: any;
    filterParams?: any,
    valueFormatter?: (params: ValueFormatterParams) => void;
    cellRenderer?: (params: CustomCellRendererProps) => void;
    pinned?: string;
    valueGetter?: any;
    valueSetter?: any;
    cellDataType?: string;
}

export enum RowSelection {
    MULTIPLE = "multiple",
    SINGLE = "single"
}

export type PageableRequest = {
    filters: Filter[];
    page: number;
    size: number;
    orders: Order[];
}

export enum SearchOperation {
    EQUAL = "EQUAL",
    MATCH = "MATCH",
    IN = "IN",
    GREATHER_THAN_EQUAL = "GREATER_THAN_EQUAL",
    LESS_THAN_EQUAL = "LESS_THAN_EQUAL",
    NOT_EQUAL = "NOT_EQUAL"
}

export enum Direction {
    ASC = "ASC",
    DESC = "DESC"
}

export type Filter = {
    field: string;
    value: string | boolean | number | string[] | null;
    operation: SearchOperation;
}

export type Order = {
    field: string;
    direction: Direction;
}

export type PageableResponse = {
    last: boolean;
    first: boolean;
}