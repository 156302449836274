import CustomModal from 'components/ModalCustom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClear: () => void;
  onConfirm: (obs: string) => void;
}

const Create: React.FC<Props> = (props: Props) => {
  const [obs, setObs] = useState('');

  const { t } = useTranslation();

  return (
    <CustomModal label={t('shippingAudit.title')} isOpen isClose={props.onClear} styles={{ minWidth: '400px' }}>
      <div className='tw-w-full tw-h-full'>
        <label className='tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60'>Obs.</label>
        <textarea
        rows={3}
        className='tw-w-full tw-rounded-md tw-bg-[#FFFFFF] tw-box-border tw-text-sm tw-text-[rgba(0, 48, 73, 0.2)] tw-pl-2 tw-pt-2 tw-placeholder-[rgb(0, 48, 73)] tw-outline-none'
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setObs(e.target.value)}
          placeholder=''
          maxLength={254}

        />
        <div className='tw-flex tw-h-full tw-justify-end tw-mt-3'>

        <button className='tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-border-none tw-bg-[#EA004C] tw-rounded-md tw-w-32 tw-text-sm tw-text-[#FFF] tw-p-3 tw-font-semibold tw-text-center' onClick={() => props.onConfirm(obs)}>
          Confirmar
        </button>
        </div>
      </div>
    </CustomModal>
  )
}

export default Create;