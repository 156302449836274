import { ValueFormatterParams } from 'ag-grid-community';
import Grid from 'components/Grid/Grid';
import { ColumnDef, Filter } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import React, { useState } from 'react';
import { numberToCurrencyBRL } from 'util/format';

interface Props {
  filters: Filter[];
  onClear: () => void;
}

const Historic: React.FC<Props> = (props: Props) => {
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'valorViagemAnterior',
      headerName: 'Valores',
      valueFormatter: (params: ValueFormatterParams) => {
        if(params.value) {
          return numberToCurrencyBRL(params.value);
        }

        return '---';
      }
    },
    {
      field: 'obs',
      headerName: 'Observação'
    },
    {
      field: 'registradoPor',
      headerName: 'Usuário',
      flex: 1
    },
  ]);
  const [filters] = useState([]);

  return (
    <CustomModal label='Histórico de Alterações' isOpen isClose={props.onClear} styles={{ minWidth: 800 }}>
      <div className='tw-w-full tw-h-full'>
        <div className='tw-w-full tw-h-96'>
          <Grid 
            columns={columns}
            filters={props.filters}
            pagination
            path='/delivery/tabelas/frete/congelada/alterada/pageable'
            showCrudButtons={false}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default Historic;