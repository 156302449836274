export type DataStepsRoutering = {
  id: number;
  active: boolean;
  latitude: number;
  longitude: number;
  order: number;
  type: string;
  distance: any;
  isCompacted: boolean;
  rows: DataStepsRoutering[];
  groupedRows: number;
  duration: {
    formattedTime: string;
    hour: number;
    minute: number;
    secondes: number;
  };
  arrival: {
    formattedTime: string;
    formattedDateTime: string;
    hour: number;
    minute: number;
    secondes: number;
  };
  conhecimentoPre: {
    id: number;
    tipo: string;
    clienteNome: string;
    cubagem: string;
    dataEmissao: string;
    dataPrevisao: string;
    enderecoBairro: string;
    enderecoBairroColeta: string;
    enderecoCep: string;
    enderecoCepColeta: string;
    enderecoCidade: string;
    enderecoCidadeColeta: string;
    enderecoComplemento: string;
    enderecoComplementoColeta: string;
    enderecoLatitude: string;
    enderecoLatitudeColeta: string;
    enderecoLongitude: string;
    enderecoLongitudeColeta: string;
    enderecoLogradouro: string;
    enderecoLogradouroColeta: string;
    enderecoNumero: string;
    enderecoNumeroColeta: string;
    enderecoUf: string;
    enderecoUfColeta: string;
    idReferencia: string;
    numero: string;
    peso: string;
    qtdVolumes: string;
    rota: string;
    rotaColeta: string;
    valor: string;
  };
}

export type DataRouteringCargas = {
  id: number;
  fillColor: any;
  active: boolean;
  cost: any;
  destiny: String | null;
  description?: string | null;
  dataAgendamento: any;
  distanceTotal: any;
  routeTimer: string;
  isCompacted: boolean;
  travelTime: {
    formattedTime: string;
    hour: number;
    minute: number;
    seconds: number;
  };
  serviceTime: {
    formattedTime: string;
    hour: number;
    minute: number;
    seconds: number;
  }
  polyline: string;
  priorityTotal: number;
  roadtrip: boolean;
  driver: {
    id: number;
    nome: string;
    cpf: string;
  }
  vehicle: {
    id: number;
    cubagem: number;
    peso: number;
    placa: string;
    quantidadeAtendimento: number;
    valor: number;
  };
  simulation: {
    id: number;
    description: string;
  }
  subsidiary: {
    id: number;
    nome: string;
    endereco: {
      latitude: number;
      longitude: number;
    }
  };
  steps: Array<DataStepsRoutering>
}

export interface RouteringCargasPayload {
  data: {
    dataOptmi: Array<DataRouteringCargas>;
    detail?: DataRouteringCargas | null;
    dataRouteSelected: Array<DataRouteringCargas>;
    dataStepsSelected: Array<DataStepsRoutering>;
  };
}

export function setDataRouteringCargas(data: RouteringCargasPayload) {
  return {
    type: '@routering/SET_DATA_ROUTERING_CARGAS',
    payload: {
      data,
    },
  };
}

export function updateRouteringDataCargas(data: any, indexCarga: number) {
  return {
    type: '@routering/UPDATE_ROUTERING_DATA_CARGAS',
    payload: {
      data,
      indexCarga
    }
  }
}

export function setDetailRouteringCarga(detail: any) {
  return {
    type: '@routering/SET_DETAIL_ROUTERING_CARGA',
    payload: {
      detail,
    },
  };
}

export function clearDetailRouteringCarga() {
  return {
    type: '@routering/CLEAR_DETAIL_ROUTERING_CARGA',
  };
}

export function updateRouteringCargaSelected(indexCarga: number, checked: boolean) {
  return {
    type: '@routering/UPDATE_ROUTERING_CARGA_SELECTED',
    payload: {
      indexCarga,
      checked
    }
  }
}

export function updateRouteringCargaChecked(indexCarga: number) {
  return {
    type: '@routering/UPDATE_ROUTERING_CARGA_CHECKED',
    payload: {
      indexCarga
    }
  }
}

export function setDataRouteSelected(dataRoute: DataRouteringCargas) {
  return {
    type: '@routering/SET_DATA_ROUTE_SELECTED',
    payload: {
      dataRoute
    }
  }
}

export function setDataRoute(dataRoute: DataRouteringCargas[]) {
  return {
    type: '@routering/SET_DATA_ROUTE',
    payload: {
      dataRoute
    }
  }
}


export function removeDataRouteSelected(indexSelected: number) {
  return {
    type: '@routering/REMOVE_DATA_ROUTE_SELECTED',
    payload: {
      indexSelected
    }
  }
}

export function clearDataRouteSelected() {
  return {
    type: '@routering/CLEAR_DATA_ROUTE_SELECTED',
  };
}

export function updateRouteringStepsSelected(indexStep: number, checked: boolean) {
  return {
    type: '@routering/UPDATE_ROUTERING_STEPS_SELECTED',
    payload: {
      indexStep,
      checked
    }
  }
}

export function updateRouteringStepsChecked(indexStep: number) {
  return {
    type: '@routering/UPDATE_ROUTERING_STEPS_CHECKED',
    payload: {
      indexStep
    }
  }
}

export function updateRouteringStepsCompacted(indexStep: number) {
  return {
    type: '@routering/UPDATE_ROUTERING_STEPS_COMPACTED',
    payload: {
      indexStep
    }
  }
}

export function setDataStepsSelected(dataSteps: DataStepsRoutering) {
  return {
    type: '@routering/SET_DATA_STEPS_SELECTED',
    payload: {
      dataSteps
    }
  }
}

export function clearDataStepSelected() {
  return {
    type: '@routering/CLEAR_DATA_STEPS_SELECTED',
  };
}

export function removeDataStepSelected(indexSelected: number) {
  return {
    type: '@routering/REMOVE_DATA_STEP_SELECTED',
    payload: {
      indexSelected
    }
  }
}


export function updateAllRouteringStepsCompacted(data: DataStepsRoutering[]) {
  return {
    type: '@routering/UPDATE_ALL_ROUTERING_STEPS_COMPACTED',
    payload: {
      data
    }
  }
}


export function updateDataRoute(indexOptmi: number, dataSteps: DataStepsRoutering[]) {
  return {
    type: '@routering/UPDATE_DATA_ROUTE',
    payload: {
      indexOptmi,
      dataSteps
    }
  }
}

export function updateAllRouteringCargaCompacted(data: DataRouteringCargas[]) {
  return {
    type: '@routering/UPDATE_ALL_ROUTERING_CARGAS_COMPACTED',
    payload: {
      data
    }
  }
}