import { GroupedRow, Team } from 'components/CreateCargo/types';
import React, { createContext, ReactNode, useContext, useState } from 'react';

interface FormationCargasContextType {
  // isModalOpen: boolean;
  // openModal: () => void;
  // closeModal: () => void;
  groupedCargoData: GroupedRow[];
  setGroupedCargas: (data: GroupedRow[]) => void;
  onRemove: (initialIndex: number, rowIndex: number) => void;
  onChangeAddress: (initialIndex: number, rowIndex: number, data: any) => void;
  onChangeVehicle: (initialIndex: number, licensePlate: string, teamList: Team[]) => void;
}

const FormationCargasContext = createContext<
  FormationCargasContextType | undefined
>(undefined);

export const useFormationCargas = (): FormationCargasContextType => {
  const context = useContext(FormationCargasContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};

interface FormationCargasProviderProps {
  children: ReactNode;
}

export const FormationCargasProvider: React.FC<FormationCargasProviderProps> = ({
  children,
}) => {
  const [groupedCargoData, setGroupedCargoData] = useState<GroupedRow[]>([]);

  // const openModal = () => setIsModalOpen(true);
  // const closeModal = () => setIsModalOpen(false);

  const setGroupedCargas = (data: GroupedRow[]) => {
    setGroupedCargoData(data);
  };

  const onRemove = (initialIndex: number, rowIndex: number) => {
    groupedCargoData[initialIndex].rows.splice(rowIndex, 1);
  };

  const onChangeAddress = (initialIndex: number, rowIndex: number, data: any) => {
    groupedCargoData[initialIndex].rows[rowIndex] = data;
  };

  const onChangeVehicle = (initialIndex: number, licensePlate: string, teamList: Team[]) => {
    groupedCargoData[initialIndex].licensePlate = licensePlate;
    groupedCargoData[initialIndex].teamList = teamList;

  }

  return (
    <FormationCargasContext.Provider
      value={{ groupedCargoData, setGroupedCargas, onRemove, onChangeAddress, onChangeVehicle }}
    >
      {children}
    </FormationCargasContext.Provider>
  );
};
