import { ValueFormatterParams } from 'ag-grid-community';
import Grid from 'components/Grid/Grid';
import { ColumnDef, Filter, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import i18next from 'i18next';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Col, Row } from 'reactstrap';
import api from 'services/api';
import EditOcorrence from './EditOccurrence';
import NewOcorrence, { FormValues, SwitchOptions } from './NewOccurrence';
import { OccurrenceData, TypeOccurrence } from './interfaces/types';

// import { Container } from './styles';

interface Props {
  onClear: () => void;
}

const Occurrence: React.FC<Props> = (props: Props) => {
  const [tabs, setTabs] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<OccurrenceData[]>([]);
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'descricao',
      headerName: 'Descrição',
      filter: true,
      checkboxSelection: true,
    },
    {
      field: 'sequencia',
      headerName: 'Seq.',
      width: 80,
      filter: true,
    },
    {
      field: 'finalizacao',
      headerName: 'Finalizadora',
      width: 110,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return 'SIM';
        }
        return 'NÃO';
      },
    },
    {
      field: 'exibicaoMotorista',
      headerName: 'Motorista',
      width: 100,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return 'SIM';
        }
        return 'NÃO';
      },
    },
    {
      field: 'bipagem',
      headerName: 'Bipagem',
      width: 100,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return 'SIM';
        }
        return 'NÃO';
      },
    },
    {
      field: 'numero',
      headerName: 'Nº',
      width: 60,
      filter: true,
    },
    {
      field: 'tipo',
      headerName: 'Tipo de Atendimento',
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.value === 'ENTREGA') {
            return 'Entrega';
          } else {
            return 'Coleta';
          }
        }
      },
    },
    {
      field: 'tipo',
      headerName: 'Tipo de Ocorrência',
      width: 130,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.data.sucesso) {
            return 'Sucesso';
          } else if (params.data.informativa) {
            return 'Informativa';
          }
          return 'Insucesso';
        }
      },
    },
    {
      field: 'contratante',
      headerName: 'Contratante',
      width: 130,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.data.contratante.id !== null) {
            return params.data.contratante.pessoaJuridica.nomeFantasia;
          } else {
            return '---';
          }
        }
      },
    },
    {
      field: 'tipoAtividade',
      headerName: 'Tipo de Atividade',
      width: 130,
      filter: true,
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          if (params.data.tipoAtividade.id !== null) {
            return params.data.tipoAtividade.nome;
          } else {
            return '---';
          }
        }
      },
    },
  ]);
  const [filters] = useState<Filter[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);

  const { t } = useTranslation();

  const getLanguage = () => {
    return i18next.language;
  };

  const handleSubmit = useCallback(
    async (values: FormValues, checked: SwitchOptions, isEdit: boolean, row: OccurrenceData | null) => {
      try {
        const data = {
          descricao: values.descricao,
          numero: values.numero,
          tipo: values.tipo,
          sucesso: values.tipoOcorrencia === TypeOccurrence.SUCESSO,
          foto: checked.checkedPhoto,
          assinatura: checked.checkedAss,
          bipagem: checked.checkedBeep,
          informativa: values.tipoOcorrencia === TypeOccurrence.INFORMATIVA,
          finalizacao: checked.checkedFinisher,
          exibicaoMotorista: checked.checkedDriver || false,
          responsabilidade:
            values.responsavel.length > 0 ? values.responsavel : null,
          sequencia: values.sequencial,
          tipoAtividade:
            values.activityType !== null && values.activityType.length > 0
              ? { id: values.activityType }
              : null,
          contratante:
            values.contractor !== null && values.contractor.length > 0
              ? { id: values.contractor }
              : null,
          fotoDescricao: values.photo,
          assinaturaDescricao: values.signature,
          recebedorDocumentoDescricao: values.docReceiver,
          recebedorDocumento: checked.checkedDocReceiver,
          recebedorNome: checked.checkedNameReceiver,
          recebedorNomeDescricao: values.nameReceiver,
          baixaManual: checked.checkedBaixaManual,
        };

        if (isEdit && row !== null) {
          const response = await api.patch(
            `/delivery/ocorrencias/${row.id}`,
            data
          );

          if (response.status === 204) {
            FrontendNotification(
              t('ocorrences.messageUpdated'),
              NotificationType.SUCCESS
            );
          } else {
            FrontendNotification(
              t('ocorrences.messageErroUpdated'),
              NotificationType.ERROR
            );
          }
        } else {
          const response = await api.post('/delivery/ocorrencias', data);

          if (response.status === 201) {
            FrontendNotification(
              t('ocorrences.messageCreated'),
              NotificationType.SUCCESS
            );
          } else {
            const errorResponse = JSON.stringify(response);

            const resultMessage = JSON.parse(errorResponse);

            const { numero } = values;

            if (
              resultMessage.message === 'Request failed with status code 400'
            ) {
              if (getLanguage() === 'pt-BR') {
                FrontendNotification(
                  `Ocorrência ${numero} já existe`,
                  NotificationType.WARNING
                );
              } else {
                FrontendNotification(
                  `Occurence ${numero} already exists`,
                  NotificationType.WARNING
                );
              }
            } else {
              FrontendNotification(
                t('ocorrences.messageErroCreated'),
                NotificationType.ERROR
              );
            }
          }
        }

        // setChecked({
        //   checkedSuccess: false,
        //   checkedPhoto: false,
        //   checkedAss: false,
        //   checkedBeep: false,
        // });
        setTabs(0);
      } catch {}
    },
    []
  );

  const onDeleteOccurrence = useCallback(async (ocorrenceId: number) => {
    try {
      const response = await api.delete(`/delivery/ocorrencias/${ocorrenceId}`);

      if (response.status === 204) {
        FrontendNotification(
          t('ocorrences.messageRemove'),
          NotificationType.SUCCESS
        );
      } else {
        FrontendNotification(
          t('ocorrences.messageErroRemoved'),
          NotificationType.ERROR
        );
      }
      setIsRemove(false);

      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  return (
    <CustomModal
      label={t('ocorrences.save')}
      isOpen
      isClose={props.onClear}
      styles={{ minWidth: '90%' }}
    >
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => onDeleteOccurrence(selectedRows[0]?.id)}
      />
      <Tabs selectedIndex={tabs} onSelect={(index: number) => setTabs(index)}>
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>
            {tabs === 2 && <Trans i18nKey="tabs.edit" />}
            {/* <Trans i18nKey="tabs.edit" /> */}
          </Tab>
        </TabList>
        <TabPanel>
          <Row className="tw-h-[400px]">
            <Col md="12">
              <Grid<OccurrenceData>
                columns={columns}
                pagination={true}
                server={false}
                rowSelection={RowSelection.SINGLE}
                path="/delivery/ocorrencias/pageable"
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                customCrudButtons
                showCrudButtons={false}
                filters={filters}
                onUpdate={(data?: any) => {
                  let rows = [];
                  rows.push(data);
                  setSelectedRows(rows);
                  setTabs(2);
                }}
                onDelete={(data?: any) => {
                  let rows = [];
                  rows.push(data);
                  setSelectedRows(rows);
                  setIsRemove(!isRemove)
                }}
              />
            </Col>
          </Row>
        </TabPanel>
        <TabPanel>
          <NewOcorrence
            onSubmit={(values: FormValues, checked: SwitchOptions) =>
              handleSubmit(values, checked, false, null)
            }
          />
        </TabPanel>
        <TabPanel>
          <EditOcorrence
            ocorenceId={String(selectedRows[0]?.id)}
            onSubmit={(values: FormValues, checked: SwitchOptions) => {
              handleSubmit(values, checked, true, selectedRows[0]);
            }}
          />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default Occurrence;
