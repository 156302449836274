import { GroupedRow, People, Team } from 'components/CreateCargo/types';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import api from 'services/api';
import { DataRouteringCargas } from 'store/modules/routering/actions';

// import { Container } from './styles';

type Props = {
  open: boolean;
  onClose: () => void;
  data: GroupedRow[];
  onUpdateRow: () => void;
  dataVehicles: any[];
};

const ReleaseLoadingDialog: React.FC<Props> = ({
  open,
  onClose,
  data,
  onUpdateRow,
  dataVehicles,
}: Props) => {
  const [routeCargo, setRouteCargo] = useState<any[]>([]);
  const [vehicles, setVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState<any[]>([]);
  const [
    showConfirmationFreightFactorCreateCargo,
    setShowConfirmationFreightFactorCreateCargo,
  ] = useState<boolean>(false);

  const { t } = useTranslation();

  // Function to remove duplicate elements from an array of objects
  function removeDuplicates(array: any[], key: keyof any): any[] {
    const seenKeys = new Set();

    // Use the filter method to create a new array without duplicate elements
    return array.filter(item => {
      // Get the value of the specified key from the object
      const keyValue = item[key];

      // Check if the key has been seen already
      if (!seenKeys.has(keyValue)) {
        // If it hasn't been seen, add it to the set and return true (keeping the item in the new array)
        seenKeys.add(keyValue);
        return true;
      }

      // If the key has been seen, return false (ignoring the item in the new array)
      return false;
    });
  }

  const onCreateCargo = useCallback(
    async (
      groupedRows: GroupedRow[],
      dataRoute: any[],
      vehiclesData: any[]
    ) => {
      try {
        setLoading(true);

        let selectedRows = removeDuplicates(groupedRows, 'simulation');

        for (const row of selectedRows) {
          const getListDriver = row?.teamList
            .filter((driver: Team) => driver.type === People.DRIVER)
            .map((item: Team) => item.id);
          const getListCollaborator = row?.teamList
            .filter(
              (collaborator: Team) => collaborator.type === People.COLLABORATOR
            )
            .map((item: Team) => item.id);

          const getListRouteOptmi: any[] = await getRouteOptmi(row.simulation);

          const dataCreateRoute = getListRouteOptmi.map((route: any) => {
            return {
              optmization: {
                id: route.id,
              },
            };
          });

          const mappingRoutes = getListRouteOptmi.map((route: any) => {
            const findVehicleByRoute = vehiclesData.find(
              (item: any) => item.id === route.vehicle.id
            );

            const getCollaborators = findVehicleByRoute?.colaboradores.map(
              (item: any) => item.id
            );

            return {
              routes: [
                {
                  optmization: {
                    id: route.id,
                  },
                },
              ],
              collaborators: getCollaborators ? getCollaborators : null,
              driver: findVehicleByRoute?.motorista.id,
            };
          });

          const data = {
            routes: dataCreateRoute,
            collaborators: getListCollaborator,
            driver:
              getListDriver && getListDriver.length > 0
                ? getListDriver[0]
                : null,
          };

          let response;

          if (dataVehicles.length > 0) {
            response = await api.post(
              '/route/cargo/create/multiple',
              mappingRoutes
            );
          } else {
            response = await api.post('/route/cargo/create', data);
          }

          if (response.status === 204) {
            setLoading(false);
            FrontendNotification(
              'Rota liberada pra carregamento!',
              NotificationType.SUCCESS
            );
            onUpdateRow();
          }
        }
        window.open('/dashboard', '_blank');

        setLoading(false);
      } catch {}
    },
    []
  );

  const onSubmit = useCallback(
    async (
      groupedRows: GroupedRow[],
      parameters: any[],
      dataRoute: any[],
      vehiclesData: any[]
    ) => {
      let selectedRows = removeDuplicates(groupedRows, 'simulation');

      for (const row of selectedRows) {
        const getListRouteOptmi: DataRouteringCargas[] = await getRouteOptmi(
          row.simulation
        );

        let findFreightFactorOptmi = null;

        if(parameters[0].freightFactor !== null) {
          findFreightFactorOptmi = getListRouteOptmi.find(
            (cargo: DataRouteringCargas) =>
              cargo.freightFactorPercentage > parameters[0].freightFactor
          );
        }

        if (findFreightFactorOptmi !== null && findFreightFactorOptmi) {
          setShowConfirmationFreightFactorCreateCargo(
            !showConfirmationFreightFactorCreateCargo
          );
        } else {
          onCreateCargo(groupedRows, dataRoute, vehiclesData);
        }
      }
    },
    []
  );

  const getRouteOptmi = useCallback(async (simulation: string) => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'simulation.id',
            value: simulation,
            operation: 'EQUAL',
          },
          {
            field: 'inactivated',
            value: false,
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/route/optmis/findByFilters', body);
      setLoading(false);
      return response.data;
      // setRouteCargo(response.data);

    } catch {}
  }, []);

  const getVehicles = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'filial.id',
            value: sessionStorage.getItem('subsidiary'),
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/delivery/veiculos/findByFilters', body);
      setVehicles(response.data);
      setLoading(false);
    } catch {}
  }, []);

  const getParameters = useCallback(async () => {
    try {
      const response = await api.get('/parameters/routing');

      setParameters(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getParameters();
  }, [getParameters]);

  return (
    <div>
      <Loading loading={loading} />
      {showConfirmationFreightFactorCreateCargo && (
        <Modal
          isOpen={showConfirmationFreightFactorCreateCargo}
          toggle={() =>
            setShowConfirmationFreightFactorCreateCargo(
              !showConfirmationFreightFactorCreateCargo
            )
          }
        >
          <ModalBody>
            <p className="tw-text-base tw-text-[#003049] tw-mb-0">
              <pre className='tw-text-base tw-text-[#EA004C] tw-font-bold tw-mb-0'>
              <Trans i18nKey="routeOptmization.simulation.msgUltrapassedFreightFactorReleaseLoading" />
              </pre>
              <Trans i18nKey="routeOptmization.simulation.msgConfirmUltrapassedFreightFactorReleaseLoading" />
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="danger"
              onClick={() => onCreateCargo(data, routeCargo, dataVehicles)}
              id="buttonSaveRemove"
            >
              <Trans i18nKey="common.confirmRemove" />
            </Button>{' '}
            <Button
              color="secondary"
              onClick={() =>
                setShowConfirmationFreightFactorCreateCargo(
                  !showConfirmationFreightFactorCreateCargo
                )
              }
              id="buttonCancelRemove"
            >
              <Trans i18nKey="common.cancelRemove" />
            </Button>
          </ModalFooter>
        </Modal>
      )}
      <Modal isOpen={open} toggle={onClose}>
        <ModalBody>
          <Trans i18nKey="common.questionReleaseLoading" />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => onSubmit(data, parameters, routeCargo, dataVehicles)}
            id="buttonSaveRemove"
          >
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button color="secondary" onClick={onClose} id="buttonCancelRemove">
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ReleaseLoadingDialog;
