import CustomModal from 'components/ModalCustom';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import history from 'services/history';
import { editSimulatorRoute } from '../../store/modules/routeringSimulation/actions';
import {
  ColumnContainer,
  ColumnText,
  ColumnTitle,
  Container,
  Line,
  RowContainer
} from './styles';
import './styles.scss';

interface Props {
  data: any;
  onClear: () => void;
  isCustom?: boolean;
}

const ModalCreatedCargo: React.FC<Props> = (props: Props) => {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  return (
    <CustomModal isOpen label='Romaneios Criados' isClose={() => props.onClear()}>
      <Container>
        <RowContainer style={{ border: 'none' }}>
          <ColumnContainer style={{ border: 'none' }}>
            <ColumnTitle>Nº Romaneio</ColumnTitle>
          </ColumnContainer>
          <ColumnContainer style={{ border: 'none' }}>
            <ColumnTitle>Motorista</ColumnTitle>
          </ColumnContainer>
          <ColumnContainer style={{ border: 'none' }}>
            <ColumnTitle>Data de criação</ColumnTitle>
          </ColumnContainer>
        </RowContainer>
        {props.data.map((item: any, index: number) => (
          <>
            <RowContainer style={{ border: 'none' }}>
              <ColumnContainer style={{ border: 'none' }}>
                <ColumnText>
                  {item.numero !== undefined && item.numero}
                </ColumnText>
              </ColumnContainer>
              <ColumnContainer style={{ border: 'none' }}>
                <ColumnText>
                  {item.motorista !== undefined && item.motorista.nome}
                </ColumnText>
              </ColumnContainer>
              <ColumnContainer style={{ border: 'none' }}>
                <ColumnText>{item.data !== undefined && item.data}</ColumnText>
              </ColumnContainer>
            </RowContainer>
            {index + 1 === props.data.length ? <></> : <Line />}
          </>
        ))}
      </Container>
      <div className="tw-flex tw-items-center tw-justify-end tw-mb-6 tw-mr-1 tw-mt-4">
        <button
          id="finishRoutering"
          className="tw-bg-[#EA004C] tw-w-1/2 tw-h-8 tw-rounded-md tw-flex tw-items-center tw-justify-center tw-shadow-md tw-shadow-[rgba(234, 0, 76, 0.2)] tw-border-none tw-w-full tw-text-base tw-font-bold tw-text-[#fff]"
          type="button"
          onClick={() => {
            dispatch(editSimulatorRoute(false));
            window.open('/controlTower', '_blank');

            props.onClear();
            
            if(!props.isCustom) {
              history.push('/home');
              window.location.reload();
            }
          }}
        >
          Finalizar
        </button>
      </div>
    </CustomModal>
  );
};

export default ModalCreatedCargo;
