import { Content } from 'components/Driver/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import api from 'services/api';
import CustomModal from '../ModalCustom';

interface DriverShift {
  turno: {
    horarioInicial: string;
    horarioFinal: string;
  };
}

interface Props {
  conhecimentoId?: number;
  onClear?: any;
}

const RouteringTimeWindow: React.FC<Props> = ({
  conhecimentoId,
  onClear,
}: Props) => {
  const [vehiclesData, setVehiclesData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [hoursInitial, setHoursInitial] = useState('');
  const [hoursFinal, setHoursFinal] = useState('');

  const getDeliveryWindow = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'conhecimentoPre.id',
            value: conhecimentoId,
            operation: 'EQUAL',
          },
        ],
        page: 0,
        size: 10,
        orders: [],
      };

      const response = await api.post(
        '/delivery/conhecimentosPre/horariosEntrega/pageable',
        data
      );

      setDriverData(response.data.content);
    } catch (err) {}
  }, [conhecimentoId]);

  useEffect(() => {
    getDeliveryWindow();
  }, [getDeliveryWindow]);

  return (
    <CustomModal
      isOpen={true}
      styles={{ maxWidth: '50%', height: '90%' }}
      label="Janela de Entrega"
      isClose={() => onClear()}
    >
      <Content style={{ marginTop: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                <Trans i18nKey="driverShift.fields.interval" />
              </th>
            </tr>
          </thead>
          <tbody>
            {driverData.map((item: any) => (
              <tr className="lineGroup">
                <td>
                  {`${item.intervalo.horarioInicial.substring(0, 5)} - ${item.intervalo.horarioFinal.substring(0, 5)}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>
    </CustomModal>
  );
};

export default RouteringTimeWindow;