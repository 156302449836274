import DeleteIcon from 'assets/images/deleteIcon.svg';
import { GroupedRow, Team } from 'components/CreateCargo/types';
import CheckBox from 'components/CustomFilterDialog/Filters/CheckBox';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import LoadingRoutering from 'core/common/LoadingRoutering';
import Select from 'react-select';
import { labelStyle } from './styles';

import collaboratorTeamIcon from 'assets/images/collaboratorTeamIcon.svg';
import driverTeamIcon from 'assets/images/driverTeamIcon.svg';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Label } from 'reactstrap/lib';
import api from 'services/api';
import Totalizator from 'shared/Totalizator';
import {
  addAccumulatorRoutering,
  resetVehiclesAccumulator,
} from 'store/modules/accumulator/actions';

type FormValues = {
  dataSaida: string;
  destino: string;
  veiculo: string;
  roundtrip: boolean;
};

type Props = {
  data: GroupedRow | null;
  onUpdate: (
    licensePlate: string,
    estimatedDate: Date | null,
    destiny: string,
    listTeam: Team[],
    roundtrip: boolean
  ) => void;
  onUpdateRow: (
    routing: boolean,
    simulation: string,
    licensePlate: string,
    estimatedDate: Date,
    destiny: string,
    listTeam: Team[],
    roundtrip: boolean
  ) => void;
};

const DetailsForm: React.FC<Props> = ({ data, onUpdate }: Props) => {
  const [teamList, setTeamList] = useState<any[]>([]);
  const [driverList, setDriverList] = useState<any[]>([]);
  const [collaboratorList, setCollaboratorList] = useState<any[]>([]);
  const [listVehicles, setListVehicles] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      border: '2px solid rgba(0, 48, 73, 0.2)',
      borderRadius: 10,
    }),
  };

  const initialValues: FormValues = {
    dataSaida: '',
    destino: '',
    veiculo: '',
    roundtrip: true,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: () => {},
  });

  const getListDriver = useCallback(async (list?: any[]) => {
    try {
      setLoading(true);

      let listTeam = list || teamList;

      const response = await api.get('/delivery/motoristas');

      const loadListDriver = response.data.map((driver: any) => {
        return {
          label: driver.nome,
          value: driver.id,
        };
      });

      let compareDriverTeam = loadListDriver;

      console.log(compareDriverTeam);

      console.log(listTeam);

      if (listTeam.length > 0) {
        compareDriverTeam = loadListDriver.filter(
          (driver: any) =>
            !listTeam.some(
              (selectedTeam: any) => selectedTeam.id === driver.value
            )
        );
      }

      setDriverList(compareDriverTeam);

      setLoading(false);
    } catch {}
  }, []);

  const getListCollaborators = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('/delivery/colaboradores');

      const loadListCollaborator = response.data.map((collaborator: any) => {
        return {
          label: collaborator.nome,
          value: collaborator.id,
        };
      });

      let compareCollaboratorTeam = loadListCollaborator;

      if (teamList.length > 0) {
        compareCollaboratorTeam = loadListCollaborator.filter(
          (collaborator: any) =>
            !teamList.some(
              (selectedTeam: any) => selectedTeam.id === collaborator.value
            )
        );
      }

      setCollaboratorList(compareCollaboratorTeam);

      setLoading(false);
    } catch {}
  }, []);

  const getVeiculos = useCallback(async () => {
    try {
      setLoading(true);

      const body = {
        filters: [
          {
            field: 'filial.id',
            value: sessionStorage.getItem('subsidiary'),
            operation: 'EQUAL',
          },
        ],
      };

      const response = await api.post('/delivery/veiculos/findByFilters', body);

      setListVehicles(response.data);

      onLoadFormValues(response.data);

      setLoading(false);
    } catch {}
  }, []);

  const onChangeDriverTeam = useCallback((text, list) => {
    let updateTeam = list;

    const findDriverTeam = updateTeam
      .map((driverTeam: any, indexDriverTeam: number) =>
        driverTeam.type === 'DRIVER' ? indexDriverTeam : -1
      )
      .filter((driverTeamFilter: any) => driverTeamFilter !== -1);

    if (findDriverTeam.length > 0) {
      updateTeam[findDriverTeam[0]].id = text.value;
      updateTeam[findDriverTeam[0]].nome = text.label;
    } else {
      updateTeam.unshift({
        type: 'DRIVER',
        id: text.value,
        nome: text.label,
      });
    }

    setTeamList(updateTeam);

    setDriverList([]);

    getListDriver();
  }, []);

  const onChangeCollaboratorTeam = useCallback((text, list) => {
    let updateTeam = list;

    updateTeam.push({
      type: 'COLLABORATOR',
      id: text.value,
      nome: text.label,
    });

    setTeamList(updateTeam);

    setCollaboratorList([]);

    getListCollaborators();
  }, []);

  const onUpdateTeamListByVehicle = useCallback(
    (list, vehicleList, vehicleId) => {
      let updateTeam = list;

      console.log(vehicleList);

      const findVehicleById = vehicleList.filter(
        (vehicle: any) => vehicle.id === Number(vehicleId)
      );

      if (findVehicleById.length > 0) {
        console.log(findVehicleById);

        const findDriverTeam = updateTeam
          .map((driverTeam: any, indexDriverTeam: number) =>
            driverTeam.type === 'DRIVER' ? indexDriverTeam : -1
          )
          .filter((driverTeamFilter: any) => driverTeamFilter !== -1);

        if (findDriverTeam.length > 0) {
          updateTeam[findDriverTeam[0]].id = findVehicleById[0].motorista.id;
          updateTeam[findDriverTeam[0]].nome = findVehicleById[0].motorista.nome;
        } else {
          updateTeam.unshift({
            type: 'DRIVER',
            id: findVehicleById[0].motorista.id,
            nome: findVehicleById[0].motorista.nome,
          });
        }

        if(findVehicleById[0].colaboradores.length > 0) {

          
          updateTeam.push({
            type: 'COLLABORATOR',
            id: findVehicleById[0].colaboradores[0].id,
            nome: findVehicleById[0].colaboradores[0].nome
          })
        }

        setTeamList(updateTeam);

        setDriverList([]);
        setCollaboratorList([]);

        getListDriver();
        getListCollaborators();
      }
    },
    []
  );

  const onUpdateCargoDetail = useCallback(
    (values: FormValues, vehicleList: any[], listTeam: Team[]) => {
      if (values.veiculo.length > 0 && listTeam.length > 0) {
        const findVehicleById = vehicleList.find(
          (vehicle: any) => String(vehicle.id) === values.veiculo
        );

        const date = new Date(values.dataSaida);

        onUpdate(
          findVehicleById.placa,
          values.dataSaida.length > 0 ? date : null,
          values.destino,
          listTeam,
          values.roundtrip
        );
      } else {
        FrontendNotification(
          'Preencha os campos do formulário corretamente',
          NotificationType.WARNING
        );
      }
    },
    []
  );

  const onLoadFormValues = useCallback((vehicleList: any[]) => {
    if (
      data !== null &&
      data.licensePlate !== null &&
      data.licensePlate.length > 0
    ) {
      const findVehicleById = vehicleList.find(
        (vehicle: any) => vehicle.placa === data.licensePlate
      );
      formik.setFieldValue('veiculo', String(findVehicleById.id));
    }
    if (data !== null && data.grouper.length > 0) {
      formik.setFieldValue('destino', data.grouper);
    }

    if (data !== null && data.estimateDate !== null) {
      const date = format(new Date(data.estimateDate), 'yyyy-MM-dd HH:mm', {
        locale: ptBR,
      });

      formik.setFieldValue('dataSaida', date);
    }

    if (data !== null && data.teamList.length > 0) {
      setTeamList(data.teamList);
    }

    if (data !== null) {
      formik.setFieldValue('roundtrip', data.roundtrip);
    }
  }, []);

  const onCalcAccumulatorVehicles = (vehicleId: number) => {
    const getVehicleById = listVehicles
      .map((item: any, index: number) => (item.id === vehicleId ? index : -1))
      .filter((itemFilter: any) => itemFilter !== -1);

    if (getVehicleById.length > 0) {
      const vehicles = listVehicles[getVehicleById[0]];

      const totalAtend = vehicles.quantidadeAtendimento;
      const totalPeso = parseFloat(vehicles.peso);
      const totalCubagem = vehicles.cubagem;
      const totalValor = vehicles.valor;

      dispatch(resetVehiclesAccumulator());
      

      dispatch(
        addAccumulatorRoutering(totalAtend, totalPeso, totalCubagem, totalValor)
      );
    }
  };

  useEffect(() => {
    getVeiculos();
    getListDriver();
    getListCollaborators();
  }, [getVeiculos, getListCollaborators, getListDriver]);

  return (
    <>
      <LoadingRoutering loading={loading} />
      <Totalizator />
      <div className="tw-mt-6 tw-mb-10">
        <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <div className="tw-w-full tw-mr-3">
            <InputCustom
              type="datetime-local"
              onChange={formik.handleChange('dataSaida')}
              error={formik.errors.dataSaida}
              touched={formik.touched.dataSaida}
              value={formik.values.dataSaida}
              placeholder=""
              title="Agendamento"
            />
          </div>
          <div className="tw-w-full tw-mr-3">
            <InputCustom
              type="text"
              onChange={formik.handleChange('destino')}
              error={formik.errors.destino}
              touched={formik.touched.destino}
              value={formik.values.destino}
              placeholder="Informe o destino"
              title="Destino"
            />
          </div>
          <div className="tw-w-full tw-mr-3">
            <SelectCustom
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                onCalcAccumulatorVehicles(Number(e.target.value));
                formik.setFieldValue('veiculo', e.target.value);

                onUpdateTeamListByVehicle(
                  teamList,
                  listVehicles,
                  e.target.value
                );
              }}
              error={formik.errors.veiculo}
              touched={formik.touched.veiculo}
              value={formik.values.veiculo}
              title="Veículo"
            >
              <>
                <option value="">Selecione uma opção</option>
                {listVehicles.map((vehicle: any, index: number) => (
                  <option value={vehicle.id} key={index}>
                    {vehicle.placa}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
        </div>
        <div className="tw-flex tw-items-start tw-justify-start tw-mt-4">
          <div className="tw-w-[500px]">
            <div className="tw-border-1 tw-h-full tw-border-[#00304933] tw-border-dashed  tw-p-[20px] tw-mt-2 tw-rounded-md" style={{ border: '1px dashed #003049' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <React.Fragment>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Label style={{ ...labelStyle }}>Motorista</Label>
                      <Select
                        id="driver"
                        styles={customStyles}
                        options={driverList}
                        onChange={(text: any) =>
                          onChangeDriverTeam(text, teamList)
                        }
                        value={{
                          label: 'Digite o nome do motorista',
                          value: '',
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                      }}
                    >
                      <Label style={{ ...labelStyle }}>Colaboradores</Label>
                      <Select
                        id="collaborator"
                        styles={customStyles}
                        options={collaboratorList}
                        onChange={(text: any) =>
                          onChangeCollaboratorTeam(text, teamList)
                        }
                        value={{
                          label: 'Digite o nome do colaborador',
                          value: '',
                        }}
                      />
                    </div>
                  </React.Fragment>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginLeft: '1rem'
                  }}
                >
                  <Label
                    className=""
                    // style={{ ...labelStyle, marginLeft: '20px' }}
                  >
                    Equipe
                  </Label>
                  <div className="tw-w-full tw-border-1 tw-border-dashed tw-border-[#003049] tw-p-5 tw-overflow-y-scroll tw-rounded-md tw-max-h-40" style={{ border: '1px dashed #003049' }}>
                    {teamList
                      .sort((a, b) => a.type - b.type)
                      .map((team: any, indexTeam) => (
                        <div className="tw-flex tw-w-full tw-items-center tw-mt-2">
                          <img
                            src={
                              team.type === 'DRIVER'
                                ? driverTeamIcon
                                : collaboratorTeamIcon
                            }
                            className='tw-mr-2'
                          />
                          <span
                            className={
                              team.type === 'DRIVER'
                                ? 'tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden'
                                : 'tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden'
                            }
                          >
                            {team.nome}
                          </span>
                          <Button
                            color="link"
                            className="noMargin"
                            onClick={() => {
                              let removedElementTeam = teamList;
                              const filterRemovedElement = removedElementTeam.filter(
                                (item: any) => item.id !== team.id
                              );
                              setTeamList(filterRemovedElement);

                              setCollaboratorList([]);

                              getListCollaborators();

                              setDriverList([]);

                              getListDriver(filterRemovedElement);
                            }}
                          >
                            <img src={DeleteIcon} alt="" />
                          </Button>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-w-[170px] tw-ml-4 tw-mt-3">
            <div>
              <CheckBox
                label="Roundrip"
                active={formik.values.roundtrip}
                onChecked={() => {
                  formik.setFieldValue('roundtrip', !formik.values.roundtrip);
                }}
                id=""
                styles={{
                  fontSize: '0.820rem',
                }}
              />
            </div>
          </div>
        </div>

        <div className="tw-mt-4 tw-mb-6">
          <div className="tw-absolute tw-right-0 tw-flex tw-items-center tw-mb-3">
            <button
              type="button"
              className="tw-bg-[#EA004C] tw-w-[120px] tw-h-[35px] tw-border-none tw-rounded-md tw-mr-4 tw-text-[#fff]"
              onClick={() =>
                onUpdateCargoDetail(formik.values, listVehicles, teamList)
              }
            >
              Salvar
            </button>
          </div>
        </div>
        <div className="tw-mb-4" />
      </div>
    </>
  );
};

export default DetailsForm;
