import Grid from 'components/Grid/Grid';
import { ColumnDef, Direction, Filter, RowSelection } from 'components/Grid/types';
import CargoPre from 'interfaces/Delivery/CargoPre';
import { useEffect, useState } from 'react';
import {
  ButtonGroup,
  Col,
  Dropdown,
  DropdownButton,
  Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import GroupedGrid from './GroupedGrid';
import { generateUngroupedReturnData } from './groupingGridData';
import { GroupedRow, GroupedType, ReturnedRow } from './types';

interface Props {
  filters: Filter[];
  setReturnedRows: (rows: ReturnedRow[]) => void;
  setSelectedGroupedRow: (row: GroupedRow) => void;
}

export const CreateCargo = (props: Props) => {
  
  const { t } = useTranslation();
  const [groupedType, setGroupedType] = useState<GroupedType>(
    GroupedType.NO_ACTION
  );
  const [selectedRows, setSelectedRows] = useState<CargoPre[]>([]);
  const [rowData, setRowData] = useState<CargoPre[]>([]);
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'enderecoCidade',
      headerName: 'destiny',
      checkboxSelection: false,
      filter: true,
    },
    {
      field: 'placa',
      headerName: 'licensePlate',
      checkboxSelection: false,
      width: 120,
      filter: true,
    },
    {
      field: 'peso',
      headerName: 'weight',
      width: 120,
      filter: true,
    },
    {
      field: 'cubagem',
      headerName: 'cubage',
      width: 120,
      filter: true,
    },
    {
      field: 'valor',
      headerName: 'value',
      width: 120,
      filter: true,
    },
    {
      field: 'clienteNome',
      headerName: 'customer',
      filter: true,
    },
    {
      field: 'enderecoUf',
      headerName: 'address.state',
      filter: true,
      width: 80,
    },
    {
      field: 'region',
      headerName: 'region',
      filter: true,
      width: 110,
    },
    {
      field: 'section',
      headerName: 'section',
      filter: true,
      width: 110,
    },
    {
      field: 'route',
      headerName: 'route',
      filter: true,
      width: 110,
    }
  ]);
  const [filters] = useState<Filter[]>(
    props.filters !== undefined ? props.filters : []
  );

  useEffect(() => {
    if (
      groupedType === GroupedType.NO_ACTION &&
      rowData != null && rowData.length > 0
    ) {
      props.setReturnedRows(generateUngroupedReturnData(rowData));
    }
  }, [rowData]);

  return (
    <div className='tw-flex tw-flex-col tw-h-full'>
      {groupedType === GroupedType.NO_ACTION ? (
        <Row className="mb-1">
          <Col md="12">
            <ButtonGroup>
              <div className="tw-flex tw-items-center">
                <DropdownButton
                  as={ButtonGroup}
                  title={t('options')}
                  className="dropdown-custom"
                >
                  <Dropdown.Item
                    className="btn-default"
                    eventKey="region"
                    onClick={() => {
                      setGroupedType(GroupedType.REGION);
                    }}
                  >
                    {t('groupedby.region')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="btn-default"
                    eventKey="route"
                    onClick={() => {
                      setGroupedType(GroupedType.ROUTE);
                    }}
                  >
                    {t('groupedby.route')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.SECTOR);
                    }}
                  >
                    {t('groupedby.section')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="btn-default"
                    eventKey="idClient"
                    onClick={() => {
                      setGroupedType(GroupedType.ID_CLIENT);
                    }}
                  >
                    {t('groupedby.client')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.NAME_CLIENT);
                    }}
                  >
                    {t('groupedby.nameClient')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.CNPJ);
                    }}
                  >
                    {t('groupedby.cnpj')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.CITY);
                    }}
                  >
                    {t('groupedby.city')}
                  </Dropdown.Item>

                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.NEIGHBORHOOD);
                    }}
                  >
                    {t('groupedby.neighborhood')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.STATE);
                    }}
                  >
                    {t('groupedby.state')}
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="btn-default"
                    eventKey="sector"
                    onClick={() => {
                      setGroupedType(GroupedType.ZIPCODE);
                    }}
                  >
                    {t('groupedby.zipcode')}
                  </Dropdown.Item>
                </DropdownButton>
              </div>

            </ButtonGroup>
          </Col>
        </Row>) : (
        <></>
      )}

      {groupedType === GroupedType.NO_ACTION ? (
        <Row className="tw-h-[100%]">
          <Col md="12">
            {/* Grid com os pré-conhecimentos. */}
            <Grid<CargoPre>
              columns={columns}
              pagination={true}
              rowSelection={RowSelection.SINGLE}
              path="delivery/conhecimentosPre/pageable"
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              showCrudButtons={false}
              filters={filters}
              orders={[
                {
                  field: 'isErro',
                  direction: Direction.ASC
                }
              ]}
              setRowData={setRowData}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {groupedType !== GroupedType.NO_ACTION ? (
        <Row className="tw-h-[100%]">
          <Col md="12">
            {/* Grid com os pré-conhecimentos agrupados pelo tipo informado.*/}
            <GroupedGrid
              rowData={rowData}
              type={groupedType}
              setReturnedRows={props.setReturnedRows}
              setGroupedType={setGroupedType}
              setSelectedGroupedRow={props.setSelectedGroupedRow}
            />
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );
};
