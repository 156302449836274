import FilterListIcon from '@mui/icons-material/FilterList';
import { Filter, SearchOperation } from 'components/Grid/types';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import Loading from 'core/common/LoadingRoutering';
import { useFormik } from 'formik';
import { Region } from 'pages/Delivery/components/Region';
import { Route } from 'pages/Delivery/components/Route';
import { Sector } from 'pages/Delivery/components/Sector';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { States } from 'util/monitoring';
import {
  ClearFilters,
  ClearFiltersText,
  SectionFilter,
  SectionText,
} from '../styles';
import formValidator from './formValidator';

type FiltersFormValues = {
  filialExpedicao: string;
  dataAtendimentoInicial: string;
  dataAtendimentoFinal: string;
  dataFaturamentoInicial: string;
  dataFaturamentoFinal: string;
  region: string;
  route: string;
  sector: string;
  tipoAtendimento: string;
  reentrega: number | string;
  clienteNome: string;
  enderecoCidade: string;
  enderecoBairro: string;
  enderecoUf: string;
  enderecoCep: string;
  idReferenciaCliente: string;
  cnpjCliente: string;
};

export type BranchSchema = {
  id: number;
  nome: string;
  cnpj: string;
};

interface FiltersProps {
  onSendFilters: (data: Filter[]) => void;
  branches: BranchSchema[];
  route: Route[];
  region: Region[];
  sector: Sector[];
}

const FormFilter: React.FC<FiltersProps> = ({
  onSendFilters,
  branches,
  route,
  region,
  sector,
}: FiltersProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  function onLoadFilters(dataFilters: FiltersFormValues) {
    const loadFiltersData = dataFilters;
    let filters: Filter[] = [];

    if (
      loadFiltersData.dataAtendimentoInicial.length > 0 &&
      loadFiltersData.dataAtendimentoFinal.length > 0
    ) {
      filters.push({
        field: 'dataCriacao',
        value: `${loadFiltersData.dataAtendimentoInicial}T00:00:00`,
        operation: SearchOperation.GREATHER_THAN_EQUAL,
      });
      filters.push({
        field: 'dataCriacao',
        value: `${loadFiltersData.dataAtendimentoFinal}T23:59:59`,
        operation: SearchOperation.LESS_THAN_EQUAL,
      });
    }
    if (loadFiltersData.filialExpedicao.length > 0) {
      filters.push({
        field: 'filial',
        value: `${loadFiltersData.filialExpedicao}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.region.length > 0) {
      filters.push({
        field: 'regionReferenceId',
        value: `${loadFiltersData.region}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.route.length > 0) {
      filters.push({
        field: 'routeReferenceId',
        value: `${loadFiltersData.route}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.sector.length > 0) {
      filters.push({
        field: 'sectionReferenceId',
        value: `${loadFiltersData.sector}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.tipoAtendimento.length > 0) {
      filters.push({
        field: 'tipo',
        value: `${loadFiltersData.tipoAtendimento}`,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.reentrega !== 'null') {
      filters.push({
        field: 'reentrega',
        value: loadFiltersData.reentrega == 0 ? true : false,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.clienteNome.length > 0) {
      filters.push({
        field: 'clienteNome',
        value: loadFiltersData.clienteNome,
        operation: SearchOperation.MATCH,
      });
    }

    if (loadFiltersData.enderecoCidade.length > 0) {
      filters.push({
        field:
          loadFiltersData.tipoAtendimento === 'Entrega'
            ? 'enderecoCidade'
            : 'enderecoCidadeColeta',
        value: loadFiltersData.enderecoCidade,
        operation: SearchOperation.MATCH,
      });
    }

    if (loadFiltersData.enderecoBairro.length > 0) {
      filters.push({
        field:
          loadFiltersData.tipoAtendimento === 'Entrega'
            ? 'enderecoBairro'
            : 'enderecoBairroColeta',
        value: loadFiltersData.enderecoBairro,
        operation: SearchOperation.MATCH,
      });
    }

    if (loadFiltersData.enderecoUf.length > 0) {
      filters.push({
        field:
          loadFiltersData.tipoAtendimento === 'Entrega'
            ? 'enderecoUf'
            : 'enderecoUfColeta',
        value: loadFiltersData.enderecoUf,
        operation: SearchOperation.EQUAL,
      });
    }

    if (loadFiltersData.enderecoCep.length > 0) {
      filters.push({
        field:
          loadFiltersData.tipoAtendimento === 'Entrega'
            ? 'enderecoCep'
            : 'enderecoCepColeta',
        value: loadFiltersData.enderecoCep,
        operation: SearchOperation.MATCH,
      });
    }

    if (loadFiltersData.idReferenciaCliente.length > 0) {
      filters.push({
        field: 'clienteIdReferencia',
        value: loadFiltersData.idReferenciaCliente,
        operation: SearchOperation.MATCH,
      });
    }

    if (loadFiltersData.cnpjCliente.length > 0) {
      filters.push({
        field: 'clienteCnpj',
        value: loadFiltersData.cnpjCliente,
        operation: SearchOperation.MATCH,
      });
    }

    return filters;
  }

  const onHandleFilters = useCallback((values: FiltersFormValues) => {
    sessionStorage.setItem('subsidiary', values.filialExpedicao);
    const dataFilters: Filter[] = onLoadFilters(values);

    onSendFilters(dataFilters);
  }, []);

  const initialValues: FiltersFormValues = {
    filialExpedicao: '',
    dataAtendimentoInicial: '',
    dataAtendimentoFinal: '',
    dataFaturamentoInicial: '',
    dataFaturamentoFinal: '',
    region: '',
    route: '',
    sector: '',
    tipoAtendimento: '',
    reentrega: 1,
    clienteNome: '',
    enderecoBairro: '',
    enderecoCidade: '',
    enderecoUf: '',
    enderecoCep: '',
    idReferenciaCliente: '',
    cnpjCliente: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FiltersFormValues) => onHandleFilters(values),
  });

  return (
    <>
      <SectionFilter className="tw-mt-4">
        <FilterListIcon style={{ color: '#535669' }} />
        <SectionText>{t('filters')}</SectionText>
        <ClearFilters onClick={() => formik.resetForm({})}>
          <ClearFiltersText>{t('action.clearFilters')}</ClearFiltersText>
        </ClearFilters>
      </SectionFilter>

      <Loading loading={loading} />

      <div className="tw-mt-6 tw-mb-10">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('filialExpedicao')}
              error={formik.errors.filialExpedicao}
              touched={formik.touched.filialExpedicao}
              value={formik.values.filialExpedicao}
              title="Filial de expedição"
            >
              <>
                <option value="">{t('action.select')}</option>
                {branches.length > 0 && branches.map((data: BranchSchema, index: number) => (
                  <option value={data.id} key={index}>
                    {data.nome}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px] tw-mt-1">
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataAtendimentoInicial')}
              error={formik.errors.dataAtendimentoInicial}
              touched={formik.touched.dataAtendimentoInicial}
              value={formik.values.dataAtendimentoInicial}
              placeholder=""
              title="Data Inicial "
            />
          </div>
          <div className="tw-mt-1 tw-w-[170px]">
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataAtendimentoFinal')}
              error={formik.errors.dataAtendimentoFinal}
              touched={formik.touched.dataAtendimentoFinal}
              value={formik.values.dataAtendimentoFinal}
              placeholder=""
              title="Data Final "
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('region')}
              error={formik.errors.region}
              touched={formik.touched.region}
              value={formik.values.region}
              title="Região"
            >
              <>
                <option value="">Selecione uma opção</option>
                {region.map((data: Region, index: number) => (
                  <option value={data.referenceId} key={index}>
                    {data.description}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('route')}
              error={formik.errors.route}
              touched={formik.touched.route}
              value={formik.values.route}
              title="Rota"
            >
              <>
                <option value="">Selecione uma opção</option>
                {route.map((data: Route, index: number) => (
                  <option value={data.referenceId} key={index}>
                    {data.description}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('sector')}
              error={formik.errors.sector}
              touched={formik.touched.sector}
              value={formik.values.sector}
              title="Setor"
            >
              <>
                <option value="">Selecione uma opção</option>
                {sector.map((data: Sector, index: number) => (
                  <option value={data.referenceId} key={index}>
                    {data.description}
                  </option>
                ))}
              </>
            </SelectCustom>
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('tipoAtendimento')}
              error={formik.errors.tipoAtendimento}
              touched={formik.touched.tipoAtendimento}
              value={formik.values.tipoAtendimento}
              title="Tipo atendimento"
            >
              <option value="">Selecione uma opção</option>
              <option value="Entrega">Entrega</option>
              <option value="Coleta">Coleta</option>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <SelectCustom
              onChange={formik.handleChange('reentrega')}
              error={formik.errors.reentrega}
              touched={formik.touched.reentrega}
              value={formik.values.reentrega}
              title="Reentrega"
            >
              <option value="null">Selecione uma opção</option>
              <option value={0}>SIM</option>
              <option value={1}>NÃO</option>
            </SelectCustom>
          </div>
          <div className="tw-w-[170px]">
            <InputCustom
              type="text"
              onChange={formik.handleChange('clienteNome')}
              error={formik.errors.clienteNome}
              touched={formik.touched.clienteNome}
              value={formik.values.clienteNome}
              placeholder="Informe o nome do cliente"
              title="Nome do Cliente"
            />
          </div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
          <div className="tw-w-[170px]">
            <InputCustom
              type="text"
              onChange={formik.handleChange('idReferenciaCliente')}
              error={formik.errors.idReferenciaCliente}
              touched={formik.touched.idReferenciaCliente}
              value={formik.values.idReferenciaCliente}
              placeholder="Informe o id ref. do cliente"
              title="Id Ref. do cliente"
            />
          </div>
          <div className="tw-w-[170px]">
            <InputCustom
              type="text"
              onChange={formik.handleChange('cnpjCliente')}
              error={formik.errors.cnpjCliente}
              touched={formik.touched.cnpjCliente}
              value={formik.values.cnpjCliente}
              placeholder="Informe o cnpj do cliente"
              title="CNPJ do Cliente"
            />
          </div>
          {formik.values.tipoAtendimento.length > 0 ? (
            <div className="tw-w-[170px]">
              <InputCustom
                type="text"
                onChange={formik.handleChange('enderecoCidade')}
                error={formik.errors.enderecoCidade}
                touched={formik.touched.enderecoCidade}
                value={formik.values.enderecoCidade}
                placeholder="Informe a cidade"
                title="Cidade"
              />
            </div>
          ): (
            <div className='tw-w-[170px]' />
          )}
        </div>

        {formik.values.tipoAtendimento.length > 0 && (
          <>
            <div className="tw-flex tw-items-center tw-justify-between tw-mt-4">
              <div className="tw-w-[170px]">
                <InputCustom
                  type="text"
                  onChange={formik.handleChange('enderecoBairro')}
                  error={formik.errors.enderecoBairro}
                  touched={formik.touched.enderecoBairro}
                  value={formik.values.enderecoBairro}
                  placeholder="Informe o bairro"
                  title="Bairro"
                />
              </div>
              <div className="tw-w-[170px]">
                <SelectCustom
                  onChange={formik.handleChange('enderecoUf')}
                  error={formik.errors.enderecoUf}
                  touched={formik.touched.enderecoUf}
                  value={formik.values.enderecoUf}
                  title="Estado"
                >
                  <>
                    <option value="">Selecione uma opção</option>
                    {States.map((item: any) => (
                      <option value={item.sigla}>{item.nome}</option>
                    ))}
                  </>
                </SelectCustom>
              </div>
              <div className="tw-w-[170px]">
                <InputCustom
                  mask={'99999-999'}
                  typeInput="mask"
                  onChange={formik.handleChange('enderecoCep')}
                  error={formik.errors.enderecoCep}
                  touched={formik.touched.enderecoCep}
                  value={formik.values.enderecoCep}
                  placeholder="Informe o cep"
                  title="CEP"
                />
              </div>
            </div>

            <div className="tw-flex tw-items-center tw-justify-between tw-mt-3"></div>
          </>
        )}

        <div className="tw-mt-4">
          <div className="tw-absolute tw-right-0">
            <button
              type="button"
              className="tw-bg-[#EA004C] tw-w-[100px] tw-h-[35px] tw-border-none tw-rounded-md tw-mr-4 tw-text-[#fff]"
              onClick={() => formik.handleSubmit()}
            >
              Filtrar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormFilter;
