import RemoveIcon from '@mui/icons-material/Remove';
import freightRemoveIcon from 'assets/images/freightMinusIcon.svg';
import freightPlusIcon from 'assets/images/freightPlusIcon.svg';
import ProgressCargo from 'pages/RouterCargo/components/CargasRouting/components/Card/components/Progress';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import changeVehicle from '../../../../../../assets/images/changeVehicle.svg';
import deleteCargo from '../../../../../../assets/images/deleteCargo.svg';
import iconAttendancesCargo from '../../../../../../assets/images/iconAttendancesCargo.svg';
import iconCargoAction from '../../../../../../assets/images/orderRightCargo.svg';
// import { Container } from './styles';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import ChangeVehicles from 'pages/RouterCargo/components/CargasRouting/components/ChangeVehicles';
import { useDispatch, useSelector } from 'react-redux';
import permissionMenu from 'shared/Permission';
import {
  DataRouteringCargas,
  clearDataStepSelected,
  setDataRoute,
  setDetailRouteringCarga,
} from 'store/modules/routering/actions';
import { getFormatDistanceSteps, numberToCurrencyBRL } from 'util/format';
import editIcon from '../../../../../../assets/images/editIconRed.svg';
import iconAttendancesCargoActive from '../../../../../../assets/images/iconAttendancesCargoActive.svg';
import iconConfirmRoutering from '../../../../../../assets/images/iconConfirmRoutering.svg';
import iconWeightCargo from '../../../../../../assets/images/iconWeightCargo.svg';
import iconWeightCargoActive from '../../../../../../assets/images/iconWeightCargoActive.svg';
import IconArrowDown from '../../../../../../assets/images/knowledge/arrowDown.svg';
import packageCargo from '../../../../../../assets/images/packageCargo.svg';
import packageCargoActive from '../../../../../../assets/images/packageCargoActive.svg';
import priceCargo from '../../../../../../assets/images/priceCargo.svg';
import priceCargoActive from '../../../../../../assets/images/priceCargoActive.svg';
import CheckBox from '../CheckBox';

import { Tooltip } from '@mui/material';
import RouteringDetais from 'components/RouteringDetails';
import { format } from 'date-fns';
import api from 'services/api';
import CardCompacted from './components/CardCompacted';
import ConfirmationDriver from './components/ConfirmationDriver';

type Props = {
  data: DataRouteringCargas;
  indexCarga: number;
  menuSelected: string;
  onUpdateData: () => void;
  onCheckedCarga: (
    indexCarga: number,
    dataRouteSelected: DataRouteringCargas
  ) => void;
  onSelectMenu: (menuSelected: string) => void;
  onUpdateRoute: (carga: DataRouteringCargas) => void;
  isAllCompacted?: boolean;
  cargas: DataRouteringCargas[];
  onUpdateDataCargo: (data: DataRouteringCargas[]) => void;
};

interface SelectedRow extends DataRouteringCargas {}

const Card: React.FC<Props> = ({
  data,
  onSelectMenu,
  indexCarga,
  onCheckedCarga,
  menuSelected,
  onUpdateData,
  onUpdateRoute,
  cargas,
  onUpdateDataCargo,
}: Props) => {
  // ------------ Local States -----------------
  const [showChangeVehicle, setShowChangeVehicle] = useState<boolean>(false);
  const [showRemoveCargo, setShowRemoveCargo] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<SelectedRow>();
  const [dataCargas, setDataCargas] = useState<any[]>([]);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const [showRouteDetails, setShowRouteDetails] = useState<boolean>(false);
  const [isCompacted, setIsCompacted] = useState(false);
  const [parameters, setParameters] = useState<any[]>([]);
  // ------- State Global -----------
  const userData = useSelector((state: any) => state.auth.user);
  const getCargas = sessionStorage.getItem('cargas');
  const dataDetails: DataRouteringCargas = useSelector(
    (state: any) => state.routering.data.detail
  );
  const dataDetailRoutering = useSelector((state: any) => state.routering.data);

  const dispatch = useDispatch();

  // -------------- Auxiliary function for translation -----------------
  const { t } = useTranslation();

  // --------Function to calculate the percentage of completed cargos -------------
  function calcPorcentage(valueInitial: number, total: number) {
    const md_val = parseFloat(`${valueInitial}`);
    const medidab = parseInt(String((md_val / total) * 100));
    return medidab;
  }
  function getCalcPorcentage(dataAccumulator: DataRouteringCargas) {
    const accumulatorAttendances = calcPorcentage(
      dataAccumulator.steps.length,
      dataAccumulator.vehicle.quantidadeAtendimento
    );

    const accumulatorWeight = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.peso);
      },
      0
    );

    const accumulatorVolume = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return (
          acc +
          parseFloat(
            itemAcc.conhecimentoPre.cubagem.length === 0
              ? 0
              : itemAcc.conhecimentoPre.cubagem
          )
        );
      },
      0
    );

    const accumulatorValor = dataAccumulator.steps.reduce(
      (acc: any, itemAcc: any) => {
        return acc + parseFloat(itemAcc.conhecimentoPre.valor);
      },
      0
    );

    return {
      porcentageAttendances: accumulatorAttendances,
      porcentageWeight: calcPorcentage(
        accumulatorWeight,
        dataAccumulator.vehicle.peso
      ),
      porcentageVolume: calcPorcentage(
        accumulatorVolume,
        dataAccumulator.vehicle.cubagem
      ),
      porcentagePrice: calcPorcentage(
        accumulatorValor,
        dataAccumulator.vehicle.valor
      ),
    };
  }

  const getParameters = useCallback(async () => {
    try {
      const response = await api.get('/parameters/routing');

      setParameters(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    let getDataCargas;

    if (getCargas) {
      getDataCargas = JSON.parse(getCargas);

      setDataCargas(getDataCargas);
    }
  }, [getCargas]);

  useEffect(() => {
    getParameters();
  }, [getParameters]);

  return (
    <div>
      {showRouteDetails && (
        <RouteringDetais
          onClear={() => {
            setShowRouteDetails(!showRouteDetails);
            onUpdateData();
          }}
          onUpdate={onUpdateData}
          driverId={selectedRow?.driver.id}
          description={selectedRow?.description}
          idRouteOptmi={selectedRow?.id}
          idTypeCargo={selectedRow?.cargoType.id}
        />
      )}
      {showChangeVehicle && selectedRow && (
        <ChangeVehicles
          description={selectedRow.destiny}
          idRomaneio={selectedRow.id}
          idSubsidiary={selectedRow.subsidiary.id}
          vehiclesId={selectedRow && selectedRow.vehicle.id}
          onClear={() => setShowChangeVehicle(!showChangeVehicle)}
          onChangeCargo={onUpdateData}
        />
      )}

      {showRemoveCargo && (
        <ConfirmationDriver
          idRomaneio={selectedRow?.id}
          onClear={() => {
            setShowRemoveCargo(!showRemoveCargo);
          }}
          onLoad={onUpdateData}
        />
      )}
      <div
        className={
          getCalcPorcentage(data).porcentageAttendances > 100 ||
          getCalcPorcentage(data).porcentageWeight > 100 ||
          getCalcPorcentage(data).porcentageVolume > 100 ||
          getCalcPorcentage(data).porcentagePrice > 100
            ? `tw-w-full tw-shadow-2xl tw-rounded-xl tw-h-auto tw-mt-3 tw-p-2 tw-cursor-pointer tw-flex-nowrap`
            : `tw-w-full tw-shadow-2xl tw-rounded-xl tw-h-auto tw-mt-3 tw-p-2 tw-cursor-pointer tw-flex-nowrap`
        }
        onClick={() => {
          if (menuSelected === 'cargas' && data.active === false) {
            dispatch(clearDataStepSelected());
            dispatch(setDetailRouteringCarga(data));
            const setDataRouteActive = [
              {
                ...data,
              },
            ];
            dispatch(setDataRoute(setDataRouteActive));
            onSelectMenu('knowledge');
          } else {
            dispatch(setDetailRouteringCarga(data));
            dispatch(clearDataStepSelected());
            onSelectMenu('cargas');
          }
        }}
        style={{
          borderTop: `10px solid ${data.fillColor}`,
          background:
            dataDetails !== null && dataDetails.id === data.id
              ? '#fff'
              : dataDetails !== null && dataDetails.id !== data.id
              ? '#e0dada'
              : '#fff',
        }}
      >
        {data.isCompacted ? (
          <CardCompacted
            onExpand={() => {
              const getDataCargoRoutering = dataDetailRoutering.dataOptmi
                .map((stepSelected: DataRouteringCargas, indexStep: number) =>
                  stepSelected.id === data.id ? indexStep : -1
                )
                .filter((filterStepSelected: any) => filterStepSelected !== -1);

              // dispatch(updateAllRouteringCargaCompacted(getDataCargoRoutering[0]));

              const updateCargoCompacted = cargas.map(
                (row: DataRouteringCargas, index: number) => {
                  if (index == getDataCargoRoutering[0]) {
                    return {
                      ...row,
                      isCompacted: false,
                    };
                  }
                  return {
                    ...row,
                  };
                }
              );

              onUpdateDataCargo(updateCargoCompacted);
            }}
            data={data}
          />
        ) : (
          <>
            <div className="tw-flex tw-relative tw-items-start tw-justify-between">
              <div className="tw-auto">
                <span
                  className="tw-text-[#EA004C] tw-font-bold tw-text-2xl"
                  onClick={(e: any) => {
                    e.stopPropagation();
                    const getDataCargoRoutering = dataDetailRoutering.dataOptmi
                      .map(
                        (
                          stepSelected: DataRouteringCargas,
                          indexStep: number
                        ) => (stepSelected.id === data.id ? indexStep : -1)
                      )
                      .filter(
                        (filterStepSelected: any) => filterStepSelected !== -1
                      );

                    // dispatch(
                    //   updateAllRouteringCargaCompacted(getDataCargoRoutering[0])
                    // );

                    const updateCargoCompacted = cargas.map(
                      (row: DataRouteringCargas, index: number) => {
                        if (index == getDataCargoRoutering[0]) {
                          return {
                            ...row,
                            isCompacted: true,
                          };
                        }
                        return {
                          ...row,
                        };
                      }
                    );

                    onUpdateDataCargo(updateCargoCompacted);

                    // setDataKnowledge(updateStepsCompacted);
                  }}
                >
                  <RemoveIcon color="error" />
                </span>
              </div>

              <div className="tw-flex tw-flex-col tw-ml-6">
                <span className="tw-text-[#003049] tw-font-bold tw-text-sm">
                  {data.driver !== null && data.driver.nome}
                </span>
                <span className="tw-text-[#003049] tw-font-normal tw-text-sm">
                  {data.vehicle.placa}
                </span>
                <span className="tw-text-[#003049] tw-font-normal tw-text-sm">
                  {data.subsidiary.nome}
                </span>
              </div>
              <div className="tw-w-auto">
                <CheckBox
                  active={data.active}
                  onChecked={() => onCheckedCarga(indexCarga, data)}
                />
              </div>
              <div className="actionCargo">
                <img src={iconCargoAction} alt="click cargo" />
              </div>
            </div>
            <div className="tw-flex tw-justify-between tw-mt-2">
              <div className="tw-flex tw-flex-col tw-mr-2">
                <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                  <img
                    src={
                      getCalcPorcentage(data).porcentageAttendances > 100
                        ? iconAttendancesCargoActive
                        : iconAttendancesCargo
                    }
                  />
                  <ProgressCargo
                    progressValue={
                      getCalcPorcentage(data).porcentageAttendances
                    }
                  />
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                  <img
                    src={
                      getCalcPorcentage(data).porcentageWeight > 100
                        ? iconWeightCargoActive
                        : iconWeightCargo
                    }
                  />
                  <ProgressCargo
                    progressValue={getCalcPorcentage(data).porcentageWeight}
                  />
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                  <img
                    src={
                      getCalcPorcentage(data).porcentageVolume > 100
                        ? packageCargoActive
                        : packageCargo
                    }
                  />
                  <ProgressCargo
                    progressValue={getCalcPorcentage(data).porcentageVolume}
                  />
                </div>
                <div className="tw-flex tw-items-center tw-justify-between tw-mt-2 tw-w-[80%]">
                  <img
                    src={
                      getCalcPorcentage(data).porcentagePrice > 100
                        ? priceCargoActive
                        : priceCargo
                    }
                  />
                  <ProgressCargo
                    progressValue={getCalcPorcentage(data).porcentagePrice}
                  />
                </div>
              </div>

              <div className="tw-flex tw-flex-col tw-mr-4 tw-ml-2">
                <div className="tw-flex tw-flex-col tw-mb-2 tw-ml-0">
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    <Trans i18nKey="routeOptmization.routeOptmis.fields.distance" />
                  </span>
                  <span className="tw-text-sm tw-font-normal tw-text-[#003049]">
                    {getFormatDistanceSteps(data.distanceTotal)}Km
                  </span>
                </div>
                <div className="tw-flex tw-flex-col tw-mb-2">
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    <Trans i18nKey="routeOptmization.routeOptmis.fields.duration" />
                  </span>
                  <span className="tw-text-sm tw-font-normal tw-text-[#003049]">
                    {`${data.routeTimer}`}
                    {data.travelTime.hour === 0 ? 'min' : 'h'}
                  </span>
                </div>
                <div className="tw-flex tw-flex-col">
                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                    <Trans i18nKey="routeOptmization.routeOptmis.fields.price" />
                  </span>
                  <span className="tw-text-sm tw-font-normal tw-text-[#003049]">
                    {data.cost === 0 ? '0,00' : numberToCurrencyBRL(data.cost)}
                  </span>
                </div>
              </div>
              <div className="tw-flex tw-flex-col">
                <img
                  src={changeVehicle}
                  className="tw-mb-4 tw-cursor-pointer"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    if (
                      permissionMenu(
                        userData.grupo.permissoes,
                        'routering.change-vehicle'
                      )
                    ) {
                      setSelectedRow(data);
                      setShowChangeVehicle(!showChangeVehicle);
                    } else {
                      FrontendNotification(
                        t('common.permission'),
                        NotificationType.WARNING
                      );
                    }
                  }}
                />
                <img
                  src={deleteCargo}
                  className="tw-mb-4 tw-cursor-pointer"
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    e.stopPropagation();
                    if (
                      permissionMenu(
                        userData.grupo.permissoes,
                        'routering.remove-route'
                      )
                    ) {
                      setSelectedRow(data);
                      setShowRemoveCargo(!showRemoveCargo);
                    } else {
                      FrontendNotification(
                        t('common.permission'),
                        NotificationType.WARNING
                      );
                    }
                  }}
                />
              </div>
            </div>
            <div className="tw-flex">
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-sm tw-text-[#000] tw-font-bold">
                  <Trans i18nKey="routeOptmization.routeOptmis.fields.dateScheduling" />
                </span>
                <span className="tw-flex tw-items-center tw-text-sm tw-text-[#000] tw-font-normal">
                  {showEdit ? (
                    <input
                      type="datetime-local"
                      onChange={e => {
                        e.stopPropagation();
                        let dataCargasItem;

                        if (getCargas) {
                          dataCargasItem = JSON.parse(getCargas);
                          const getCargasById = dataCargasItem
                            .map((item: any, index: number) =>
                              item.id === data.id ? index : -1
                            )
                            .filter((itemFilter: any) => itemFilter !== -1);

                          dataCargasItem[getCargasById[0]].dataAgendamento =
                            e.target.value;
                          sessionStorage.setItem(
                            'cargas',
                            JSON.stringify(dataCargasItem)
                          );

                          setDataCargas(dataCargasItem);
                        }
                      }}
                      value={
                        dataCargas?.find(
                          (itemCarga: any) => itemCarga.id === data.id
                        ).dataAgendamento
                      }
                      className="tw-max-w-28"
                      style={{
                        width: '83%',
                        borderRadius: 10,
                        border: '2px solid rgba(0,48,73,0.2)',
                        height: 35,
                      }}
                    />
                  ) : (
                    <>
                      {data.dataAgendamento !== null
                        ? format(new Date(data.dataAgendamento), 'dd/MM/yyyy')
                        : '----'}
                    </>
                  )}
                  <img
                    src={showEdit ? iconConfirmRoutering : editIcon}
                    alt=""
                    style={{
                      marginLeft: '0.613rem',
                      marginBottom: '0.120rem',
                      width: 18,
                      height: 18,
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (showEdit) {
                        onUpdateRoute(data);
                        setShowEdit(!showEdit);
                      } else {
                        setShowEdit(!showEdit);
                      }
                    }}
                  />{' '}
                </span>
              </div>
              {permissionMenu(
                userData.grupo.permissoes,
                'routering.view-freight-cost'
              ) && (
                <div className="tw-flex tw-items-center tw-justify-center tw-ml-2">
                  <div className="tw-flex tw-flex-col">
                    <label className="tw-text-sm tw-font-bold tw-text-[#003049] tw-mb-0">
                      Frete
                    </label>
                    <Tooltip
                      title={
                        data.freightCost !== null ? data.freightCost : '---'
                      }
                      placement="top"
                    >
                      <span className="tw-text-sm tw-text-[#003049] tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap tw-max-w-[8ch]">
                        R${' '}
                        {data.freightCost !== null ? data.freightCost : '---'}
                      </span>
                    </Tooltip>
                  </div>
                  {data.freightFactorPercentage !== null && (
                    <div className="tw-flex tw-mt-5 tw-ml-2">
                      <span
                        className="tw-text-sm tw-font-bold tw-mr-1"
                        style={{
                          color: `${
                            parameters.length > 0 &&
                            parameters[0].freightFactor &&
                            data.freightFactorPercentage >
                              parameters[0].freightFactor
                              ? '#EA004C'
                              : '#08B639'
                          }`,
                        }}
                      >
                        {data.freightFactorPercentage}
                      </span>
                      {parameters.length > 0 &&
                        parameters[0].freightFactor &&
                        parameters[0].freightFactor !== null && (
                          <>
                            {parameters.length > 0 &&
                            parameters[0].freightFactor &&
                            data.freightFactorPercentage >
                              parameters[0].freightFactor ? (
                              <img src={freightRemoveIcon} />
                            ) : (
                              <img src={freightPlusIcon} />
                            )}
                          </>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="">
              <button
                type="button"
                className="more-details btn align-items"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedRow(data);
                  setShowRouteDetails(!showRouteDetails);
                }}
              >
                <span className="tw-text-sm tw-font-bold tw-text-[#EA004C]">
                  <Trans i18nKey="knowledge.moreDetails" />
                </span>
                <img src={IconArrowDown} alt="" className="tw-ml-2 tw-mt-1" />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
