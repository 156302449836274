import { Branch } from "components/SubsidiaryRegister/interfaces/types";
import Driver from "interfaces/Delivery/Driver";

export enum VehicleType {
  VUC = "VUC",
  TOCO = "TOCO",
  BITRUCK = "BITRUCK",
  TRUCK = "TRUCK",
  CARRETA = "CARRETA",
  VAN = "VAN",
  VEICULO_PASSEIO = "VEICULO_PASSEIO",
  MOTO = "MOTO",
  BICICLETA = "BICICLETA",
  TRUCK_FRIO = "TRUCK_FRIO",
  CARRETA_FRIO = "CARRETA_FRIO",
  TOCO_FRIO = "TOCO_FRIO",
  BITRUCK_FRIO = "BITRUCK_FRIO",
  RODOTREM = "RODOTREM",
  TRES_QUARTOS = "TRES_QUARTOS",
  TRES_QUARTOS_FRIO = "TRES_QUARTOS_FRIO",
}

export type IVehicles = {
  id: number;
  placa: string;
  idReferencia: string;
  descricao: string;
  motorista: Driver;
  filial: Branch;
  tipo: VehicleType;
  disponivel: boolean;
  quantidadeAtendimento: number;
  peso: number;
  cubagem: number;
  valor: number;
  veiculoProprio: boolean;
  velocidadeMedia: number;
  ano: number;
  anoFabricacao: number;
  dataCriacao: string;
  dataModificacao: string;
  marcaModelo: string;
  colaboradores: any[]
}