import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import { Error } from './styles';

interface InputProps {
  title?: string;
  name?: string;
  id?: string;
  edit?: boolean;
  disabled?: boolean;
  isHidden?: boolean;
  value?: any;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  touched?: any;
  error?: any;
  children: JSX.Element | React.ReactChild | React.ReactChild[];
  style?: any;
}

interface StylesProps {
  edit?: boolean;
  isHidden?: boolean;
}

const SelectCustom: React.FC<InputProps> = (
  {
    title,
    id,
    disabled,
    edit,
    touched,
    error,
    onChange,
    isHidden,
    value,
    style,
    children,
  }: InputProps,
  ...rest
) => {
  const styleSelect = {
    height: '2.5rem',
    backgroundColor: `${({ edit }: StylesProps) =>
      edit || disabled === true ? '#ccc' : '#FFFFFF'}`,
    opacity: `${({ edit }: StylesProps) => (edit ? 0.8 : 1)}`,
    border: `0.125rem solid rgba(0, 48, 73, 0.2)`,
    borderRadius: '0.625rem',
    fontfamily: 'Lato',
    fontWeight: 300,
    fontStyle: 'normal',
    fontSize: '0.875rem',
    lineHeight: '1.188rem',
    color: 'rgb(0, 48, 73)',
    marginTop: '0.5rem',
    // marginBottom: responsiveSize(10),
    paddingLeft: '0.625rem',
  };
  return (
    <>
      <span className="tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60">
        {title}
      </span>
      <div className="tw-relative">
        <select
          id={id}
          {...rest}
          onChange={onChange}
          value={value}
          className={`tw-appearance-none formControl tw-h-9 tw-w-full tw-bg-[${
            edit || disabled ? '#ccc' : '#FFFFFF'
          }] tw-rounded-[10px] tw-text-sm tw-text-[rgba(0, 48, 73, 0.2)] tw-placeholder-[rgba(0. 48, 73, 0.2)] option tw-pl-2 tw-outline-none tw-mt-1.5`}
          style={{ ...style, border: '2.5px solid rgba(0, 48, 73, 0.2)' }}
        >
          {children}
        </select>
        <div className="tw-absolute tw-flex tw-items-center tw-pointer-events-none tw-top-3 tw-right-1 tw-cursor-pointer">
          <KeyboardArrowDownIcon
            width={32}
            height={32}
            style={{ color: '#EA004C' }}
          />
        </div>
      </div>

      {/* <Input {...rest} type="select" onChange={onChange} value={value} style={{...styleSelect, style}} id={id} disabled={disabled}>
        {children}
      </Input> */}

      {touched && error && <Error>{error}</Error>}
    </>
  );
};

export default SelectCustom;
