import { ICellRendererParams } from 'ag-grid-community';
import checkedIcon from 'assets/images/checkedIcon.svg';
import closedIcon from 'assets/images/closedIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import api from 'services/api';
import { validateField } from './utils/validate';

interface Props {
  data: ICellRendererParams;
  baseURL: string;
}

const ParameterValue: React.FC<Props> = (props: Props) => {
  const { data: params } = props;
  const { value } = params;
  const [enabled, setEnabled] = useState(false);
  const [textValue, setTextValue] = useState(value || '');
  const [loading, setLoading] = useState(false);
  const data: any = params.node;

  const renderValueByType = (type: string, value: string) => {
    let defaultValue;

    switch (type) {
      case 'Double':
        defaultValue = String(value).length > 0 && value !== null ? parseFloat(value).toFixed(2) : null;
        break;
      case 'Integer':
        defaultValue = String(value).length > 0 && value !== null ? parseInt(value) : null;
        break;
      case 'Long':
        defaultValue = Number(value);
        break;
      case 'Boolean':
        defaultValue = value === 'true';
        break;
      default:
        defaultValue = value.length > 0 ? value : null;
        break;
    }
    return defaultValue;
  };

  const onUpdateData = useCallback(async () => {
    setLoading(true);
    let updateData: any[] = [];

    params.api.forEachNode(node => {
      updateData.push(node.data);
    });

    let msg: string = '';

    const mappingData = updateData.map(item => {
      if (msg.length === 0) {
        msg = validateField(item.key, item.type, item.value);
      }
      const valueConverter = renderValueByType(item.type, item.value);

      return {
        key: item.key,
        value: valueConverter,
      };
    });

    if (msg.length > 0) {
      FrontendNotification(msg, NotificationType.WARNING);
    } else {
      const body = mappingData;

      const response = await api.post(`${props.baseURL}` + '/custom', body);

      if (response.status === 204) {
        FrontendNotification(
          'Parametro atualizado com sucesso!',
          NotificationType.SUCCESS
        );
      }
    }

    setLoading(false);
  }, []);

  const handleValueChange = () => {
    params.api.stopEditing(); // Finaliza a edição
    if (params.column) {
      params.node.setDataValue(params.column.getColId(), textValue !== null ? textValue : '');
    }
    setEnabled(!enabled);

    onUpdateData();
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-mt-2">
      <Loading loading={loading} />
      {enabled ? (
        <div className="tw-flex tw-w-full tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center tw-justify-center">
            {params.data.type === 'Boolean' ? (
              <input
                type="checkbox"
                value={textValue}
                checked={textValue === 'true'}
                onChange={(e: any) => {
                  setTextValue(e.target.checked ? 'true' : 'false');
                }}
              />
            ) : params.data.type === "LocalDate" ? (
              <input
              type={"date"}
              className="tw-flex tw-w-full tw-h-7 tw-pl-2 tw-border-none tw-border-1 tw-border-[#EA004C] tw-rounded-md !tw-mr-1"
              style={{ border: '2px solid #ccc' }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTextValue(e.target.value);
              }}
              value={textValue}
            />
            ) : (
              <input
                type={
                  params.data.type === 'Integer' ||
                  params.data.type === 'Double' ||
                  params.data.type === 'Long'
                    ? 'number'
                    : 'text'
                }
                className="tw-flex tw-w-full tw-h-7 tw-pl-2 tw-border-none tw-border-1 tw-border-[#EA004C] tw-rounded-md !tw-mr-1"
                style={{ border: '2px solid #ccc' }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextValue(e.target.value);
                }}
                value={textValue}
              />
            )}
          </div>
          <div className="tw-flex tw-items-center">
            <img
              src={checkedIcon}
              className="tw-cursor-pointer"
              onClick={() => {
                handleValueChange();
              }}
            />
            <img
              src={closedIcon}
              className="tw-cursor-pointer tw-w-3 tw-h-3 tw-ml-2"
              onClick={() => {
                setEnabled(!enabled);
              }}
            />
          </div>
        </div>
      ) : (
        <div className="tw-flex tw-w-full tw-justify-between tw-mb-4 tw-h-full">
          <span className="tw-text-base">{value}</span>
          <img
            src={editIcon}
            className="tw-cursor-pointer"
            onClick={() => {
              setEnabled(!enabled);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ParameterValue;
