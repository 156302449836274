import AppLogo from 'components/AppLogo';
import React, { useState } from 'react';
import { Nav } from 'reactstrap';
//Assets
import CheckMenu from '../../../../assets/images/checkMenu.svg';
import CheckMenuLight from '../../../../assets/images/checkMenuLight.svg';
import Image from '../../../../assets/images/logo_light.svg';
import TruckMenu from '../../../../assets/images/truckMenu.svg';
import TruckMenuLight from '../../../../assets/images/truckMenuLight.svg';

interface Props {
  onChange: (menuSelected: string) => any;
  showMenu?: boolean;
}

const SideBarRouting: React.FC<Props> = ({
  onChange,
  showMenu = false,
}: Props) => {
  const [selectedMenu, setSelectedMenu] = useState<string>('cargas');

  return (
    <React.Fragment>
      <div className="tw-flex-col tw-min-w-24 tw-max-w-24 tw-items-center tw-justify-center tw-bg-[#6369D1] tw-h-auto">
        <React.Fragment>
          <AppLogo
            src={Image}
            className="tw-w-16 img-fluid tw-mt-4 tw-mb-2 tw-ml-4"
          />
          {showMenu && (
            <div className="tw-flex-col tw-relative tw-h-auto">
              <Nav className="tw-w-full tw-h-auto tw-flex-col">
                <div
                  onClick={() => {
                    setSelectedMenu('cargas');
                    onChange('cargas');
                  }}
                  className={
                    selectedMenu === 'cargas'
                      ? 'tw-bg-[#EDF2F4] tw-shadow-lg tw-w-4/5 tw-rounded-s-md tw-p-5 tw-flex tw-items-center tw-mt-4 tw-ml-5 tw-cursor-pointer'
                      : 'tw-w-4/5 tw-shadow-md tw-p-5 tw-flex tw-items-center tw-mt-4 tw-ml-5 tw-cursor-pointer'
                  }
                >
                  <img
                    alt=""
                    className="tw-w-10"
                    src={selectedMenu === 'cargas' ? TruckMenu : TruckMenuLight}
                  />
                </div>
                {!window.location.pathname.includes('cargosFormation') && (
                  <div
                    onClick={() => {
                      setSelectedMenu('check');
                      onChange('check');
                    }}
                    className={
                      selectedMenu === 'check'
                        ? 'tw-bg-[#EDF2F4] tw-shadow-lg tw-w-4/5 tw-rounded-s-md tw-p-5 tw-flex tw-items-center tw-mt-4 tw-ml-5 tw-cursor-pointer'
                        : 'tw-w-4/5 tw-shadow-md tw-p-5 tw-flex tw-items-center tw-mt-4 tw-ml-5 tw-cursor-pointer'
                    }
                  >
                    <img
                      alt=""
                      src={
                        selectedMenu === 'check' ? CheckMenuLight : CheckMenu
                      }
                    />
                  </div>
                )}
              </Nav>
            </div>
          )}
        </React.Fragment>
      </div>
    </React.Fragment>
  );
};

export default SideBarRouting;
