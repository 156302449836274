/* eslint-disable no-nested-ternary */
import { VehicleType } from 'components/Vehicles/interfaces/types';
import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import orderBottom from '../../assets/images/arrow-down-3101.svg';
import bicycle from '../../assets/images/typeVehicles/bicycle.svg';
import carreta from '../../assets/images/typeVehicles/cart.svg';
import motorcycle from '../../assets/images/typeVehicles/motorcycle.svg';
import toco from '../../assets/images/typeVehicles/toco.svg';
import tourVehicles from '../../assets/images/typeVehicles/tourVehicles.svg';
import truck from '../../assets/images/typeVehicles/truck.svg';
import van from '../../assets/images/typeVehicles/van.svg';
import vuc from '../../assets/images/typeVehicles/vuc.svg';
import './styles.scss';

interface Props {
  onChange: (type: string) => void;
  value: string;
  fieldId: string;
  isView: boolean;
}

export default function CustomSelectVehicles({
  onChange,
  value,
  fieldId,
  isView,
}: Props) {
  const [vehicleTypes, setVehicleTypes] = React.useState(value || '');

  const renderIcons = (icon: string) => {
    let urlIcon = vuc;
    switch (icon) {
      case VehicleType.VUC:
        urlIcon = vuc;
        break;
      case VehicleType.TOCO:
        urlIcon = toco;
        break;
      case VehicleType.BITRUCK:
        urlIcon = toco;
        break;
      case VehicleType.TRUCK:
        urlIcon = truck;
        break;
      case VehicleType.CARRETA:
        urlIcon = carreta;
        break;
      case VehicleType.VAN:
        urlIcon = van;
        break;
      case VehicleType.VEICULO_PASSEIO:
        urlIcon = tourVehicles;
        break;
      case VehicleType.MOTO:
        urlIcon = motorcycle;
        break;
      case VehicleType.BICICLETA:
        urlIcon = bicycle;
        break;
      case VehicleType.TRUCK_FRIO:
        urlIcon = truck;
        break;
      case VehicleType.CARRETA_FRIO:
        urlIcon = carreta;
        break;
      case VehicleType.TOCO_FRIO:
        urlIcon = toco;
        break;
      case VehicleType.BITRUCK_FRIO:
        urlIcon = toco;
        break;
      case VehicleType.RODOTREM:
        urlIcon = toco;
        break;
      case VehicleType.TRES_QUARTOS:
        urlIcon = carreta;
        break;
      case VehicleType.TRES_QUARTOS_FRIO:
        urlIcon = carreta;
        break;
      default:
        urlIcon = '';
        break;
    }

    return urlIcon;
  };

  useEffect(() => {
    setVehicleTypes(value);
  }, [value]);

  return (
    <Dropdown className='!tw-outline-none'>
      <Dropdown.Toggle
        disabled={isView}
        id={fieldId}
        className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-9 !tw-bg-[#fff] tw-border-2 tw-border-[#ccc] !tw-rounded-md focus:tw-border-none tw-mb-2 !tw-outline-none"
        style={{ border: '2.5px solid rgba(0, 48, 73, 0.2)' }}
      >
        <div className="tw-flex tw-items-center tw-absolute" id="vuc">
          {vehicleTypes && vehicleTypes.length > 1 && (
            <img src={renderIcons(vehicleTypes)} className="tw-w-5 tw-h-5 tw-mb-4 tw-mr-2" alt="" />
          )}
          <p className="tw-text-sm tw-text-[rgba(23,40,49,0.6)] tw-align-center tw-mt-0.4">
            {vehicleTypes && vehicleTypes.length > 1
              ? vehicleTypes
              : 'Selecione'}
          </p>
        </div>
        <div className="tw-absolute tw-top-1.4 tw-right-2.5">
          <img
            src={orderBottom}
            alt=""
            className="tw-w-2.5 tw-h-2.5"
            // style={{ width: '0.6rem', height: '0.6rem' }}
          />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className="tw-w-full tw-overflow-hidden tw-overflow-y-scroll tw-outline-none">
        <div className='tw-overflow-y-scroll tw-h-60'>
          <Dropdown.Item
            className='tw-flex tw-items-center tw-justify-center !tw-p-0 tw-ml-2 tw-mt-2'
            // style={{ padding: 5 }}
            onClick={() => {
              setVehicleTypes('S');
              onChange('S');
            }}
          >
            <div className="tw-flex tw-w-full tw-items-end tw-justify-start tw-p-0.5">
              <p className="tw-text-sm tw-text-[#000]">
                <Trans i18nKey="notices.select" />
              </p>
            </div>
          </Dropdown.Item>
          {Object.values(VehicleType).map((item: string) => (
            <Dropdown.Item
              style={{ padding: 5 }}
              onClick={() => {
                setVehicleTypes(item);
                onChange(item);
              }}
              id={item.toLowerCase()}
            >
              <div className="tw-flex tw-items-center" id={item.toLowerCase()}>
                <img src={renderIcons(item)} className="tw-w-5 tw-h-5 tw-mb-4 tw-mr-2" alt="" />
                <p className="tw-text-sm tw-text-[rgba('0, 48, 73, 0.2')]">{item.replaceAll('_', ' ')}</p>
              </div>
            </Dropdown.Item>
          ))}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
