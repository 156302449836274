import { CreateCargo } from 'components/CreateCargo/CreateCargo';
import { GroupedRow, ReturnedRow } from 'components/CreateCargo/types';
import { Filter } from 'components/Grid/types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { resetCargasAccumulator, resetVehiclesAccumulator } from 'store/modules/accumulator/actions';
import { Container, Title } from '../ModalFilter/styles';

type Props = {
  handleReturnedRows: (data: ReturnedRow[]) => void;
  handleSelectedGroupedRow: (data: GroupedRow) => void;
  filters: Filter[];
};

const LoadCargos: React.FC<Props> = ({
  handleReturnedRows,
  handleSelectedGroupedRow,
  filters,
}: Props) => {

  const [width, setWidth] = useState(800);
  const [height, setHeight] = useState(450);
  const [returnedRows, setReturnedRows] = useState<ReturnedRow[]>([]);

  const dispatch = useDispatch();


  const onResize = (data: any) => {
    console.log(data);
    setWidth(data.size.width);
    setHeight(data.size.height);
  };

  useEffect(() => {
    handleReturnedRows(returnedRows);

    dispatch(resetCargasAccumulator());
    dispatch(resetVehiclesAccumulator());
  }, [returnedRows]);

  return (
    <div
      id="cargas"
      className="tw-absolute tw-cursor-default tw-top-[20px] tw-right-[20px] tw-h-max tw-z-[1000]"
    >
      {/* <SelectVehicles /> */}
      <Resizable
        width={width}
        height={height}
        onResize={(e: any, data: any) => onResize(data)}
        draggableOpts={{ grid: [20, 20] }}
      >
        <div className="tw-shadow-lg">
          <div
            className="tw-bg-[#EA004C] tw-rounded-sm"
          >
            <Container>
              <Title>Formação de Cargas</Title>
            </Container>
          </div>
          <div
            className="tw-bg-[#f1f5f9] tw-cursor-default tw-p-2"
            style={{ width: width + 'px', height: height + 'px' }}
          >
            <CreateCargo filters={filters} setReturnedRows={setReturnedRows} setSelectedGroupedRow={handleSelectedGroupedRow}/>
          </div>
        </div>
      </Resizable>
    </div>
  );
};

export default LoadCargos;
