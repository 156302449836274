import { VehicleType } from 'components/Vehicles/interfaces/types';
import React, { useCallback, useEffect, useState } from 'react';
import Select from 'react-select';
import { renderIcons } from './types';
// import { Container } from './styles';

interface Props {
  onChange: (type: string) => void;
  value: string;
  fieldId: string;
  isView: boolean;
}

const CustomSelectVehicles: React.FC<Props> = (props: Props) => {
  const [vehicleTypes, setVehicleTypes] = useState<any[]>([]);
  const [selectedVehicle, setSelectedVehicle] =  useState<any>();

  const getVehicleTypes = useCallback(() => {
    let data = Object.values(VehicleType).map((item: any) => {
      return {
        label: item,
        value: item,
      };
    });
    data.unshift({
      label: 'Selecione uma opção',
      value: ''
    });
    setVehicleTypes(data);

    const findTypeVehicle = data.find((item: any) => item.value === props.value);

    setSelectedVehicle(findTypeVehicle)
  }, [props.value]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      marginTop: 0,
      height: '2.25rem',
      borderRadius: '0.375rem'
    }),
    option: (provided: any) => ({
      ...provided,
      background: '#FFF',
      color: '#000',
    }),
  };

  useEffect(() => {
    getVehicleTypes();
  }, [getVehicleTypes]);

  return (
    <div className='tw-mb-2'>
      <label className='tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60 tw-mb-1'>Tipo</label>
      <Select
        options={vehicleTypes}
        id="typeAlert"
        name="subsidiary"
        placeholder="Selecione"
        styles={customStyles}
        className="basic-multi-select"
        classNamePrefix="select"
        onChange={(selectedOption: any) => {
          setSelectedVehicle(selectedOption);
          props.onChange(selectedOption.value)
        }}
        isDisabled={props.isView}
        // defaultValue={selectedVehicle}
        value={selectedVehicle}
        formatOptionLabel={(item: any) => {
          return (
            <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
              <img src={renderIcons(item.value)} alt="" />
              {item.label.replaceAll('_', ' ')}
            </div>
          );
        }}
      />
    </div>
  );
};

export default CustomSelectVehicles;
