import CustomDialog from 'components/CustomDialog';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormFilters from './components/FormFilters';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const Filters: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  return (
    <CustomDialog open={props.open} onClose={props.onClose} showHeaderIcon title={t('shippingAudit.filters.title')} >
      <FormFilters onConfirm={props.onConfirm} />
      {/* <Filters onSendFilters={(data: Filter[]) => onConfirm(data)} branches={branches} route={route} region={region} sector={sector} /> */}
    </CustomDialog>
  )
}

export default Filters;