import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import { formatNumber } from 'util/format';
import Header from './Header';
import ListShipping from './ListShipping';
import Rating, { RatingData } from './Rating';
import ShippingContent from './ShippingContent';
import { Container, Content, Wrapper } from './styles';
import formValidatorRatingPT from './validation/formValidatorRatingPT';

export interface ICreateShippingTableForm {
  description?: string;
  dateInitial?: string;
  finalDate?: string;
  type: string;
  filialOrigem?: string;
  operadorLogistico?: string;
  tipoVeiculo?: string;
  veiculo?: string;
  tipoAtendimento?: string;
  qtdKM?: string;
  valueKM?: string;
  surplusValue?: string;
  initialDay?: string;
  zipcode?: string;
  valueZipcode?: string;
  obs: string;
}

interface NewShippingTableProps {
  isEdit: boolean;
  editId: number;
  data: any[];
  onHandleSubmit: () => void;
  isCopy: boolean;
  isView: boolean;
}

const NewShippingTable: React.FC<NewShippingTableProps> = ({
  isEdit,
  editId,
  data,
  onHandleSubmit,
  isCopy = false,
  isView = false,
}: NewShippingTableProps) => {
  const [dataRating, setDataRating] = useState<any[]>([]);
  const [isEditRating, setIsEditRanting] = useState<boolean>(false);
  const [updateIdRanting, setUpdateIdRanting] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [ratingData, setRatingData] = useState<RatingData>({
    branchs: [],
    logisticOperators: [],
    vehicle: [],
  });

  const [selectedRow, setSelectedRow] = useState<any[]>([]);

  const { t } = useTranslation();

  const onDeleteRow = useCallback(async (data: any, dataRating: any[]) => {
    let dataFilter = data;

    const findIndexRatingByData = dataFilter.findIndex((i: any) => i.id == dataRating[0].id);

    dataFilter = dataFilter.filter(
      (item: any, index: number) => index != findIndexRatingByData
    );

    setDataRating(dataFilter);
  }, []);

  const onSubmitShipping = useCallback(
    async (values: ICreateShippingTableForm, rating: any[]) => {
      try {
        setLoading(true);

        console.log(values);

        const onMappingRating = rating.map((item: any) => {
          let formattedValueExcedente = String(
            item.franquias[0].valorExcedente
          );
          if (formattedValueExcedente.includes(',')) {
            formattedValueExcedente = formattedValueExcedente.replace(
              /\./g,
              ''
            );
          }
          formattedValueExcedente = formattedValueExcedente.replace(',', '.');
          const valueExcedente = parseFloat(formattedValueExcedente);

          let formattedValue = String(
            values.type === 'CEP'
              ? item.franquias[0].valueZipcode
              : item.franquias[0].valor
          );
          if (formattedValue.includes(',')) {
            formattedValue = formattedValue.replace(/\./g, '');
          }
          formattedValue = formattedValue.replace(',', '.');
          const value = parseFloat(formattedValue);

          let vehicleType = item.tipoVeiculo;

          return {
            id: null,
            filialOrigem:
              item.filialOrigem.id !== null ? item.filialOrigem : null,
            operadorLogistico:
              item.operadorLogistico.id.length > 0
                ? item.operadorLogistico
                : null,
            tipoVeiculo: vehicleType.length > 0 ? vehicleType : null,
            veiculo: item.veiculo.id.length > 0 ? item.veiculo : null,
            tipoConhecimento: null,
            obs: item.obs,
            franquias: [
              {
                km: item.franquias[0].qtd > 0 ? item.franquias[0].qtd : 0,
                valor: value,
                valorExcedente:
                  String(item.franquias[0].valorExcedente).length > 0
                    ? valueExcedente
                    : 0,
                diaInicio:
                  String(item.franquias[0].diaInicio).length > 0
                    ? item.franquias[0].diaInicio
                    : 0,
                cep:
                  String(item.franquias[0].zipcode).length > 0
                    ? String(item.franquias[0].zipcode).split('-')[0]
                    : null,
              },
            ],
          };
        });

        if (rating.length <= 0) {
          FrontendNotification(
            t('shippingTable.messageErrorMinRating'),
            NotificationType.WARNING
          );
        } else {
          const requestData = {
            descricao: values.description,
            tipo: values.type,
            dataInicial: values.dateInitial,
            dataFinal: values.finalDate,
            classificadores: onMappingRating,
          };

          const response = await api.post(
            'delivery/tabelas/frete',
            requestData
          );

          if (response.status === 201) {
            FrontendNotification(
              'Tabela de Frete cadastrada com sucesso!',
              NotificationType.SUCCESS
            );
            onHandleSubmit();
          }
          setLoading(false);
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const initialValues: ICreateShippingTableForm = {
    description: '',
    dateInitial: '',
    finalDate: '',
    type: '',
    filialOrigem: '',
    operadorLogistico: '',
    tipoVeiculo: '',
    veiculo: '',
    tipoAtendimento: '',
    qtdKM: '',
    valueKM: '',
    surplusValue: '',
    initialDay: '',
    zipcode: '',
    valueZipcode: '',
    obs: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formValidatorRatingPT,
    onSubmit: (values: ICreateShippingTableForm) => {
      onRegisterRating(values, isEditRating, updateIdRanting, dataRating);
    },
  });

  function convertDecimalValue(currency: any) {
    var currency: any = currency; //it works for US-style currency strings as well
    var cur_re = /\D*(\d+|\d.*?\d)(?:\D+(\d{2}))?\D*$/;
    var parts: any = cur_re.exec(currency);
    var number = parseFloat(
      parts[1].replace(/\D/, '') + '.' + (parts[2] ? parts[2] : '00')
    );

    return number.toFixed(2);
  }

  const onRegisterRating = useCallback(
    async (
      values: ICreateShippingTableForm,
      isEdit: boolean,
      updateIdRanting: any,
      dataUpdate: any[]
    ) => {
      try {
        let typeVehicle = values.tipoVeiculo;
        if (values.tipoVeiculo && values.tipoVeiculo === 'S') {
          typeVehicle = values.tipoVeiculo.replace('S', '');
        }

        let rantingData: any[] = dataUpdate;
        const data = {
          filialOrigem: {
            id: values.filialOrigem,
          },
          operadorLogistico: {
            id:
              String(values.operadorLogistico) &&
              String(values.operadorLogistico).length > 0
                ? String(values.operadorLogistico)
                : '',
          },
          tipoVeiculo:
            typeVehicle === '3/4'
              ? 'TRES_QUARTOS'
              : typeVehicle === '3/4_FRIO'
              ? 'TRES_QUARTOS_FRIO'
              : typeVehicle,
          veiculo: {
            id:
              String(values.veiculo) && String(values.veiculo).length > 0
                ? String(values.veiculo)
                : '',
          },
          obs: values.obs,
          tipoConhecimento: '',
          franquias: [
            {
              qtd: values.qtdKM,
              valor: values.valueKM,
              valorExcedente: values.surplusValue,
              diaInicio: values.initialDay,
              zipcode:
                String(values.zipcode) && String(values.zipcode).length > 0
                  ? String(values.zipcode)
                  : '',
              valueZipcode: values.valueZipcode,
            },
          ],
        };

        console.log("Register", data);

        formik.resetForm({
          values: {
            description: values.description,
            type: values.type,
            dateInitial: values.dateInitial,
            finalDate: values.finalDate,
            filialOrigem: '',
            operadorLogistico: '',
            tipoVeiculo: '',
            veiculo: '',
            qtdKM: '',
            valueKM: '',
            surplusValue: '',
            initialDay: '',
            zipcode: '',
            valueZipcode: '',
            obs: ''
          },
        });

        if (isEdit) {
          const updatedRowData: any[] = dataUpdate.map((row, indexRow) => {
            if (indexRow == updateIdRanting) {
              return { id: row.id, ...data };
            }
            return row;
          });
          setDataRating(updatedRowData);
        } else {
          const registerData = {  id: dataUpdate.length + 1 ,...data}
          setDataRating((oldArray: any) => [...oldArray, registerData]);
        }
        setIsEditRanting(false);
      } catch {}
    },
    []
  );

  const onEditRow = useCallback((data: any[], dataRating: any[]) => {
    const findIndexRatingByData = dataRating.findIndex(i => i.id == data[0].id);

    const getRowData = data[0];

    formik.setFieldValue('filialOrigem', String(getRowData.filialOrigem.id));
    formik.setFieldValue(
      'operadorLogistico',
      String(getRowData.operadorLogistico.id)
    );
    formik.setFieldValue('tipoVeiculo', getRowData.tipoVeiculo);
    formik.setFieldValue('veiculo', String(getRowData.veiculo.id));
    formik.setFieldValue('qtdKM', String(getRowData.franquias[0].qtd));
    formik.setFieldValue(
      'valueKM',
      formatNumber(getRowData.franquias[0].valor)
    );
    formik.setFieldValue(
      'surplusValue',
      formatNumber(getRowData.franquias[0].valorExcedente)
    );
    formik.setFieldValue(
      'initialDay',
      parseInt(getRowData.franquias[0].diaInicio)
    );

    formik.setFieldValue(
      'zipcode',
      getRowData.franquias[0].zipcode !== null
        ? String(getRowData.franquias[0].zipcode)
        : null
    );
    formik.setFieldValue(
      'valueZipcode',
      formatNumber(getRowData.franquias[0].valueZipcode)
    );
    formik.setFieldValue('obs', getRowData.obs);

    setIsEditRanting(true);
    setUpdateIdRanting(findIndexRatingByData);

    setDataRating(dataRating);
  }, []);

  const onCopyRow = useCallback((id: number, data: any[]) => {
    const getRowData = data[0];

    formik.setFieldValue('type', getRowData.tipo);
    formik.setFieldValue('description', getRowData.descricao);
    formik.setFieldValue('dateInitial', getRowData.dataInicial);
    formik.setFieldValue('finalDate', getRowData.dataFinal);

    const onLoadRating = getRowData.classificadores.map((rating: any, index: number) => {
      return {
        id: index + 1,
        filialOrigem: {
          id:
            rating.filialOrigem !== null ? String(rating.filialOrigem.id) : '',
        },
        operadorLogistico: {
          id:
            rating.operadorLogistico !== null
              ? String(rating.operadorLogistico.id)
              : '',
        },
        tipoVeiculo: rating.tipoVeiculo !== null ? rating.tipoVeiculo : '',
        veiculo: {
          id: rating.veiculo !== null ? String(rating.veiculo.id) : '',
        },
        tipoConhecimento: '',
        obs: rating.obs,
        franquias: [
          {
            qtd: rating.franquias[0].km !== null ? rating.franquias[0].km : '',
            valor:
              rating.franquias[0].valor !== null
                ? rating.franquias[0].valor
                : '',
            valorExcedente:
              rating.franquias[0].valorExcedente !== null
                ? rating.franquias[0].valorExcedente
                : '',
            diaInicio:
              rating.franquias[0].diaInicio !== null
                ? rating.franquias[0].diaInicio
                : '',
            zipcode:
              rating.franquias[0].cep !== null
                ? `${rating.franquias[0].cep}`
                : '',
            valueZipcode:
              rating.franquias[0].valor !== null
                ? rating.franquias[0].valor
                : '',
          },
        ],
      };
    });

    setDataRating(onLoadRating);
  }, []);

  const getRatingDataContent = useCallback(async () => {
    try {
      const ratingData = localStorage.getItem('ratingData');

      let data;

      if (ratingData) {
        data = JSON.parse(ratingData);
      } else {
        data = {
          branchs: [],
          logisticOperators: [],
          vehicle: [],
        };
      }

      setRatingData(data);
    } catch {}
  }, []);

  useEffect(() => {
    getRatingDataContent();
    if (isEdit) {
      onCopyRow(editId, data);
    }
  }, [data]);

  return (
    <>
      <Loading loading={loading} />
      {/* {onCopyRow(editId, data)} */}
      <Container>
        <Header
          isBlocked={dataRating.length > 0}
          isView={isView}
          onChange={(field: string, value: string) =>
            formik.setFieldValue(field, value)
          }
          values={formik.values}
          errors={formik.errors}
          touched={formik.touched}
          copy={isCopy}
        />
        <Wrapper>
          <Rating
            onChange={(field: string, value: string) =>
              formik.setFieldValue(field, value)
            }
            isView={isView}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
          />
          <ShippingContent
            onSubmit={formik.handleSubmit}
            onChange={(field: string, value: any) =>
              formik.setFieldValue(field, value)
            }
            isView={isView}
            values={formik.values}
            errors={formik.errors}
            touched={formik.touched}
          />
          <Content>
            <ListShipping
              data={dataRating}
              ratingData={ratingData}
              onDelete={(data: any[]) => onDeleteRow(dataRating, data)}
              onEdit={(data: any[]) => onEditRow(data, dataRating)}
              onSubmitShipping={() =>
                onSubmitShipping(formik.values, dataRating)
              }
              type={formik.values.type}
              isView={isView}
            />
          </Content>
        </Wrapper>
      </Container>
    </>
  );
};

export default NewShippingTable;
