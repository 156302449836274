/* eslint-disable react/jsx-filename-extension  */

import CustomTabs from 'components/CustomTabs';
import Grid from 'components/Grid/Grid';
import { ColumnDef, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import api from 'services/api';
import EditRegion from './EditRegion';
import NewRegion from './NewRegion';

// import { Container } from './styles';

export type Region = {
  id: number;
  description: string;
  referenceId: string;
};

interface Props {
  isClose: () => void;
}

const Region: React.FC<Props> = ({ isClose }: Props) => {
  const [tabs, setTabs] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<Region[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [columns] = useState<ColumnDef[]>([
    {
      field: 'description',
      headerName: 'Descrição',
      filter: true,
    },
    {
      field: 'referenceId',
      headerName: 'Id de referência',
      filter: true,
      flex: 1
    },
  ]);

  const onHandleDelete = useCallback(async (data: Region[]) => {
    try {
      const response = await api.delete(`/delivery/region/${data[0].id}`);

      if (response.status === 204) {
        FrontendNotification(
          'Região removida com sucesso!',
          NotificationType.SUCCESS
        );
      }

      setIsRemove(false);
      setSelectedRows([]);
      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  function onUpdate() {
    if(selectedRows.length > 0) {
      setTabs(2);
    }
  }

  function onDelete() {
    setIsRemove(!isRemove);
  }

  function onCreate() {
    setTabs(1);
  }

  return (
    <CustomModal isOpen isClose={isClose} label="Região" styles={{ minWidth: '600px' }}>
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => onHandleDelete(selectedRows)}
      />

      <CustomTabs
        onChange={(selectedTab: number) => setTabs(selectedTab)}
        tabSelected={tabs}
        renderTab1={(
          <>
          <div style={{ height: '400px', maxHeight: 'max-content' }}>
            <Grid<Region>
              columns={columns}
              pagination={true}
              rowSelection={RowSelection.SINGLE}
              path="/delivery/region/pageable"
              filters={[]}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onUpdate={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setTabs(2);
              }}
              onDelete={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setIsRemove(!isRemove)
              }}
              onCreate={onCreate}
              showCrudButtons={false}
              customCrudButtons
              />
          </div>
          <div className='tw-mb-20' />
              </>
        )}
        renderTab2={(
          <NewRegion onClear={() => setTabs(0)} />
        )}
        renderTab3={(
          <EditRegion onClear={() => setTabs(0)} data={selectedRows[0]} />
        )}
      />
    </CustomModal>
  );
};

export default Region;
