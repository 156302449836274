import { IPointInterest } from 'components/PointInterest/types/types';
import React from 'react';
import { Trans } from 'react-i18next';

// import { Container } from './styles';

interface Props {
  data: IPointInterest[];
}

const DetailsPointsInterest: React.FC<Props> = (props: Props) => {
  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-w-full">
      <div className="tw-flex tw-w-full tw-h-full tw-items-center tw-justify-around">
        {props.data && props.data.length > 0 && (
          <div className="tw-w-full tw-h-full">
            {/* <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="routeOptmization.routeSteps.steps.serviceNumber" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].numero}
              </span>
            </div> */}
            {/* <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="routeOptmization.routeSteps.steps.expectedArrival" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].eta !== null
                  ? format(
                      new Date(props.data[activeIndex].eta),
                      'dd/MM/yyyy HH:mm',
                      {
                        locale: pt,
                      }
                    )
                  : '---'}
              </span>
            </div> */}
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center">
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.description" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1">
                  {props.data[0].description}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-2">
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.type" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1">
                  {props.data[0].typePointInterest.description}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-2">
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.enderecoLogradouro" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1">
                  {props.data[0].endereco.logradouro}
                </span>
              </div>
            </div>

            <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-2">
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.enderecoNumero" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1 tw-w-full">
                  {props.data[0].endereco.numero}
                </span>
              </div>
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.enderecoCep" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1 tw-w-full">
                  {props.data[0].endereco.cep}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-2">
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.enderecoCidade" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1 tw-w-full">
                  {props.data[0].endereco.cidade}
                </span>
              </div>
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.enderecoBairro" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1 tw-w-full">
                  {props.data[0].endereco.bairro}
                </span>
              </div>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-mt-2">
              <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
                <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                  <Trans i18nKey="pointInterest.fields.enderecoEstado" />:
                </span>
                <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1 tw-w-full">
                  {props.data[0].endereco.uf}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsPointsInterest;
