// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
Garante que o grid não passe do espaço da DIV reservado para ele.
Se usar 100% ele irá passar e portanto é necessário que deixe em 90%.
*/
.ag-root-wrapper.ag-layout-normal {
    width: 100%;
    height: 90%;
}

.ag-root-wrapper-body.ag-layout-normal {
    /* height: auto; */
}

#agGrid {
    width: 100%;
}

.ag-theme-quartz {
    width: inherit !important;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/Grid/style.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":["/**\nGarante que o grid não passe do espaço da DIV reservado para ele.\nSe usar 100% ele irá passar e portanto é necessário que deixe em 90%.\n*/\n.ag-root-wrapper.ag-layout-normal {\n    width: 100%;\n    height: 90%;\n}\n\n.ag-root-wrapper-body.ag-layout-normal {\n    /* height: auto; */\n}\n\n#agGrid {\n    width: 100%;\n}\n\n.ag-theme-quartz {\n    width: inherit !important;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
