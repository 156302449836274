import { ICargoTypes } from 'components/CargoTypes/types/types';
import { Content } from 'components/Driver/styles';
import InputCustom from 'components/Input';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Label } from 'reactstrap';
import api from 'services/api';
import CustomModal from '../ModalCustom';
import { DriverShiftTitle } from './styles';

interface DriverShift {
  turno: {
    horarioInicial: string;
    horarioFinal: string;
  };
}

interface Props {
  onClear?: any;
  driverId?: number;
  description?: string | null;
  onUpdate: () => void;
  idRouteOptmi?: number;
  idTypeCargo?: number | null;
}

const RouteringDetais: React.FC<Props> = ({
  onClear,
  driverId,
  idRouteOptmi,
  description,
  onUpdate,
  idTypeCargo,
}: Props) => {
  const [vehiclesData, setVehiclesData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [hoursInitial, setHoursInitial] = useState('');
  const [hoursFinal, setHoursFinal] = useState('');
  const [descriptionRouteOptmi, setDescriptionRouteOptmi] = useState(
    description !== null && description && description.length > 0
      ? description
      : ''
  );
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState<any[]>([]);
  const [cargoTypes, setCargoTypes] = useState<any[]>([]);
  const [valueTypeCargo, setValueTypeCargo] = useState();

  const { t } = useTranslation();

  const onSaveTypeCargas = useCallback(async (typeCargo: any) => {
    try {
      setLoading(true);

      const response = await api.patch(
        `/route/optmis/${idRouteOptmi}/cargo-type/${typeCargo.value}`
      );

      if (response.status === 204) {
        FrontendNotification(
          t('cargoTypes.messageSaveSuccess'),
          NotificationType.SUCCESS
        );
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getDriverShift = useCallback(async () => {
    try {
      const dataShift = {
        filters: [
          {
            field: 'id',
            value: driverId,
            operation: 'EQUAL',
          },
        ],
        page: 0,
        size: 10,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/pageable',
        dataShift
      );
      setHoursInitial(response.data.content[0].turno.horarioInicial);
      setHoursFinal(response.data.content[0].turno.horarioFinal);
    } catch (err) {}
  }, [driverId]);

  const getDriverDetails = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'motorista.id',
            value: driverId,
            operation: 'EQUAL',
          },
        ],
        page: 0,
        size: 10,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/horariosPausa/pageable',
        data
      );

      setDriverData(response.data.content);
    } catch (err) {}
  }, [driverId]);

  const onUpdateDescriptionRoute = useCallback(
    async (descriptionOptmi: any) => {
      try {
        setLoading(true);
        const data = {
          description: descriptionOptmi,
        };

        const response = await api.patch(
          `/route/optmis/description/${idRouteOptmi}`,
          data
        );

        FrontendNotification(
          'Descrição da rota atualizada com sucesso!',
          NotificationType.SUCCESS
        );

        onUpdate();

        setLoading(false);
      } catch {}
    },
    []
  );

  const getParameters = useCallback(async () => {
    try {
      const response = await api.get('/parameters/routing');

      setParameters(response.data);
    } catch {}
  }, []);

  const getCargoTypes = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get('/delivery/tipos/cargas');

      const mappingResponse = response.data.map((item: ICargoTypes) => {
        return {
          value: item.id,
          label: item.nome,
        };
      });

      setCargoTypes(mappingResponse);

      if (idTypeCargo !== null) {
        const findCargoTypesById = mappingResponse.find(
          (item: any) => String(item.value) == String(idTypeCargo)
        );

        if (findCargoTypesById) {
          setValueTypeCargo(findCargoTypesById);
        }
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Eleva o z-index do menu
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Ajusta o menu quando renderizado fora do DOM do Select
    }),
    option: (provided: any) => ({
      ...provided,
      background: '#FFF',
      color: '#000',
    }),
  };

  useEffect(() => {
    getDriverDetails();
    getDriverShift();
    getParameters();
    getCargoTypes();
  }, [getDriverDetails, getDriverShift, getParameters, getCargoTypes]);

  return (
    <CustomModal
      isOpen={true}
      styles={{ maxWidth: '50%', height: '90%' }}
      label="Mais Detalhes"
      isClose={onClear}
    >
      <Loading loading={loading} />

      <DriverShiftTitle>Turno:</DriverShiftTitle>

      <Content style={{ marginTop: 10, marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                Horario Inicio
                {/* <Trans i18nKey="driverShift.fields.description" /> */}
              </th>
              <th>
                Horario Final
                {/* <Trans i18nKey="driverShift.fields.interval" /> */}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="lineGroup">
              <td>{hoursInitial.substring(0, 5)}</td>
              <td>{hoursFinal.substring(0, 5)}</td>
            </tr>
          </tbody>
        </table>
      </Content>

      <DriverShiftTitle>Intervalos:</DriverShiftTitle>

      <Content style={{ marginTop: 10, marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                <Trans i18nKey="driverShift.fields.description" />
              </th>
              <th>
                <Trans i18nKey="driverShift.fields.interval" />
              </th>
            </tr>
          </thead>
          <tbody>
            {driverData.map((item: any) => (
              <tr className="lineGroup">
                <td>{item.descricao}</td>
                <td>
                  {`${item.intervalo.horarioInicial.substring(
                    0,
                    5
                  )} - ${item.intervalo.horarioFinal.substring(0, 5)}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>

      <DriverShiftTitle>Jornada Motorista:</DriverShiftTitle>

      <Content style={{ marginTop: 10, marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                Horario Inicio
                {/* <Trans i18nKey="driverShift.fields.description" /> */}
              </th>
              <th>
                Horario Final
                {/* <Trans i18nKey="driverShift.fields.interval" /> */}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="lineGroup">
              <td>
                {parameters.length > 0 &&
                  parameters[0].driverTimeWindow &&
                  parameters[0].driverTimeWindow !== null &&
                  parameters[0].driverTimeWindow.split(',')[0]}
              </td>
              <td>
                {parameters.length > 0 &&
                  parameters[0].driverTimeWindow &&
                  parameters[0].driverTimeWindow !== null &&
                  parameters[0].driverTimeWindow.split(',')[1]}
              </td>
            </tr>
          </tbody>
        </table>
      </Content>

      {/* <DriverShiftTitle>Descrição da rota:</DriverShiftTitle>
      <Row>
        <Col md={10}>
          <InputCustom
            type="text"
            placeholder="Insira a descrição da rota"
            onChange={(e: any) => setDescriptionRouteOptmi(e.target.value)}
            onBlur={() => onUpdateDescriptionRoute(descriptionRouteOptmi)}
            value={descriptionRouteOptmi}
          />
        </Col>
      </Row> */}
      <div className="tw-grid tw-grid-cols-2 tw-mt-4 tw-w-[90%]">
        <div className="tw-w-full">
          <Label className="tw-text-lg tw-text-[#000] tw-font-bold tw-mb-0">
            Descrição da rota:
          </Label>

          <InputCustom
            type="text"
            placeholder="Insira a descrição da rota"
            onChange={(e: any) => setDescriptionRouteOptmi(e.target.value)}
            onBlur={() => onUpdateDescriptionRoute(descriptionRouteOptmi)}
            value={descriptionRouteOptmi}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-min-w-[200px] tw-ml-2">
          <Label className="tw-text-lg tw-text-[#000] tw-font-bold">
            Tipo de Carga
          </Label>
          <div className="tw-flex tw-flex-col tw-w-full tw-items-center">
            <div className="tw-w-full">
              <Select
                id="typeAlert"
                name="subsidiary"
                options={cargoTypes}
                placeholder="Selecione"
                styles={customStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOption: any) => {
                  setValueTypeCargo(selectedOption);
                }}
                value={valueTypeCargo}
              />
            </div>
            <div className='tw-flex tw-justify-end tw-w-full tw-mt-3'>

            <button
              className="tw-flex tw-items-center tw-h-[38px] tw-w-full tw-max-w-[120px] tw-justify-center tw-bg-[#EA004C] tw-text-sm tw-text-[#fff] tw-font-semibold tw-rounded-md tw-cursor-pointer tw-border-none tw-ml-4"
              type="button"
              onClick={() => onSaveTypeCargas(valueTypeCargo)}
              >
              Salvar
            </button>
              </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default RouteringDetais;
