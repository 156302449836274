import React from 'react';

// import { Container } from './styles';
interface Props {
  onChecked: () => void;
  type: string;
  checked: boolean;
  title: string;
}

const Switch: React.FC<Props> = (props: Props) => {
  
  const renderCheckedType = (type: string) => {
    let color = "#6369D1";

    switch (type) {
      case 'fence':
        color = "#EA004C";
        break;
      case 'coarse':
        color = "#08B639";
        break;
      case 'position':
        color = "#006CEA";
        break;
    
      default:
        break;
    }

    return color;
  };

  return (
    <div className="tw-flex tw-mb-4 tw-mr-3">
      <div className="tw-flex tw-flex-col tw-items-start">
        <span className='tw-text-sm tw-text-[#003049] tw-font-light tw-mb-1'>{props.title}</span>
        <div
          className="tw-relative tw-w-[4.40rem] tw-h-8 tw-bg-[#edf2f4] tw-rounded-3xl"
          style={{ border: '1px solid #c4c4c4' }}
          onClick={() => props.onChecked()}
        >
          {props.checked ? (
            <div className={`tw-w-8 tw-h-5 tw-rounded-3xl tw-bg-[${renderCheckedType(props.type)}] tw-absolute tw-right-1 tw-top-1`} />
          ) : (
            <div className="tw-w-8 tw-h-5 tw-rounded-3xl tw-bg-[#003049] tw-absolute tw-left-1 tw-top-1" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Switch;
