/* eslint-disable react/jsx-filename-extension  */
/* eslint-disable no-unused-vars  */
/* eslint-disable no-empty  */
/* eslint-disable no-var  */
/* eslint-disable prefer-const  */
/* eslint-disable no-self-assign  */
/* eslint-disable prefer-template  */
/* eslint-disable spaced-comment  */
/* eslint-disable array-callback-return  */
/* eslint-disable no-unneeded-ternary  */
/* eslint-disable no-shadow  */
/* eslint-disable class-methods-use-this  */
/* eslint-disable prefer-destructuring  */
/* eslint-disable lines-between-class-members  */
/* eslint-disable react/jsx-curly-brace-presence  */
/* eslint-disable react/jsx-boolean-value  */

import polyline from '@mapbox/polyline';
import Alertas from 'components/Alertas';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import Leaflet from 'leaflet';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet/dist/leaflet.css';
import React from 'react';
import { Trans } from 'react-i18next';
import { Map, Marker, Polyline, Popup, TileLayer } from 'react-leaflet';
import getIconVehicle from 'shared/IconVehicles';
import pointFilial from '../../assets/images/pointFilial.svg';
import SuperiorInterface from '../../components/SuperiorInterface';
import Loading from '../../core/common/Loading';
import api from '../../services/api';
import BaseComponents from '../../template/BaseComponents';
import Carousel from './Carousel';
import './style.scss';

export default class Monitoring extends BaseComponents {
  constructor(props) {
    super(props);
    this.loadRomaneios = this.loadRomaneios.bind(this);
    this.loadKnowledge = this.loadKnowledge.bind(this);
    this.setPositionMap = this.setPositionMap.bind(this);
    this.leafletMap = React.createRef();
    this.positions = sessionStorage.getItem('loadMap');
  }

  state = {
    stepsActual: null,
    vehicles: [],
    cargas: [],
    indexRomaneio: 0,
    alertCargo: null,
    showAlerts: false,
    dataPositions: {},
    points: [],
    pointsOSRM: [],
    lat: -8.0527,
    lng: -34.9099,
    zoom: 13,
    initMapRoute: false,
    getPositionTruck: false,
    firstLoadKnowledge: true,
    getRomaneios: null,
    markerId: null,
    idDriver: null,
    popoverOpen: false,

    selectedTruck: {
      id: 0,
      selected: false,
    },
    visibleRomaneios: true,
    clock: this.time(),
    subsidiary: [],
    uf: [
      {
        id: 1,
        nome: 'Pernambuco ',
      },
      {
        id: 2,
        nome: 'Distrito Federal ',
      },
      {
        id: 3,
        nome: 'Sergipe',
      },
      {
        id: 4,
        nome: 'Santa Catarina',
      },
      {
        id: 5,
        nome: 'Roraima',
      },
      {
        id: 6,
        nome: 'Rondônia',
      },
      {
        id: 7,
        nome: 'Pará',
      },
      {
        id: 8,
        nome: 'Mato Grosso do Sul',
      },
      {
        id: 9,
        nome: 'Mato Grosso',
      },
      {
        id: 10,
        nome: 'Maranhão',
      },
      {
        id: 11,
        nome: 'Goiás',
      },
      {
        id: 12,
        nome: 'Espírito Santo',
      },
      {
        id: 13,
        nome: 'Amapá',
      },
      {
        id: 14,
        nome: 'Ceará',
      },
      {
        id: 15,
        nome: 'Acre',
      },
      {
        id: 16,
        nome: 'Rio Grande do Sul',
      },
      {
        id: 17,
        nome: 'Minas Gerais',
      },
      {
        id: 18,
        nome: 'São Paulo',
      },
      {
        id: 19,
        nome: 'Paraíba',
      },
      {
        id: 20,
        nome: 'Amazonas',
      },
      {
        id: 21,
        nome: 'Piauí',
      },
      {
        id: 22,
        nome: 'Rio Grande do Norte',
      },
      {
        id: 23,
        nome: 'Bahia',
      },
      {
        id: 24,
        nome: 'Paraná',
      },
      {
        id: 25,
        nome: 'Rio de Janeiro',
      },
      {
        id: 26,
        nome: 'Alagoas',
      },
      {
        id: 27,
        nome: 'Tocantins',
      },
    ],
    status: [
      {
        id: 1,
        nome: '001 - Aberto',
      },
      {
        id: 2,
        nome: '002 - Cancelado',
      },
      {
        id: 3,
        nome: '003 - Pendente',
      },
      {
        id: 4,
        nome: '004 - Devolução',
      },
      {
        id: 5,
        nome: '005 - Em Atraso',
      },
      {
        id: 6,
        nome: '006 - Saiu Para Entrega',
      },
      {
        id: 7,
        nome: '007 - Entregue',
      },
    ],
    generalIndicators: {},
    initialPosition: [-8.0527, -34.9099],
    jsonOSRM: [],
  };

  async getTruckMarkers(select) {
    let data;

    const subsidiary = sessionStorage.getItem('subsidiary');

    await api
      .get(`/delivery/veiculos/filial/${subsidiary}`)
      .then(res => {
        this.setState({
          vehicles: res.data,
        });

        this.setCargoData(res.data);
      })
      .catch(error => {
        // BackendErrorNotification(error);
      });
  }
  async filterDriverFilial(select) {
    const data = {
      filters: [
        {
          field: 'filial.id',
          value: select,
          operation: 'EQUAL',
        },
      ],
    };
    await api
      .post('/delivery/motoristas/findByFilters', data)
      .then(res => {
        this.setCargoData(res.data);
      })
      .catch(error => {});
  }

  setRoutes = async cargoData => {
    this.showLoading(true);

    this.showLoading(false);

    if (cargoData && cargoData.routeOptmi !== null) {
      this.loadKnowledge(cargoData.conhecimentos, false);
    } else {
      const message =
        navigator.language === 'pt' || navigator.language === 'pt-BR'
          ? 'Não existe rota pra esse romaneio'
          : 'There is no route for this cargo.';
      FrontendNotification(message, NotificationType.ERROR);
    }
  };

  async getSubsidiary() {
    await api
      .get('/cadastro/filiais')
      .then(res => {
        const filialAtiva = res.data.filter(f => f.status === 'ACTIVE');

        this.setState(
          {
            subsidiary: filialAtiva,
          },
          () => {
            // this.getGeneralIndicators();
          }
        );
      })
      .catch(error => {
        // BackendErrorNotification(error);
      });
  }

  getGeneralIndicators() {
    if (this.state.subsidiary.length > 0) {
      api
        .get('/delivery/romaneios/indicadores/' + this.state.subsidiary[0].id)
        .then(res => {
          this.setState({
            generalIndicators: res.data,
          });
        })
        .catch(error => {
          // BackendErrorNotification(error);
        });
    }
  }

  async getRoutes(id) {
    // this.leafletMap.leafletElement.removeControl();
    const json = [];
    const resDataFilters = [];
    const reqDataFilters = {
      filters: [{ field: 'romaneio.id', value: id, operation: 'EQUAL' }],
    };
    await api
      .post('/delivery/romaneiosRotas/findByFilters', reqDataFilters)
      .then(async result => {
        resDataFilters.push(result.data);

        // const dataFilial = {
        //   latitude: resDataFilters[0].romaneio.filial.endereco.latitude,
        //   longitude: resDataFilters[0].romaneio.filial.endereco.longitude,
        // }

        // let dataFilialArr = this.state.points;

        resDataFilters.forEach(arrayKnowledge => {
          arrayKnowledge.forEach(dataKnowledge => {
            // json.unshift(Leaflet.latLng(dataKnowledge.romaneio.filial.endereco.latitude,dataKnowledge.romaneio.filial.endereco.longitude))
            json.push(
              Leaflet.latLng(dataKnowledge.latitude, dataKnowledge.longitude)
            );

            // json.push(Leaflet.latLng(dataKnowledge.longitude,dataKnowledge.latitude));
          });
        });

        this.setState({
          jsonOSRM: json,
          initMapRoute: json.length > 0,
        });
      })
      .catch(error => {
        // BackendErrorNotification(error);
      });
  }

  async setPositionMap(lat, lng) {
    this.setState({
      initialPosition: [lat, lng],
      zoom: 40,
    });
  }

  async componentDidMount() {
    sessionStorage.removeItem('conhecimentosData');

    this.showLoading(true);

    const listSubsidiary = JSON.parse(sessionStorage.getItem('subsidiaryList'));
    if (listSubsidiary && listSubsidiary.length > 0) {
      this.setState({
        initialPosition: [
          listSubsidiary[0].endereco.latitude,
          listSubsidiary[0].endereco.longitude,
        ],
        zoom: 20,
      });
    }

    // this.getCarga();

    this.showLoading(false);
    setTimeout(() => {
      this.leafletMap.leafletElement.invalidateSize();
    }, 250);

    const dataC = {
      filters: [],
    };

    // // const response = await api.get('/cadastro/parametros')

    // // if (response.data && response.data.length !== 0) {
    // //   const intervalPosition = response.data.find(item => item.nome === 'mobile_gpspos_interval' && item.tipo === 'WEB')

    // //   const convertTimer = intervalPosition.valor * 60000;

    // //   setInterval(async () => {
    // //     await this.getTruckMarkers()
    // //     await this.getSubsidiary();
    // //   }, convertTimer)

    // }

    this.timer = setInterval(() => {
      this.setState({ clock: this.time() });
    }, 1000);
  }

  time() {
    var d = new Date();
    let hr = d.getHours();
    let min = d.getMinutes();
    if (min < 10) {
      min = '0' + min;
    }

    return hr + ':' + min;
  }

  componentWillUnmount() {
    this.setPositionMap();

    // clearInterval(this.interval);
    // clearInterval(this.timer);
  }

  //<i class='text-marker'>" +number +'º</i>'

  getHtmlPositionIcon(number) {
    return Leaflet.divIcon({
      className: 'custom-div-icon',
      html:
        "<div class='marker-container'><div class='marker-box'><i class='marker-text'>" +
        number +
        'º</i></div></div>',
      iconSize: [30, 42],
      iconAnchor: [15, 42],
    });
  }

  /** Executado do clique em caminhão */
  getPosition = async vehiclesId => {
    this.showLoading(true);
    if (this.state.visibleRomaneios) {
      const response = await api.post('/delivery/romaneios/pageable', {
        filters: [
          {
            field: 'veiculo.id',
            value: vehiclesId,
            operation: 'EQUAL',
          },
        ],
        orders: [
          {
            field: 'data',
            direction: 'DESC',
          },
          {
            field: 'dataCriacao',
            direction: 'DESC',
          },
        ],
        page: 0,
        size: 1000,
      });

      const filterCargoCancel = response.data.content.filter(
        item => item.cancelado !== true
      );

      this.setState({
        popoverOpen: true,
      });

      if (response.data && filterCargoCancel.length > 0) {
        this.setState({
          getRomaneios: filterCargoCancel,
        });

        // this.loadKnowledge(response.data, 'truck');
      } else {
        this.setState({
          getRomaneios: null,
        });
      }
      this.showLoading(false);

      // this.setConhecimentosTruckData(true, marker.options.data, response.data[0].id);
    }
  };

  changeVisibleRomaneios = visible => {
    this.leafletMap.leafletElement.closePopup();
  };

  setLoading(showLoad) {}

  async setConhecimentosTruckData(truckSelected, data, idRomaneio) {
    let newPoints = [];
    // Adiciona o caminhão selecionado a lista

    // const dataModified = data.map(item => {
    //   item.latitude = item.posicao.latitude;
    //   item.longitude = item.posicao.longitude;
    // });

    newPoints.push(data);

    if (data) {
      const dataFilial = {
        latitude: data.filial.endereco.latitude,
        longitude: data.filial.endereco.longitude,
      };

      newPoints.unshift(dataFilial);
    }

    // Carrega rota se id for diferente de "0"
    if (idRomaneio !== 0) {
      await this.getRoutes(idRomaneio);
    }

    if (newPoints && newPoints.length > 0) {
      this.setState({
        points: newPoints,
        selectedTruck: {
          id: truckSelected ? data.id : 0,
          selected: truckSelected,
        },
        visibleRomaneios: false,
        initialPosition: [newPoints[0].latitude, newPoints[0].longitude],
      });
    } else {
      this.setState({
        points: newPoints,
        selectedTruck: {
          id: 0,
          selected: truckSelected,
        },
        visibleRomaneios: false,
        initialPosition: this.state.initialPosition,
      });
    }
    this.leafletMap.leafletElement.closePopup();
  }

  async getVehiclesByDriver(driverId) {
    try {
      this.showLoading(true);
      const dataVehicle = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: 'EQUAL',
          },
        ],
      };
      const response = await api.post(
        '/delivery/veiculos/findByFilters',
        dataVehicle
      );

      this.showLoading(false);
    } catch (err) {}
  }

  async getCarga() {
    try {
      this.showLoading(true);
      const subsidiary = sessionStorage.getItem('subsidiary');
      const motoristas = [];
      this.state.points.map(element => {
        motoristas.push(element.motorista.id);
      });

      const dataVehicle = {
        filters: [
          // {
          //   field: 'status',
          //   value: 'ACTIVE',
          //   operation: 'EQUAL',
          // },
          // {
          //   field: 'filial.id',
          //   value: subsidiary,
          //   operation: 'EQUAL',
          // },
          // {
          //   field: 'motorista.id',
          //   value: motoristas,
          //   operation: 'IN'
          // }
        ],
      };
      const response = await api.post(
        '/delivery/romaneios/findByFiltersTc',
        dataVehicle
      );

      if (response.data.length > 0) {
        this.setState({
          cargas: response.data,
        });
      }

      this.showLoading(false);
    } catch (err) {}
  }

  async setConhecimentosData(truckSelected, data, idRomaneio) {
    let newPoints = [];
    // Adiciona o caminhão selecionado a lista

    if (truckSelected) {
      newPoints.push(data);
    } else {
      newPoints = data;
    }

    if (data.length > 0) {
      const dataFilial = {
        latitude: data[0].filial.endereco.latitude,
        longitude: data[0].filial.endereco.longitude,
      };

      newPoints.unshift(dataFilial);
    }

    const filterKnowledge = newPoints.filter(
      item => item.latitude !== null && item.longitude !== null
    );

    // Carrega rota se id for diferente de "0"
    if (idRomaneio !== 0) {
      await this.getRoutes(idRomaneio);
    }

    if (
      newPoints &&
      newPoints.length > 0 &&
      this.state.getRomaneios[0].routeOptmi !== null
    ) {
      const routeCargoPolyline = polyline.decode(
        this.state.getRomaneios[0].routeOptmi.polyline
      );
      this.setState({
        jsonOSRM: routeCargoPolyline,
        points: filterKnowledge,
        selectedTruck: {
          id: truckSelected ? data[0].id : 0,
          selected: truckSelected,
        },
        visibleRomaneios: false,
        initialPosition: [
          filterKnowledge[0].latitude,
          filterKnowledge[0].longitude,
        ],
      });
    } else {
      const message =
        navigator.language === 'pt' || navigator.language === 'pt-BR'
          ? 'Não existe rota pra esse romaneio'
          : 'There is no route for this cargo.';
      this.setState({
        points: filterKnowledge,
        selectedTruck: {
          id: 0,
          selected: truckSelected,
        },
        visibleRomaneios: false,
        initialPosition: this.state.initialPosition,
      });
    }

    this.leafletMap.leafletElement.closePopup();
  }

  // async  loadTruck() {
  //   await api
  //     .get('/delivery/motoristas/markers')
  //     .then(res => {

  //        this.setCargoData(res.data);
  //     })
  //     .catch(error => {
  //       BackendErrorNotification(error);
  //     });
  // }

  async setCargoData(data) {
    const isModified = data.filter(
      item => item.motorista.latitude !== 0 && item.motorista.longitude !== 0
    );
    const dataModified = await data.map(item => {
      item.isTruck = true;
      item.latitude = item.motorista.latitude;
      item.longitude = item.motorista.longitude;
      return item;
    });

    if (dataModified && dataModified.length > 0) {
      this.setState({
        points: dataModified,
        selectedTruck: {
          id: 0,
          selected: false,
        },
        visibleRomaneios: true,
        initialPosition: [dataModified[0].latitude, dataModified[0].longitude],
        jsonOSRM: [],
        initMapRoute: false,
      });
    } else {
      const dataModified = [];
      this.setState({
        points: dataModified,
        selectedTruck: {
          id: 0,
          selected: false,
        },
        visibleRomaneios: true,
        initialPosition: this.state.initialPosition,
        jsonOSRM: [],
        initMapRoute: false,
      });
    }
  }

  loadRomaneios(data) {
    const cargoData = data.map(cargo => {
      return cargo.motorista;
    });

    this.setCargoData(cargoData);
  }

  async setRouteMap(lat, lng, romaneioID) {
    var idRomaneio = 0;
    this.leafletMap.leafletElement.closePopup();
    let conhecimentos = [];

    const dataConhecimento = {
      filters: [
        {
          field: 'id',
          value: romaneioID,
          operation: 'EQUAL',
        },
      ],
    };

    const response = await api.post(
      '/delivery/romaneios/findByFilters',
      dataConhecimento
    );
    const data = response.data;

    // map trucks
    if (data && data.length > 0) {
      // obtem os dados do romaneio
      data[0].conhecimentos.map(async r => {
        r.romaneio.motorista.isTruck = false;
        r.romaneio.placa = r.romaneio.placa;
        r.romaneio.numero = r.romaneio.numero;
        r.romaneio.motorista.nome = r.romaneio.motorista.nome;
        idRomaneio = r.romaneio.id;
        // conhecimentos.push(romaneio.motorista);
      });

      // mapeia os dados no formato de posicoes para o mapa
      data[0].conhecimentos.map(c => {
        let conhecimento = {};

        conhecimento = {
          id: c.id,
          isTruck: false,
          position: c.rota,
          referenceId: c.idReferencia,
          name: c.cliente.nome,
          status: c.statusEntrega,
          previsao: c.dataPrevisao,
          latitude: c.endereco.latitude,
          longitude: c.endereco.longitude,

          placa: c.romaneio.placa,
          numeroRomaneio: c.romaneio.numero,
          filial: c.filialOrigem,
          // numeroRomaneio: 2,
          numero: c.numero,
          ordemEntrega: c.ordemEntrega,
        };
        conhecimentos.push(conhecimento);
      });

      let conhecimentoTruck2 = data;

      const covertJson = JSON.stringify(conhecimentoTruck2);

      sessionStorage.setItem('conhecimentosData', covertJson);
    }

    this.setConhecimentosData(false, conhecimentos, idRomaneio);
  }

  /** Executado do filtro */
  loadKnowledge(data, truckSelect) {
    var idRomaneio = 0;
    this.leafletMap.leafletElement.closePopup();
    let conhecimentos = [];

    // map trucks
    if (data && data.length > 0) {
      // obtem os dados do romaneio
      data.map(async r => {
        r.romaneio.motorista.isTruck = truckSelect ? true : false;
        r.romaneio.placa = r.romaneio.placa;
        r.romaneio.numero = r.romaneio.numero;
        r.romaneio.motorista.nome = r.romaneio.motorista.nome;
        idRomaneio = r.romaneio.id;
        // conhecimentos.push(romaneio.motorista);
      });

      // mapeia os dados no formato de posicoes para o mapa
      data.map(c => {
        let conhecimento = {};

        conhecimento = {
          id: c.id,
          isTruck: truckSelect ? true : false,
          position: c.rota,
          referenceId: c.idReferencia,
          name: c.cliente.nome,
          status: c.statusEntrega,
          previsao: c.dataPrevisao,
          latitude: c.endereco.latitude,
          longitude: c.endereco.longitude,

          placa: c.romaneio.placa,
          numeroRomaneio: c.romaneio.numero,
          filial: c.filialOrigem,
          // numeroRomaneio: 2,
          numero: c.numero,
          ordemEntrega: c.ordemEntrega,
        };
        conhecimentos.push(conhecimento);
      });

      let conhecimentoTruck2 = data;

      const covertJson = JSON.stringify(conhecimentoTruck2);

      sessionStorage.setItem('conhecimentosData', covertJson);
    }

    if (truckSelect === true) {
      this.setConhecimentosData(false, conhecimentos, idRomaneio);
    }

    this.setConhecimentosData(false, conhecimentos, idRomaneio);
  }

  toogle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  loadIconVehicles = (driverId, isAlert, typeVehicle) => {
    let truckIcon;

    let findCargas = this.state.cargas.find(
      item => item.veiculo.id === driverId
    );

    truckIcon = new Leaflet.Icon({
      iconUrl: getIconVehicle(typeVehicle, isAlert, isAlert, 'NO_PRAZO', false),
      iconSize: [40, 40],
      // shadowSize:   [50, 64], // size of the shadow
      iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
    });

    return truckIcon;
  };

  render() {
    const PointFilial = new Leaflet.Icon({
      iconUrl: pointFilial,
      iconSize: [90, 90],
      // shadowSize:   [50, 64], // size of the shadow
      iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
    });

    return (
      <>
        {/* <Sector /> */}

        {/* <Parameters /> */}

        {this.state.showAlerts && (
          <Alertas
            idCargo={this.state.alertCargo}
            isShipment={false}
            onClear={() =>
              this.setState({
                showAlerts: !this.state.showAlerts,
              })
            }
          />
        )}
        <Loading loading={this.getStatusLoading()} />
        {/* <GeneralIndicators generalIndicators={this.state.generalIndicators} /> */}
        <SuperiorInterface
          listSubsidiary={JSON.parse(sessionStorage.getItem('subsidiaryList'))}
          listState={this.state.uf}
          listStatus={this.state.status}
          toggleTruck={this.state.selectedTruck}
          visibleRomaneios={this.changeVisibleRomaneios}
          time={this.state.clock}
          selectFilial={select => {}}
          // loadSubsidiaryInitial={(dataInitial) => {
          //   this.setState({
          //     initialPosition: [dataInitial.endereco.latitude, dataInitial.endereco.longitude],
          //     zoom: 20,
          //   });

          // }}
          onSelectVehicles={() => {
            this.getTruckMarkers(sessionStorage.getItem('subsidiary'));
          }}
          setPositionMap={(lat, lng, romaneioID) =>
            this.setState({
              initialPosition: [lat, lng],
              zoom: 40,
            })
          }
          fowardedRef={this.positions}
          loadRomaneios={this.loadRomaneios}
          loadKnowledge={this.loadKnowledge}
          setLoading={this.setLoading}
        />
        <Map
          ref={m => {
            this.leafletMap = m;
          }}
          center={this.state.initialPosition}
          zoom={this.state.zoom}
          style={{ maxHeight: '120%', width: '100%' }}
          zoomControl={true}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <></>
          {this.state.points.map((point, index) => {
            const position = [point.latitude, point.longitude];

            return (
              <>
                {point.isTruck ? (
                  <Marker
                    key={point.id}
                    position={position}
                    icon={this.loadIconVehicles(
                      point.id,
                      point.motorista.possuiAlertaNaoLido,
                      point.tipo
                    )}
                    onClick={e => {
                      this.getPosition(point.id);
                      this.setState({
                        stepsActual: 0,
                      });
                    }}
                    data={point}
                  >
                    <Popup>
                      {this.state.getRomaneios &&
                        this.state.getRomaneios !== null &&
                        this.state.getRomaneios.length > 0 && (
                          <Carousel
                            data={this.state.getRomaneios}
                            actualSteps={this.state.stepsActual}
                            onViewRoutes={indexCarga => {
                              this.setState({
                                indexRomaneio: indexCarga,
                              });
                              this.setRoutes(
                                this.state.getRomaneios[indexCarga]
                              );
                            }}
                            onViewAlerts={indexCarga => {
                              this.setState({
                                alertCargo: this.state.getRomaneios[indexCarga]
                                  .id,
                                showAlerts: !this.state.showAlerts,
                              });
                            }}
                          />
                        )}
                    </Popup>
                  </Marker>
                ) : (
                  <Marker
                    key={`marker-${point.id}`}
                    position={position}
                    icon={
                      index === 0
                        ? PointFilial
                        : this.getHtmlPositionIcon(point.ordemEntrega)
                    }
                  >
                    {index === 0 ? (
                      <></>
                    ) : (
                      <Popup className="popup">
                        <b>
                          <Trans i18nKey="delivery.order.number" />:
                        </b>{' '}
                        {point.numero} <br />
                        <b>
                          <Trans i18nKey="delivery.order.customer.title" />:
                        </b>{' '}
                        {point.name} <br />
                        <b>
                          <Trans i18nKey="common.status" />:
                        </b>{' '}
                        {point.status === 'NO_PRAZO'
                          ? 'No prazo'
                          : point.status}{' '}
                        <br />
                        {/* <b><Trans i18nKey="delivery.order.estimatedTime" />:</b> {formatDate(point.previsao)} */}
                      </Popup>
                    )}
                  </Marker>
                )}
              </>
            );
          })}

          <Polyline color={'red'} positions={[this.state.jsonOSRM]} />
        </Map>
      </>
    );
  }
}
