import ProgressBar from 'components/Map/Cargo/progressbar';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

// import { Container } from './styles';

interface Props {
  data: any[];
}

const Carousel: React.FC<Props> = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onExiting = () => setAnimating(true);
  const onExited = () => setAnimating(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.data.length - 1 ? 0 : activeIndex + 1;

    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? 0 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleMarkerClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-w-full">
      <div className="tw-flex tw-w-full tw-h-full tw-items-center tw-justify-around">
        {props.data && props.data.length > 0 && (
          <div className="tw-w-full tw-h-full">
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start tw-mb-2">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="dashboardMap.serviceNumber" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].numero}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start tw-mb-2">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="dashboardMap.driver" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].motorista.nome}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start tw-mb-2">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="dashboardMap.licensePlate" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].placa}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="dashboardMap.delivered" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].totalEfetivadas}/{props.data[activeIndex].totalAtendimentos}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start">
              <ProgressBar widthProgress={undefined} topProgress={undefined} value={props.data[activeIndex].percentualEntregas} />
            </div>
          </div>
        )}
        
      </div>
    </div>
  );
};

export default Carousel;
