import { ICargoTypes } from 'components/CargoTypes/types/types';
import InputCustom from 'components/Input';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';

// import { Container } from './styles';

interface FormValues {
  name: string;
}

interface Props {
  isEdit?: boolean;
  row?: ICargoTypes;
  onClose: () => void;
}

const Create: React.FC<Props> = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const handleSubmit = useCallback(
    async (values: FormValues, row?: ICargoTypes, isEdit?: boolean) => {
      try {
        setLoading(true);

        const body = {
          nome: values.name,
        };

        if (isEdit && isEdit === true) {
          const response = await api.patch(
            `/delivery/tipos/cargas/${row?.id}`,
            body
          );

          if(response.status === 204) {
            FrontendNotification(t('cargoTypes.messageUpdatedSuccess'), NotificationType.SUCCESS);
          }
        } else {
          const response = await api.post('/delivery/tipos/cargas', body);
          
          if(response.status === 201) {
            FrontendNotification(t('cargoTypes.messageCreateSuccess'), NotificationType.SUCCESS);
          }
        }

        formik.resetForm({ })

        props.onClose();

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const initialValues: FormValues = {
    name: '',
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values: FormValues) =>
      handleSubmit(values, props.row, props.isEdit),
  });

  const onLoadFormValues = useCallback((row?: ICargoTypes) => {
    if (row) {
      formik.setFieldValue('name', row.nome);
    }
  }, []);

  useEffect(() => {
    if (props.isEdit) {
      onLoadFormValues(props.row);
    }
  }, []);

  return (
    <div className="tw-w-full tw-h-full">
      <div className="tw-grid tw-grid-cols-1 tw-gap-3 tw-mb-2">
        <div>
          <InputCustom
            title={t('cargoTypes.fields.name')}
            onChange={formik.handleChange('name')}
            touched={formik.touched.name}
            error={formik.errors.name}
            value={formik.values.name}
            placeholder={t('cargoTypes.fields.placeholder.name')}
          />
        </div>
        <div className="tw-flex tw-w-full tw-h-full tw-items-end tw-justify-end">
          <button
            className="tw-border-none tw-bg-[#EA004C] tw-rounded-md tw-w-32 tw-text-sm tw-text-[#fff] tw-font-semibold tw-h-8 tw-p-5 tw-flex tw-items-center tw-justify-center tw-cursor-pointer"
            type="button"
            onClick={() => formik.handleSubmit()}
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Create;
