import Remove from '@mui/icons-material/Remove';
import { Tooltip } from '@mui/material';
import changeAttendances from 'assets/images/changeAttendances.svg';
import changeRouteSteps from 'assets/images/changeRoute.svg';
import deleteIconRoutering from 'assets/images/deleteIconRoutering.svg';
import emptyWindowDeliveryIcon from 'assets/images/emptyWindowDeliveryIcon.svg';
import iconDragDrop from 'assets/images/iconDragDrop.svg';
import viewMapRoutering from 'assets/images/viewMapRoutering.svg';
import ChangeVehiclesLoads from 'components/ChangeVehiclesLoads';
import RouteringTimeWindow from 'components/RouteringTimeWindow';
import WindowDelivery from 'components/WindowDelivery';
import Loading from 'core/common/LoadingRoutering';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import CheckBox from 'pages/RouterCargo/components/CargasRouting/components/CheckBox';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import permissionMenu from 'shared/Permission';
import {
  clearDataStepSelected,
  DataRouteringCargas,
  DataStepsRoutering,
  removeDataStepSelected,
  setDataStepsSelected,
  updateRouteringStepsChecked,
  updateRouteringStepsCompacted,
} from 'store/modules/routering/actions';
import { saveStepsId } from 'store/modules/routeringSimulation/actions';
import { getFormatDistanceSteps, numberToCurrencyBRL } from 'util/format';
import CardCompacted from './components/CardCompacted';
import ConfirmationDriver from './components/ConfirmationDriver';

interface Props {
  data: DataStepsRoutering[];
  setData: (dataOrder?: any, dataFilter?: any) => void;
  onChangeSteps: () => void;
  onChangeAddress: (address: any, dataAddress: any) => void;
  onCoordenates: (latitude: number, longitude: number) => void;
  onUpdateData: (
    dataFilter: any,
    indexFilter: number,
    routeId: number,
    stepId: number | undefined
  ) => void;
  onDragEnd: (result: any, data: DataStepsRoutering[]) => void;
  dataOptmis: DataRouteringCargas;
  isAllowedDrag: boolean;
  onUpdate: () => void;
}

const Card: React.FC<Props> = ({
  data,
  setData,
  onChangeSteps,
  onChangeAddress,
  onCoordenates,
  onUpdateData,
  dataOptmis,
  isAllowedDrag,
  onDragEnd,
  onUpdate,
}: Props) => {
  /* Local States */
  const [loading, setLoading] = useState<boolean>(false);
  const [isCompacted, setIsCompacted] = useState<boolean>(false);
  const [dataKnowledge, setDataKnowledge] = useState<DataStepsRoutering[]>([]);
  const [selectOrder, setSelectOrder] = useState<any>({
    active: false,
    indexOrder: null,
  });
  const [selectedRow, setSelectedRow] = useState<DataStepsRoutering>();
  const [showChangeVehicles, setShowChangeVehicles] = useState<boolean>(false);
  const [showDeliveryTimeWindow, setShowDeliveryTimeWindow] = useState<boolean>(
    false
  );
  const [showCreateDeliveryWindow, setShowCreateDeiveryWindow] = useState<boolean>(false);
  const [showRemoveStep, setShowRemoveStep] = useState<boolean>(false);
  const [indexLoad, setIndexLoad] = useState<number>(0);

  // Global States
  const userData = useSelector((state: any) => state.auth.user);
  const dataDetailRoutering: DataRouteringCargas = useSelector(
    (state: any) => state.routering.data.detail
  );
  const dataStepSelected: DataStepsRoutering[] = useSelector(
    (state: any) => state.routering.data.dataStepsSelected
  );

  // Updates the data defined in the global state
  const dispatch = useDispatch();

  const { t } = useTranslation();

  async function reorderSteps(
    indexSource: number,
    indexDestination: number,
    dataSteps: DataStepsRoutering[]
  ) {
    let items = dataSteps;

    const [reorderedItem] = items.splice(indexSource, 1);
    items.splice(indexDestination, 0, reorderedItem);

    const reordernedItems = items.map((item, indexReordenate) => {
      return {
        ...item,
        order: indexReordenate + 1,
      };
    });

    setData(reordernedItems);
  }

  /* This function performs the drag and drop of route steps */

  useEffect(() => {
    let sortKnowledge = data
      .slice()
      .sort((a: any, b: any) => a.order - b.order);

    setDataKnowledge(sortKnowledge);
  }, [data]);

  return (
    <DragDropContext
      onDragEnd={(result: any, provided: any) =>
        onDragEnd(result, dataKnowledge)
      }
    >
      <Loading loading={loading} />

      {showCreateDeliveryWindow && (
        <WindowDelivery onClear={() => {
          setShowCreateDeiveryWindow(!showCreateDeliveryWindow);
          onUpdate();
        }} />
      )}

      {showDeliveryTimeWindow && (
        <RouteringTimeWindow
          onClear={() => setShowDeliveryTimeWindow(!showDeliveryTimeWindow)}
          conhecimentoId={selectedRow?.conhecimentoPre.id}
        />
      )}
      {showChangeVehicles && (
        <ChangeVehiclesLoads
          dataOptmi={dataOptmis}
          onUpdate={onChangeSteps}
          onClear={() => setShowChangeVehicles(!showChangeVehicles)}
        />
      )}
      {showRemoveStep && (
        <ConfirmationDriver
          idRomaneio={selectedRow?.id}
          indexLoad={indexLoad}
          idRoute={selectedRow?.id}
          onClear={() => setShowRemoveStep(!showRemoveStep)}
          onLoad={(index: any, routeId: any) =>
            onUpdateData(dataOptmis, index, routeId, selectedRow?.id)
          }
          cpfDriver={undefined}
          onConfirm={undefined}
        />
      )}

      <Droppable droppableId="steps">
        {provided => (
          <ul {...provided.droppableProps} ref={provided.innerRef}>
            {dataKnowledge.map((cargo: any, index: number) => {
              let cargoData: DataStepsRoutering = cargo;
              return (
                <Draggable
                  key={cargoData.id}
                  draggableId={`drag-${cargoData.id}`}
                  index={index}
                  isDragDisabled={isAllowedDrag ? false : true}
                >
                  {provided => (
                    <li
                      className="tw-bg-[#fff] tw-shadow-2xl tw-rounded-xl tw-w-auto tw-h-auto tw-p-3 tw-mt-3 tw-relative tw-list-none"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {isAllowedDrag && (
                        <div
                          className={
                            cargoData.isCompacted
                              ? 'tw-absolute tw-bottom-1/2 tw-left-2 tw-mr-10 tw-cursor-grabbing'
                              : 'tw-absolute tw-bottom-1/2 tw-left-1 tw-mr-10 tw-cursor-grabbing'
                          }
                        >
                          <img src={iconDragDrop} alt="" />
                        </div>
                      )}
                      {!cargoData.isCompacted && (
                        <div
                          className="tw-absolute tw-mr-2 tw-top-5 tw-cursor-pointer"
                          onClick={() => {
                            // setSelectedRow(cargoData);

                            if (isAllowedDrag) {
                              let dataCargo = Array.from(dataKnowledge);
                              const getDataStepsRoutering = dataKnowledge
                                .map(
                                  (
                                    stepSelected: DataStepsRoutering,
                                    indexStep: number
                                  ) =>
                                    stepSelected.id === cargoData.id
                                      ? indexStep
                                      : -1
                                )
                                .filter(
                                  (filterStepSelected: any) =>
                                    filterStepSelected !== -1
                                );

                              dispatch(
                                updateRouteringStepsCompacted(
                                  getDataStepsRoutering[0]
                                )
                              );

                              const updateStepsCompacted = dataCargo.map(
                                (row: DataStepsRoutering, index: number) => {
                                  if (index == getDataStepsRoutering[0]) {
                                    return {
                                      ...row,
                                      isCompacted: !row.isCompacted,
                                    };
                                  }
                                  return {
                                    ...row,
                                  };
                                }
                              );

                              setDataKnowledge(updateStepsCompacted);
                            } else {
                              const getDataStepsRoutering = dataDetailRoutering.steps
                                .map(
                                  (
                                    stepSelected: DataStepsRoutering,
                                    indexStep: number
                                  ) =>
                                    stepSelected.id === cargoData.id
                                      ? indexStep
                                      : -1
                                )
                                .filter(
                                  (filterStepSelected: any) =>
                                    filterStepSelected !== -1
                                );

                              dispatch(
                                updateRouteringStepsCompacted(
                                  getDataStepsRoutering[0]
                                )
                              );
                            }
                          }}
                        >
                          <Remove color="error" />
                        </div>
                      )}
                      {cargoData.isCompacted ? (
                        <CardCompacted
                          data={cargoData}
                          onExpand={() => {
                            if (isAllowedDrag) {
                              let dataCargo = dataKnowledge;
                              const getDataStepsRoutering = dataKnowledge
                                .map(
                                  (
                                    stepSelected: DataStepsRoutering,
                                    indexStep: number
                                  ) =>
                                    stepSelected.id === cargoData.id
                                      ? indexStep
                                      : -1
                                )
                                .filter(
                                  (filterStepSelected: any) =>
                                    filterStepSelected !== -1
                                );

                              dispatch(
                                updateRouteringStepsCompacted(
                                  getDataStepsRoutering[0]
                                )
                              );

                              const updateStepsCompacted = dataCargo.map(
                                (row: DataStepsRoutering, index: number) => {
                                  if (index == getDataStepsRoutering[0]) {
                                    return {
                                      ...row,
                                      isCompacted: !row.isCompacted,
                                    };
                                  }
                                  return {
                                    ...row,
                                  };
                                }
                              );

                              setDataKnowledge(updateStepsCompacted);
                            } else {
                              const getDataStepsRoutering = dataDetailRoutering.steps
                                .map(
                                  (
                                    stepSelected: DataStepsRoutering,
                                    indexStep: number
                                  ) =>
                                    stepSelected.id === cargoData.id
                                      ? indexStep
                                      : -1
                                )
                                .filter(
                                  (filterStepSelected: any) =>
                                    filterStepSelected !== -1
                                );

                              dispatch(
                                updateRouteringStepsCompacted(
                                  getDataStepsRoutering[0]
                                )
                              );
                            }
                          }}
                          onChangeOrder={() => {}}
                        />
                      ) : (
                        <>
                          <div className="tw-ml-7">
                            <div className="tw-flex tw-items-center tw-justify-between">
                              <div className="tw-flex tw-mr-2 tw-justify-between">
                                {/* {selectOrder.active &&
                                  selectOrder.indexOrder === index && (
                                    <OrderTooltip
                                      order={cargoData.order}
                                      indexSource={index}
                                      data={dataKnowledge}
                                      onFilter={(
                                        indexSource: number,
                                        indexDestination: number
                                      ) =>
                                        reorderSteps(
                                          index,
                                          indexDestination - 1,
                                          dataKnowledge
                                        )
                                      }
                                      direction="top"
                                    />
                                  )} */}
                                <div className="tw-flex tw-flex-col">
                                  <div
                                    className="tw-flex tw-items-center tw-justify-center tw-bg-[#6369D1] tw-w-6 tw-h-6 tw-rounded-full tw-cursor-pointer"
                                    onClick={() => {
                                      if (
                                        permissionMenu(
                                          userData.grupo.permissoes,
                                          'routering.change-order-steps'
                                        )
                                      ) {
                                        setSelectOrder({
                                          active: !selectOrder.active,
                                          indexOrder: index,
                                        });
                                      } else {
                                        FrontendNotification(
                                          t('common.permission'),
                                          NotificationType.WARNING
                                        );
                                      }
                                    }}
                                  >
                                    <span className="tw-text-xs tw-text-[#fff] tw-font-bold">
                                      {cargoData.order}
                                    </span>
                                  </div>
                                  {cargoData.rows && cargoData.rows.length > 0 && (
                                    <div className="tw-flex tw-items-center tw-justify-center tw-bg-[#EA004C] tw-w-6 tw-h-6 tw-rounded-full tw-cursor-pointer tw-mt-2">
                                      <span className="tw-text-xs tw-text-[#fff] tw-font-bold">
                                        {cargoData.rows.length > 0
                                          ? `+ ${cargoData.groupedRows}`
                                          : `+ 0`}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                <div className="tw-flex tw-flex-col tw-ml-3">
                                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap">
                                    <Trans i18nKey="routeOptmization.routeSteps.fields.clientName" />
                                  </span>
                                  <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden">
                                    {cargoData.conhecimentoPre.clienteNome}
                                  </span>
                                </div>
                                <div className="tw-flex tw-flex-col tw-ml-3">
                                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap">
                                    <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryNumber" />
                                  </span>
                                  <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                    {cargoData.conhecimentoPre.numero}
                                  </span>
                                </div>
                                <div className="tw-flex tw-flex-col tw-ml-3">
                                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                    <Trans i18nKey="routeOptmization.routeSteps.fields.idReference" />
                                  </span>
                                  <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                    {cargoData.conhecimentoPre.idReferencia
                                      .length > 0
                                      ? cargoData.conhecimentoPre.idReferencia
                                      : '---'}
                                  </span>
                                </div>
                                <div className="tw-flex tw-flex-col tw-ml-3">
                                  <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                    <Trans i18nKey="routeOptmization.routeSteps.fields.route" />
                                  </span>
                                  <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                    {cargoData.conhecimentoPre.tipo ===
                                      'Entrega' &&
                                    cargoData.conhecimentoPre.rota.length > 0
                                      ? cargoData.conhecimentoPre.rota
                                      : '-'}
                                    {cargoData.conhecimentoPre.tipo ===
                                      'Coleta' &&
                                    cargoData.conhecimentoPre.rotaColeta
                                      .length > 0
                                      ? cargoData.conhecimentoPre.rotaColeta
                                      : '---'}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                              <div className="tw-flex tw-flex-col">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryAddress" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.tipo === 'Entrega'
                                    ? `${cargoData.conhecimentoPre.enderecoLogradouro}, ${cargoData.conhecimentoPre.enderecoNumero}, ${cargoData.conhecimentoPre.enderecoComplemento}`
                                    : `${cargoData.conhecimentoPre.enderecoLogradouroColeta}, ${cargoData.conhecimentoPre.enderecoNumeroColeta}, ${cargoData.conhecimentoPre.enderecoComplementoColeta}`}
                                </span>
                              </div>
                              {!isAllowedDrag && (
                                <div className="tw-flex tw-flex-col tw-mr-0 tw-cursor-pointer">
                                  <CheckBox
                                    active={cargoData.active}
                                    onChecked={(e: any) => {
                                      const getDataStepSelectedIndex = dataStepSelected
                                        .map(
                                          (
                                            stepSelected: DataStepsRoutering,
                                            indexStep: number
                                          ) =>
                                            stepSelected.id === cargoData.id
                                              ? indexStep
                                              : -1
                                        )
                                        .filter(
                                          (filterStepSelected: any) =>
                                            filterStepSelected !== -1
                                        );

                                      const getDataStepsRoutering = dataDetailRoutering.steps
                                        .map(
                                          (
                                            stepSelected: DataStepsRoutering,
                                            indexStep: number
                                          ) =>
                                            stepSelected.id === cargoData.id
                                              ? indexStep
                                              : -1
                                        )
                                        .filter(
                                          (filterStepSelected: any) =>
                                            filterStepSelected !== -1
                                        );

                                      const findIsActiveStep = dataKnowledge.find(
                                        (item: DataStepsRoutering) =>
                                          item.active === true
                                      );
                                      if (cargoData.active === true) {
                                        dispatch(
                                          removeDataStepSelected(
                                            getDataStepSelectedIndex[0]
                                          )
                                        );
                                      } else {
                                        if (!findIsActiveStep) {
                                          dispatch(clearDataStepSelected());
                                        }
                                        dispatch(
                                          setDataStepsSelected(cargoData)
                                        );
                                      }

                                      dispatch(
                                        updateRouteringStepsChecked(
                                          getDataStepsRoutering[0]
                                        )
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.neighborhood" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.tipo === 'Entrega'
                                    ? cargoData.conhecimentoPre.enderecoBairro
                                    : cargoData.conhecimentoPre
                                        .enderecoBairroColeta}
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.city" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.tipo === 'Entrega'
                                    ? cargoData.conhecimentoPre.enderecoCidade
                                    : cargoData.conhecimentoPre
                                        .enderecoCidadeColeta}
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.uf" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.tipo === 'Entrega'
                                    ? cargoData.conhecimentoPre.enderecoUf
                                    : cargoData.conhecimentoPre
                                        .enderecoUfColeta}
                                </span>
                              </div>
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.expectedDate" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.dataPrevisao
                                    .length > 0
                                    ? cargoData.conhecimentoPre.dataPrevisao
                                    : '---'}
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.arrival" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.arrival.formattedDateTime}
                                </span>
                              </div>
                              {!isAllowedDrag && (
                                <div
                                  className="tw-flex tw-flex-col"
                                  onClick={() => {
                                    const pathname = window.location.pathname;
                                    if (
                                      permissionMenu(
                                        userData.grupo.permissoes,
                                        'routering.change-steps'
                                      )
                                    ) {
                                      if (dataStepSelected.length === 0) {
                                        FrontendNotification(
                                          'É necessario selecionar pelo menos um atendimento da rota.',
                                          NotificationType.WARNING
                                        );
                                      } else {
                                        setShowChangeVehicles(
                                          !showChangeVehicles
                                        );
                                        dispatch(
                                          saveStepsId(
                                            cargoData.conhecimentoPre.id
                                          )
                                        );
                                      }
                                    } else {
                                      FrontendNotification(
                                        t('common.permission'),
                                        NotificationType.WARNING
                                      );
                                    }
                                  }}
                                >
                                  <img src={changeRouteSteps} />
                                </div>
                              )}
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryWindow" />
                                </span>
                                {cargoData.conhecimentoPre.horariosEntrega !== null && cargoData.conhecimentoPre.horariosEntrega.length > 0 ? (
                                <span
                                  className="tw-text-xs tw-text-[#EA004C] tw-font-bold tw-cursor-pointer"
                                  onClick={() => {
                                    setShowDeliveryTimeWindow(
                                      !showDeliveryTimeWindow
                                    );
                                    setSelectedRow(cargoData);
                                  }}
                                >
                                  Visualizar
                                </span>

                                ): (
                                  <Tooltip  title="Não existe janela de entrega cadastrada."
                                  placement="top" className='tw-w-full tw-flex tw-justify-center tw-cursor-pointer'>
                                    <img src={emptyWindowDeliveryIcon} onClick={() => {
                                      setShowCreateDeiveryWindow(!showCreateDeliveryWindow);
                                      setSelectedRow(cargoData);
                                    }} className='tw-w-5 tw-h-5' />
                                  </Tooltip>
                                )}
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.distance" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.distance !== null
                                    ? `${getFormatDistanceSteps(
                                        cargoData.distance
                                      )}Km`
                                    : '---'}
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.duration" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.duration.formattedTime.slice(0, 5)}
                                  h
                                </span>
                              </div>
                              {!isAllowedDrag && (
                                <div
                                  className="tw-flex tw-flex-col"
                                  onClick={() => {
                                    if (
                                      permissionMenu(
                                        userData.grupo.permissoes,
                                        'routering.change-address'
                                      )
                                    ) {
                                      onChangeAddress(
                                        cargoData.conhecimentoPre,
                                        data
                                      );
                                    } else {
                                      FrontendNotification(
                                        t('common.permission'),
                                        NotificationType.WARNING
                                      );
                                    }
                                  }}
                                >
                                  <img src={changeAttendances} alt="" />
                                </div>
                              )}
                            </div>
                            <div className="tw-flex tw-items-center tw-justify-between tw-mt-2">
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.qtdVolume" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.qtdVolumes.length >
                                  0
                                    ? cargoData.conhecimentoPre.qtdVolumes
                                    : '---'}
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.weight" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.peso.length > 0
                                    ? cargoData.conhecimentoPre.peso
                                    : '---'}
                                  Kg
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.volume" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.cubagem.length > 0
                                    ? cargoData.conhecimentoPre.cubagem
                                    : '---'}
                                  m³
                                </span>
                              </div>
                              <div className="tw-flex tw-flex-col tw-mr-2">
                                <span className="tw-text-sm tw-text-[#003049] tw-font-bold">
                                  <Trans i18nKey="routeOptmization.routeSteps.fields.price" />
                                </span>
                                <span className="tw-text-xs tw-text-[#003049] tw-font-normal">
                                  {cargoData.conhecimentoPre.valor.length > 0
                                    ? numberToCurrencyBRL(
                                        Number(cargoData.conhecimentoPre.valor)
                                      )
                                    : '---'}
                                </span>
                              </div>
                              <div
                                className="tw-flex tw-flex-col"
                                onClick={() =>
                                  onCoordenates(
                                    cargoData.latitude,
                                    cargoData.longitude
                                  )
                                }
                              >
                                <img src={viewMapRoutering} />
                              </div>
                            </div>
                            {!isAllowedDrag && (
                              <div
                                className="tw-flex tw-items-center tw-justify-end tw-mt-2"
                                onClick={() => {
                                  if (
                                    permissionMenu(
                                      userData.grupo.permissoes,
                                      'routering.remove-steps'
                                    )
                                  ) {
                                    setShowRemoveStep(!showRemoveStep);
                                    setSelectedRow(cargoData);

                                    setIndexLoad(index);
                                  } else {
                                    FrontendNotification(
                                      t('common.permission'),
                                      NotificationType.WARNING
                                    );
                                  }
                                }}
                              >
                                <img src={deleteIconRoutering} />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </li>
                  )}
                </Draggable>
              );
            })}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Card;
