import { divIcon } from "leaflet";
import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

// Importação de ícones do Material-UI
import {
  AddHomeWork as AddHomeWorkIcon,
  AddLocation as AddLocationIcon,
  AirplanemodeActive as AirplanemodeActiveIcon,
  AttachMoney as AttachMoneyIcon,
  CarRepair as CarRepairIcon,
  DirectionsBoat as DirectionsBoatIcon,
  EvStation as EvStationIcon,
  Factory as FactoryIcon,
  Garage as GarageIcon,
  Home as HomeIcon,
  Hotel as HotelIcon,
  LocalCarWash as LocalCarWashIcon,
  LocalConvenienceStore as LocalConvenienceStoreIcon,
  LocalHospital as LocalHospitalIcon,
  LocalParking as LocalParkingIcon,
  Store as StoreIcon,
  Warehouse as WarehouseIcon,
} from "@mui/icons-material";

// Define os valores permitidos
type IconValue =
  | "AddHomeWork"
  | "AddLocation"
  | "AirplanemodeActive"
  | "AttachMoney"
  | "CarRepair"
  | "DirectionsBoat"
  | "EvStation"
  | "Factory"
  | "Garage"
  | "Home"
  | "Hotel"
  | "LocalConvenienceStore"
  | "LocalCarWash"
  | "LocalHospital"
  | "LocalParking"
  | "Store"
  | "Warehouse";

  export const icons: any = [
    {
      id: "AddHomeWork",
      label: "Escritório"
    },
    {
      id: "AddLocation",
      label: "Local Genérico"
    },
    {
      id: "AirplanemodeActive",
      label: "Aeroporto"
    },
    {
      id: "AttachMoney",
      label: "Dinheiro"
    },
    {
      id: "CarRepair",
      label: "Oficina"
    },
    {
      id: "DirectionsBoat",
      label: "Porto"
    },
    {
      id: "EvStation",
      label: "Estação de Carregamento"
    },
    {
      id: "Factory",
      label: "Fábrica"
    },
    {
      id: "Garage",
      label: "Garagem"
    },
    {
      id: "Home",
      label: "Casa"
    },
    {
      id: "Hotel",
      label: "Hotel"
    },
    {
      id: "LocalConvenienceStore",
      label: "Loja de Convêniencia"
    },
    {
      id: "LocalCarWash",
      label: "Lava Jato"
    },
    {
      id: "LocalGasStati",
      label: "Posto de Gasolina"
    },
    {
      id: "LocalHospital",
      label: "Hospital"
    },
    {
      id: "LocalParking",
      label: "Estacionamento"
    },
    {
      id: "Store",
      label: "Loja"
    },
    {
      id: "Warehouse",
      label: "Centro de Distribuição"
    },
  ];

// Função para renderizar ícones como JSX
export const renderIcons = (value: string, isMarker: boolean): React.ReactElement | null => {
  const style = { color: "#EA004C !important", width: isMarker ? 48 : 24, height: isMarker ? 32 : 24 };

  switch (value) {
    case "AddHomeWork":
      return <AddHomeWorkIcon color="error" style={style} />;
    case "AddLocation":
      return <AddLocationIcon color="error"  style={style} />;
    case "AirplanemodeActive":
      return <AirplanemodeActiveIcon color="error"  style={style} />;
    case "AttachMoney":
      return <AttachMoneyIcon color="error"  style={style} />;
    case "CarRepair":
      return <CarRepairIcon color="error"  style={style} />;
    case "DirectionsBoat":
      return <DirectionsBoatIcon color="error"  style={style} />;
    case "EvStation":
      return <EvStationIcon color="error"  style={style} />;
    case "Factory":
      return <FactoryIcon color="error"  style={style} />;
    case "Garage":
      return <GarageIcon color="error"  style={style} />;
    case "Home":
      return <HomeIcon color="error"  style={style} />;
    case "Hotel":
      return <HotelIcon color="error"  style={style} />;
    case "LocalConvenienceStore":
      return <LocalConvenienceStoreIcon color="error"  style={style} />;
    case "LocalCarWash":
      return <LocalCarWashIcon color="error"  style={style} />;
    case "LocalHospital":
      return <LocalHospitalIcon color="error"  style={style} />;
    case "LocalParking":
      return <LocalParkingIcon color="error"  style={style} />;
    case "Store":
      return <StoreIcon color="error"  style={style} />;
    case "Warehouse":
      return <WarehouseIcon color="error"  style={style} />;
    default:
      return <HomeIcon color="error"  style={style} />; // Caso o valor não seja encontrado
  }
};

// Função para criar um ícone personalizado para o Leaflet
export const createCustomMarkerIcon = (value: string): any => {
  const muiIcon = renderIcons(value, true); // Renderiza o ícone correspondente como JSX

  if (!muiIcon) return null; // Retorna null se o valor não for válido

  const iconMarkup = renderToStaticMarkup(muiIcon); // Converte JSX para HTML estático
  return divIcon({
    html: iconMarkup, // Usa o HTML como ícone
    className: "custom-marker-icon", // Classe CSS opcional
    iconSize: [46, 46], // Ajuste o tamanho do marcador
    
  });
};