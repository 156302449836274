import collaboratorTeamIcon from 'assets/images/collaboratorTeamIcon.svg';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import driverTeamIcon from 'assets/images/driverTeamIcon.svg';
import serviceTimeDriverIcon from 'assets/images/serviceTimeDriverIcon.svg';
import CustomSelectVehicles from 'components/CustomSelectVehicles';
import { SearchOperation } from 'components/Grid/types';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import { labelStyle } from 'components/Vehicles/styles';
import Loading from 'core/common/Loading';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button, Label } from 'reactstrap';
import api from 'services/api';
import { IVehicles, VehicleType } from '../../interfaces/types';
import formValidatorEN from './formValidatorEN';
import formValidatorPT from './formValidatorPT';

// import { Container } from './styles';

interface Props {
  isEdit?: boolean;
  selectedRow?: IVehicles;
  isNew: any;
  onConfirm: () => void;
}

interface FormValues {
  tipo: string;
  descricao: string;
  idReferencia: string;
  motoristaId: string;
  filialId: string;
  veiculoProprio: string;
  placa: string;
  marcaModelo: string;
  ano: string;
  anoFabricacao: string;
  peso: string;
  quantidadeAtendimento: string;
  cubagem: string;
  valor: string;
  velocidadeMedia: string;
}

const Create: React.FC<Props> = (props: Props) => {
  const [subsidiary, setSubsidiary] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [driver, setDriver] = useState([]);
  const [collaborator, setCollaborator] = useState([]);
  const [parameters, setParameters] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const getSubsidiary = useCallback(async () => {
    try {
      const body = {
        filters: [
          {
            field: 'status',
            value: 'ACTIVE',
            operation: SearchOperation.EQUAL,
          },
        ],
      };

      const response = await api.post('/cadastro/filiais/findByFilters', body);

      setSubsidiary(response.data);
    } catch {}
  }, []);

  const getDriver = useCallback(async (subsidiaryId, nameDriver, listTeam) => {
    try {
      const currentSubsidiary = sessionStorage.getItem('subsidiary');
      const body = {
        filters: [
          {
            field: 'filial.id',
            value: subsidiaryId,
            operation: SearchOperation.EQUAL,
          },
          {
            field: 'nome',
            operation: SearchOperation.MATCH,
            value: nameDriver,
          },
        ],
        page: 0,
        size: 20,
        orders: [],
      };

      const response = await api.post('/delivery/motoristas/pageable', body);

      const sortDriver = response.data.content.sort((a: any, b: any) => {
        const isReversed = 1;

        return isReversed * a.nome.localeCompare(b.nome);
      });

      const mappingDriverData = sortDriver.map((item: any) => {
        return {
          label: item.nome,
          value: item.id,
        };
      });

      let compareDriver = mappingDriverData.filter(
        (driver: any) =>
          !listTeam.some(
            (selectedTeam: any) => selectedTeam.id === driver.value
          )
      );

      setDriver(compareDriver);
    } catch {}
  }, []);

  const getCollaborator = useCallback(
    async (subsidiaryId, nameCollaborator, listTeam) => {
      try {
        const body = {
          filters: [
            {
              field: 'filial.id',
              value: subsidiaryId,
              operation: SearchOperation.EQUAL,
            },
            {
              field: 'nome',
              operation: SearchOperation.MATCH,
              value: nameCollaborator,
            },
          ],
          page: 0,
          size: 20,
          orders: [],
        };

        const response = await api.post(
          '/delivery/colaboradores/pageable',
          body
        );

        const sortCollaborator = response.data.content.sort(
          (a: any, b: any) => {
            const isReversed = 1;

            return isReversed * a.nome.localeCompare(b.nome);
          }
        );

        const mappingCollaboratorData = sortCollaborator.map((item: any) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        let compareCollaborator = mappingCollaboratorData.filter(
          (collaborator: any) =>
            !listTeam.some(
              (selectedTeam: any) => selectedTeam.id === collaborator.value
            )
        );

        setCollaborator(compareCollaborator);
      } catch (err) {}
    },
    []
  );

  const getParameters = useCallback(async () => {
    try {
      const response = await api.get('/parameters/routing');

      setParameters(response.data);
    } catch {}
  }, []);

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      border: '2px solid rgba(0, 48, 73, 0.2)',
      borderRadius: 10,
    }),
  };

  const onChangeDriver = useCallback((filialId, inputText, list) => {
    console.log(inputText);

    if (inputText.length >= 3) {
      getDriver(filialId, inputText, list);
    }
  }, []);

  const onChangeDriverTeam = useCallback((text, list) => {
    let updateTeam = list;

    const findDriverTeam = updateTeam
      .map((driverTeam: any, indexDriverTeam: any) =>
        driverTeam.type === 'DRIVER' ? indexDriverTeam : -1
      )
      .filter((driverTeamFilter: any) => driverTeamFilter !== -1);

    if (findDriverTeam.length > 0) {
      updateTeam[findDriverTeam[0]].id = text.value;
      updateTeam[findDriverTeam[0]].nome = text.label;
    } else {
      updateTeam.unshift({
        type: 'DRIVER',
        id: text.value,
        nome: text.label,
      });
    }

    setTeamList(updateTeam);

    setDriver([]);
  }, []);

  const onChangeCollaborator = useCallback((filialId, inputText, list) => {
    if (inputText.length >= 3) {
      getCollaborator(filialId, inputText, list);
    }
  }, []);

  const onChangeCollaboratorTeam = useCallback((text, list) => {
    let updateTeam = list;

    updateTeam.push({
      type: 'COLLABORATOR',
      id: text.value,
      nome: text.label,
    });

    setTeamList(updateTeam);

    setCollaborator([]);
  }, []);

  const handleSubmit = useCallback(async (values: FormValues, reset: any, team: any[], isEdit?: boolean) => {
    try {
      setLoading(true);

      // Verifica se o veiculo é proprio
      const isVehicles = values.veiculoProprio === 'Sim';

      // Pega o motorista selecionado na equipe.
      const getDriverTeam = team.map(item => {
        return item.id;
      });

      // Pega o colaborador selecionado na equipe.
      const getCollaboratorTeam = team
        .filter(itemFilter => itemFilter.type === 'COLLABORATOR')
        .map(item => {
          return {
            id: item.id,
          };
        });

      const body = {
        tipo: values.tipo === "3/4" ? VehicleType.TRES_QUARTOS : values.tipo === "3/4_FRIO" ? VehicleType.TRES_QUARTOS_FRIO : values.tipo,
        descricao: values.descricao,
        idReferencia: values.idReferencia,
        motorista: {
          id: getDriverTeam[0]
        },
        filial: {
          id: values.filialId
        },
        veiculoProprio: isVehicles,
        placa: values.placa,
        marcaModelo: values.marcaModelo,
        ano: values.ano,
        anoFabricacao: values.anoFabricacao,
        peso: values.peso,
        quantidadeAtendimento: values.quantidadeAtendimento,
        cubagem: values.cubagem,
        valor: values.valor,
        velocidadeMedia: values.velocidadeMedia,
        colaboradores: getCollaboratorTeam
      };

      if(isEdit) {
        const response = await api.patch(`/delivery/veiculos/${props.selectedRow?.id}`, body);

        if(response.status === 204) {
          FrontendNotification(t('vehicles.msgSuccessSave'), NotificationType.SUCCESS);
          reset({});
          setTeamList([]);
          props.onConfirm();
        }
      } else {
        const response = await api.post('/delivery/veiculos', body);

        if(response.status === 201) {
          FrontendNotification(
            t('vehicles.msgSuccessSave'),
            NotificationType.SUCCESS
          );

          if(props.isNew) {
            setTeamList([]);
          } else {
            reset({});
            setTeamList([]);
          }

          props.onConfirm();

        }
      }
      setLoading(false);
    }catch{}
  }, [])

  const onLoadForm = useCallback((data?: IVehicles) => {
    if (data) {
      let teamVehicles: any = teamList;

      // Adiciona o motorista a equipe
      teamVehicles.push({
        id: data.motorista.id,
        nome: data.motorista.nome,
        type: 'DRIVER',
      });

      // Adiciona o colaborador a equipe
      if (data.colaboradores.length > 0) {
        data.colaboradores.map((item: any) => {
          return teamVehicles.push({
            id: item.id,
            nome: item.nome,
            type: 'COLLABORATOR',
          });
        });
      }

      formik.setFieldValue('tipo', data.tipo);
      formik.setFieldValue('descricao', data.descricao);
      formik.setFieldValue('idReferencia', data.idReferencia);
      formik.setFieldValue('motoristaId', data.motorista.id);
      formik.setFieldValue('filialId', data.filial.id);
      formik.setFieldValue(
        'veiculoProprio',
        data.veiculoProprio ? 'Sim' : 'Nao'
      );
      formik.setFieldValue('placa', data.placa);
      formik.setFieldValue('marcaModelo', data.marcaModelo);
      formik.setFieldValue('ano', data.ano);
      formik.setFieldValue('anoFabricacao', data.anoFabricacao);
      formik.setFieldValue('quantidadeAtendimento', data.quantidadeAtendimento);
      formik.setFieldValue('peso', data.peso);
      formik.setFieldValue('cubagem', data.cubagem);
      formik.setFieldValue('valor', data.valor);
      formik.setFieldValue('velocidadeMedia', data.velocidadeMedia);
    }
  }, []);
  

  const initialValues: FormValues = {
    tipo: '',
    descricao: '',
    idReferencia: '',
    motoristaId: '',
    filialId: sessionStorage.getItem('subsidiary') || '',
    veiculoProprio: '',
    placa: '',
    marcaModelo: '',
    ano: '',
    anoFabricacao: '',
    peso: '',
    quantidadeAtendimento: '',
    cubagem: '',
    valor: '',
    velocidadeMedia: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema:
      navigator.language === 'pt' || 'pt-BR'
        ? formValidatorPT
        : formValidatorEN,
    onSubmit: (values: FormValues, { resetForm }) => handleSubmit(values, resetForm, teamList, props.isEdit),
  });

  useEffect(() => {
    getSubsidiary();
    getParameters();
  }, [getParameters]);

  useEffect(() => {
    if (props.isEdit) {
      onLoadForm(props.selectedRow);
    }
  }, []);

  return (
    <div className="tw-flex-col tw-w-full tw-mt-6">
      <Loading loading={loading} />
      <div className="tw-flex tw-items-center">
        <div className="tw-flex tw-flex-col tw-w-1/3 tw-mr-2">
          <Label className="tw-text-sm tw-text-[#003049] tw-opacity-60">
            <Trans i18nKey="vehicles.fields.type" />*
          </Label>
          <CustomSelectVehicles
            fieldId="selectType"
            onChange={(type: string) => formik.setFieldValue('tipo', type)}
            value={formik.values.tipo}
            isView={false}
          />
          {formik.touched.tipo && formik.errors.tipo && (
            <p className="tw-text-sm tw-text-[#EA004C] tw-font-bold">
              {formik.errors.tipo}
            </p>
          )}
        </div>
        <div className="tw-flex tw-flex-col tw-w-1/3 tw-mr-2">
          <InputCustom
            id="description"
            title={`${t('vehicles.fields.description')}*`}
            placeholder={t('vehicles.placeholder.description')}
            onChange={formik.handleChange('descricao')}
            value={formik.values.descricao}
            touched={formik.touched.descricao}
            error={formik.errors.descricao}
          />
        </div>
        <div className="tw-flex tw-flex-col tw-w-1/3 tw-mr-2">
          <InputCustom
            id='idReference'
            title={`${t('vehicles.fields.idReference')}*`}
            placeholder={t('vehicles.placeholder.idReference')}
            onChange={formik.handleChange('idReferencia')}
            value={formik.values.idReferencia}
            touched={formik.touched.idReferencia}
            error={formik.errors.idReferencia}
          />
        </div>
      </div>
      <div className="tw-flex tw-w-full">
        <div className="tw-flex-col tw-w-2/4">
          <div className="tw-flex tw-items-center tw-mt-3">
            <div className="tw-flex tw-flex-col tw-w-full tw-mr-2">
              <SelectCustom
                id='subsidiaryId'
                title={t('vehicles.fields.subsidiary')}
                onChange={formik.handleChange('filialId')}
                value={formik.values.filialId}
                touched={formik.touched.filialId}
                error={formik.errors.filialId}
              >
                <>
                  <option label={t('vehicles.placeholder.subsidiary')} />
                  {subsidiary.map((row: any) => (
                    <option label={row.nome} value={row.id} />
                  ))}
                </>
              </SelectCustom>
            </div>
            <div className="tw-flex tw-flex-col tw-w-full tw-mr-2">
              <SelectCustom
                id='ownerVehicles'
                title={t('vehicles.fields.ownerVehicles')}
                onChange={formik.handleChange('veiculoProprio')}
                value={formik.values.veiculoProprio}
                touched={formik.touched.veiculoProprio}
                error={formik.errors.veiculoProprio}
              >
                <option label={t('common.select')} value="" />
                <option label="Sim" value="Sim" />
                <option label="Nao" value="Nao" />
              </SelectCustom>
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-mt-4">
            <div className="tw-flex tw-flex-col tw-w-full tw-mr-2">
              <InputCustom
                id="year"
                title={t('vehicles.fields.year')}
                placeholder={t('vehicles.placeholder.year')}
                onChange={formik.handleChange('ano')}
                value={formik.values.ano}
                touched={formik.touched.ano}
                error={formik.errors.ano}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-w-full tw-mr-2">
              <InputCustom
                id='manufacturingYear'
                title={t('vehicles.fields.manufacturingYear')}
                placeholder={t('vehicles.placeholder.manufacturingYear')}
                onChange={formik.handleChange('anoFabricacao')}
                value={formik.values.anoFabricacao}
                touched={formik.touched.anoFabricacao}
                error={formik.errors.anoFabricacao}
              />
            </div>
          </div>
          <div className="tw-flex tw-items-center tw-mt-4">
            <div className="tw-flex tw-flex-col tw-w-full tw-mr-2">
              <InputCustom
                id='licensePlate'
                title={t('vehicles.fields.licensePlate')}
                placeholder={t('vehicles.placeholder.licensePlate')}
                onChange={formik.handleChange('placa')}
                value={formik.values.placa}
                touched={formik.touched.placa}
                error={formik.errors.placa}
              />
            </div>
            <div className="tw-flex tw-flex-col tw-w-full tw-mr-2">
              <InputCustom
                id='brandModel'
                title={t('vehicles.fields.brandModel')}
                placeholder={t('vehicles.placeholder.brandModel')}
                onChange={formik.handleChange('marcaModelo')}
                value={formik.values.marcaModelo}
                touched={formik.touched.marcaModelo}
                error={formik.errors.marcaModelo}
              />
            </div>
          </div>
        </div>
        <div className="tw-flex-col tw-w-2/4">
          <div className="tw-flex tw-items-start tw-justify-start tw-mt-4">
            <div className="tw-w-[500px]">
              <div
                className="tw-border-1 tw-h-full tw-border-[#00304933] tw-border-dashed  tw-p-[20px] tw-mt-2 tw-rounded-md"
                style={{ border: '1px dashed #003049' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <React.Fragment>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Label style={{ ...labelStyle }}>Motorista</Label>
                        <Select
                          id="driver"
                          styles={customStyles}
                          options={driver}
                          onChange={(text: any) =>
                            onChangeDriverTeam(text, teamList)
                          }
                          onInputChange={(inputText: any) =>
                            onChangeDriver(
                              formik.values.filialId,
                              inputText,
                              teamList
                            )
                          }
                          value={{
                            label: 'Digite o nome do motorista',
                            value: '',
                          }}
                        />
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: '100%',
                        }}
                      >
                        <Label style={{ ...labelStyle }}>Colaboradores</Label>
                        <Select
                          id="collaborator"
                          styles={customStyles}
                          options={collaborator}
                          onChange={(text: any) =>
                            onChangeCollaboratorTeam(text, teamList)
                          }
                          onInputChange={inputText =>
                            onChangeCollaborator(
                              formik.values.filialId,
                              inputText,
                              teamList
                            )
                          }
                          value={{
                            label: 'Digite o nome do colaborador',
                            value: '',
                          }}
                        />
                      </div>
                    </React.Fragment>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      marginLeft: '1rem',
                    }}
                  >
                    <Label
                      className=""
                      // style={{ ...labelStyle, marginLeft: '20px' }}
                    >
                      Equipe
                    </Label>
                    <div
                      className="tw-w-full tw-border-1 tw-border-dashed tw-border-[#003049] tw-p-5 tw-overflow-y-scroll tw-rounded-md tw-max-h-40"
                      style={{ border: '1px dashed #003049' }}
                    >
                      {teamList
                        .sort((a: any, b: any) => a.type - b.type)
                        .map((team: any, indexTeam) => (
                          <>
                            <div className="tw-flex tw-w-full tw-items-center tw-mt-2">
                              <img
                                src={
                                  team.type === 'DRIVER'
                                    ? driverTeamIcon
                                    : collaboratorTeamIcon
                                }
                                className="tw-mr-2"
                              />
                              <span
                                className={
                                  team.type === 'DRIVER'
                                    ? 'tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden'
                                    : 'tw-text-sm tw-text-[#003049] tw-font-bold tw-text-nowrap tw-max-w-[13ch] tw-text-ellipsis tw-overflow-hidden'
                                }
                              >
                                {team.nome}
                              </span>
                              <Button
                                color="link"
                                className="noMargin"
                                id="deleteEquip"
                                onClick={() => {
                                  let removedElementTeam = teamList;

                                  const filterRemovedElement = removedElementTeam.filter(
                                    (item, index) => index !== indexTeam
                                  );

                                  setTeamList(filterRemovedElement);
                                }}
                              >
                                <img src={DeleteIcon} alt="" />
                              </Button>
                            </div>
                            <div>
                              {team.type === 'DRIVER' && (
                                <div className="tw-flex tw-items-center">
                                  <img src={serviceTimeDriverIcon} />
                                  <div
                                    className="tw-flex tw-rounded-md tw-w-12 tw-h-6 tw-justify-center tw-opacity-60 tw-mr-2 tw-ml-2"
                                    style={{ border: '1px solid #003049' }}
                                  >
                                    <span>
                                      {parameters.length > 0 &&
                                        parameters[0].driverTimeWindow !== null && parameters[0].driverTimeWindow.split(
                                          ','
                                        )[0]}
                                    </span>
                                  </div>
                                  <div
                                    className="tw-flex tw-rounded-md tw-w-12 tw-h-6 tw-justify-center tw-opacity-60"
                                    style={{ border: '1px solid #003049' }}
                                  >
                                    <span>
                                      {parameters.length > 0 &&
                                        parameters[0].driverTimeWindow.split(
                                          ','
                                        )[1]}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-mt-4">
        <div className="tw-flex-col tw-w-1/5 tw-mr-2">
          <InputCustom
            title={t('vehicles.fields.weight')}
            type="number"
            placeholder={t('vehicles.placeholder.weight')}
            onChange={formik.handleChange('peso')}
            value={formik.values.peso}
            touched={formik.touched.peso}
            error={formik.errors.peso}
          />
        </div>
        <div className="tw-flex-col tw-w-1/5 tw-mr-2">
          <InputCustom
            title={t('vehicles.fields.maxAttendances')}
            type="number"
            placeholder={t('vehicles.placeholder.maxAttendances')}
            onChange={formik.handleChange('quantidadeAtendimento')}
            value={formik.values.quantidadeAtendimento}
            touched={formik.touched.quantidadeAtendimento}
            error={formik.errors.quantidadeAtendimento}
          />
        </div>
        <div className="tw-flex-col tw-w-1/5 tw-mr-2">
          <InputCustom
            title={t('vehicles.fields.cubagemMax')}
            type="number"
            placeholder={t('vehicles.placeholder.cubagemMax')}
            onChange={formik.handleChange('cubagem')}
            value={formik.values.cubagem}
            touched={formik.touched.cubagem}
            error={formik.errors.cubagem}
          />
        </div>
        <div className="tw-flex-col tw-w-1/5 tw-mr-2">
          <InputCustom
            title={t('vehicles.fields.priceMax')}
            type="number"
            placeholder={t('vehicles.placeholder.priceMax')}
            onChange={formik.handleChange('valor')}
            value={formik.values.valor}
            touched={formik.touched.valor}
            error={formik.errors.valor}
          />
        </div>
        <div className="tw-flex-col tw-w-1/5 tw-mr-2">
          <InputCustom
            title={t('vehicles.fields.averageSpeed')}
            type="number"
            placeholder={t('vehicles.placeholder.averageSpeed')}
            onChange={formik.handleChange('velocidadeMedia')}
            value={formik.values.velocidadeMedia}
            touched={formik.touched.velocidadeMedia}
            error={formik.errors.velocidadeMedia}
          />
        </div>
      </div>
      <div className="tw-flex tw-justify-end tw-mt-4 tw-mr-2">
        <button id="buttonSave" className="tw-flex tw-items-center tw-justify-center tw-text-center tw-w-48 tw-h-9 tw-bg-[#EA004C] tw-rounded-md tw-text-sm tw-text-[#fff] tw-border-none" onClick={() => formik.handleSubmit()}>
          {props.isEdit ? (
            <Trans i18nKey="vehicles.buttonUpdate" />
          ) : (
            <Trans i18nKey="vehicles.buttonSave" />
          )}
        </button>
      </div>
    </div>
  );
};

export default Create;
