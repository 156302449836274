import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import permissionMenu from 'shared/Permission';
import changeAttendances from '../../../assets/images/changeAttendances.svg';
import changeRouteSteps from '../../../assets/images/changeRoute.svg';
import deleteIconRoutering from '../../../assets/images/deleteIconRoutering.svg';
// Assets
import leftArrow from '../../../assets/images/left-arrow.png';
import rightArrow from '../../../assets/images/right-arrow.png';

interface CarouselProps {
  data: any;
  onChangeAddress: () => void;
  onDeleteStep: (stepId: number, indexStep: number) => void;
  onChangeRoute: (stepId: number, data: any) => void;
}

export default function Carousel({
  data,
  onChangeAddress,
  onDeleteStep,
  onChangeRoute,
}: CarouselProps) {
  const [stepsActual, setStepsActual] = React.useState<number>(0);
  const [active, setActive] = useState<boolean>(false);

  const userData = useSelector((state: any) => state.auth.user);

  const { t } = useTranslation();

  const getStepsContent = useCallback(
    (stepActual: number, dataArr: any) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === dataArr.length - 1) {
        setStepsActual(dataArr.length - 1);
      } else {
        setStepsActual(prevState => {
          return prevState + 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  const getStepsContentLeft = useCallback(
    (stepActual: number, dataArr: any) => {
      if (dataArr.length === 1) {
        setStepsActual(0);
      } else if (stepActual === 0) {
        setStepsActual(0);
      } else {
        setStepsActual(prevState => {
          return prevState - 1;
        });
      }

      setActive(!active);
    },
    [stepsActual]
  );

  useEffect(() => {
    if (stepsActual > data.length - 1) {
      setStepsActual(0);
    }
  }, [stepsActual, data.length]);

  return (
    <div className="carouselContainer">
      <div className="carouselWrapper">
        <div
          className="carouselPanelLeft"
          style={{ cursor: 'pointer' }}
          onClick={() => getStepsContentLeft(stepsActual, data)}
        >
          <img alt="" src={leftArrow} width="15" height="15" />
        </div>
        <div className="carouselPanelContent">
          <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.steps.serviceNumber" />
              :{'  '}
            </b>{' '}
            {data && data.length > 0
              ? data[stepsActual].conhecimentoPre.numero
              : ''}{' '}
            <br />
          </div>
          <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.steps.client" />:{' '}
            </b>
            {data && data.length > 0
              ? data[stepsActual].conhecimentoPre.clienteNome
              : ''}{' '}
            <br />
          </div>
          <div className="carouselPanelContentText">
            <b style={{ marginRight: '3px' }}>
              <Trans i18nKey="routeOptmization.routeSteps.steps.expectedArrival" />
              :{' '}
            </b>{' '}
            {data && data.length > 0
              ? data[stepsActual].arrival.formattedDateTime
              : ''}{' '}
            <br />
          </div>
          <div
            className="carouselPanelContentText"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <b>
              <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryAddress" />
              :
            </b>{' '}
            {data && data.length > 0
              ? `${data[stepsActual].conhecimentoPre.enderecoLogradouro}, ${data[stepsActual].conhecimentoPre.enderecoNumero}, ${data[stepsActual].conhecimentoPre.enderecoComplemento}`
              : ''}{' '}
            <br />
          </div>
          <div
            className="carouselPanelContentText"
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <b style={{ marginRight: 3 }}>
                  <Trans i18nKey="routeOptmization.routeSteps.fields.neighborhood" />
                  :
                </b>
                <span
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '80px',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {data && data.length > 0
                    ? data[stepsActual].conhecimentoPre.enderecoBairro
                    : ''}{' '}
                </span>
              </div>
              <br />
              <b style={{ marginRight: 3 }}>
                <Trans i18nKey="routeOptmization.routeSteps.fields.city" />:
              </b>
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '80px',
                  textOverflow: 'ellipsis',
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual].conhecimentoPre.enderecoCidade
                  : ''}{' '}
              </span>
              <br />
            </div>
            <div style={{ display: 'flex', marginTop: 5 }}>
              <b style={{ marginRight: 3 }}>UF:</b>
              <span
                style={{
                  marginRight: 5,
                }}
              >
                {data && data.length > 0
                  ? data[stepsActual].conhecimentoPre.enderecoUf
                  : ''}{' '}
              </span>
              <br />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-around',
              }}
            >
              <img
                src={changeRouteSteps}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (
                    permissionMenu(
                      userData.grupo.permissoes,
                      'routering.change-steps'
                    )
                  ) {
                    onChangeRoute(
                      data[stepsActual].conhecimentoPre.id,
                      data[stepsActual]
                    );
                  } else {
                    FrontendNotification(
                      t('common.permission'),
                      NotificationType.WARNING
                    );
                  }
                }}
              />

              <span
                onClick={() => {
                  if (
                    permissionMenu(
                      userData.grupo.permissoes,
                      'routering.change-address'
                    )
                  ) {
                    onChangeAddress();
                  } else {
                    FrontendNotification(
                      t('common.permission'),
                      NotificationType.WARNING
                    );
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <img src={changeAttendances} />
              </span>
              <img
                src={deleteIconRoutering}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (
                    permissionMenu(
                      userData.grupo.permissoes,
                      'routering.remove-steps'
                    )
                  ) {
                    onDeleteStep(data[stepsActual].id, stepsActual);
                  } else {
                    FrontendNotification(
                      t('common.permission'),
                      NotificationType.WARNING
                    );
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          className="carouselPanelRight"
          style={{ cursor: 'pointer' }}
          onClick={() => getStepsContent(stepsActual, data)}
        >
          <img alt="" src={rightArrow} width="15" height="15" />
        </div>
      </div>
    </div>
  );
}
