import styled from 'styled-components';

export const InputCustom = styled.input`
  border: 1px solid #535669;
  padding: 0.310rem;
  border-radius: 0.313rem;
  box-sizing: border-box;
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  /* width: 10px; */
`;

export const Title = styled.span`
  font-family: 'Lato';
  font-size: 0.875rem;
  color: #535669;
  margin-bottom: 0.313rem;
`;