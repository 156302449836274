import MapIcon from 'assets/images/mapVehicleIcon.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'reactstrap/lib';
import { saveSubsidiary } from 'store/modules/map/actions';
import './style.scss';

interface Filial {
  nome: string;
  id: number;
}

interface Props {
  listSubsidiary: Filial[];
  isViewMap?: boolean;
  onChangeSubsidiary: (id: string) => void;
}

const TopBar: React.FC<Props> = ({
  listSubsidiary,
  onChangeSubsidiary,
  isViewMap,
}) => {
  const [dataSubsidiary, setDataSubsidiary] = useState<Filial[]>([]);
  const [idSubsidiary, setIdSubisidiary] = useState<string>('');

  const userData = useSelector((state: any) => state.auth.user);

  const getSubsidiaryId = useSelector((state: any) => state.map.subsidiaryId);

  const dispatch = useDispatch();

  const getSubsidiaryUsers = useCallback(async () => {
    try {
      setIdSubisidiary(userData.filiais[0].id);
      dispatch(saveSubsidiary(userData.filiais[0].id));
      sessionStorage.setItem('subsidiary', userData.filiais[0].id);
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (listSubsidiary) {
      setDataSubsidiary(listSubsidiary);
    }
    getSubsidiaryUsers();
  }, [listSubsidiary, getSubsidiaryUsers]);

  return (
    <div className="tw-flex tw-h-16 tw-w-full tw-bg-[#EDF2F4] tw-shadow-md">
      <Form inline>
        <div className="tw-pl-4 tw-flex tw-flex-col">
          <span className="tw-text-sm tw-font-normal">
            <Trans i18nKey="filter.subsidiary" />
          </span>
          <select
            name="filialSelector"
            id="filial1"
            className="tw-w-auto tw-h-6 tw-z-50 tw-border-0 tw-bg-[#Edf2f4] tw-box-border tw-text-base tw-text-[#EA004C] tw-font-semibold"
            // style={{ borderBottom: '3px solid #EA004C' }}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              sessionStorage.setItem('subsidiary', e.target.value);
              dispatch(saveSubsidiary(e.target.value));
              setIdSubisidiary(e.target.value);
              onChangeSubsidiary(e.target.value);
            }}
            value={getSubsidiaryId}
          >
            <option label="Todas" value="-1" />
            {dataSubsidiary.map((subsidiary: Filial) => (
              <option key={subsidiary.id} value={subsidiary.id}>
                {subsidiary.nome}
              </option>
            ))}
          </select>
        </div>
      </Form>
      {isViewMap && (
        <div className="tw-w-full tw-flex tw-items-center tw-justify-end tw-mr-4">
          <button className="tw-w-full tw-max-w-28 tw-h-12 tw-bg-[#EA004C] tw-border-none tw-rounded-md tw-text-base tw-text-[#fff] tw-font-normal tw-flex tw-items-center tw-justify-center" onClick={() => {
            window.open('/delivery/map', '_blank');
          }}>
            <img src={MapIcon} />
            Mapa
          </button>
        </div>
      )}
    </div>
  );
};

export default TopBar;
