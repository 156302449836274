import CargoCreate from 'pages/CargoCreate';
import DashboardMap from 'pages/Dashboard';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReportsJourney from '../components/ReportsJourney';
import ReportsMetabase from '../components/ReportsMetabase';
// import Routing from '../components/Routing';
import Dashboard from 'components/Dashboard';
import Routering from 'components/Routering';
import Scheduling from '../components/Sheduling';
import ActiveAccount from '../pages/ActiveAccount';
import Billing from '../pages/Billing';
import ConfirmAccount from '../pages/ConfirmAccount';
import ConfirmForgotPassword from '../pages/ConfirmForgotPassword';
import ControlTower from '../pages/ControlTower';
import Delivery from '../pages/Delivery';
import ForgotPassword from '../pages/ForgotPassword';
import IntegrationGW from '../pages/IntegrationGW';
import Main from '../pages/Main';
import ManagementForms from '../pages/ManagementForms';
import MapDeliveryTracking from '../pages/MapDeliveryTracking';
import Reports from '../pages/Reports';
import ResetPassword from '../pages/ResetPassword';
import RouterCargo from '../pages/RouterCargo';
import SignIn from '../pages/SignIn';
import UploadCSV from '../pages/UploadCSV';
import CorrectCSV from '../pages/UploadCSV/CorrectCSV';
import ZenviaSMS from '../pages/ZenviaSMS';
import NotFound from '../template/notFound';
import { PrivateRoute } from './privateRoute';
import { PublicRoute } from './publicRoute';
import { RoutesComponent } from './route';

export default function Routes() {
  const [route, setRoute] = React.useState();
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={SignIn} />
        {/* <Route path="/signup" component={SignUp} /> */}
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/confirmForgot" component={ConfirmForgotPassword} />
        <Route path="/resetPassword" component={ResetPassword} />
        <Route path="/activeAccount" component={ActiveAccount} />
        <Route path="/confirmAccount" component={ConfirmAccount} />
        <PublicRoute
          path="/delivery/trackingMap"
          component={MapDeliveryTracking}
        />
        {/* <PrivateRoute
          path="/deliveryTracking"
          component={Main}
          content={DeliveryTracking}
        /> */}
        <PrivateRoute
          path="/manageForms"
          component={Main}
          content={ManagementForms}
        />
        <PrivateRoute path="/uploadCSV" component={Main} content={UploadCSV} />
        <PrivateRoute
          path="/controlTower"
          // component={Main}
          component={ControlTower}
        />
        <PrivateRoute
          path="/delivery/map"
          // component={Main}
          component={DashboardMap}
        />
        <PrivateRoute
          path="/correctCSV"
          // component={Main}
          component={CorrectCSV}
        />
        <PrivateRoute
          path="/integrationGW"
          component={Main}
          content={IntegrationGW}
        />
        <PrivateRoute path="/zenviaSMS" component={Main} content={ZenviaSMS} />
        <PrivateRoute path="/home" component={Main} content={Delivery} />
        <PrivateRoute
          path="/reports"
          component={Main}
          content={Reports}
        />
        <PrivateRoute
          path="/metabase"
          component={Main}
          content={ReportsMetabase}
        />

        <RoutesComponent path="/routering" component={RouterCargo} />
        <RoutesComponent path="/cargosFormation" component={CargoCreate} />
        <RoutesComponent path="/routing" component={Routering} />        
        <PrivateRoute path="/billing" component={Main} content={Billing} />
        <PrivateRoute path="/scheduling" component={Main} content={Scheduling} />
        <PrivateRoute exact path="/reportsJourney" component={Main} content={ReportsJourney} />
        <PrivateRoute exact path="/dashboard" component={Main} content={Dashboard} />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}
