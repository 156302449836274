import { ICellRendererParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';

const Schedule: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const { value } = props;
  const [enabled, setEnabled] = useState(value?.enabled || false);
  const [time, setTime] = useState(value || '');
  const { selected }: any = props.node;

  console.log(value);

  const handleValueChange = (value: string) => {
    // const day = value.day;
    props.api.stopEditing(); // Finaliza a edição
    if (props.column) {
      props.node.setDataValue(props.column.getColId(), value);
    }
    setTime(value);
  };


  useEffect(() => {
    if(value) {
      setTime(value)
    }
  }, [value])
  
  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-mt-2">
      <>
        <input
          type="datetime-local"
          value={time}
          className="tw-w-full tw-h-6 tw-z-50"
          onChange={e => handleValueChange(e.target.value)}
          disabled={!selected}
          // onChange={e => setTime(e.target.value)}
        />
      </>
    </div>
  );
};

export default Schedule;
