import packageVehicle from 'assets/images/packageVehicle.svg';
import packageVehicleEmpty from 'assets/images/packageVehicleEmpty.svg';
import packageVehicleMax from 'assets/images/packageVehicleMax.svg';
import priceVehicleEmpty from 'assets/images/priceVehicleEmpty.svg';
import priceVehicleIcon from 'assets/images/priceVehicleIcon.svg';
import priceVehicleMax from 'assets/images/priceVehicleMax.svg';
import serviceVehicle from 'assets/images/serviceVehicle.svg';
import serviceVehicleEmpty from 'assets/images/serviceVehicleEmpty.svg';
import serviceVehicleMax from 'assets/images/serviceVehicleMax.svg';
import weigtVehicleMax from 'assets/images/weightVehicleMax.svg';
import weigtVehicle from 'assets/images/weigtVehicle.svg';
import weigtVehicleEmpty from 'assets/images/weigtVehicleEmpty.svg';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  TooltipWrapper,
} from 'ui/tooltip';
import TotalizatorValue from './TotalizatorValue';
import { TotalizatorType } from './types';

const Totalizator: React.FC = () => {
  const [porcentageCargas, setPorcentageCargas] = useState<any>(0);
  const [porcentageWeight, setPorcentageWeight] = useState<any>(0);
  const [porcentageCubagem, setPorcentageCubagem] = useState<any>(0);
  const [porcentageValue, setPorcentageValue] = useState<any>(0);

  const dispatch = useDispatch();

  const accumulatorValueCarga = useSelector(
    (state: any) => state.accumulator.accumulatorValueCarga
  );
  const accumulatorValueVehicle = useSelector(
    (state: any) => state.accumulator.accumulatorValue
  );



  const onCalcPorcentageAccumulator = useCallback(() => {
    console.log("AccumulatorCarga", accumulatorValueCarga);
    console.log("AccumulatorVehicle", accumulatorValueVehicle);
    const totalAtend =
      accumulatorValueVehicle.accumulatorAttendances === 0
        ? 0
        : (accumulatorValueCarga.accumulatorAttendances /
            accumulatorValueVehicle.accumulatorAttendances) *
          100;
    const totalPeso =
      accumulatorValueVehicle.accumulatorWeight === 0
        ? 0
        : (parseFloat(accumulatorValueCarga.accumulatorWeight) /
            parseFloat(accumulatorValueVehicle.accumulatorWeight)) *
          100;
    const totalCubagem =
      accumulatorValueVehicle.accumulatorVolume === 0
        ? 0
        : (parseFloat(accumulatorValueCarga.accumulatorVolume) /
            parseFloat(accumulatorValueVehicle.accumulatorVolume)) *
          100;
    const totalValor =
      accumulatorValueVehicle.accumulatorPrice === 0
        ? 0
        : (parseFloat(accumulatorValueCarga.accumulatorPrice) /
            parseFloat(accumulatorValueVehicle.accumulatorPrice)) *
          100;

    sessionStorage.setItem('@accumulatorPorcentage', JSON.stringify({
      porcentageAtend: totalAtend,
      porcentageWeight: totalPeso,
      porcentageCubage: totalCubagem,
      porcentagePrice: totalValor
    }));

    setPorcentageCargas(totalAtend.toFixed(2));
    setPorcentageWeight(totalPeso.toFixed(2));
    setPorcentageCubagem(totalCubagem.toFixed(2));
    setPorcentageValue(totalValor.toFixed(2));
  }, [accumulatorValueCarga, accumulatorValueVehicle]);

  const onLoadIcons = (porcentage: number, type: TotalizatorType) => {
    switch (type) {
      case TotalizatorType.SERVICE:
        if(porcentage > 100) {
          return <img src={serviceVehicleMax} />
        } else if(porcentage > 0) {
          return <img src={serviceVehicle} />
        } else {
          return <img src={serviceVehicleEmpty} />
        }
        break;
        case TotalizatorType.WEIGHT:
        if(porcentage > 100) {
          return <img src={weigtVehicleMax} />
        } else if(porcentage > 0) {
          return <img src={weigtVehicle} />
        } else {
          return <img src={weigtVehicleEmpty} />
        }
        break;
        case TotalizatorType.CUBAGE:
        if(porcentage > 100) {
          return <img src={packageVehicleMax} />
        } else if(porcentage > 0) {
          return <img src={packageVehicle} />
        } else {
          return <img src={packageVehicleEmpty} />
        }
        break;
        case TotalizatorType.VALUE:
        if(porcentage > 100) {
          return <img src={priceVehicleMax} />
        } else if(porcentage > 0) {
          return <img src={priceVehicleIcon} />
        } else {
          return <img src={priceVehicleEmpty} />
        }
        break;
    
      default:
        break;
    }
  }

  useEffect(() => {
    onCalcPorcentageAccumulator();
  }, [accumulatorValueCarga, accumulatorValueVehicle]);

  return (
    <div className="tw-flex tw-flex-col tw-items-end tw-mr-8 tw-mb-2">
      <div className="tw-flex tw-flex-row">
        <div className="tw-mr-4 tw-cursor-pointer">
          <TooltipProvider>
            <TooltipWrapper>
              <TooltipTrigger asChild>
                {onLoadIcons(parseFloat(porcentageCargas), TotalizatorType.SERVICE)}
              </TooltipTrigger>
              <TooltipContent>
                <TotalizatorValue
                  title="Atend."
                  porcentage={parseFloat(porcentageCargas)}
                  dataCargos={accumulatorValueCarga.accumulatorAttendances}
                  dataVehicle={accumulatorValueVehicle.accumulatorAttendances}
                />
              </TooltipContent>
            </TooltipWrapper>
          </TooltipProvider>
        </div>
        <div className="tw-mr-4 tw-cursor-pointer">
          <TooltipProvider>
            <TooltipWrapper>
              <TooltipTrigger asChild>
              {onLoadIcons(parseFloat(porcentageWeight), TotalizatorType.WEIGHT)}
              </TooltipTrigger>
              <TooltipContent>
                <TotalizatorValue
                  title="Peso"
                  porcentage={parseFloat(porcentageWeight).toFixed(2)}
                  dataCargos={accumulatorValueCarga.accumulatorWeight}
                  dataVehicle={accumulatorValueVehicle.accumulatorWeight}
                />
              </TooltipContent>
            </TooltipWrapper>
          </TooltipProvider>
        </div>
        <div className="tw-mr-4 tw-cursor-pointer">
          <TooltipProvider>
            <TooltipWrapper>
              <TooltipTrigger asChild>
              {onLoadIcons(parseFloat(porcentageCubagem), TotalizatorType.CUBAGE)}
              </TooltipTrigger>
              <TooltipContent>
                <TotalizatorValue
                  title="Cubagem"
                  porcentage={porcentageCubagem}
                  dataCargos={accumulatorValueCarga.accumulatorVolume}
                  dataVehicle={accumulatorValueVehicle.accumulatorVolume}
                />
              </TooltipContent>
            </TooltipWrapper>
          </TooltipProvider>
        </div>
        <div className="tw-cursor-pointer">
          <TooltipProvider>
            <TooltipWrapper>
              <TooltipTrigger asChild>
              {onLoadIcons(parseFloat(porcentageValue), TotalizatorType.VALUE)}
              </TooltipTrigger>
              <TooltipContent>
                <TotalizatorValue
                  title="Valor"
                  porcentage={porcentageValue}
                  dataCargos={accumulatorValueCarga.accumulatorPrice}
                  dataVehicle={accumulatorValueVehicle.accumulatorPrice}
                />
              </TooltipContent>
            </TooltipWrapper>
          </TooltipProvider>
        </div>
      </div>
    </div>
  );
};

export default Totalizator;
