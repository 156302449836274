import CustomModal from 'components/ModalCustom';
import SelectCustom from 'components/SelectCustom';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import formValidation from './formValidation';
import {
  ButtonSaveJourney
} from './styles';

interface Props {
  onClear?: any;
  journeySelected?: any;
}

const DriverShift: React.FC<Props> = ({ onClear, journeySelected }: Props) => {
  const { t } = useTranslation();
  const [dataJourney, setDataJourney] = useState<any>([]);
  const [journeyIdValue, setJourneyIdValue] = useState<any>();

  const formik = useFormik({
    initialValues: {
      journeyId: ''
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      sessionStorage.setItem('journeyId', values.journeyId);
      FrontendNotification(t('driver.journey'), NotificationType.SUCCESS);
      onClear()
    }
  })

  const getListJourney = useCallback(async () => {
    try { 
      const response: any = await api.get('/workday/journeys');

      setDataJourney(response.data);

      formik.setFieldValue('journeyId', sessionStorage.getItem('journeyId'))


    }catch(err) {}
  }, []);



  useEffect(() => {
    getListJourney();
  }, [getListJourney])

  return (
    <CustomModal
      isOpen
      label={t('driverShift.title')}
      isClose={onClear}
      styles={{ maxWidth: '50%' }}
    >
      <div className='tw-flex tw-items-center tw-justify-between tw-w-full tw-h-full'>
        <div>
        <SelectCustom
            onChange={formik.handleChange('journeyId')}
            value={formik.values.journeyId}
            id='journeyId'
            touched={formik.touched.journeyId}
            error={formik.errors.journeyId}
          >
            <option label={t('common.select')}></option>
            {dataJourney.map((item: any) => (
              <option label={item.name} value={item.id} />
            ))}
          </SelectCustom>
        </div>
        <div className='tw-w-full tw-max-w-28 tw-h-full tw-flex tw-items-center tw-justify-end tw-ml-4'>
        <ButtonSaveJourney className="tw-w-full tw-max-w-28 tw-bg-[#EA004C] tw-border-none tw-shadow-md tw-h-9 tw-mt-3 tw-rounded-md tw-text-sm tw-text-[#fff] tw-font-normal" type='button' id='buttonSaveJourney' onClick={() => formik.handleSubmit()}>Salvar</ButtonSaveJourney>
        </div>
      </div>
    </CustomModal>
  );
};

export default DriverShift;
