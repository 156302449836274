import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';

import checkedIcon from 'assets/images/towerChecked.svg';


const Checkbox: React.FC<ICellRendererParams> = (
  props: ICellRendererParams
) => {
  const { value } = props;
  const [enabled, setEnabled] = useState(value?.enabled || true);
  const [time, setTime] = useState('');
  const { selected }: any = props.node;

  const handleValueChange = (value: string) => {
    // const day = value.day;
    props.api.stopEditing(); // Finaliza a edição
    if (props.column) {
      props.node.setDataValue(props.column.getColId(), value);
    }
    setTime(value);
  };

  const handleChecked = (enabled: boolean) => {
    // const day = value.day;
    props.api.stopEditing(); // Finaliza a edição
    if (props.column) {
      props.node.setDataValue(props.column.getColId(), enabled);
    }

    console.log(props.data);
  };

  return (
      <div
        className="tw-flex !tw-w-5 !tw-h-5 tw-bg-[#fff] tw-border tw-border-[#000] tw-rounded-[4px] tw-cursor-pointer tw-p-[2px] tw-mr-2 tw-ml-0 tw-mt-2"
        onClick={() => {

            setEnabled(!enabled);
            handleChecked(!enabled);
        }}
      >
        <div className="tw-flex tw-items-center">
          {enabled ? (
            <div className="tw-bg-[#EA004C] tw-rounded-[4px] tw-border-none tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-p-2">
              <img src={checkedIcon} className="tw-w-3 tw-h-3" />
            </div>
          ) : (
            <div
              className="tw-w-4 tw-h-4 tw-rounded-[4px] tw-bg-[#fff]"
              style={{ border: '1px solid #EA004C' }}
            />
          )}
        </div>
      </div>
  );
};

export default Checkbox;
