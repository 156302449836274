import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
// import { Container } from './styles';

type Props = {
  onClose: () => void;
  isClose?: boolean;
  open: boolean;
  children: JSX.Element;
  showHeaderIcon: boolean;
  title: string;
};

const CustomDialog: React.FC<Props> = ({
  onClose,
  open,
  children,
  showHeaderIcon,
  title,
  isClose
}) => {
  return (
    <Modal
      isOpen={open}
      style={{
        minWidth: 600,
        borderRadius: '0.375rem',
      }}
    >
      <div className="tw-h-8 tw-w-full tw-bg-[#EA004C] tw-flex tw-items-center tw-justify-center tw-rounded-t-md">
        <div className='tw-flex'>

        {showHeaderIcon && (
          <FilterListIcon style={{ color: 'white' }} fontSize="medium" />
        )}
        <span className="tw-text-sm tw-text-[#FFF] tw-ml-2">{title}</span>
        </div>

        {!isClose && (

        <div className='tw-absolute tw-right-2 tw-top-[2px] tw-cursor-pointer' onClick={onClose}>
          <CloseIcon style={{ fill: '#FFF' }} />
        </div>
        )}
      </div>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default CustomDialog;
