import Pagination from '@material-ui/lab/Pagination';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import IconViewRoute from 'assets/images/iconViewRoute.svg';
import CustomModal from 'components/ModalCustom';
import SearchFilter from 'components/SearchFilter';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'reactstrap/lib';
import api from 'services/api';
import RouteringDetails from './RouteringDetails';
import './styles.scss';

export default function Routering({ onClear }) {
  const [modal, setModal] = React.useState(true);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [removeConfirmation, setRemoveConfirmation] = useState(false);
  const [routeCargoId, setRouteCargoId] = useState();

  const [detailRoutering, setDetailRoutering] = useState(false);
  const [detailRouteringId, setDetailRouteringId] = useState();

  const [routeCargo, setRouteCargo] = useState([]);

  const [optionsFilter, setOptionsFilter] = useState('placa');
  const [valueSearch, setValueSearch] = useState('');

  const [filters, setFilters] = useState([]);

  const { t } = useTranslation();

  const getRouteCargo = useCallback(
    async dataFilters => {
      try {
        setLoading(true);

        const currentFilters = [
          {
            field: 'optmization.cancelado',
            value: false,
            operation: 'EQUAL',
          },
          {
            field: 'inactivated',
            value: false,
            operation: 'EQUAL'
          }
        ];

        const dataFilters = {
          filters: filters.length > 0 ? filters : currentFilters,
          orders: [
            {
              field: 'dataCriacao',
              direction: 'DESC',
            },
          ],
          page: currentPage,
          size: 10,
        };

        const response = await api.post('/route/cargo/pageable', dataFilters);

        setRouteCargo(response.data.content);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    },
    [currentPage, filters]
  );

  const deleteRouteCargo = useCallback(async cargoId => {
    try {
      const data = {
        idCargo: cargoId,
      };

      const response = await api.post('/route/cargo/cancel', data);

      if (response.status === 204) {
        FrontendNotification(
          t('Simulação removida com sucesso'),
          NotificationType.SUCCESS
        );
      }
      setRemoveConfirmation(false);

      getRouteCargo();
    } catch (err) {}
  }, []);

  const paginate = pageNumber => setCurrentPage(pageNumber - 1);

  useEffect(() => {
    getRouteCargo();
  }, [getRouteCargo]);
  return (
    <>
      {detailRoutering && (
        <RouteringDetails
          isOpen={detailRoutering}
          idRoute={detailRouteringId}
          onClear={() => {
            setDetailRoutering(!detailRoutering);
            getRouteCargo();
          }}
        />
      )}
      <Loading loading={loading} />
      <Modal isOpen={removeConfirmation}>
        <ModalBody>
          <Trans i18nKey="common.questionRemove" />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteRouteCargo(routeCargoId)}>
            <Trans i18nKey="common.confirmRemove" />
          </Button>{' '}
          <Button
            color="secondary"
            onClick={() => setRemoveConfirmation(!removeConfirmation)}
          >
            <Trans i18nKey="common.cancelRemove" />
          </Button>
        </ModalFooter>
      </Modal>
      <CustomModal
        size="xl"
        isOpen={modal}
        dialogClassName="my-modal"
        label="Carregamentos"
        isClose={onClear}
        style={{ maxWidth: '100%', position: 'relative' }}
      >
        <div style={{ padding: '1.25rem' }}>
          <div style={{ marginBottom: 20 }}>
            <SearchFilter
              isLicensePlate
              isDriver
              // isReferenceId
              isRouteNumber
              // isDocNumber
              isService
              valueSearch={valueSearch}
              optionValue={optionsFilter}
              handleChange={e => setValueSearch(e.target.value)}
              handleSelect={e => setOptionsFilter(e.target.value)}
              onExecuteFilter={() => {
                setCurrentPage(0);
                if (optionsFilter === 'placa' && valueSearch.length > 0) {
                  setFilters([
                    {
                      field: 'optmization.vehicle.placa',
                      value: valueSearch,
                      operation: 'MATCH',
                    },
                    {
                      field: 'optmization.cancelado',
                      value: false,
                      operation: 'EQUAL',
                    },
                  ]);
                } else if (
                  optionsFilter === 'motorista.nome' &&
                  valueSearch.length > 0
                ) {
                  setFilters([
                    {
                      field: 'optmization.driver.nome',
                      value: valueSearch,
                      operation: 'MATCH',
                    },
                    {
                      field: 'optmization.cancelado',
                      value: false,
                      operation: 'EQUAL',
                    },
                  ]);
                } else if (
                  optionsFilter === 'idReferencia' &&
                  valueSearch.length > 0
                ) {
                  setFilters([
                    {
                      field: 'optmization.steps.conhecimentoPre.idReferencia',
                      value: valueSearch,
                      operation: 'MATCH',
                    },
                    {
                      field: 'optmization.cancelado',
                      value: false,
                      operation: 'EQUAL',
                    },
                  ]);
                } else if (
                  optionsFilter === 'numeroRota' &&
                  valueSearch.length > 0
                ) {
                  setFilters([
                    {
                      field: 'optmization.id',
                      value: valueSearch,
                      operation: 'EQUAL',
                    },
                    {
                      field: 'optmization.cancelado',
                      value: false,
                      operation: 'EQUAL',
                    },
                  ]);
                } else if (
                  optionsFilter === 'docNumber' &&
                  valueSearch.length > 0
                ) {
                  setFilters([
                    {
                      field: 'optmization.steps.conhecimentoPre.numero',
                      value: valueSearch,
                      operation: 'MATCH',
                    },
                    {
                      field: 'optmization.cancelado',
                      value: false,
                      operation: 'EQUAL',
                    },
                  ]);
                } else {
                  setFilters([
                    {
                      field: 'optmization.cancelado',
                      value: false,
                      operation: 'EQUAL',
                    },
                  ]);
                }
              }}
            />
          </div>
          <table style={{ width: '100%', marginTop: 20 }}>
            <thead>
              <tr>
                <th style={{ padding: 0 }}>
                  <span className="columnRoutering">Placa</span>
                </th>
                <th className="columnRoutering" style={{ padding: 0 }}>
                  <span>Motorista</span>
                </th>
                <th className="columnRoutering" style={{ padding: 0 }}>
                  <span>Nº da Rota</span>
                </th>
                <th className="columnRoutering" style={{ padding: 0 }}>
                  <span>Atendimentos</span>
                </th>
                <th className="columnRoutering" style={{ padding: 0 }}>
                  <span>Data de Liberacao</span>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {routeCargo.map(item => (
                <tr className="lineGroupSimulation" style={{ height: 27 }}>
                  <td style={{ padding: 0, marginLeft: 10 }}>
                    <span className="rowRoutering">
                      {item.optmization.veiculo.placa}
                    </span>
                  </td>
                  <td style={{ padding: 0, marginLeft: 10 }}>
                    <span className="rowRoutering">
                      {item.optmization.driver.nome}
                    </span>
                  </td>
                  <td style={{ padding: 0, marginLeft: 10 }}>
                    <span>{item.optmization.id}</span>
                  </td>
                  <td style={{ padding: 0, marginLeft: 10 }}>
                    <div className="centerRowRoutering">
                      <span className="rowRoutering">
                        {item.optmization.stepsCount}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className="rowRoutering">
                      {format(
                        new Date(item.dateRelease),
                        'dd/MM/yyyy HH:mm',
                        pt
                      )}
                    </span>
                  </td>
                  <td>
                    <Button
                      color="link"
                      className="noMargin"
                      onClick={() => {
                        setRouteCargoId(item.optmization.id);
                        setRemoveConfirmation(!removeConfirmation);
                      }}
                    >
                      <img src={DeleteIcon} alt="" />
                    </Button>
                    <Button
                      color="link"
                      className="noMargin"
                      onClick={() => {
                        setDetailRoutering(!detailRoutering);
                        setDetailRouteringId(item.optmization.id);
                        localStorage.setItem(
                          'optmizationId',
                          item.optmization.id
                        );
                      }}
                    >
                      <img src={IconViewRoute} alt="" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 10,
            }}
          >
            <Pagination
              count={totalPages}
              variant="outlined"
              shape="rounded"
              onChange={(e, page) => paginate(page)}
            />
          </div>
        </div>
      </CustomModal>
    </>
  );
}
