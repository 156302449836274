import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import { PreviewForm } from 'shared/BuilderForm';
import { getData } from 'store/modules/csvMapping/actions';
import { editSimulatorRoute } from 'store/modules/routeringSimulation/actions';
import TopBar from './TopBar';
import './styles.scss';

interface SubsidiaryData {
  id?: number;
  nome?: string;
}

const UploadCSV = () => {
  const [subsidiary, setSubsidiary] = React.useState<SubsidiaryData[]>([]);
  const [filesUpload, setFiles] = useState([]);
  const [filesPreview, setFilesPreview] = useState<any>([]);
  const [typeUpload, setTypeUpload] = useState<string>('');
  const [nextStep, setNextStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentSubsidiary, setCurrentSubsidiary] = useState<number | null>(
    null
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const getSubsidiary = useCallback(async () => {
    const data = {
      filters: [
        {
          field: 'status',
          value: 'ACTIVE',
          operation: 'EQUAL',
        },
      ],
    };
    const response = await api.post('/cadastro/filiais/findByFilters', data);
    setSubsidiary(response.data);

    dispatch(editSimulatorRoute(false));
  }, []);

  const handleUpload = async (files: any) => {
    const uploadedFiles = files.map((file: any) => ({
      file,
      name: file.name,
      preview: URL.createObjectURL(file),
      progress: 0,

      uploaded: false,
      error: false,
      url: URL.createObjectURL(file),
    }));

    setTypeUpload('R');
    setFiles(uploadedFiles[0].file);
    setFilesPreview(uploadedFiles[0]);
  };

  const handleSubmit = useCallback(async (filesSubmit, type) => {
    if (filesSubmit.length === 0) {
      FrontendNotification(t('errors.upload'), NotificationType.ERROR);
    } else {
      setLoading(true);

      const separatorFileExtension = filesSubmit.path.split('.');

      const fileExtension = separatorFileExtension;

      const data = new FormData();
      const subsidiaryValue: any = sessionStorage.getItem('subsidiary');

      data.append('file', filesSubmit);
      data.append('separator', 'SEMICOLON');
      data.append('idFilial', subsidiaryValue);

      if (filesSubmit.name.includes('.csv')) {
        try {
          const emailSendLanguage =
            navigator.language === 'pt' || navigator.language === 'pt-BR'
              ? 'pt_BR'
              : 'en_US';
          const response = await api.post(
            `/delivery/csvMapping/upload?lang=${emailSendLanguage}`,
            data
          );
          const currentOrders = [
            {
              field: 'ordemEntrega',
              direction: 'ASC',
            },
            {
              field: 'id',
              direction: 'ASC',
            },
          ];

          if (response.status === 204) {
            const dataCSV = {
              filters: [
                {
                  field: 'filial',
                  value: [Number(sessionStorage.getItem('subsidiary'))],
                  operation: 'IN',
                },
              ],
              orders: currentOrders,
              page: 0,
              size: 10,
            };
            const responseCSV = await api.post(
              `/delivery/csvMapping/pageable?lang=${emailSendLanguage}`,
              dataCSV
            );

            const arrData = responseCSV.data.content.elements.map(
              (item: any, index: number) => {
                return {
                  ...item,
                  id: index + 1,
                  active: false,
                  activeAll: false,
                  cpf: '',
                  vehicleId: '',
                  deliveryOrder: index + 1,
                };
              }
            );

            const dataValue = {
              headers: responseCSV.data.content.headers,
              elements: arrData,
            };

            dispatch(getData(dataValue));

            FrontendNotification(
              'Upload realizado com sucesso!',
              NotificationType.SUCCESS
            );
            setFilesPreview({});

            setTimeout(() => {
              history.push('/cargosFormation');

              window.location.reload();
              setLoading(false);
            }, 2000);
          } else {
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          FrontendNotification(t('errors.upload'), NotificationType.ERROR);
        }
      } else {
        setLoading(false);
        FrontendNotification(t('errors.upload'), NotificationType.ERROR);
      }
    }
  }, []);

  useEffect(() => {
    getSubsidiary();
  }, [getSubsidiary]);

  return (
    <div>
      <Loading loading={loading} />
      <TopBar
        listSubsidiary={subsidiary}
        getSubsidiary={(subsidiaryCurrent: number) =>
          setCurrentSubsidiary(subsidiaryCurrent)
        }
      />

      <div className='previewForm'>
        <PreviewForm
          files={filesPreview}
          subsidiaryCurrent={currentSubsidiary}
          onSubmit={() => handleSubmit(filesUpload, typeUpload)}
          onUpload={handleUpload}
          onReset={() => {
            setFilesPreview([]);
            setFiles([]);
          }}
        />
      </div>
    </div>
  );
};

export default UploadCSV;
