import FilterIcon from 'assets/images/filterIcon.svg';
import PointInterestIcon from 'assets/images/pointInterestIcon.svg';
import PointInterestIconActive from 'assets/images/pointInterestIconActive.svg';
import CustomFilterDialog from 'components/CustomFilterDialog';
import Leaflet from 'leaflet';

// import PointInterestIconActive from 'assets/images/pointInterestIconActive.svg';
import { Cached } from '@mui/icons-material';
import TruckIcon from 'assets/images/map/truck.svg';
import { SearchOperation } from 'components/Grid/types';
import { IPointInterest } from 'components/PointInterest/types/types';
import { Branch } from 'components/SubsidiaryRegister/interfaces/types';
import { createCustomMarkerIcon } from 'components/TypePointInterest/icons';
import Loading from 'core/common/Loading';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import 'leaflet/dist/leaflet.css';
import DetailsPointsInterest from 'pages/ControlTower/GetRoutes/components/DetailsPointsInterest';
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LayerGroup, Map, Marker, Popup, TileLayer } from 'react-leaflet';
import Select from 'react-select';
import api from 'services/api';
import getIconVehicle from 'shared/IconVehicles';
import Carousel from './components/Carousel';
import { IShipmentPoints } from './types/types';
// import { Container } from './styles';
const Dashboard: React.FC = () => {
  const [initialPosition, setInitialPosition] = useState<any>([
    -8.0248893,
    -34.8917043,
  ]);
  const [zoom, setZoom] = useState<number>(14);
  const [shipments, setShipments] = useState<IShipmentPoints[]>([]);
  const [shipmentsData, setShipmentsData] = useState([]);
  const [shipmentValue, setShipmentValue] = useState<any>();
  const [isShowPointsInterest, setIsShowPointsInterest] = useState<boolean>(
    false
  );
  const [points, setPoints] = useState([]);
  const [detailsPointsInterest, setDetailsPointsInterest] = useState<
    IPointInterest[]
  >([]);

  const [detailShipment, setDetailShipment] = useState([]);

  const [branchs, setBranchs] = useState([]);
  const [branchValue, setBranchValue] = useState()

  const [modalFilters, setModalFilters] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  let mapRef: any = useRef();

  const applyOffset = (markers: any) => {
    const offsetFactor = 0.0001; // Ajuste o valor para o quanto quer separar

    return markers.map((marker: any, index: any, array: any) => {
      // Conta quantos marcadores estão na mesma posição
      const samePositionMarkers = array.filter(
        (m: any) =>
          m.latitude === marker.latitude && m.longitude === marker.longitude
      );

      // Se tiver mais de um marcador na mesma posição, aplica o offset
      if (samePositionMarkers.length > 1) {
        const angle = (360 / samePositionMarkers.length) * index;
        const radians = (angle * Math.PI) / 180;

        return {
          ...marker,
          latitude: marker.latitude + Math.cos(radians) * offsetFactor,
          longitude: marker.longitude + Math.sin(radians) * offsetFactor,
        };
      }

      return marker;
    });
  };

  const getShipmentByVehicle = useCallback(async () => {
    try {
      setLoading(true);
      const filters = sessionStorage.getItem('data::shipment');

      let dataFilters: any = null;

      if (filters) {
        dataFilters = JSON.parse(filters);
      }

      console.log()
      if (dataFilters['dtFinal'].length > 0) {
        dataFilters['dtFinal'] = `${dataFilters['dtFinal']}T23:59:59`;
      }

      if (dataFilters['dtInicial'].length > 0) {
        dataFilters['dtInicial'] = `${dataFilters['dtInicial']}T00:00:00`;

        const finalDate = new Date();

        dataFilters['dtFinal'] = `${format(finalDate, 'yyyy-MM-dd', { locale: ptBR })}T23:59:59`;
      }


      const filtersCurrent = {
        romaneiosPendentes: dataFilters['romaneiosPendentes'],
        romaneiosFinalizados: dataFilters['romaneiosFinalizados'],
        nomeCliente: dataFilters['nomeCliente'],
        placa: dataFilters['placa'],
        nomeMotorista: dataFilters['nomeMotorista'],
        numeroCte: dataFilters['numeroCte'],
        idReferencia: dataFilters['idReferencia'],
        operadorLogistico: dataFilters['operadorLogistico'],
      }

      const subsidiary = sessionStorage.getItem('subsidiary');

      const body = {
        filter: dataFilters['dtInicial'].length > 0 ||
          dataFilters['dtFinal'].length > 0 ? dataFilters : filtersCurrent,
      };
      
      const response = await api.post(
        `/delivery/kpis/findShipmentByVeiculo/${subsidiary}`,
        body
      );

      const mappingResponse = response.data.map((item: IShipmentPoints) => {
        return {
          label: item.placa,
          value: item.id,
        };
      });
      if (response.status) {
        setShipments(response.data);
        setShipmentsData(mappingResponse);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getPointInterest = useCallback(async () => {
    try {
      setLoading(true);
      let branchs: any = sessionStorage.getItem('subsidiaryList');
      if (branchs) {
        branchs = JSON.parse(branchs);
      }

      // Pega a filial atual
      const branchId = sessionStorage.getItem('subsidiary');

      // Captura a filial atual pelo id
      const findBranchById: Branch = branchs.find((item: Branch) => String(item.id) == branchId);

      if(findBranchById) {
        setInitialPosition([findBranchById?.endereco.latitude, findBranchById?.endereco.longitude]);
        setZoom(30);
      }

      if (findBranchById && findBranchById !== null) {
        const response = await api.get(
          `/delivery/pointInterest/findLocationsWithinRadius?latitude=${
            findBranchById.endereco.latitude
          }&longitude=${findBranchById?.endereco.longitude}&radius=${100000}`
        );
        setPoints(applyOffset(response.data));
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const findPointInterestById = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const body = {
        filters: [
          {
            field: 'id',
            value: id,
            operation: SearchOperation.EQUAL,
          },
        ],
      };
      const response = await api.post(
        '/delivery/pointInterest/findByFilters',
        body
      );
      if (response.status === 200) {
        setDetailsPointsInterest(response.data);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const findShipmentsById = useCallback(async (id: number) => {
    try {
      setLoading(true);
      const body = {
        filters: [
          {
            field: 'id',
            value: id,
            operation: SearchOperation.EQUAL,
          },
        ],
      };
      const response = await api.post(
        '/delivery/romaneios/findByFilters',
        body
      );
      if (response.status === 200) {
        setDetailShipment(response.data);
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const getBranchs = useCallback(async () => {
    try {
      const body = {
        filters: [{ field: 'status', value: 'ACTIVE', operation: 'EQUAL' }],
      };

      const response = await api.post('/cadastro/filiais/findByFilters', body);

      let mappingResponse = response.data.map((item: Branch) => {
        return {
          label: item.nome,
          value: item.id
        }
      });

      mappingResponse.unshift({
        label: "Todos",
        value: "-1"
      });

      const branch = sessionStorage.getItem('subsidiary');

      const findBranchById = mappingResponse.find((item: any) => item.value == branch);

      setBranchValue(findBranchById);

      setBranchs(mappingResponse);
    } catch (error) {}
  }, []);

  const customStyles = {
    control: (styles: any) => ({
      ...styles,
      minWidth: '180px !important',
      height: '30px',
      marginTop: '4px',
      marginRight: '4px',
      border: '2px solid rgba(0, 48, 73, 0.2)',
      borderRadius: 10,
    }),
  };

  const getPositionIcon = (data: IShipmentPoints) => {
    return new Leaflet.Icon({
      iconUrl:
        shipments.length === 0
          ? TruckIcon
          : getIconVehicle(data.tipo, false, false, ''),
      iconSize: [40, 40],
      // shadowSize:   [50, 64], // size of the shadow
      iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
      // shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
    });
  };

  // const pinIcon =
  useEffect(() => {
    getShipmentByVehicle();
    getPointInterest();
    getBranchs();
  }, [getShipmentByVehicle, getPointInterest, getBranchs]);

  return (
    <div className="tw-relative tw-w-full tw-h-full">
      <Loading loading={loading} />
      <CustomFilterDialog
      isViewMap
        open={modalFilters}
        onClose={() => setModalFilters(!modalFilters)}
        data={sessionStorage.getItem('component::shipment')}
        dataComponent={sessionStorage.getItem('data::shipment')}
        component="data::shipment"
        onSendFilter={() => {
          setModalFilters(!modalFilters);
          getShipmentByVehicle();
          setShipmentValue({});
        }}
        onCancel={() => {}}
        onFilter={() => {}}
      >
        <React.Fragment>
          <></>
        </React.Fragment>
      </CustomFilterDialog>
      <Map
        ref={(m: any) => {
          mapRef = m;
        }}
        center={initialPosition}
        zoom={zoom}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <LayerGroup>
          {shipments.map((item: IShipmentPoints) => (
            <Marker
              key={item.id}
              position={[item.latitude, item.longitude]}
              icon={getPositionIcon(item)}
              onclick={e => {
                findShipmentsById(item.id);
              }}
            >
              <Popup className='tw-w-[300px]'>
                <Carousel data={detailShipment} />
              </Popup>
            </Marker>
          ))}
        </LayerGroup>
        {isShowPointsInterest && (
          <Fragment>
            {points.map((item: any) => (
              <Marker
                key={item.id}
                position={[item.latitude, item.longitude]}
                icon={createCustomMarkerIcon(item.typePointInterest.icon)}
                onClick={(e: any) => {
                  findPointInterestById(item.id);
                }}
              >
                <Popup className="tw-w-[300px] tw-top-[-190px] tw-min-h-28">
                  <DetailsPointsInterest data={detailsPointsInterest} />
                </Popup>
              </Marker>
            ))}
          </Fragment>
        )}
      </Map>
      <div
        className="tw-absolute tw-top-3 tw-right-0 tw-p-3"
        style={{ zIndex: 1000 }}
      >
        <div className="tw-w-auto tw-h-11 tw-bg-[#6369D1] tw-rounded-md">
          <div className="tw-flex tw-w-full tw-h-full tw-items-center">
            <div className="tw-flex tw-items-center tw-ml-3 tw-mb-1 tw-w-full">
              <div className="tw-w-full tw-cursor-pointer tw-mr-2">
                <img
                  src={FilterIcon}
                  onClick={() => setModalFilters(!modalFilters)}
                />
              </div>
              <div
                className="tw-w-full tw-cursor-pointer tw-mr-2"
                onClick={() => setIsShowPointsInterest(!isShowPointsInterest)}
              >
                {isShowPointsInterest ? (
                  <img src={PointInterestIconActive} />
                ) : (
                  <img src={PointInterestIcon} />
                )}
              </div>
              <div className="tw-w-full">
                <Select
                  id="driver"
                  styles={customStyles}
                  options={shipmentsData}
                  onChange={(text: any) => {
                    setShipmentValue(text);
                    const getShipmentById:
                      | IShipmentPoints
                      | undefined = shipments.find(
                      (item: IShipmentPoints) => item.id === text.value
                    );
                    if (getShipmentById) {
                      setInitialPosition([
                        getShipmentById.latitude,
                        getShipmentById.longitude,
                      ]);
                      setZoom(45);
                    }
                  }}
                  placeholder="Buscar Veículo"
                  value={shipmentValue}
                />
              </div>
              <div className="tw-w-full">
                <Select
                  id="driver"
                  styles={customStyles}
                  options={branchs}
                  value={branchValue}
                  onChange={(text: any) => {
                    setBranchValue(text);
                    sessionStorage.setItem("subsidiary", text.value);
                    getShipmentByVehicle();
                    getPointInterest();
                  }}
                  placeholder="Buscar Filial"
                />
              </div>
              <div className='tw-w-full'>
                  <button className='tw-border-none tw-bg-[#6369D1] tw-mr-2 tw-ml-2' onClick={() => getShipmentByVehicle()}>
                    <Cached style={{ fill:'#FFF' }} />
                  </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
