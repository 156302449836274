import { object, string } from 'yup';

export default object().shape({
  description: string().required('* Campo Obrigatório!'),
  endereco_logradouro: string().required('* Campo Obrigatório!'),
  endereco_cidade: string().required('* Campo Obrigatório!'),
  endereco_bairro: string().required('* Campo Obrigatório!'),
  endereco_uf: string().required('* Campo Obrigatório!'),
  endereco_cep: string().required('* Campo Obrigatório!'),
  id_type_point_interest: string().required('* Campo Obrigatório!'),
});
