import FilterListIcon from '@mui/icons-material/FilterList';
import CheckBox from 'components/CustomFilterDialog/Filters/CheckBox';
import CustomSelectVehicles from 'components/CustomSelectVehicles';
import InputCustom from 'components/Input';
import SelectCustom from 'components/SelectCustom';
import { Path } from 'constants/path';
import { useFormik } from 'formik';
import { BranchSchema } from 'pages/CargoCreate/components/ModalFilter/FormFilter';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import validation from './validators/validation';

interface FormValues {
  filial: string;
  dataCriacaoInicial: string;
  dataCriacaoFinal: string;
  dataFechamentoInicial: string;
  dataFechamentoFinal: string;
  numero: string;
  transportadora: string;
  tipoVeiculo: string;
  descricao: string;
  isChanged: boolean | null;
}

interface Props {
  onConfirm: () => void;
}

const FormFilters: React.FC<Props> = (props: Props) => {
  const [branches, setBranches] = useState<BranchSchema[]>([]);

  const { t } = useTranslation();

  const onHandleFilters = useCallback((values: FormValues) => {
    sessionStorage.setItem('shippingAudit', JSON.stringify(values));

    props.onConfirm();
  }, []);

  const onLoadFormValues = useCallback(() => {
    let filtersData: any = sessionStorage.getItem('shippingAudit');

    if (filtersData) {
      filtersData = JSON.parse(filtersData);
    }

    if (filtersData) {
      formik.setFieldValue('filial', filtersData.filial);
      formik.setFieldValue(
        'dataCriacaoInicial',
        filtersData.dataCriacaoInicial
      );
      formik.setFieldValue('dataCriacaoFinal', filtersData.dataCriacaoFinal);
      formik.setFieldValue(
        'dataFechamentoInicial',
        filtersData.dataFechamentoInicial
      );
      formik.setFieldValue(
        'dataFechamentoFinal',
        filtersData.dataFechamentoFinal
      );
      formik.setFieldValue('numero', filtersData.numero);
      formik.setFieldValue('transportadora', filtersData.transportadora);
      formik.setFieldValue('tipoVeiculo', filtersData.tipoVeiculo);
      formik.setFieldValue('descricao', filtersData.descricao);
      formik.setFieldValue('isChanged', filtersData.isChanged);
    }
  }, []);

  const initialValues: FormValues = {
    filial: '',
    dataCriacaoInicial: '',
    dataCriacaoFinal: '',
    dataFechamentoInicial: '',
    dataFechamentoFinal: '',
    numero: '',
    transportadora: '',
    tipoVeiculo: '',
    descricao: '',
    isChanged: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: (values: FormValues) => onHandleFilters(values),
  });

  const getBranch = useCallback(async () => {
    try {
      const response = await api.get(Path.REGISTRATION_BRANCH);

      setBranches(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getBranch();
  }, [getBranch]);

  useEffect(() => {
    onLoadFormValues();
  }, [onLoadFormValues]);

  return (
    <div>
      <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-between">
        <div className="tw-flex tw-items-center tw-gap-2">
          <FilterListIcon style={{ color: '#535669' }} />
          <span>{t('filters')}</span>
        </div>
        <div>
          <span
            className="tw-text-sm tw-text-[#EA004C] tw-font-normal tw-cursor-pointer"
            onClick={() => formik.resetForm({})}
          >
            {t('action.clearFilters')}
          </span>
        </div>
      </div>

      <div className="tw-mt-4 tw-mb-2 tw-w-full tw-h-full">
        <div className="tw-grid tw-grid-cols-3 tw-gap-3">
          <div>
            <SelectCustom
              onChange={formik.handleChange('filial')}
              error={formik.errors.filial}
              touched={formik.touched.filial}
              value={formik.values.filial}
              title="Filial de expedição"
            >
              <Fragment>
                <option value="">{t('action.select')}</option>
                {branches.length > 0 &&
                  branches.map((data: BranchSchema, index: number) => (
                    <option value={data.id} key={index}>
                      {data.nome}
                    </option>
                  ))}
              </Fragment>
            </SelectCustom>
          </div>
          <div>
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataCriacaoInicial')}
              error={formik.errors.dataCriacaoInicial}
              touched={formik.touched.dataCriacaoInicial}
              value={formik.values.dataCriacaoInicial}
              placeholder=""
              title="Criação do Romaneio - Início"
            />
          </div>
          <div>
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataCriacaoFinal')}
              error={formik.errors.dataCriacaoFinal}
              touched={formik.touched.dataCriacaoFinal}
              value={formik.values.dataCriacaoFinal}
              placeholder=""
              title="Criação do Romaneio - Final"
            />
          </div>
          <div>
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataFechamentoInicial')}
              error={formik.errors.dataFechamentoInicial}
              touched={formik.touched.dataFechamentoInicial}
              value={formik.values.dataFechamentoInicial}
              placeholder=""
              title="Fechamento - Início"
            />
          </div>
          <div>
            <InputCustom
              type="date"
              onChange={formik.handleChange('dataFechamentoFinal')}
              error={formik.errors.dataFechamentoFinal}
              touched={formik.touched.dataFechamentoFinal}
              value={formik.values.dataFechamentoFinal}
              placeholder=""
              title="Fechamento - Final"
            />
          </div>
          <div>
            <InputCustom
              type="text"
              onChange={formik.handleChange('numero')}
              error={formik.errors.numero}
              touched={formik.touched.numero}
              value={formik.values.numero}
              placeholder=""
              title="Nº do Romaneio"
            />
          </div>
          <div>
            <InputCustom
              type="text"
              onChange={formik.handleChange('transportadora')}
              error={formik.errors.transportadora}
              touched={formik.touched.transportadora}
              value={formik.values.transportadora}
              placeholder=""
              title="Transportadora"
            />
          </div>
          <div>
            <CustomSelectVehicles
              fieldId="selectType"
              onChange={(type: string) =>
                formik.setFieldValue('tipoVeiculo', type)
              }
              value={formik.values.tipoVeiculo}
              isView={false}
            />
          </div>
          <div>
            <InputCustom
              type="text"
              onChange={formik.handleChange('descricao')}
              error={formik.errors.descricao}
              touched={formik.touched.descricao}
              value={formik.values.descricao}
              placeholder=""
              title="Descrição da Tabela"
            />
          </div>
          <div>
            <CheckBox
              label="Registros alterados"
              active={formik.values.isChanged}
              onChecked={() => {
                if (formik.values.isChanged === null) {
                  formik.setFieldValue('isChanged', true);
                } else {
                  formik.setFieldValue('isChanged', !formik.values.isChanged);
                }
              }}
              id=""
              styles={{
                fontSize: '0.820rem',
              }}
            />
          </div>
        </div>
        <div className="tw-flex tw-w-full tw-justify-end">
          <button
            className="tw-flex tw-items-center tw-justify-center tw-p-3 tw-text-sm tw-font-semibold tw-text-[#FFF] tw-border-none tw-bg-[#EA004C] tw-h-9 tw-rounded-md tw-w-32"
            onClick={() => formik.handleSubmit()}
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default FormFilters;
