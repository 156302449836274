import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import dataAlert from '../../pages/ControlTower/components/Alert/data/data.json';
import dataShipment from '../../pages/ControlTower/data/data.json';
import Filters from './Filters';
import { CloseModal, Container, Title } from './styles';

export interface DataComponentField {
  label: string;
  field: string;
  placeholder: string;
  type: string;
  value: any;
  groups: DataComponentField[];
  filter: boolean;
}

interface CustomFilterDialogProps {
  data: string | null;
  dataComponent?: string | null;
  open: boolean;
  component: string;
  onClose: () => void;
  onSendFilter: () => void;
  onCancel: () => void;
  onFilter: () => void;
  children: JSX.Element;
  isViewMap?: boolean;
}

const CustomFilterDialog: React.FC<CustomFilterDialogProps> = ({
  open,
  onClose,
  data,
  dataComponent,
  component,
  onSendFilter,
  onCancel,
  children,
  isViewMap
}) => {
  const [dataJson, setDataJson] = useState<DataComponentField[]>([]);
  const [dataJsonComponent, setDataJsonComponent] = useState();

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiPaper-root': {
      borderRadius: '0.313rem',
      width: '400px',
    },
    '& .MuiDialogTitle-root': {
      background: '#EA004C',
      height: '2rem',
      padding: '0.313rem',
      borderRadius: '0.313rem 0.313rem 0 0',
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

  const onChangeFieldFilter = useCallback(
    (field: string, value: string, dataJson: DataComponentField[]) => {
      let data: DataComponentField[] = dataJson;

      const findDataByField = data
        .map((el: any, index: number) => (el.field === field ? index : -1))
        .filter((elFilter: any) => elFilter !== -1);

      data[findDataByField[0]].value = value;

      setDataJson(data);

      console.log(data);
    },
    []
  );

  useEffect(() => {
    if (data) {
      setDataJson(JSON.parse(data));
    }
    if(dataComponent) {
      setDataJsonComponent(JSON.parse(dataComponent));
    }
  }, [dataComponent, data]);

  return (
    <Dialog onClose={onClose} open={open}>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title">
          <Container>
            <FilterListIcon style={{ color: 'white' }} fontSize="medium" />
            <Title>Filtros</Title>

            <CloseModal onClick={onClose}>
              <CloseIcon style={{ color: 'white' }} />
            </CloseModal>
          </Container>
        </DialogTitle>
        <DialogContent>
          <Filters
            isViewMap={isViewMap}
            filtersData={dataJson}
            data={dataJsonComponent}
            onClearFilter={() => {
              const getDataFilters = dataJson.map((item: any) => {
                return {
                  ...item,
                  value: ""
                }
              });

              const dataFields: any = component === "data::alert" ? dataAlert : dataShipment;

              const dataJSONFileds = JSON.stringify(dataFields);

              const dataComponentField = JSON.parse(dataJSONFileds);

              sessionStorage.setItem(component, dataJSONFileds);
              setDataJsonComponent(dataComponentField);

              setDataJson(getDataFilters);

            }}
            onCancel={onClose}
            onFilter={() => {
              sessionStorage.setItem(component, JSON.stringify(dataJsonComponent));

              onSendFilter();
            }}
          />
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </Dialog>
  );
};

export default CustomFilterDialog;
