import { Content } from 'components/Driver/styles';
import InputCustom from 'components/Input';
import Loading from 'core/common/LoadingRoutering';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';
import api from 'services/api';
import CustomModal from '../ModalCustom';
import { DriverShiftTitle } from './styles';

interface DriverShift {
  turno: {
    horarioInicial: string;
    horarioFinal: string;
  };
}

interface Props {
  onClear?: any;
  driverId?: number;
  description?: string | null;
  onUpdate: () => void;
  idRouteOptmi?: number;
}

const RouteringDetais: React.FC<Props> = ({ onClear, driverId, idRouteOptmi, description, onUpdate }: Props) => {
  const [vehiclesData, setVehiclesData] = useState([]);
  const [driverData, setDriverData] = useState([]);
  const [hoursInitial, setHoursInitial] = useState('');
  const [hoursFinal, setHoursFinal] = useState('');
  const [descriptionRouteOptmi, setDescriptionRouteOptmi] = useState(description !== null && description && description.length > 0 ? description : '');
  const [loading, setLoading] = useState(false);
  const [parameters, setParameters] = useState<any[]>([]);

  console.log("Parameter Description", description);

  const getDriverShift = useCallback(async () => {
    try {
      const dataShift = {
        filters: [
          {
            field: 'id',
            value: driverId,
            operation: 'EQUAL',
          },
        ],
        page: 0,
        size: 10,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/pageable',
        dataShift
      );
      setHoursInitial(response.data.content[0].turno.horarioInicial);
      setHoursFinal(response.data.content[0].turno.horarioFinal);
    } catch (err) {}
  }, [driverId]);

  const getDriverDetails = useCallback(async () => {
    try {
      const data = {
        filters: [
          {
            field: 'motorista.id',
            value: driverId,
            operation: 'EQUAL',
          },
        ],
        page: 0,
        size: 10,
        orders: [],
      };

      const response = await api.post(
        '/delivery/motoristas/horariosPausa/pageable',
        data
      );

      setDriverData(response.data.content);
    } catch (err) {}
  }, [driverId]);

  const onUpdateDescriptionRoute = useCallback(async (descriptionOptmi: any) => {
    try {
      setLoading(true);
      const data = {
        description: descriptionOptmi,
      };

      const response = await api.patch(`/route/optmis/description/${idRouteOptmi}`, data);

      FrontendNotification('Descrição da rota atualizada com sucesso!', NotificationType.SUCCESS);

      onUpdate();

      setLoading(false);
    }catch{}
  }, []);

  const getParameters = useCallback(async () => {
    try {
      const response = await api.get('/parameters/routing');

      setParameters(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    getDriverDetails();
    getDriverShift();
    getParameters();
  }, [getDriverDetails, getDriverShift, getParameters]);

  return (
    <CustomModal
      isOpen={true}
      styles={{ maxWidth: '50%', height: '90%' }}
      label="Mais Detalhes"
      isClose={onClear}
    >
      <Loading loading={loading} />

      <DriverShiftTitle>Turno:</DriverShiftTitle>

      <Content style={{ marginTop: 10, marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                Horario Inicio
                {/* <Trans i18nKey="driverShift.fields.description" /> */}
              </th>
              <th>
                Horario Final
                {/* <Trans i18nKey="driverShift.fields.interval" /> */}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="lineGroup">
              <td>{hoursInitial.substring(0, 5)}</td>
              <td>{hoursFinal.substring(0, 5)}</td>
            </tr>
          </tbody>
        </table>
      </Content>

      <DriverShiftTitle>Intervalos:</DriverShiftTitle>

      <Content style={{ marginTop: 10, marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                <Trans i18nKey="driverShift.fields.description" />
              </th>
              <th>
                <Trans i18nKey="driverShift.fields.interval" />
              </th>
            </tr>
          </thead>
          <tbody>
            {driverData.map((item: any) => (
              <tr className="lineGroup">
                <td>{item.descricao}</td>
                <td>
                  {`${item.intervalo.horarioInicial.substring(
                    0,
                    5
                  )} - ${item.intervalo.horarioFinal.substring(0, 5)}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Content>

      <DriverShiftTitle>Jornada Motorista:</DriverShiftTitle>

      <Content style={{ marginTop: 10, marginBottom: 10 }}>
        <table>
          <thead>
            <tr>
              <th>
                Horario Inicio
                {/* <Trans i18nKey="driverShift.fields.description" /> */}
              </th>
              <th>
                Horario Final
                {/* <Trans i18nKey="driverShift.fields.interval" /> */}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="lineGroup">
              <td>{parameters.length > 0 && parameters[0].driverTimeWindow && parameters[0].driverTimeWindow  !== null && parameters[0].driverTimeWindow.split(',')[0]}</td>
              <td>{parameters.length > 0 && parameters[0].driverTimeWindow && parameters[0].driverTimeWindow !== null && parameters[0].driverTimeWindow.split(',')[1]}</td>
            </tr>
          </tbody>
        </table>
      </Content>

      <DriverShiftTitle>Descrição da rota:</DriverShiftTitle>
      <Row>
        <Col md={10}>
          <InputCustom
            type="text"
            placeholder="Insira a descrição da rota"
            onChange={(e: any) => setDescriptionRouteOptmi(e.target.value)}
            onBlur={() => onUpdateDescriptionRoute(descriptionRouteOptmi)}
            value={descriptionRouteOptmi}
          />
        </Col>
      </Row>
    </CustomModal>
  );
};

export default RouteringDetais;
