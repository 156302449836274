import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomModal from '../ModalCustom';
import axios from 'axios';
import { searchAddress } from '../../services/endpoint';
import { IState, Props } from './types/types';
import InputCustom from '../Input';
import { motion } from 'framer-motion';
import { List, ListItem } from '@mui/material';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Leaflet, { LeafletMouseEvent } from 'leaflet';
import MapIcon from 'assets/images/mapPin.svg';
import { Trans } from 'react-i18next';
import ReactLoading from 'react-loading';
import {
  FrontendNotification,
  NotificationType,
} from '../../core/common/Notification';
import { States } from '../../util/monitoring';
import api from '../../services/api';
import ModalConfirm from './ModalConfirm';

const SearchAddress: React.FC<Props> = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [results, setResults] = useState<any[]>([]);
  const [dataCoords, setDataCoords] = useState<any>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const [initialPosition, setInitialPosition] = useState<[number, number]>([
    -8.0527,
    -34.9099,
  ]);
  const [zoom, setZoom] = useState<number>(35);
  const [position, setPosition] = useState<any>([]);

  const [addressText, setAddressText] = useState<string>('');
  const [display, setDisplay] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);

  const resultsRef: any = useRef(null);

  const handleSubmit = useCallback(
    async (dataCoords: any, positions: any, data: any) => {
      try {
        setLoadingSubmit(true);

        const addressStreet =
          dataCoords && dataCoords.address
            ? dataCoords.address.road || dataCoords.address.natural
            : data[0].enderecoLogradouro;
        const addressZipcode =
          dataCoords && dataCoords.address && dataCoords.address.postcode
            ? dataCoords.address.postcode.replaceAll('-', '')
            : data[0].addressZipcode;

        const addressNeighborhood =
          dataCoords && dataCoords.address && dataCoords.address.suburb
            ? dataCoords.address.suburb
            : data[0].enderecoBairro;

        if (positions.length === 0) {
          const message =
            navigator.language === 'pt' || navigator.language === 'pt-BR'
              ? 'Selecione o ponto no mapa'
              : 'Select the point on the map';
          FrontendNotification(message, NotificationType.ERROR);
        } else {
          const stateAddress =
            dataCoords && dataCoords.address && dataCoords.address.state
              ? dataCoords.address.state
              : data[0].estado;


          const requestData = [
            {
              enderecoLogradouro: addressStreet,
              enderecoNumero: data[0].addressNumber,
              enderecoBairro: addressNeighborhood,
              enderecoCidade:
                dataCoords && dataCoords.address && dataCoords.address
                  ? dataCoords.address.city ||
                    dataCoords.address.city_district ||
                    dataCoords.address.town
                  : data[0].addressCity,
              enderecoUf: States.find((itemState: IState) =>
                dataCoords && dataCoords.address
                  ? itemState.nome === stateAddress
                  : itemState.sigla === stateAddress
              )?.sigla,
              enderecoCep: addressZipcode,
              enderecoLatitude: positions[0],
              enderecoLongitude: positions[1],
              status: '100',
            },
          ];

          const body = {
            street: requestData[0].enderecoLogradouro,
            neighborhood: requestData[0].enderecoBairro,
            city: requestData[0].enderecoCidade,
            state: requestData[0].enderecoUf,
            number: requestData[0].enderecoNumero,
            zipCode: requestData[0].enderecoCep,
            latitude: requestData[0].enderecoLatitude,
            longitude: requestData[0].enderecoLongitude,
          };

          const response = await api.patch(
            `/delivery/conhecimentosPre/addresses/${data[0].id}`,
            body
          );

          setLoadingSubmit(false);
          props.onClose();
          props.onConfirm(props.data, requestData);
        }
      } catch {}
    },
    []
  );

  const onSearch = useCallback(async (text: string) => {
    try {
      setLoading(true);
      let data: any = [];

      const response = await axios.get(
        `${searchAddress}/?addressdetails=1&q=${text}&format=json`
      );

      const mappingResponse = response.data.map((item: any) => {
        return {
          label: item.display_name,
          value: item.display_name,
          lat: item.lat,
          lon: item.lon,
          ...item,
        };
      });

      setResults(mappingResponse);
      setDataCoords(response.data[0]);
      setDisplay(!display);

      setLoading(false);
    } catch {}
  }, []);

  const getLoadFormValues = () => {
    setAddressText(
      `${props.data[0].addressStreet.trim()},${
        props.data[0].addressNumber
      },${props.data[0].addressCity.trim()},${props.data[0].addressState.trim()}`
    );

    if (
      props.data[0].enderecoLatitude.length > 0 &&
      props.data[0].enderecoLongitude.length > 0
    ) {
      setPosition([
        props.data[0].enderecoLatitude,
        props.data[0].enderecoLongitude,
      ]);
      setInitialPosition([
        props.data[0].enderecoLatitude,
        props.data[0].enderecoLongitude,
      ]);
      setZoom(34);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setAddressText(e.target.value);
  };

  const handleSelect = (address: any) => {
    setAddressText(address.value);
    setResults([]);
    setDataCoords(address);
    setDisplay(false);
  };

  const pinIcon = new Leaflet.Icon({
    iconUrl: MapIcon,
    iconSize: [40, 40],
    iconAnchor: [20, 20],
    popupAnchor: [-30, -35],
  });

  console.log(props.data);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (resultsRef.current && !resultsRef.current.contains(event.target)) {
        setResults([]);
        setDisplay(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getLoadFormValues();
  }, []);

  return (
    <CustomModal
      isOpen
      label="Localizar endereço"
      styles={{ minWidth: '80%', width: '80%' }}
      isClose={props.onClose}
    >
      <div className="tw-w-full tw-h-full">
        {showConfirm && (
          <ModalConfirm
            onClear={() => setShowConfirm(!showConfirm)}
            onConfirm={() => {
              setShowConfirm(!showConfirm);
              handleSubmit(dataCoords, position, props.data);
            }}
          />
        )}
        <div className="tw-w-full tw-mb-4">
          <strong className="tw-text-sm tw-font-bold tw-text-[#000]">
            Endereço atual:
          </strong>
          <span className="tw-text-sm tw-font-medium tw-text-[#000] tw-ml-2">
            {props.data[0].addressStreet}, {props.data[0].addressNumber},{' '}
            {props.data[0].addressNeighborhood}, {props.data[0].addressCity},{' '}
            {props.data[0].addressState}
          </span>

          <span className="tw-text-sm tw-font-medium tw-text-[#000] tw-ml-2">
            <strong className="tw-text-sm tw-font-bold tw-text-[#000]">
              CEP:{' '}
            </strong>{' '}
            {props.data[0].addressZipcode}, {props.data[0].addressComplement}
          </span>
        </div>
        <div className="tw-w-full tw-flex tw-items-center">
          <div className="tw-w-full tw-relative">
            <div>
              <InputCustom
                placeholder=""
                title=""
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
                value={addressText}
              />
              {loading && (
                <div className="tw-absolute tw-right-2 tw-top-[10px]">
                  <ReactLoading
                    type="spin"
                    color="#EA004C"
                    height={20}
                    width={20}
                  />
                </div>
              )}
            </div>

            {results.length > 0 ? (
              <motion.div
                ref={resultsRef}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="tw-absolute tw-w-full tw-z-50"
              >
                <List
                  className="tw-bg-[#fff] tw-rounded-lg tw-border tw-max-h-[400px] tw-overflow-y-scroll"
                  style={{ border: '1px solid #ccc' }}
                >
                  {results.map((result, index: number) => (
                    <ListItem
                      key={index}
                      className="tw-w-full tw-p-2 tw-cursor-pointer hover:tw-bg-[#EA004C] hover:tw-text-[#fff]"
                      style={{}}
                      onClick={() => handleSelect(result)}
                    >
                      {result.label}
                    </ListItem>
                  ))}
                </List>
              </motion.div>
            ) : display ? (
              <motion.div
                ref={resultsRef}
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -10 }}
                className="tw-absolute tw-w-full tw-z-50"
              >
                <div
                  className="tw-bg-[#fff] tw-rounded-md tw-border tw-border-[#ccc] tw-p-4"
                  style={{ border: '1px solid #ccc' }}
                >
                  <span>No Search results.....</span>
                </div>
              </motion.div>
            ) : (
              <></>
            )}
          </div>

          <div className="tw-flex tw-items-center tw-w-1/3">
            <button
              className="tw-bg-[#EA004C] tw-text-sm tw-text-[#fff] tw-font-medium tw-border-none tw-rounded-md tw-p-2 tw-w-full tw-h-10 tw-ml-2 tw-mr-2 tw-flex tw-items-center tw-justify-center"
              type="button"
              onClick={() => onSearch(searchQuery)}
            >
              <Trans i18nKey="searchAddress.buttonSearch" />
            </button>
            <button
              className="tw-bg-[#EA004C] tw-text-xs tw-text-[#fff] tw-font-medium tw-border-none tw-rounded-md tw-p-2 tw-w-full tw-h-10 tw-mr-2 tw-flex tw-items-center tw-justify-center"
              type="button"
              onClick={() => {
                setPosition([dataCoords.lat, dataCoords.lon]);
                setInitialPosition([dataCoords.lat, dataCoords.lon]);
                setZoom(40);
              }}
            >
              <Trans i18nKey="filiais.buttons.location" />
            </button>
          </div>
        </div>
        <div className="tw-mt-4">
          <Map
            center={initialPosition}
            zoom={zoom}
            zoomControl
            className="tw-max-h-[400px] tw-cursor-pointer"
            onclick={(e: LeafletMouseEvent) => {
              const { lat, lng } = e.latlng;


              console.log(lat, lng);

              setPosition([lat, lng]);
            }}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {position.length > 0 && (
              <Marker position={position} icon={pinIcon} />
            )}
          </Map>
        </div>
        <div className="tw-w-full tw-h-full tw-flex tw-justify-end">
          <button
            className="tw-bg-[#EA004C] tw-text-sm tw-text-[#fff] tw-font-medium tw-border-none tw-rounded-md tw-p-2 tw-h-10 tw-ml-2 tw-mt-4 tw-w-1/5 tw-flex tw-items-center tw-justify-center"
            type="button"
            onClick={() => setShowConfirm(!showConfirm)}
          >
            Confirmar
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SearchAddress;
