import iconFilter from 'assets/images/filterTower.svg';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import api from 'services/api';
import CardKnowledge from './components/CardKnowledge';
import Filters from './components/Filters';
import { Cargo } from './interfaces/types';

interface Props {
  onClear: () => void;
  idRomaneio?: string;
  isAlert?: boolean;
  onPrintCargo?: (idCargo: string) => void;
  alertCargo?: boolean;
}

const ListAttendances: React.FC<Props> = (props: Props) => {
  const [knowledge, setKnowledge] = useState<any[]>([]);
  const [moreDetailsListKnowledge, setMoreDetailsListKnowledge] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const { t } = useTranslation();

  const getCargoData = useCallback(async () => {
    try {
      setLoading(true);
      const fieldFilter = props.alertCargo ? 'id' : 'romaneio.id';
      const body = {
        filters: [
          {
            field: fieldFilter,
            value: props.idRomaneio,
            operation: 'EQUAL',
          },
        ],
        orders: [],
        size: 1000,
        page: 0,
      };
      const { data } = await api.post('/delivery/conhecimentos/pageable', body);

      const orderLate = data.content
        .filter((op: any) => op.statusEntrega === 'ATRASADO')
        .sort((a: any, b: any) => a.ordemEntrega - b.ordemEntrega);
      const orderOnTime = data.content
        .filter((op: any) => op.statusEntrega === 'NO_PRAZO')
        .sort((a: any, b: any) => a.ordemEntrega - b.ordemEntrega);
      const orderCancelled = data.content
        .filter((op: any) => op.statusEntrega === 'CANCELADO')
        .sort((a: any, b: any) => a.ordemEntrega - b.ordemEntrega);
      const orderDone = data.content
        .filter((op: any) => op.statusEntrega === 'ENTREGUE')
        .sort((a: any, b: any) => a.ordemEntrega - b.ordemEntrega);
      const orderLoads = orderLate.concat(
        orderOnTime,
        orderCancelled,
        orderDone
      );

      setKnowledge(orderLoads);

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getCargoData();
  }, [getCargoData]);

  console.log('Romaneio', props.idRomaneio);
  return (
    <CustomModal
      isOpen
      label={t('listAttendances.title')}
      isClose={() => props.onClear()}
    >
      <>
        <Loading loading={loading} />
        <div style={{ display: 'flex' }}>
          {!props.isAlert && !filterVisible && (
            <button
              className="buttonFilterTower"
              type="button"
              onClick={e => {
                e.preventDefault();
                setFilterVisible(!filterVisible);
              }}
            >
              <img src={iconFilter} alt="" style={{ marginRight: 6 }} />
              <span className="buttonTextFilter">
                <Trans i18nKey="controlTower.buttonFilter" />
              </span>
            </button>
          )}

          {filterVisible && (
            <Filters
              romaneioId={props.idRomaneio}
              setData={(data: any) => setKnowledge(data)}
              onClose={() => setFilterVisible(!filterVisible)}
            />
          )}
        </div>
        <div className="tw-grid tw-grid-cols-3 tw-sm:grid-cols-2 tw-lg:grid-cols-4 tw-xl:grid-cols-4 gap-4 tw-items-start">
          {knowledge.map((k: Cargo, i: number) => (
            <CardKnowledge
              data={k}
              index={i}
              onReloadCargo={() => getCargoData()}
            />
          ))}
          {/* <CardKnowledge /> */}
        </div>
      </>
    </CustomModal>
  );
};

export default ListAttendances;
