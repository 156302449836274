import AccessControlIcon from 'assets/images/accessControlIcon.svg';
import LogoIntegrationSendGrid from 'assets/images/integrationsSendGrid.svg';
import classNames from 'classnames';
import Breaks from 'components/Breaks';
import Collaborator from 'components/Collaborator';
import IntegrationsAPI from 'components/IntegrationsAPI';
import IntegrationsBling from 'components/IntegrationsBling';
import IntegrationsSAP from 'components/IntegrationsSAP';
import IntegrationsSascar from 'components/IntegrationsSascar';
import IntegrationsSendGrid from 'components/IntegrationsSendGrid';

import CargoTypes from 'components/CargoTypes';
import Parameters from 'components/Parameters';
import PointInterest from 'components/PointInterest';
import Privacy from 'components/Privacy';
import ReasonsAlert from 'components/ReasonsAlert';
import ReasonsPoint from 'components/ReasonsPoint';
import Reports from 'components/Reports';
import Routering from 'components/Routering';
import ShippingAudit from 'components/ShippingAudit';
import TypeCollaborator from 'components/TypeCollaborator';
import TypePointInterest from 'components/TypePointInterest';
import WebHook from 'components/WebHooks';
import WindowDelivery from 'components/WindowDelivery';
import WorkingDay from 'components/WorkingDay';
import Region from 'pages/Delivery/components/Region';
import Route from 'pages/Delivery/components/Route';
import Sector from 'pages/Delivery/components/Sector';
import ShippingTable from 'pages/Delivery/components/ShippingTable';
import ZenviaSMS from 'pages/ZenviaSMS';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Collapse, Nav, NavItem, NavLink } from 'reactstrap';
import LogoIntegrationSAPB1 from '../../assets/images/Sap-b1-logo.svg';
import AccessControlIconActive from '../../assets/images/accessControlIconActive.svg';
import Image from '../../assets/images/app-logo-sidebar.svg';
import avatar from '../../assets/images/avatar.png';
import registerIconActive from '../../assets/images/cadastro.png';
import registerIcon from '../../assets/images/cadastro.svg';
import IconMenu from '../../assets/images/hamburguerMenu.png';
import iconMenuRoutering from '../../assets/images/iconMenuRoutering.svg';
import iconMenuRouteringActive from '../../assets/images/iconMenuRouteringActive.svg';
import IntegrationIcon from '../../assets/images/integrationsIcon.svg';
import IntegrationIconActive from '../../assets/images/integrationsIconActive.svg';
import JourneyIcon from '../../assets/images/journeyIcon.svg';
import JourneyIconActive from '../../assets/images/journeyIconActive.svg';
import LogoIntegrationBling from '../../assets/images/logoBling.svg';
import MonitoringIcon from '../../assets/images/monitoringIcon.svg';
import MonitoringIconActive from '../../assets/images/monitoringIconActive.svg';
import PrivacyIconActive from '../../assets/images/politicPrivacyIconActive.png';
import PrivacyIcon from '../../assets/images/politicPrivacyIconInactive.png';
import ReportIcon from '../../assets/images/reportIcon.svg';
import ReportIconActive from '../../assets/images/reportIconActive.svg';
import reportsActive from '../../assets/images/reportsActive.svg';
import reportsInactive from '../../assets/images/reportsInactive.svg';
import SettingsIcon from '../../assets/images/settingsIcon.svg';
import SettingsIconActive from '../../assets/images/settingsIconActive.svg';
import api from '../../services/api';
import history from '../../services/history';
import permissionMenu from '../../shared/Permission';
import { logout, updatePrimaryAccess } from '../../store/modules/auth/actions';
import ActivityType from '../ActivityType';
import AppLogo from '../AppLogo';
import Contractor from '../Contractor';
import Driver from '../Driver';
import ModalOperator from '../ModalOperator';
import Notices from '../Notices';
import { FrontendNotification, NotificationType } from '../Notification';
import Ocorrence from '../Ocorrence';
import Phases from '../Phases';
import Reasons from '../Reasons';
import RegisterUsers from '../RegisterUsers';
import RouteringSimulations from '../RouteringSimulations';
import SendNotification from '../SendNotification';
import SubsidiaryRegister from '../SubsidiaryRegister';
import TemplateModal from '../TemplateModal';
import Vehicles from '../Vehicles';
import VideoClasses from '../VideoClasses';

export default function SideBar({ isOpen, toggle, selectedPage }) {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const { t } = useTranslation();
  const [collapsable, setCollapsable] = useState(false);
  const [filiais, setFiliais] = useState([]);

  const [toogleMenu, setToogleMenu] = useState({
    register: false,
    operator: false,
    accessControl: false,
    yourCompany: false,
    clients: false,
    reports: false,
    dashboard: false,
    delivery: false,
    drivers: false,
    settings: false,
    integrations: false,
    routing: false,
    privacy: false,
    journey: false,
  });

  const [menuSelected, setMenuSelected] = React.useState('');

  const [collapsed, setCollapsed] = useState(false);
  const [permission, setPermission] = useState([]);
  const toggleNav = () => setCollapsed(!collapsed);

  const primeiroAcesso = useSelector(state => state.auth.primaryAccess);

  const userData = useSelector(state => state.auth.user);

  const setParamsReports = typeReports => {
    const paramsString = {
      'ds0.id_empresa': userData.company.id,
    };
    const paramsAsString = JSON.stringify(paramsString);
    let urlParams;
    if (typeReports === 'report.assertiveness') {
      urlParams = `https://datastudio.google.com/embed/reporting/cc2d036f-61bb-4666-98c4-e29e613d8830/page/9TsVC?params=${paramsAsString}`;
    } else if (typeReports === 'report.sla') {
      urlParams = `https://datastudio.google.com/embed/reporting/76433762-d91e-4ca7-bccb-76ae7be26770/page/PHkUC?params=${paramsAsString}`;
    } else if (typeReports === 'dashboard.assertiveness') {
      urlParams = `https://datastudio.google.com/embed/reporting/cc2d036f-61bb-4666-98c4-e29e613d8830/page/p_wat90etvlc?params=${paramsAsString}`;
    } else if (typeReports === 'dashboard.sla') {
      urlParams = `https://datastudio.google.com/embed/reporting/76433762-d91e-4ca7-bccb-76ae7be26770/page/d8tUC?params=${paramsAsString}`;
    }

    sessionStorage.setItem('urlParams', urlParams);

    history.push('/reports');

    window.location.reload();
    toggle();
    setCollapsable(true);
    setCollapsed(true);
  };

  const getUser = useCallback(async () => {
    try {
      setPermission(userData.grupo.permissoes);
    } catch (err) {}
  }, []);

  const getFiliais = useCallback(async () => {
    try {
      const response = await api.get('/cadastro/filiais');

      const filiaisFilter = response.data.filter(
        item => item.status === 'ACTIVE'
      );

      const convertJson = JSON.stringify(filiaisFilter);

      sessionStorage.setItem('subsidiaryList', convertJson);
      sessionStorage.setItem('subsidiary', filiaisFilter[0].id);

      setFiliais(filiaisFilter);
    } catch (err) {}
  }, []);

  let message = null;
  message = t('common.alert');

  useEffect(() => {
    setTimeout(() => {
      setCollapsable(isOpen);
    }, 250);
  }, [isOpen]);

  const returnModalRegister = useCallback(key => {
    if (key === 'register.cancelReason') {
      return <Reasons onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.notices') {
      return <Notices onClear={() => setMenuSelected('')} />;
    }

    if (key === 'register.notification') {
      return <SendNotification onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.activity') {
      return <ActivityType onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.contractor') {
      return <Contractor onClear={() => setMenuSelected('')} />;
    }

    if (key === 'register.exception') {
      return <Ocorrence onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.steps') {
      return <Phases onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.driver') {
      return <Driver onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.collaborator') {
      return <Collaborator onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.typeCollaborator') {
      return <TypeCollaborator onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.logisticOperator') {
      return <ModalOperator onClear={() => setMenuSelected('')} />;
    }
    if (key === 'accessControl.users') {
      return <RegisterUsers onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.subsidiary') {
      return <SubsidiaryRegister onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.videoLesson') {
      return <VideoClasses onClear={() => setMenuSelected('')} />;
    }

    if (key === 'register.vehicles') {
      return <Vehicles onClear={() => setMenuSelected('')} />;
    }

    if (key === 'register.template') {
      return <TemplateModal onClear={() => setMenuSelected('')} />;
    }

    if (key === 'register.create-window-delivery') {
      return <WindowDelivery onClear={() => setMenuSelected('')} />;
    }

    if(key === "register.create-parameters") {
      return <Parameters onClear={() => setMenuSelected('')} />
    }

    if (key === 'routing') {
      return <RouteringSimulations onClear={() => setMenuSelected('')} />;
    }
    if (key === 'routering') {
      return <Routering onClear={() => setMenuSelected('')} />;
    }
    if (key === 'integration.bling') {
      return <IntegrationsBling onClear={() => setMenuSelected('')} />;
    }
    if (key === 'integration.sap-b1') {
      return <IntegrationsSAP onClear={() => setMenuSelected('')} />;
    }
    if (key === 'integration.sendgrid') {
      return <IntegrationsSendGrid onClear={() => setMenuSelected('')} />;
    }
    if (key === 'integration.log') {
      return <IntegrationsAPI onClear={() => setMenuSelected('')} />;
    }
    if (key === 'integration.webhook') {
      return <WebHook onClear={() => setMenuSelected('')} />;
    }
    if (key === 'integration.sascar') {
      return <IntegrationsSascar onClear={() => setMenuSelected('')} />;
    }
    if (key === 'workday.journey') {
      return <WorkingDay onClear={() => setMenuSelected('')} />;
    }
    if (key === 'workday.break') {
      return <Breaks onClear={() => setMenuSelected('')} />;
    }
    if (key === 'workday.reason') {
      return <ReasonsPoint onClear={() => setMenuSelected('')} />;
    }
    if (key === 'reports') {
      return <Reports onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.collaborator') {
      return <Collaborator onClear={() => setMenuSelected('')} />;
    }
    if (key === 'register.typeCollaborator') {
      return <TypeCollaborator onClear={() => setMenuSelected('')} />;
    }
    if (key === 'settings.sms') {
      return <ZenviaSMS onClear={() => setMenuSelected('')} />;
    }

    if (key === 'register.shippingTable') {
      return <ShippingTable isClose={() => setMenuSelected('')} />;
    }

    if(key === 'register.audit-shipping') {
      return <ShippingAudit onClear={() => setMenuSelected('')} />
    }

    if (key === 'register.route') {
      return <Route isClose={() => setMenuSelected('')} />;
    }

    if (key === 'register.region') {
      return <Region isClose={() => setMenuSelected('')} />;
    }

    if (key === 'register.sector') {
      return <Sector isClose={() => setMenuSelected('')} />;
    }

    if(key === 'register.create-point-interest') {
      return <PointInterest onClear={() => setMenuSelected('')} />
    }

    if(key === 'register.create-type-point-interest') {
      return <TypePointInterest onClear={() => setMenuSelected('')} />
    }

    if(key === "register.cargo-types") {
      return <CargoTypes onClear={() => setMenuSelected('')} />
    }

    if (key === 'register.privacy') {
      return (
        <Privacy
          onClear={() => {
            setMenuSelected('');
            setToogleMenu({
              ...toogleMenu,
              privacy: false,
            });
          }}
        />
      );
    }
    if (key === 'register.create-reason-alert') {
      return <ReasonsAlert onClear={() => setMenuSelected('')} />;
    }
    FrontendNotification(t('common.permission'), NotificationType.ERROR);
  }, []);

  const SUBMENU = [
    {
      title: 'Operador Logistico',
    },
    {
      title: 'Mapa',
    },
  ];

  const CONTROL_MENU = [
    {
      isSelected: selectedPage === 'COMPANY',
    },
    {
      isSelected: selectedPage === 'MONITORING',
    },
    {
      isSelected: selectedPage === 'CUSTOMERS',
    },
    {
      isSelected: selectedPage === 'REPORTS',
    },
    {
      isSelected: selectedPage === 'DELIVERY',
    },
    {
      isSelected: selectedPage === 'COURIERS',
    },
    {
      isSelected: selectedPage === 'ACCESS',
      active: false,
    },
    {
      isSelected: selectedPage === 'SETTINGS',
    },
    {
      isSelected: selectedPage === 'INTEGRATION',
    },
    {
      isSelected: selectedPage === 'REGISTER',
      active: false,
    },
  ];

  function handleLogout() {
    sessionStorage.clear();
    dispatch(logout());
  }

  useEffect(() => {
    getUser();
    getFiliais();
  }, [getUser, getFiliais]);

  return (
    <>
      {/* <IntegrationsSAP /> */}
      {menuSelected.length > 0 && returnModalRegister(menuSelected)}
      {primeiroAcesso && filiais.length === 0 && (
        <SubsidiaryRegister onClear={() => dispatch(updatePrimaryAccess())} />
      )}
      {collapsable ? (
        <div className={classNames('sidebar', { 'is-open': isOpen })}>
          <div className="flex items-center justify-between">
            <AppLogo src={Image} className="img-fluid mt-2 mb-2 ml-2" />
            <img
              src={IconMenu}
              onClick={toggle}
              alt=""
              className="hamburgerButton"
            />
          </div>
          <div className="side-menu">
            <Nav vertical className="nav">
              <NavItem
                id="register"
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    register: !toogleMenu.register,
                  });
                }}
                className={CONTROL_MENU[9].isSelected ? 'active' : ''}
              >
                <NavLink
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.register },
                    { selected: toogleMenu.register }
                  )}
                >
                  {toogleMenu.register ? (
                    <img src={registerIconActive} alt="" className="mr-2" />
                  ) : (
                    <img src={registerIcon} alt="" className="mr-2" />
                  )}
                  <span
                    style={{
                      fontWeight: 'bold',
                      fontSize: 16,
                      color: toogleMenu.register ? '#6369D1' : '#EA004C',
                    }}
                  >
                    <Trans i18nKey="menu.register" />
                  </span>
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={toogleMenu.register}
                className={classNames('items-menu', {
                  'mb-1': !CONTROL_MENU[9].active,
                })}
              >
                <NavItem className="pl-4" id="driver">
                  <NavLink
                    onClick={() => {
                      if (permissionMenu(permission, 'register.driver')) {
                        setMenuSelected('register.driver');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <Trans i18nKey="delivery.driver.name" />
                  </NavLink>
                </NavItem>

                <NavItem className="pl-4" id="collaborator">
                  <NavLink
                    onClick={() => {
                      if (permissionMenu(permission, 'register.collaborator')) {
                        setMenuSelected('register.collaborator');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Colaborador
                    {/* <Trans i18nKey="delivery.driver.name" /> */}
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="collaboratorType">
                  <NavLink
                    onClick={() => {
                      if (
                        permissionMenu(permission, 'register.typeCollaborator')
                      ) {
                        setMenuSelected('register.typeCollaborator');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Tipo do Colaborador
                    {/* <Trans i18nKey="delivery.driver.name" /> */}
                  </NavLink>
                </NavItem>

                <NavItem className="pl-4" id="logisticOperator">
                  <NavLink
                    onClick={() => {
                      if (
                        permissionMenu(permission, 'register.logisticOperator')
                      ) {
                        setMenuSelected('register.logisticOperator');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <Trans i18nKey="driver.fields.operatorLogistic" />
                  </NavLink>
                </NavItem>

                <>
                  <NavItem className="pl-4" id="cancelReason">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(permission, 'register.cancelReason')
                        ) {
                          setMenuSelected('register.cancelReason');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="reasons.title" />
                    </NavLink>
                  </NavItem>

                  <NavItem className="pl-4" id="notices">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.notices')) {
                          setMenuSelected('register.notices');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="notices.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="ocorrences">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.exception')) {
                          setMenuSelected('register.exception');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="ocorrences.menu" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="phases">
                    <NavLink
                      onClick={() => {
                        if (permissionMenu(permission, 'register.steps')) {
                          setMenuSelected('register.steps');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="phases.menu" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="subsidiary">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.subsidiary')) {
                          setMenuSelected('register.subsidiary');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="filiais.menuTitle" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="notifications">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.subsidiary')) {
                          setMenuSelected('register.notification');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="notifications.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="activity">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.subsidiary')) {
                          setMenuSelected('register.activity');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="activity.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="contractor">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.subsidiary')) {
                          setMenuSelected('register.contractor');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="contractor.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="videoClasses">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(permission, 'register.videoLesson')
                        ) {
                          setMenuSelected('register.videoLesson');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="videoClasses.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="vehicles">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.vehicles')) {
                          setMenuSelected('register.vehicles');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="vehicles.titleMenu" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="template">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (permissionMenu(permission, 'register.template')) {
                          setMenuSelected('register.template');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="template.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="shippingTable">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        setMenuSelected('register.shippingTable');
                      }}
                      to="/home"
                    >
                      Tabela de Frete
                      {/* <Trans i18nKey="template.title" /> */}
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="shippingTable">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        setMenuSelected('register.audit-shipping');
                      }}
                      to="/home"
                    >
                      {/* Auditoria de Frete */}
                      <Trans i18nKey="shippingAudit.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="route">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        setMenuSelected('register.route');
                      }}
                      to="/home"
                    >
                      Rota
                      {/* <Trans i18nKey="template.title" /> */}
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="region">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        setMenuSelected('register.region');
                      }}
                      to="/home"
                    >
                      Região
                      {/* <Trans i18nKey="template.title" /> */}
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="sector">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        setMenuSelected('register.sector');
                      }}
                      to="/home"
                    >
                      Setor
                      {/* <Trans i18nKey="template.title" /> */}
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="reasonsAlert">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'register.create-reason-alert'
                          )
                        ) {
                          setMenuSelected('register.create-reason-alert');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="reasonsAlert.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="windowDelivery">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'register.create-window-delivery'
                          )
                        ) {
                          setMenuSelected('register.create-window-delivery');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="windowDelivery.titleMenu" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="parameters">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'register.create-parameters'
                          )
                        ) {
                          setMenuSelected('register.create-parameters');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="parameters.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="pointInterest">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'register.create-point-interest'
                          )
                        ) {
                          setMenuSelected('register.create-point-interest');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="pointInterest.title" />
                    </NavLink>
                  </NavItem>
                  <NavItem className="pl-4" id="typePointInterest">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'register.create-type-point-interest'
                          )
                        ) {
                          setMenuSelected('register.create-type-point-interest');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="typePointInterest.title" />
                    </NavLink>
                  </NavItem>

                  <NavItem className="pl-4" id="cargoTypes">
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        if (
                          permissionMenu(
                            permission,
                            'register.cargo-types'
                          )
                        ) {
                          setMenuSelected('register.cargo-types');
                        } else {
                          FrontendNotification(
                            t('common.permission'),
                            NotificationType.ERROR
                          );
                        }
                      }}
                      to="/home"
                    >
                      <Trans i18nKey="cargoTypes.title" />
                    </NavLink>
                  </NavItem>
                </>
              </Collapse>

              <NavItem
                id="journey"
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    journey: !toogleMenu.journey,
                  });
                }}
                className={CONTROL_MENU[1].isSelected ? 'active' : ''}
              >
                <NavLink
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.journey },
                    { selected: toogleMenu.journey }
                  )}
                >
                  <img
                    src={!toogleMenu.journey ? JourneyIcon : JourneyIconActive}
                    alt=""
                    className="mr-2"
                  />
                  <span
                    style={{
                      color: toogleMenu.journey ? '#6369D1' : '#EA004C',
                      fontWeight: 'bold',
                      fontFamily: 'Lato',
                      fontSize: 16,
                    }}
                  >
                    <Trans i18nKey="driverShift.title" />
                  </span>
                </NavLink>
              </NavItem>

              <Collapse
                isOpen={toogleMenu.journey}
                className={classNames('items-menu', {
                  'mb-1': !toogleMenu.journey,
                })}
              >
                <NavItem className="pl-4" id="map">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'workday.break')) {
                        setMenuSelected('workday.break');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <Trans i18nKey="breaks.title" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="map">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'workday.journey')) {
                        setMenuSelected('workday.journey');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <Trans i18nKey="journey.titleMenu" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="map">
                  <NavLink
                    tag={Link}
                    to="/home"
                    onClick={() => {
                      if (permissionMenu(permission, 'workday.pointControl')) {
                        history.push('/scheduling');
                        window.location.reload();
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                  >
                    Tratamento
                    {/* <Trans i18nKey="map.title" /> */}
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="map">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'workday.reports')) {
                        history.push('/reportsJourney');
                        window.location.reload();
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Relatórios
                    {/* <Trans i18nKey="map.title" /> */}
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="map">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'workday.reason')) {
                        setMenuSelected('workday.reason');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Motivo
                    {/* <Trans i18nKey="breaks.title" /> */}
                  </NavLink>
                </NavItem>
              </Collapse>

              <NavItem
                id="monitoring"
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    operator: !toogleMenu.operator,
                  });
                }}
                className={CONTROL_MENU[1].isSelected ? 'active' : ''}
              >
                <NavLink
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.operator },
                    { selected: toogleMenu.operator }
                  )}
                >
                  <img
                    src={
                      !toogleMenu.operator
                        ? MonitoringIcon
                        : MonitoringIconActive
                    }
                    alt=""
                    className="mr-2"
                  />
                  <span
                    style={{
                      color: toogleMenu.operator ? '#6369D1' : '#EA004C',
                      fontWeight: 'bold',
                      fontFamily: 'Lato',

                      fontSize: 16,
                    }}
                  >
                    <Trans i18nKey="menu.monitoring" />
                  </span>
                </NavLink>
              </NavItem>

              <Collapse
                isOpen={toogleMenu.operator}
                className={classNames('items-menu', {
                  'mb-1': !toogleMenu.operator,
                })}
              >
                <NavItem className="pl-4" id="map">
                  <NavLink tag={Link} to="/home">
                    <Trans i18nKey="map.title" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="controlTower">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (
                        permissionMenu(permission, 'monitoring.controlTower')
                      ) {
                        window.open('/controlTower', '_blank');

                        window.location.reload();
                        toggle();
                        setCollapsable(true);
                        setCollapsed(true);
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Torre de Controle
                  </NavLink>
                </NavItem>

                <NavItem className="pl-4" id="csv">
                  <NavLink
                    onClick={() => {
                      if (permissionMenu(permission, 'monitoring.uploadCSV')) {
                        history.push('/uploadCSV');
                        window.location.reload();
                        toggle();
                        setCollapsable(true);
                        setCollapsed(true);
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Upload/Download CSV
                  </NavLink>
                </NavItem>
              </Collapse>

              <NavItem
                id="reports"
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    reports: !toogleMenu.reports,
                  });
                }}
                className={CONTROL_MENU[3].isSelected ? 'active' : ''}
              >
                <NavLink
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.reports },
                    { selected: toogleMenu.reports }
                  )}
                >
                  {/* tag={Link} to="/contact" */}
                  <img
                    src={!toogleMenu.reports ? reportsInactive : reportsActive}
                    alt=""
                    className="mr-2"
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: toogleMenu.reports ? '#6369D1' : '#EA004C',
                    }}
                  >
                    <Trans i18nKey="menu.reports" />
                  </span>
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={toogleMenu.reports}
                className={classNames('items-menu', {
                  'mb-1': !toogleMenu.reports,
                })}
              >
                <NavItem
                  id="metabase"
                  className="pl-4"
                  onClick={() => {
                    if (permissionMenu(permission, 'reports')) {
                      setMenuSelected('reports');
                    } else {
                      FrontendNotification(
                        t('common.permission'),
                        NotificationType.ERROR
                      );
                    }
                  }}
                  to="/home"
                >
                  <NavLink tag={Link} to="/home">
                    Relatórios
                    {/* <Trans i18nKey="metabase.title" /> */}
                  </NavLink>
                </NavItem>
                <NavItem
                  id="metabase"
                  className="pl-4"
                  to="/dashboard"
                >
                  <NavLink tag={Link} to="/dashboard">
                    Dashboard
                    {/* <Trans i18nKey="metabase.title" /> */}
                  </NavLink>
                </NavItem>
              </Collapse>

              <NavItem
                id="accessControl"
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    accessControl: !toogleMenu.accessControl,
                  });
                }}
                className={CONTROL_MENU[6].isSelected ? 'active' : ''}
              >
                <NavLink
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.accessControl },
                    { selected: toogleMenu.accessControl }
                  )}
                >
                  {/* tag={Link} to="/contact" */}
                  <img
                    src={
                      !toogleMenu.accessControl
                        ? AccessControlIcon
                        : AccessControlIconActive
                    }
                    alt=""
                    className="mr-2"
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: toogleMenu.accessControl ? '#6369D1' : '#EA004C',
                    }}
                  >
                    <Trans i18nKey="menu.accessControl" />
                  </span>
                </NavLink>
              </NavItem>

              <Collapse
                isOpen={toogleMenu.accessControl}
                className={classNames('items-menu', {
                  'mb-1': !CONTROL_MENU[9].active,
                })}
              >
                <NavItem className="pl-4" id="users">
                  <NavLink
                    onClick={() => {
                      if (permissionMenu(permission, 'accessControl.users')) {
                        setMenuSelected('accessControl.users');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <Trans i18nKey="users.title" />
                  </NavLink>
                </NavItem>
              </Collapse>
              <NavItem
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    integrations: !toogleMenu.integrations,
                  });
                }}
              >
                <NavLink
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.integrations },
                    { selected: toogleMenu.integrations }
                  )}
                >
                  <img
                    src={
                      !toogleMenu.integrations
                        ? IntegrationIcon
                        : IntegrationIconActive
                    }
                    alt=""
                    className="mr-2"
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: toogleMenu.integrations ? '#6369D1' : '#EA004C',
                    }}
                  >
                    <Trans i18nKey="menu.integrations" />
                  </span>
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={toogleMenu.integrations}
                className={classNames('items-menu', {
                  'mb-1': !toogleMenu.integrations,
                })}
              >
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'integration.bling')) {
                        setMenuSelected('integration.bling');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <img src={LogoIntegrationBling} alt="" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'integration.sap-b1')) {
                        setMenuSelected('integration.sap-b1');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <img src={LogoIntegrationSAPB1} alt="" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'integration.sendgrid')) {
                        setMenuSelected('integration.sendgrid');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <img src={LogoIntegrationSendGrid} alt="" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'integration.log')) {
                        setMenuSelected('integration.log');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    <Trans i18nKey="integrationsAPI.menuIntegration" />
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'integration.webhook')) {
                        setMenuSelected('integration.webhook');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Webhook
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'settings.sms')) {
                        setMenuSelected('settings.sms');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Zenvia
                  </NavLink>
                </NavItem>
              </Collapse>
              <NavItem
                className={CONTROL_MENU[8].isSelected ? 'active' : ''}
                onClick={() => {
                  setToogleMenu({
                    ...toogleMenu,
                    routing: !toogleMenu.routing,
                  });
                }}
                id="routering"
              >
                <NavLink
                  to="/home"
                  className={classNames(
                    { 'dropdown-toggle': true },
                    { 'dropdown-toggle-open': toogleMenu.routing },
                    { selected: toogleMenu.routing }
                  )}
                >
                  <img
                    src={
                      !toogleMenu.routing
                        ? iconMenuRoutering
                        : iconMenuRouteringActive
                    }
                    alt=""
                    className="mr-2"
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: toogleMenu.routing ? '#6369D1' : '#EA004C',
                    }}
                  >
                    <Trans i18nKey="routeOptmization.routering" />
                  </span>
                </NavLink>
              </NavItem>
              <Collapse
                isOpen={toogleMenu.routing}
                className={classNames('items-menu', {
                  'mb-1': !toogleMenu.routing,
                })}
              >
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'routing')) {
                        setMenuSelected('routing');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Simulação
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="assertiveness">
                  <NavLink
                    tag={Link}
                    onClick={() => {
                      if (permissionMenu(permission, 'routering')) {
                        setMenuSelected('routering');
                      } else {
                        FrontendNotification(
                          t('common.permission'),
                          NotificationType.ERROR
                        );
                      }
                    }}
                    to="/home"
                  >
                    Carregamento
                  </NavLink>
                </NavItem>
                <NavItem className="pl-4" id="csv">
                  <NavLink
                    onClick={() => {
                      window.open('/cargosFormation', '_blank');
                      window.location.reload();
                      toggle();
                      setCollapsable(true);
                      setCollapsed(true);
                    }}
                    to="/cargosFormation"
                  >
                    Formação de Cargas
                  </NavLink>
                </NavItem>
              </Collapse>
              <NavItem
                className={toogleMenu.privacy ? 'active' : ''}
                id="privacy"
              >
                <NavLink
                  onClick={() => {
                    if (permissionMenu(permission, 'register.subsidiary')) {
                      setMenuSelected('register.privacy');
                      setToogleMenu({
                        ...toogleMenu,
                        privacy: !toogleMenu.privacy,
                      });
                    } else {
                      FrontendNotification(
                        t('common.permission'),
                        NotificationType.ERROR
                      );
                    }
                  }}
                >
                  <img
                    src={!toogleMenu.privacy ? PrivacyIcon : PrivacyIconActive}
                    alt=""
                    style={{ width: 21, height: 21 }}
                    className="mr-2"
                  />
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: toogleMenu.privacy ? '#6369D1' : '#EA004C',
                    }}
                  >
                    <Trans i18nKey="privacy.menuTitle" />
                  </span>
                </NavLink>
              </NavItem>

              <div
                className="avatar-container"
                style={{ marginBottom: '40px' }}
              >
                <div>
                  <img src={avatar} alt="" />
                </div>
                <div className="user-data">
                  <span
                    style={{
                      fontFamily: 'Lato',
                      fontSize: 16,
                      fontWeight: 'bold',
                    }}
                  >
                    {userData.nome}
                  </span>
                  <br />
                  <Link
                    to="/"
                    onClick={() => {
                      handleLogout();
                    }}
                  >
                    <span className="logout" style={{ fontFamily: 'Lato' }}>
                      <Trans i18nKey="menu.logout" />
                    </span>
                  </Link>
                </div>
              </div>
            </Nav>
          </div>
        </div>
      ) : (
        <>
          <div
            className={classNames('collapsable', { 'is-collapsable': isOpen })}
            onMouseOver={toggle}
          >
            <div className="header">
              <img
                src={IconMenu}
                onClick={toggle}
                alt=""
                className="hamburgerButton"
              />
            </div>
            <div className="side-menu">
              <Nav vertical className="nav">
                <NavItem
                  className={
                    CONTROL_MENU[0].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img src={registerIcon} alt="" className="mr-2" />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[1].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink style={{ paddingLeft: 35 }}>
                    <img
                      src={
                        !toogleMenu.operator
                          ? MonitoringIcon
                          : MonitoringIconActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[2].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img
                      src={
                        !toogleMenu.reports ? reportsInactive : reportsActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[3].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img
                      src={
                        !CONTROL_MENU[3].isSelected
                          ? ReportIcon
                          : ReportIconActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[5].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img
                      src={
                        !toogleMenu.accessControl
                          ? AccessControlIcon
                          : AccessControlIconActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[6].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img
                      src={
                        !toogleMenu.settings ? SettingsIcon : SettingsIconActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[7].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img
                      src={
                        !CONTROL_MENU[8].isSelected
                          ? IntegrationIcon
                          : IntegrationIconActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={
                    CONTROL_MENU[8].isSelected ? 'item-active' : 'item'
                  }
                >
                  <NavLink>
                    <img
                      src={
                        menuSelected !== 'routing'
                          ? iconMenuRoutering
                          : iconMenuRouteringActive
                      }
                      alt=""
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>
                <NavItem
                  className={toogleMenu.privacy ? 'item-active' : 'item'}
                  id="configurations"
                >
                  <NavLink>
                    <img
                      src={
                        !toogleMenu.privacy ? PrivacyIcon : PrivacyIconActive
                      }
                      alt=""
                      style={{ width: 21, height: 21 }}
                      className="mr-2"
                    />
                  </NavLink>
                </NavItem>

                <div
                  className="avatar-container"
                  style={{ marginBottom: '40px' }}
                >
                  <div>
                    <img src={avatar} alt="" />
                  </div>
                  <div className="user-data">
                    <span
                      style={{
                        fontSize: 16,
                        fontFamily: 'Lato',
                        fontWeight: 'bold',
                      }}
                    >
                      {userData.nome}
                    </span>
                    <br />
                    <Link
                      to="/"
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      <span className="logout" style={{ fontFamily: 'Lato' }}>
                        <Trans i18nKey="menu.logout" />
                      </span>
                    </Link>
                  </div>
                </div>
              </Nav>
            </div>
          </div>
        </>
      )}
    </>
  );
}
