import {
  CellClickedEvent,
  CellValueChangedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react'; // React Grid Logic
import LoadingIndicator from 'core/common/Loading';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash } from 'react-icons/fa';
import api from 'services/api';
import Loading from './Loading';
import CreateIcon from './icons/CreateIcon';
import RemoveIcon from './icons/RemoveIcon';
import UpdateIcon from './icons/UpdateIcon';
import './style.css';
import { ColumnDef, CustomButtons, GridProps } from './types';

const Grid = <T extends any>(props: GridProps<T>) => {
  const { t, i18n } = useTranslation();
  const gridRef = useRef<AgGridReact<T>>(null);
  const [columns] = useState(props.columns);
  const [colDefs, setColDefs] = useState<any>([]);
  const [pagination] = useState(props.pagination);
  const [rowSelection] = useState(props.rowSelection);
  const [path] = useState(props.path);
  const [rowData, setRowData] = useState<T[]>();
  const [showCrudButtons] = useState(props.showCrudButtons);
  const [loading, setLoading] = useState(false);
  const [gridApi, setGridApi] = useState<any>(null);
  const [refresh, setRefresh] = useState<string>('');
  const [crudButtons] = useState<ColumnDef[]>([
    {
      field: '',
      headerName: '',
      pinned: 'right',
      cellRenderer: (params: CustomCellRendererProps) => {
        if (params.data) {
          return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
              <div className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full tw-mr-3" onClick={props.onUpdate}>
                <FaEdit className="tw-mr-1 tw-w-4 tw-h-4" color="#ffa500" />
              </div>
              <div className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-h-full" onClick={props.onDelete}>
                <FaTrash className="tw-mr-1 tw-w-4 tw-h-4" color="#EA004C" />
              </div>
            </div>
          );
        }
      },
    },
  ]);

  const loadingOverlayComponent = useMemo(() => {
    return Loading;
  }, []);

  const onGridReady = useCallback(
    (params: any) => {
      let cols: any[] = [];
      columns.forEach(column => {
        // Realiza a tradução da key.
        if (column.headerName) {
          column.headerName = i18n.t(column.headerName);
        }

        cols.push(column);
      });

      if(props.customCrudButtons) {

        
        crudButtons.forEach((buttons: any) => {
          cols.push(buttons);
        });
        
      }
      setColDefs(cols);
      setGridApi(params.api);
      if (props.onGridApi) {
        props.onGridApi(params.api);
      }
      if (!props.server) {
        const reqDTO = {
          filters: props.filters,
          orders: props.orders,
          size: 1000,
          page: 0,
        };

        api.post(path, reqDTO).then(response => {
          setRowData(response.data.content);
          props.setRowData?.(response.data.content);
        });
      }
    },
    [props.filters]
  );

  const onCellClicked = useCallback((params: CellClickedEvent) => {}, []);

  /**
   * Responsável por atualizar a linha de uma célula editada.
   */
  const onCellValueChanged = (params: CellValueChangedEvent) => {
    console.log(
      'Update id: ' + params.data.id + ' | ColId: ' + params.column.getColId()
    );
  };

  /**
   * Responsável por informar quais os registros estão selecionados.
   */
  const onSelectionChanged = (params: SelectionChangedEvent) => {
    const selectedRows = params.api.getSelectedRows();
    props.setSelectedRows?.(selectedRows);
    gridApi.refreshCells({
      force: true, // força a atualização de todas as células
    });
  };

  useEffect(() => {
    if (gridApi && props.server) {
      const dataSource = {
        getRows: async (params: any) => {
          const page = params.endRow / 100 - 1;
          setLoading(true);

          let filters = props.filters;

          // Adiciona os filtros de colunas customizados.
          if (params.filterModel != null) {
            for (const customFilter in params.filterModel) {
              // Tem que fazer o teste se é um array, pois caso o receba
              // será um filtro por período.
              if (Array.isArray(params.filterModel[customFilter].value)) {
                let newFilters: any[] = params.filterModel[customFilter].value;
                filters = removeFilterByField(filters, newFilters[0].field);

                // Adiciona os novos filtros.
                for (var i = 0; i < newFilters.length; i++) {
                  filters.push({
                    field: newFilters[i].field,
                    value: newFilters[i].value,
                    operation: newFilters[i].operation,
                  });
                }
              } else {
                let newFilter: any = params.filterModel[customFilter].value;
                filters = removeFilterByField(filters, newFilter.field);
                if (newFilter.value != '') {
                  filters.push({
                    field: newFilter.field,
                    value: newFilter.value,
                    operation: newFilter.operation,
                  });
                }
              }
            }

            // Se não encontrar propriedades no filter model indica que
            // não foram informados valores para os filtros.
            // if (!hasProps) {
            //   filters = removeFilterValue(filters, "status");
            // }
          }
          const reqDTO = {
            filters: filters,
            orders: props.orders,
            size: 100,
            page: page,
          };

          const response = await api.post(path, reqDTO);
          params.successCallback(
            response.data.content,
            response.data.totalElements
          );
          setRowData(response.data.content);
          props.setRowData?.(response.data.content);
          setLoading(false);
        },
      };
      gridApi.setDatasource(dataSource);
    }
  }, [gridApi]);

  const onForceUpdate = () => {
    console.log("Entrou");
   
    console.log(gridApi);
  }

  useEffect(() => {
    if (gridRef.current) {
     onForceUpdate();
      // gridRef.current.api.setRowData([]); // Atualiza os dados no grid
      // gridRef.current.api.setRowData(rowData); // Atualiza os dados no grid
      
    }
  }, [props.force]);

 
  // Função para remover todos os objetos que não possuem um determinado campo field
  const removeFilterValue = (array: any[], field: string): any[] => {
    return array.filter(obj => obj.field === field);
  };

  // Remove um filtro da lista pelo field.
  const removeFilterByField = (array: any[], field: string) => {
    return array.filter(item => item.field !== field);
  };

  return (
    <>
      <LoadingIndicator loading={loading} />
      <div
        className={'tw-mb-2 tw-flex tw-flex-col'}
        style={{ display: `${showCrudButtons ? '' : 'none'}` }}
      >
        <div className="tw-flex">
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={props.onCreate}
            id="btnCreate"
          >
            <CreateIcon className="tw-mr-1" /> {t('action.create')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={props.onUpdate}
            id="btnUpdate"
          >
            <UpdateIcon className="tw-mr-1" /> {t('action.update')}
          </button>
          <button
            className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
            onClick={props.onDelete}
            id="btnDelete"
          >
            <RemoveIcon className="tw-mr-1" /> {t('action.remove')}
          </button>
        </div>
        <div className="tw-flex tw-mt-2">
          {props.customButtons &&
            props.customButtons.map((button: CustomButtons) => (
              <button
                className="tw-bg-[#EA004C] tw-border-none tw-text-[#fff] tw-flex tw-pt-[0.375rem] tw-pb-[0.375rem] tw-pl-[0.75rem] tw-pr-[0.75rem] tw-rounded-[0.25rem] tw-items-center tw-justify-center tw-mr-2"
                onClick={button.action}
                id={'btnDelete'}
              >
                <img src={button.icon} className="tw-mr-1" /> {button.label}
              </button>
            ))}
        </div>
        {/* <Col md="12">
          {props.customButtons &&
            props.customButtons.map((button: CustomButtons) => (
              <Button
                className="tw-bg-[#EA004C] tw-border-none tw-mt-2 tw-mr-2"
                onClick={button.action}
                id={'btnDelete'}
              >
                <img src={button.icon} className="tw-mr-1" /> {button.label}
              </Button>
            ))}
        </Col> */}
      </div>

      <Row className="ag-theme-quartz tw-h-full tw-w-full">
        <Col md="12">
          {/* The AG Grid component */}
          <AgGridReact
            key={props.refresh}
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            rowModelType={props.server ? 'infinite' : 'clientSide'}
            pagination={pagination}
            rowSelection={rowSelection}
            loadingOverlayComponent={loadingOverlayComponent}
            loadingCellRenderer={loadingOverlayComponent}
            onGridReady={onGridReady}
            onCellValueChanged={onCellValueChanged}
            onSelectionChanged={onSelectionChanged}
            onCellClicked={onCellClicked}
            suppressRowClickSelection={!!props.customCrudButtons}
            // setGridApi={props.onGridApi}
          />
        </Col>
      </Row>
    </>
  );
};

export default Grid;
