import MapIcon from 'assets/images/mapPin.svg';
import axios from 'axios';
import InputCustom from 'components/Input';
import { IPointInterest } from 'components/PointInterest/types/types';
import { icons, renderIcons } from 'components/TypePointInterest/icons';
import { ITypePointInterest } from 'components/TypePointInterest/types/types';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { useFormik } from 'formik';
import Leaflet from 'leaflet';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Select from 'react-select';
import { Label } from 'reactstrap';
import api from 'services/api';
import { searchAddress, viaCep } from 'services/endpoint';
import { States } from 'util/monitoring';
import formValidator from './validators/formValidator';

interface Props {
  onClear: () => void;
  isEdit?: boolean;
  data?: IPointInterest;
}

interface FormValues {
  description: string;
  id_type_point_interest: string;
  endereco_logradouro: string;
  endereco_numero: string;
  endereco_cidade: string;
  endereco_bairro: string;
  endereco_uf: string;
  endereco_cep: string;
  endereco_complemento: string;
}

const NewPointInterest: React.FC<Props> = (props: Props) => {
  const [typePointInterest, setTypePointInterest] = useState<any[]>();
  const [listTypePointInterest, setListTypePointInterest] = useState<any[]>([]);
  const [initialPosition, setInitialPosition] = useState<any>([
    -8.0527,
    -34.9099,
  ]);
  const [zoom, setZoom] = useState<number>(14);
  const [position, setPosition] = useState<any>([]);
  const [states, setStates] = useState<any[]>([]);
  const [valueState, setValueState] = useState<any[]>([]);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();
  let mapRef: any = useRef();

  const handleSubmit = useCallback(
    async (
      values: FormValues,
      isEdit?: boolean,
      row?: IPointInterest,
      points?: any,
      resetForm?: any
    ) => {
      try {
        setLoading(true);
        if (points && points.length === 0) {
          setLoading(false);
          FrontendNotification(
            t('pointInterest.messageEmptyLocation'),
            NotificationType.WARNING
          );
        } else {
          const body = {
            description: values.description,
            endereco: {
              logradouro: values.endereco_logradouro,
              pais: 'Brasil',
              numero:
                String(values.endereco_numero).length > 0
                  ? values.endereco_numero
                  : null,
              cidade: values.endereco_cidade,
              bairro: values.endereco_bairro,
              uf: values.endereco_uf,
              cep: values.endereco_cep,
              complemento:
                values.endereco_complemento.length > 0
                  ? values.endereco_complemento
                  : '',
              latitude: points[0],
              longitude: points[1],
            },
            typePointInterest: {
              id: values.id_type_point_interest,
            },
          };

          if (isEdit) {
            const response = await api.patch(
              `/delivery/pointInterest/${row?.id}`,
              body
            );

            if (response.status === 204) {
              FrontendNotification(
                t('pointInterest.messageUpdatedSuccess'),
                NotificationType.SUCCESS
              );
            }
          } else {
            const response = await api.post('/delivery/pointInterest', body);

            if (response.status === 201) {
              FrontendNotification(
                t('pointInterest.messageSuccess'),
                NotificationType.SUCCESS
              );
            }
          }

          resetForm({});
          props.onClear();

          setLoading(false);
        }
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const getTypePointInterest = useCallback(async () => {
    try {
      const body = {
        filters: [],
        orders: [],
        page: 0,
        size: 100,
      };

      const response = await api.post(
        '/delivery/typePointInterest/pageable',
        body
      );

      const mappingResponse = response.data.content.map(
        (rows: ITypePointInterest) => {
          return {
            id: rows.id,
            label: rows.description,
            icon: rows.icon,
          };
        }
      );

      setListTypePointInterest(mappingResponse);
      if (props.isEdit && props.data) {
        const findTypeInterestById = mappingResponse.find(
          (item: any) => item.icon === props.data?.typePointInterest.icon
        );

        setTypePointInterest(findTypeInterestById);
        formik.setFieldValue(
          'id_type_point_interest',
          props.data.typePointInterest.id
        );
      }
    } catch {}
  }, []);

  const getStates = useCallback(() => {
    const sortType = 'asc';

    const mappingData = States.map((item: any) => {
      return {
        value: item.sigla,
        label: item.nome,
      };
    });

    const orderSort: any[] = mappingData.sort((a, b) => {
      const isReversed = sortType === 'asc' ? 1 : -1;

      return isReversed * a.label.localeCompare(b.label);
    });

    setStates(orderSort);

    if (props.isEdit && props.data) {
      const findStatesById = orderSort.find(
        (item: any) => item.value === props.data?.endereco.uf
      );
      setValueState(findStatesById);
    }
  }, []);

  const onSearchAddress = useCallback(
    async (values: FormValues, listStates: any[]) => {
      try {
        setLoading(true);

        const findStateById = listStates.find(
          (item: any) => item.id === values.endereco_uf
        );

        const body = {
          logradouro: values.endereco_logradouro.replace(/\s+/g, '+'),
          cep: values.endereco_cep.replace(/\s+/g, '+').replace('-', ''),
          pais: 'Brasil',
          bairro: values.endereco_bairro.replace(/\s+/g, '+'),
          cidade: values.endereco_cidade.replace(/\s+/g, '+'),
          estado: findStateById.label.replace(/\s+/g, '+'),
        };

        const { logradouro, cep, pais, bairro, cidade, estado } = body;

        const response = await axios.get(
          `${searchAddress}/search?addressdetails=1&q=${logradouro},${cep},${cidade},${bairro},${estado}&format=json&limit=1`
        );

        if (response.data.length === 0) {
          FrontendNotification(
            t('pointInterest.messageErrorLocation'),
            NotificationType.WARNING
          );
        } else {
          setPosition([response.data[0].lat, response.data[0].lon]);
          setInitialPosition([response.data[0].lat, response.data[0].lon]);
          setZoom(40);
        }

        setLoading(false);
      } catch {
        setLoading(false);
      }
    },
    []
  );

  const onSearchCep = useCallback(async (cep: string, dataStates: any[]) => {
    try {
      setLoading(true);
      if (cep.length === 9) {
        const response = await axios.get(`${viaCep}/${cep}/json/`);

        formik.setFieldValue('endereco_bairro', response.data.bairro);
        formik.setFieldValue('endereco_logradouro', response.data.logradouro);
        formik.setFieldValue('endereco_cidade', response.data.localidade);
        formik.setFieldValue('endereco_uf', response.data.uf);

        const findStatesById = dataStates.find(
          (item: any) => item.value === response.data.uf
        );

        setValueState(findStatesById);
      }

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const onLoadFormValues = useCallback((row: IPointInterest) => {
    if (row) {
      formik.setFieldValue('description', row.description);
      formik.setFieldValue('endereco_logradouro', row.endereco.logradouro);
      formik.setFieldValue('endereco_numero', row.endereco.numero);
      formik.setFieldValue('endereco_cidade', row.endereco.cidade);
      formik.setFieldValue('endereco_bairro', row.endereco.bairro);
      formik.setFieldValue('endereco_uf', row.endereco.uf);
      formik.setFieldValue('endereco_cep', row.endereco.cep);
      formik.setFieldValue('endereco_complemento', row.endereco.complemento);
      setPosition([row.endereco.latitude, row.endereco.longitude]);
      setInitialPosition([row.endereco.latitude, row.endereco.longitude]);
      setZoom(40);
    }
  }, []);

  const initialValues: FormValues = {
    description: '',
    id_type_point_interest: '',
    endereco_logradouro: '',
    endereco_numero: '',
    endereco_cidade: '',
    endereco_bairro: '',
    endereco_uf: '',
    endereco_cep: '',
    endereco_complemento: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: formValidator,
    onSubmit: (values: FormValues, { resetForm }) => {
      handleSubmit(values, props.isEdit, props.data, position, resetForm);
    },
  });

  const customStyles = {
    menu: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Eleva o z-index do menu
    }),
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 9999, // Ajusta o menu quando renderizado fora do DOM do Select
    }),
    option: (provided: any) => ({
      ...provided,
      background: '#FFF',
      color: '#000',
    }),
  };

  const pinIcon = new Leaflet.Icon({
    iconUrl: MapIcon,
    iconSize: [40, 40],
    // shadowSize:   [50, 64], // size of the shadow
    iconAnchor: [20, 20], // point of the icon which will correspond to marker's location
    // shadowAnchor: [4, 62],  // the same for the shadow
    popupAnchor: [-30, -35], // point from which the popup should open relative to the iconAnchor
  });

  useEffect(() => {
    getTypePointInterest();
    getStates();
  }, [getTypePointInterest, getStates]);

  useEffect(() => {
    if (props.isEdit && props.data) {
      onLoadFormValues(props.data);
    }
  }, []);

  return (
    <div className="tw-w-full tw-h-full">
      <Loading loading={loading} />
      <div className="tw-flex tw-h-full tw-mt-4">
        <div className="tw-flex-col tw-w-full tw-mr-4 tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.description')}
            onChange={formik.handleChange('description')}
            placeholder={t('typePointInterest.form.placeholder')}
            error={formik.errors.description}
            touched={formik.touched.description}
            value={formik.values.description}
          />
        </div>
        <div className="tw-flex-col tw-w-full tw-mb-4">
          <Label className="tw-text-sm tw-text-[rgb(0,48,73)] tw-opacity-60">
            {t('pointInterest.fields.type')}
          </Label>
          <Select
            id="typeAlert"
            name="subsidiary"
            options={listTypePointInterest}
            placeholder="Selecione"
            styles={customStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption: any) => {
              setTypePointInterest(selectedOption);
              formik.setFieldValue('id_type_point_interest', selectedOption.id);
            }}
            value={typePointInterest}
            formatOptionLabel={(item: any) => {
              return (
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
                >
                  {renderIcons(
                    icons.find((ic: any) => ic.id === item.icon)?.id,
                    false
                  )}
                  {item.label}
                </div>
              );
            }}
          />
          {formik.touched.id_type_point_interest &&
            formik.errors.id_type_point_interest && (
              <span className="tw-text-sm tw-text-[#EA004C] tw-font-bold">
                {formik.errors.id_type_point_interest}
              </span>
            )}
        </div>

        <div className="tw-flex-col tw-w-full tw-ml-4 tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.enderecoCep')}
            typeInput="mask"
            mask="99999-999"
            onChange={formik.handleChange('endereco_cep')}
            onBlur={() => onSearchCep(formik.values.endereco_cep, states)}
            placeholder={t('pointInterest.placeholders.enderecoCep')}
            error={formik.errors.endereco_cep}
            touched={formik.touched.endereco_cep}
            value={formik.values.endereco_cep}
          />
        </div>
      </div>
      <div className="tw-flex tw-h-full">
        <div className="tw-flex-col tw-w-full tw-mr-4 tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.enderecoLogradouro')}
            onChange={formik.handleChange('endereco_logradouro')}
            placeholder={t('pointInterest.placeholders.enderecoLogradouro')}
            error={formik.errors.endereco_logradouro}
            touched={formik.touched.endereco_logradouro}
            value={formik.values.endereco_logradouro}
          />
        </div>
        <div className="tw-flex-col tw-w-full tw-mr-4 tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.enderecoNumero')}
            type="number"
            onChange={formik.handleChange('endereco_numero')}
            placeholder={t('pointInterest.placeholders.enderecoNumero')}
            error={formik.errors.endereco_numero}
            touched={formik.touched.endereco_numero}
            value={formik.values.endereco_numero}
          />
        </div>
        <div className="tw-flex-col tw-w-full tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.enderecoBairro')}
            onChange={formik.handleChange('endereco_bairro')}
            placeholder={t('pointInterest.placeholders.enderecoBairro')}
            error={formik.errors.endereco_bairro}
            touched={formik.touched.endereco_bairro}
            value={formik.values.endereco_bairro}
          />
        </div>
      </div>
      <div className="tw-flex tw-h-full tw-mb-2">
        <div className="tw-flex-col tw-w-full tw-mr-4 tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.enderecoCidade')}
            onChange={formik.handleChange('endereco_cidade')}
            placeholder={t('pointInterest.placeholders.enderecoCidade')}
            error={formik.errors.endereco_cidade}
            touched={formik.touched.endereco_cidade}
            value={formik.values.endereco_cidade}
          />
        </div>
        <div className="tw-flex-col tw-w-full tw-mr-4 tw-mb-3">
          <Label className="tw-text-sm tw-text-[rgb(0,48,73)] tw-opacity-60">
            {t('pointInterest.fields.enderecoEstado')}
          </Label>
          <Select
            id="typeAlert"
            name="subsidiary"
            options={states}
            placeholder="Selecione"
            styles={customStyles}
            // className="basic-multi-select"
            classNamePrefix="select"
            onChange={(selectedOption: any) => {
              setValueState(selectedOption);
              formik.setFieldValue('endereco_uf', selectedOption.value);
            }}
            value={valueState}
          />
          {formik.touched.endereco_uf && formik.errors.endereco_uf && (
            <span className="tw-text-sm tw-text-[#EA004C] tw-font-bold">
              {formik.errors.endereco_uf}
            </span>
          )}
        </div>
        <div className="tw-flex-col tw-w-full tw-mt-1">
          <InputCustom
            title={t('pointInterest.fields.enderecoComplemento')}
            onChange={formik.handleChange('endereco_complemento')}
            placeholder={t('pointInterest.placeholders.enderecoComplemento')}
            error={formik.errors.endereco_complemento}
            touched={formik.touched.endereco_complemento}
            value={formik.values.endereco_complemento}
          />
        </div>
        <div className="tw-flex-col tw-w-full tw-mt-8 tw-ml-3">
          <button
            type="button"
            className="tw-w-full tw-h-9 tw-bg-[#EA004C] tw-border-none tw-text-sm tw-font-bold tw-text-[#FFF] tw-rounded-md"
            onClick={() => onSearchAddress(formik.values, states)}
          >
            Localizar no mapa
          </button>
        </div>
      </div>

      <div className="tw-flex tw-w-full tw-h-[20%]">
        <Map
          ref={(m: any) => {
            mapRef = m;
          }}
          center={initialPosition}
          zoom={zoom}
          zoomControl
          className="tw-max-h-80 tw-cursor-pointer"
          onclick={e => setPosition([e.latlng.lat, e.latlng.lng])}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {position.length !== 0 && (
            <Marker position={position} icon={pinIcon} />
          )}
        </Map>
      </div>
      {position.length > 0 && (
        <div className="tw-flex tw-justify-end tw-w-full tw-mt-8 tw-ml-3">
          <button
            type="submit"
            className="tw-w-2/6 tw-h-9 tw-bg-[#EA004C] tw-border-none tw-text-sm tw-font-bold tw-text-[#FFF] tw-rounded-md"
            onClick={() => formik.handleSubmit()}
          >
            Salvar
          </button>
        </div>
      )}
    </div>
  );
};

export default NewPointInterest;
