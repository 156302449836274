import { ICellRendererParams } from 'ag-grid-community';
import React, { useState } from 'react';

import checkedIcon from 'assets/images/towerChecked.svg';

interface Props {
  params: ICellRendererParams;
  value: any;
}

const WeekDay: React.FC<ICellRendererParams> = (props: ICellRendererParams) => {
  const { value } = props;
  const [enabled, setEnabled] = useState(value?.enabled || false);
  const [time, setTime] = useState(value?.time || '');
  const { selected }: any = props.node;

  const handleValueChange = () => {
    const day = value.day;
    props.api.stopEditing(); // Finaliza a edição
    if (props.column) {
      props.node.setDataValue(props.column.getColId(), { day, enabled, time });
    }
  };

  const handleChecked = (enabled: boolean) => {
    const day = value.day;
    props.api.stopEditing(); // Finaliza a edição
    if (props.column) {
      props.node.setDataValue(props.column.getColId(), { day, enabled, time });
    }
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-start tw-mt-2">
      {selected && (
        <>
          <div
            className="tw-flex tw-w-4 tw-h-4 tw-bg-[#fff] tw-border tw-border-[#000] tw-rounded-[4px] tw-cursor-pointer tw-p-[2px] tw-mr-2 tw-ml-0"
            onClick={() => {
              setEnabled(!enabled);
              handleChecked(!enabled);
            }}
          >
            <div className="tw-flex tw-items-center">
              {enabled ? (
                <div className="tw-bg-[#EA004C] tw-rounded-[4px] tw-border-none tw-w-4 tw-h-4 tw-flex tw-items-center tw-justify-center tw-p-2">
                  <img src={checkedIcon} className="tw-w-3 tw-h-3" />
                </div>
              ) : (
                <div className="tw-w-4 tw-h-4 tw-rounded-[4px] tw-bg-[#fff]" style={{ border: '1px solid #EA004C' }} />
              )}
            </div>
          </div>
          {enabled && (
            <input
              type="time"
              value={time}
              className="tw-w-14 tw-h-6 tw-z-50"
              onChange={e => setTime(e.target.value)}
              onBlur={handleValueChange}
            />
          )}
        </>
      )}
    </div>
  );
};

export default WeekDay;
