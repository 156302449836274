import FilterListIcon from '@mui/icons-material/FilterList';
import {
  ColDef,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css'; // Core CSS
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Theme
import { AgGridReact } from 'ag-grid-react';
import {
  Direction,
  Filter,
  RowSelection,
  SearchOperation,
} from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'services/api';
import { formatDateTimeBR, numberToCurrencyBRL } from 'util/format';
import Create from './components/Create';
import Filters from './components/Filters';
import Historic from './components/Historic';
import UpdateShipping from './components/UpdateShipping';

interface Props {
  onClear: () => void;
}

const ShippingAudit: React.FC<Props> = (props: Props) => {
  const [columns] = useState<ColDef[]>([
    {
      field: 'romaneio.data',
      headerName: 'Criação do Romaneio',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          let date: any = new Date(params.value);
          date = date.setDate(date.getDate() + 1);
          return format(date, 'dd/MM/yyyy', {
            locale: pt,
          });
        }

        return '---';
      },
    },
    {
      field: 'romaneio.dataFinalizacao',
      headerName: 'Data Fechamento',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return formatDateTimeBR(params.value);
        }

        return '---';
      },
    },
    {
      field: 'dataRegistro',
      headerName: 'Registro Tabela',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return formatDateTimeBR(params.value);
        }

        return '---';
      },
    },
    {
      field: 'classificador.operadorLogistico.nomeFantasia',
      headerName: 'Transportadora Rom.',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value;
        }

        return '---';
      },
    },
    {
      field: 'classificador.operadorLogistico.nomeFantasia',
      headerName: 'Transportadora Motorista',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value;
        }

        return '---';
      },
    },
    {
      field: 'romaneio.numero',
      headerName: 'Nº Rom.',
    },
    {
      field: 'classificador.tipoVeiculo',
      headerName: 'Tipo do Veic.',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value.replace('_', ' ');
        }
        return '---';
      },
    },
    {
      field: 'tabelaFrete.descricao',
      headerName: 'Descrição da Tabela',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return params.value;
        }
        return '---';
      },
    },
    {
      field: 'franquia.valor',
      headerName: 'Valor da Franquia',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return numberToCurrencyBRL(params.value);
        }
        return '---';
      },
    },
    {
      field: 'franquia.km',
      headerName: 'Km Franquia',
    },
    {
      field: 'franquia.valorExcedente',
      headerName: 'Km Exc.',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return numberToCurrencyBRL(params.value);
        }
        return '---';
      },
    },
    {
      field: 'kmViagem',
      headerName: 'Km Viagem',
    },
    {
      field: 'kmAcumulado',
      headerName: 'Km Acumulado',
    },
    {
      field: 'valorPagamento',
      headerName: 'Valor do Pgto.',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.value) {
          return numberToCurrencyBRL(params.value);
        }
        return '---';
      },
    },
    {
      field: 'valorViagem',
      headerName: 'Valor da Viagem',
      pinned: 'right',
      valueFormatter: (params: ValueFormatterParams) => {
        if (params.data) {
          return numberToCurrencyBRL(
            params.data.valorPagamentoAjustado !== null
              ? Number(params.data.valorPagamentoAjustado)
              : Number(params.data.valorViagem)
          );
        }
        return '---';
      },
      cellRenderer: (params: ICellRendererParams) => (
        <UpdateShipping
          onView={(data: any) => {
            let rows: any[] = [];
            rows.push(data);
            console.log(data);
            setSelectedRows(rows);
            setShowHistoric(!showHistoric);
          }}
          {...params}
        />
      ),
      valueGetter: (params: any) =>
        params.data && params.data.valorPagamentoAjustado !== null
          ? Number(params.data?.valorPagamentoAjustado)
          : Number(params.data?.valorViagem), // Pegamos o dia Monday
      valueSetter: (params: any) => {
        console.log(params.newValue);
        params.data.valorViagem = params.newValue.data;
        params.data.valorPagamentoAjustado = params.newValue.data;
        params.data.isUpdate = params.newValue.isUpdate;
        return true;
      },
    },
  ]);
  const [rowData, setRowData] = useState<any[]>([]);
  const [gridApi, setGridApi] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showFilters, setShowFilters] = useState(false);
  const [showHistoric, setShowHistoric] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [update, setUpdate] = useState(false);
  const [dataFilters, setDataFilters] = useState<Filter[]>([]);

  const gridRef: any = useRef(null);

  const { t } = useTranslation();

  const onGridReady = useCallback(async (params?: any) => {
    try {
      setLoading(true);

      setGridApi(params.api);

      setLoading(false);
    } catch {}
  }, []);

  const onUpdateData = useCallback(async (data, valueObs: string) => {
    try {
      setLoading(true);

      const body = data
        .filter((item: any) => item.isUpdate === true)
        .map((row: any) => {
          return {
            id: Number(row.romaneio.id),
            value: parseFloat(row.valorViagem),
            obs: valueObs,
          };
        });

      const response = await api.patch(
        `/delivery/romaneios/updateFreightCost`,
        body
      );

      if (response.status === 204) {
        FrontendNotification(
          t('shippingAudit.update'),
          NotificationType.SUCCESS
        );
      }

      setShowConfirm(false);

      onHandleFilters();

      // setUpdate(false);
      // setUpdate(true);

      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, []);

  const onHandleFilters = useCallback(() => {
    setShowFilters(false);

    let loadFilters: any = sessionStorage.getItem('shippingAudit');

    if (loadFilters) {
      loadFilters = JSON.parse(loadFilters);
    }

    let filters: Filter[] = [];

    if (loadFilters.filial.length > 0) {
      filters.push({
        field: 'romaneio.filial.id',
        value: Number(loadFilters.filial),
        operation: SearchOperation.EQUAL,
      });
    }
    if (loadFilters.dataCriacaoInicial.length > 0) {
      filters.push({
        field: 'romaneio.data',
        value: `${loadFilters.dataCriacaoInicial}`,
        operation: SearchOperation.GREATHER_THAN_EQUAL,
      });
      if (loadFilters.dataCriacaoFinal.length > 0) {
        filters.push({
          field: 'romaneio.data',
          value: `${loadFilters.dataCriacaoFinal}`,
          operation: SearchOperation.LESS_THAN_EQUAL,
        });
      } else {
        filters.push({
          field: 'romaneio.data',
          value: `${format(new Date(), 'yyyy-MM-dd', { locale: pt })}T23:59:59`,
          operation: SearchOperation.LESS_THAN_EQUAL,
        });
      }
    }
    if (loadFilters.dataFechamentoInicial.length > 0) {
      filters.push({
        field: 'romaneio.dataFinalizacao',
        value: `${loadFilters.dataFechamentoInicial}T00:00:00`,
        operation: SearchOperation.GREATHER_THAN_EQUAL,
      });
      if (loadFilters.dataFechamentoFinal.length > 0) {
        filters.push({
          field: 'romaneio.dataFinalizacao',
          value: `${loadFilters.dataFechamentoFinal}T23:59:59`,
          operation: SearchOperation.LESS_THAN_EQUAL,
        });
      } else {
        filters.push({
          field: 'romaneio.dataFinalizacao',
          value: `${format(new Date(), 'yyyy-MM-dd', { locale: pt })}T23:59:59`,
          operation: SearchOperation.LESS_THAN_EQUAL,
        });
      }
    }
    if (loadFilters.numero.length > 0) {
      filters.push({
        field: 'romaneio.numero',
        value: Number(loadFilters.numero),
        operation: SearchOperation.EQUAL,
      });
    }
    if (loadFilters.tipoVeiculo.length > 0) {
      filters.push({
        field: 'classificador.tipoVeiculo',
        value: `${loadFilters.tipoVeiculo}`,
        operation: SearchOperation.EQUAL,
      });
    }
    if (loadFilters.transportadora.length > 0) {
      filters.push({
        field: 'classificador.operadorLogistico.nomeFantasia',
        value: `${loadFilters.transportadora}`,
        operation: SearchOperation.MATCH,
      });
    }
    if (loadFilters.descricao.length > 0) {
      filters.push({
        field: 'tabelaFrete.descricao',
        value: `${loadFilters.descricao}`,
        operation: SearchOperation.MATCH,
      });
    }
    if (loadFilters.isChanged !== null && loadFilters.isChanged) {
      filters.push({
        field: 'romaneio.previsaoValorFreteDataAlteracao',
        operation: SearchOperation.IS_NOT_NULL,
      });
    }

    setDataFilters(filters);
  }, []);

  useEffect(() => {
    if (!gridApi) return; // Evita chamadas desnecessárias

    if (gridApi.isDestroyed()) return;

    const dataSource = {
      getRows: async (params: any) => {
        try {
          setLoading(true);
          const currentOrders = [
            {
              field: 'romaneio.data',
              direction: Direction.DESC,
            },
          ];

          const page = params.endRow / 100 - 1;

          let filters: any[] = dataFilters || [];

          let orders: any[] = currentOrders || [];

          if (params.sortModel) {
            for (const sort in params.sortModel) {
              console.log(params.sortModel[sort]);
              orders = [];
              orders.push({
                field: String(params.sortModel[sort].colId),
                direction: params.sortModel[sort].sort.toUpperCase(),
              });
            }
          }

          const buildFilters = (filters: any) =>
            filters
              .map(
                (item: any) => `{
                      field: "${item.field}",
                      value: "${item.value || ''}",
                      operation: ${item.operation}
                    }`
              )
              .join(',');

          const buildOrders = () =>
            orders
              .map(
                (item: any) => `{
                  field: "${item.field}",
                  direction: ${
                    item.direction === Direction.ASC
                      ? Direction.ASC
                      : Direction.DESC
                  }
                }`
              )
              .join(',');

          console.log(buildFilters(filters));

          const query = `
          {
            pageable_TabelaFreteCongelada(
              filters: [${buildFilters(filters)}],
              page: ${page},
              size: 100,
              orders: [${buildOrders()}]
            ) {
              content {
                id,
                dataRegistro,
                km,
                kmViagem,
                kmAcumulado,
                valor,
                valorViagem,
                valorPagamento,
                valorPagamentoAjustado,
                romaneio {
                  id,
                  data,
                  numero,
                  dataFinalizacao,
                  filial {
                    id
                  },
                  operadorLogistico {
                    id,
                    nomeFantasia
                  },
                  tipoVeiculo,
                  veiculo {
                    tipo
                  },
                  previsaoValorFreteDataAlteracao,
                },
                tabelaFrete {
                  id,
                  descricao,
                },
                franquia {
                  id,
                  km,
                  valor,
                  valorExcedente,
                  cep
                },
                classificador {
                  id,
                  tipoVeiculo,
                  operadorLogistico {
                    id,
                    nomeFantasia
                  }
                }
              },
              totalPages,
              totalElements
            }
          }
        `;

          const body = query;

          const response = await api.post(
            '/graphql',
            { query: body },
            {
              headers: {
                'Content-Type': 'application/json', // Ensure correct content type
              },
            }
          );

          const mappingResponse = response.data.data.pageable_TabelaFreteCongelada.content.map(
            (item: any) => {
              return {
                ...item,
                isUpdate: false,
              };
            }
          );

          params.successCallback(
            mappingResponse,
            response.data.data.pageable_TabelaFreteCongelada.totalElements
          );

          setRowData(mappingResponse);

          setLoading(false);
        } catch {
          setLoading(false);
        }
      },
    };
    gridApi.setDatasource(dataSource);
  }, [gridApi, update, dataFilters]);

  useEffect(() => {
    if (!sessionStorage.getItem('shippingAudit')) {
      const initialDate = format(new Date(), 'yyyy-MM-dd', { locale: pt });
      const finalDate = format(new Date(), 'yyyy-MM-dd', { locale: pt });

      const data = {
        filial: sessionStorage.getItem('subsidiary'),
        dataCriacaoInicial: initialDate,
        dataCriacaoFinal: finalDate,
        dataFechamentoInicial: '',
        dataFechamentoFinal: '',
        numero: '',
        transportadora: '',
        tipoVeiculo: '',
        descricao: '',
        clienteNome: '',
        cep: '',
        cte: '',
        isChanged: null,
      };

      sessionStorage.setItem('shippingAudit', JSON.stringify(data));
      onHandleFilters();
    } else {
      onHandleFilters();
    }
  }, []);

  console.log(rowData);

  return (
    <CustomModal
      label="Auditoria de Frete"
      isOpen
      isClose={props.onClear}
      styles={{ minWidth: '98%' }}
    >
      <>
        <Loading loading={loading} />
        {showFilters && (
          <Filters
            open={showFilters}
            onClose={() => setShowFilters(!showFilters)}
            onConfirm={() => onHandleFilters()}
          />
        )}
        {showHistoric && (
          <Historic
            onClear={() => setShowHistoric(!showHistoric)}
            filters={[
              {
                field: 'romaneio.id',
                value: selectedRows[0].romaneio.id,
                operation: SearchOperation.EQUAL,
              },
            ]}
          />
        )}

        {showConfirm && (
          <Create
            onClear={() => setShowConfirm(!showConfirm)}
            onConfirm={(obs: string) => onUpdateData(rowData, obs)}
          />
        )}

        <div className="tw-w-full tw-h-full">
          <div className="tw-w-full tw-h-full tw-flex tw-justify-end tw-mb-3">
            <button
              id="btnFilters"
              className="tw-w-auto tw-h-9 tw-bg-[#EA004C] tw-rounded-md tw-border-none tw-p-2 tw-flex tw-items-center tw-justify-center tw-text-sm tw-text-[#FFF] tw-font-semibold"
              onClick={() => setShowFilters(!showFilters)}
            >
              <FilterListIcon style={{ color: '#FFF' }} />
              Filtros
            </button>
          </div>
          <div className="ag-theme-quartz tw-w-full tw-h-96 flex">
            <AgGridReact
              ref={gridRef}
              rowData={rowData}
              rowModelType="infinite"
              getRowId={params => params.data.id}
              columnDefs={columns}
              pagination
              suppressPaginationPanel={false}
              paginationPageSizeSelector={false}
              onSelectionChanged={(params: SelectionChangedEvent) => {
                const rows: any[] = params.api.getSelectedRows();
                setSelectedRows(rows);

                params.api.refreshCells({
                  force: true,
                });
              }}
              rowSelection={RowSelection.SINGLE}
              onGridReady={onGridReady}
              suppressRowClickSelection
            />
          </div>
          <div className="tw-flex tw-h-full tw-justify-end">
            <button
              className="tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-border-none tw-bg-[#EA004C] tw-rounded-md tw-w-32 tw-text-sm tw-text-[#FFF] tw-p-3 tw-font-semibold tw-text-center"
              onClick={() => {
                setShowConfirm(!showConfirm);
              }}
            >
              Salvar
            </button>
          </div>
        </div>
      </>
    </CustomModal>
  );
};

export default ShippingAudit;
