import React, { useState } from 'react';
import ReactInputMask from 'react-input-mask';

// import { Container } from './styles';
interface Props {
  title?: string;
  name?: string;
  id?: string;
  autoComplete?: string;
  className?: any;
  typeInput?: string;
  style?: any;
  styleTitle?: any;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  mask?: any;
  messageTooltip?: JSX.Element;
  isTooltip?: boolean;
  placeholder: string;
  type?: any;
  touched?: any;
  error?: any;
  value?: any;
}

const InputCustom: React.FC<Props> = (props: Props, ...rest) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState<boolean>(false);

  return (
    <>
      {props.typeInput === 'mask' ? (
        <div className="tw-flex tw-flex-col tw-mb-2">
          <div className="tw-flex tw-items-center tw-mb-2">
            <span className="tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60">
              {props.title}
            </span>
          </div>
          <ReactInputMask
            {...rest}
            className={`tw-h-9 tw-w-full tw-rounded-md tw-bg-[${
              props.disabled ? '#ccc' : '#FFFFFF'
            }] tw-box-border tw-text-sm tw-text-[rgba(0, 48, 73, 0.2)] tw-pl-2 tw-placeholder-[rgb(0, 48, 73)] tw-outline-none`}
            mask={props.mask}
            onChange={props.onChange}
            onBlur={props.onBlur}
            placeholder={props.placeholder}
            style={{
              ...props.style,
              border: '2.5px solid rgba(0, 48, 73, 0.2)',
            }}
            value={props.value}
            id={props.id}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
          />
        </div>
      ) : (
        <div className='tw-flex tw-flex-col tw-mb-2'>
          <div className="tw-flex tw-items-center tw-mb-2">
            <span className="tw-text-sm tw-text-[rgb(0, 48, 73)] tw-opacity-60">
              {props.title}
            </span>
          </div>
          <input
            {...rest}
            className={`tw-h-9 tw-w-full tw-rounded-md tw-bg-[${
              props.disabled ? '#ccc' : '#FFFFFF'
            }] tw-box-border tw-text-sm tw-text-[rgba(0, 48, 73, 0.2)] tw-pl-2 tw-placeholder-[rgb(0, 48, 73)] tw-outline-none`}
            style={{
              ...props.style,
              border: '2.5px solid rgba(0, 48, 73, 0.2)',
            }}
            type={
              props.type === 'password' && isVisiblePassword
                ? 'text'
                : props.type
            }
            placeholder={props.placeholder}
            onChange={props.onChange}
            onBlur={props.onBlur}
            value={props.value}
            id={props.id}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
          />
        </div>
      )}
      {props.touched && props.error && (
        <div className='tw-flex tw-w-full'>
          <span className='tw-text-xs tw-text-[#EA004C] tw-font-bold'>{props.error}</span>
        </div>
      )}
    </>
  );
};

export default InputCustom;
