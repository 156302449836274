import Grid from 'components/Grid/Grid';
import { Direction, RowSelection } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import { FrontendNotification, NotificationType } from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import Create from './components/Create';
import { IWindowDelivery } from './types/types';

// import { Container } from './styles';

interface Props {
  onClear: () => void;
}

const WindowDelivery: React.FC<Props> = (props: Props) => {
  const [columns] = useState<any[]>([
    {
      field: 'nome',
      headerName: 'Cliente',
      filter: true
    },
    {
      field: 'idReferencia',
      headerName: 'Id Ref.',
      flex:1
    },
  ]);
  const [selectedRows, setSelectedRows] = useState<IWindowDelivery[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);
  const [tabs, setTabs] = useState<number>(0);

  const { t } = useTranslation();

  const onRemove = useCallback(async(removeId: number) => {
    try {
      const response = await api.delete(`/delivery/clientes/${removeId}`);

      if(response.status === 204) {
        setIsRemove(false);
        FrontendNotification(t('windowDelivery.messageRemove'), NotificationType.SUCCESS);
        setTabs(1);
        setTabs(0);
      }
    }catch{}
  }, [])

  return (
    <CustomModal
      label={t('windowDelivery.title')}
      isOpen
      isClose={props.onClear}
      styles={{ minWidth: '60%' }}
    >
      <ModalDelete isOpen={isRemove} onClose={() => setIsRemove(false)} onConfirm={() => onRemove(selectedRows[0].id)} />
      <Tabs selectedIndex={tabs} onSelect={(index: number) => setTabs(index)}>
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
        </TabList>
        <TabPanel>
          <div className="tw-h-96">
            <Grid<IWindowDelivery>
              columns={columns}
              filters={[]}
              orders={[
                {
                  field: 'dataModificacao',
                  direction: Direction.DESC
                }
              ]}
              pagination
              path="/delivery/clientes/pageable"
              customCrudButtons
              showCrudButtons={false}
              rowSelection={RowSelection.SINGLE}
              onUpdate={(data?: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setTabs(2);
              }}
              onDelete={(data?: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setIsRemove(!isRemove)
              }}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <Create onConfirm={() => {
            setTabs(0);
          }} />
        </TabPanel>
        <TabPanel>
          <Create isEdit data={selectedRows} onConfirm={() => setTabs(0)} />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default WindowDelivery;
