import Loading from 'core/common/Loading';
import {
  FrontendNotification,
  NotificationType
} from 'core/common/Notification';
import React, { useCallback, useEffect, useState } from 'react';
import DropZone from 'react-dropzone';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import api from 'services/api';
import history from 'services/history';
import { typeIntegrationCSV } from 'store/modules/map/actions';
import deleteIcon from '../../../../assets/images/delete.png';
import excelIcon from '../../../../assets/images/excelIcon.png';
import warningCSV from '../../../../assets/images/warningCSV.svg';
import { getData } from '../../../../store/modules/csvMapping/actions';
import './styles.scss';

export default function Preview({
  onUpload,
  onSubmit,
  files,
  onReset,
  subsidiaryCurrent,
}) {
  const [conhecimentosPre, setConhecimentosPre] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [activeClose, setActiveClose] = useState(false);
  const subsidiaryActive = sessionStorage.getItem('subsidiary');
  const subsidiaryActual = useSelector(state => state.map.subsidiaryId);
  const dispatch = useDispatch();

  const getPreKnowledge = useCallback(async () => {
    try {
      const subsidiary = sessionStorage.getItem('subsidiary');

      if (subsidiary && subsidiary !== null && subsidiary.length > 0) {
        const dataCSV = {
          filters: [
            {
              field: 'status',
              value: 'ACTIVE',
              operation: 'EQUAL',
            },
            {
              field: 'filial',
              value: subsidiary,
              operation: 'EQUAL',
            },
          ],
          orders: [],
          page: 0,
          size: 10,
        };
        const response = await api.post(
          '/delivery/conhecimentosPre/pageable',
          dataCSV
        );

        setConhecimentosPre(response.data.content);
      }
    } catch (err) {}
  }, [subsidiaryActual]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    try {
      const currentOrders = [
        {
          field: 'ordemEntrega',
          direction: 'ASC',
        },
        {
          field: 'id',
          direction: 'ASC',
        },
      ];
      // const { language } = i18next;
      const emailSendLanguage =
        navigator.language === 'pt' || navigator.language === 'pt-BR'
          ? 'pt_BR'
          : 'en_US';
      const dataCSV = {
        filters: [
          {
            field: 'filial',
            value: [Number(sessionStorage.getItem('subsidiary'))],
            operation: 'IN',
          },
        ],
        orders: currentOrders,
        page: 0,
        size: 10,
      };
      const response = await api.post(
        `/delivery/csvMapping/pageable?lang=${emailSendLanguage}`,
        dataCSV
      );

      if (
        response.data &&
        response.data.content.elements.length > 0 &&
        response.status === 200
      ) {
        const arrData = response.data.content.elements.map((item, index) => {
          const indexItem = item.columns
            .map((itemColumn, i) =>
              itemColumn.id === 'deliveryOrder' ? i : -1
            )
            .filter(indexColumn => indexColumn !== -1);

          item.columns[indexItem].data = index + 1;

          return {
            ...item,
            active: false,
            activeAll: false,
            vehicleId: '',
            deliveryOrder: index + 1,
          };
        });

        const dataValue = {
          headers: response.data.content.headers,
          elements: arrData,
          totalPagesElements: response.data.content.totalPages,
        };

        dispatch(getData(dataValue));
        dispatch(typeIntegrationCSV('UPLOAD_CSV_PENDENCY'));

        // FrontendNotification(
        //   'Upload realizado com sucesso!',
        //   NotificationType.SUCCESS
        // );

        setTimeout(() => {
          history.push('/correctCSV');

          window.location.reload();
          setLoading(false);
        }, 1000);
      }
      setLoading(false);
    } catch (err) {
      FrontendNotification(
        'Erro ao realizar o upload!',
        NotificationType.ERROR
      );
    }
  }, []);

  useEffect(() => {
    getPreKnowledge();
  }, [getPreKnowledge]);
  return (
    <>
      <Loading loading={loading} />
      <div
        className="containerPreview"
        style={{
          position: 'relative',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div className="header" style={{ paddingTop: '40px' }}>
          {conhecimentosPre.length > 0 && (
            <div style={{ position: 'absolute', left: 0, marginLeft: 29 }}>
              <button
                type="button"
                style={{
                  width: '186px',
                  height: '35px',
                  background: '#EA004C',
                  borderRadius: 6,
                  border: 'none',
                  color: '#fff',
                  fontFamily: 'Lato',
                  fontWeight: 700,
                  fontSize: 14,
                  padding: '7px',
                }}
                onClick={() => handleSubmit()}
              >
                <img src={warningCSV} alt="" style={{ marginRight: 10 }} />
                Registros pendentes
              </button>
            </div>
          )}
          <div>
            <h1 className="title" style={{ marginBottom: 5 }}>
              Upload/Download CSV
            </h1>
          </div>
        </div>
        <div className="downloadCSV">

          <a href="/files/conhecimentos.csv" target="_blank" download>
            <button
              type="button"
              style={{
                display: 'flex',
                width: '156px',
                height: '131px',
                background: '#fff',
                borderTop: '8px solid #00B865',
                boxShadow: '0px 5px 12px rgba(0, 0, 0, 0.18)',
                borderRadius: '7px 7px 0 0',
                marginLeft: '0px',
                marginBottom: '20px',
                alignItems: 'left',
                justifyContent: 'left',
                borderLeft: 'none',
                borderRight: 'none',
                borderBottom: 'none',
              }}
              onClick={() => {}}
              className="boxCSV"
            >
              <div style={{  display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 10 }}>
                <img
                  src={excelIcon}
                  style={{ marginTop: 10 }}
                  width="43"
                  height="43"
                  alt=""
                />
                <span style={{ marginLeft: 5, fontFamily: 'Lato', fontSize: 16 }}>conhecimentos.csv</span>
              </div>
            </button>
          </a>
        </div>
        <span
          style={{
            fontFamily: 'Lato',
            fontSize: 14,
            color: '#003049',
            marginBottom: 10,
          }}
        >
          <Trans i18nKey="uploadCSV.messageSeparator" />
        </span>

        <span
          style={{
            fontFamily: 'Lato',
            fontSize: 14,
            color: '#003049',
            marginTop: 20,
            marginBottom: 10,
          }}
        >
          * Limite máx. de 200 registros.
        </span>

        <div className="container">
          <div className="linePreview" />

          {files.preview ? (
            <>
              <div
                className="previewCSV"
                onMouseEnter={() => setActiveClose(!activeClose)}
                onMouseLeave={() => setActiveClose(false)}
              >
                <div
                  style={{
                    position: 'relative',
                  }}
                >
                  {activeClose && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        cursor: 'pointer',
                      }}
                      onClick={onReset}
                    >
                      <img src={deleteIcon} alt="" />
                    </div>
                  )}
                  <img
                    src={excelIcon}
                    style={{ marginTop: 10 }}
                    width="78"
                    height="78"
                    alt=""
                  />
                </div>

                <span className="fileName">{files.file.name}</span>
              </div>
            </>
          ) : (
            <DropZone onDropAccepted={onUpload}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <div
                  className="sectionDrop"
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  isDragReject={isDragReject}
                  style={{
                    cursor: 'pointer',
                    border: isDragActive
                      ? `4px dashed #EA004C`
                      : `4px dashed #D2D2D2`,
                    marginBottom: '15px',
                    marginTop: '0',
                  }}
                >
                  <span style={{ fontSize: 18 }}>
                    <Trans i18nKey="common.description" />
                  </span>
                  <input {...getInputProps()} />
                </div>
              )}
            </DropZone>
          )}

          <div className="linePreview" />

          <div className="footer">
            <button
              onClick={onSubmit}
              type="button"
              className="sendButton"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Trans i18nKey="common.upload" />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}