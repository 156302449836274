import { ICellRendererParams } from 'ag-grid-community';
import React, { useEffect, useState } from 'react';

import checkIcon from 'assets/images/checkedIcon.svg';
import closeIcon from 'assets/images/closeIcon.svg';
import editIcon from 'assets/images/editIcon.svg';
import viewIcon from 'assets/images/viewIcon.svg';
import { numberToCurrencyBRL } from 'util/format';

interface UpdateShippingProps extends ICellRendererParams {
  onView: (data: any) => void;
}

const UpdateShipping: React.FC<UpdateShippingProps> = ({
  onView,
  ...props
}) => {
  const { value } = props;
  const [enabled, setEnabled] = useState(false);
  const [rawValue, setRawValue] = useState(0); // Armazena o valor numérico real
  const [formattedValue, setFormattedValue] = useState('0,00'); // Armazena o valor formatado

  useEffect(() => {
    if (value !== undefined) {
      const numericValue = value;
      setRawValue(numericValue);
      setFormattedValue(
        numericValue
          .toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          })
          .replace('R$', '')
          .replace(',', '.')
          .trim()
      );
    }
  }, [value]);

  const handleValueChange = () => {
    console.log(formattedValue);
    let inputValue = formattedValue;

    const parts = inputValue.split('.');

    if (parts.length > 2) {
      inputValue =
        parts
          .slice(0, -1)
          .join('')
          .replace(/\D/g, '') +
        '.' +
        parts[parts.length - 1];
    }

    props.api.stopEditing();
    if (props.column) {
      props.node.setDataValue(props.column.getColId(), {
        data: Number(inputValue), // Salvar o valor correto em centavos
        isUpdate: true,
      });
    }
    setEnabled(false);
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-start">
      {enabled ? (
        <div className="tw-flex tw-items-center tw-mt-1">
          <input
            type="text"
            id='updatedValue'
            value={formattedValue}
            className="tw-w-4/5 tw-h-8 tw-rounded-lg tw-border-none tw-z-50"
            onChange={e => {
              const onlyNumbers = e.target.value
                .replace(/\D/g, '')
                .replace(/\./g, '')
                .replace(/[^0-9,]/g, '');

              const numericValue = Number(onlyNumbers) / 100;

              setRawValue(numericValue);

              setFormattedValue(
                numericValue
                  .toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                  })
                  .replace(',', '.')
              );
            }}
            style={{ border: '3px solid #EA004C' }}
          />

          <img
            className="tw-ml-2 tw-cursor-pointer"
            src={checkIcon}
            alt=""
            id='confirm'
            onClick={handleValueChange}
          />
          <img
            className="tw-w-3 tw-h-3 tw-ml-2 tw-cursor-pointer"
            src={closeIcon}
            alt=""
            id='cancel'
            onClick={() => {
              setEnabled(false);
              props.api.stopEditing();
            }}
          />
        </div>
      ) : (
        <div className="tw-flex tw-items-center">
          {props.data && (
            <>
              <span>{numberToCurrencyBRL(Number(value))}</span>
              {props.data.romaneio.previsaoValorFreteDataAlteracao !== null && (
                <div
                  className="tw-cursor-pointer"
                  onClick={() => onView(props.data)}
                  id='view'
                >
                  <img className="tw-mb-2 tw-ml-2" src={viewIcon} alt="" />
                </div>
              )}
              <div
                className="tw-ml-2 tw-cursor-pointer"
                onClick={() => setEnabled(true)}
                id='edit'
              >
                <img src={editIcon} alt="" />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default UpdateShipping;
