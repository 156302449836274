import { CustomCellRendererProps } from 'ag-grid-react';
import Grid from 'components/Grid/Grid';
import { ColumnDef } from 'components/Grid/types';
import CustomModal from 'components/ModalCustom';
import ModalDelete from 'components/ModalDelete';
import { renderIcons } from 'components/TypePointInterest/icons';
import {
  FrontendNotification,
  NotificationType,
} from 'core/common/Notification';
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import api from 'services/api';
import NewPointInterest from './components/NewPointInterest';
import { IPointInterest } from './types/types';

interface Props {
  onClear: () => void;
}

const PointInterest: React.FC<Props> = (props: Props) => {
  const { t } = useTranslation();

  const [columns] = useState<ColumnDef[]>([
    {
      field: 'description',
      headerName: t('pointInterest.fields.description'),
    },
    {
      field: 'typePointInterest.description',
      headerName: t('pointInterest.fields.type'),
      width: 120,
      cellRenderer: (params: CustomCellRendererProps) => {
        if (params.value) {
          return (
            <div className="tw-flex tw-w-full tw-items-center">
              {renderIcons(params.data.typePointInterest.icon, false)}
              <span className="tw-ml-2">{params.value}</span>
            </div>
          );
        }
      },
    },
    {
      field: 'endereco.logradouro',
      headerName: t('pointInterest.fields.enderecoLogradouro'),
    },
    {
      field: 'endereco.numero',
      headerName: t('pointInterest.fields.enderecoNumero'),
    },
    {
      field: 'endereco.cidade',
      headerName: t('pointInterest.fields.enderecoCidade'),
    },
    {
      field: 'endereco.bairro',
      headerName: t('pointInterest.fields.enderecoBairro'),
    },
    {
      field: 'endereco.uf',
      headerName: t('pointInterest.fields.enderecoEstado'),
    },
    {
      field: 'endereco.cep',
      headerName: t('pointInterest.fields.enderecoCep'),
    },
  ]);
  const [tabs, setTabs] = useState<number>(0);
  const [selectedRows, setSelectedRows] = useState<IPointInterest[]>([]);
  const [isRemove, setIsRemove] = useState<boolean>(false);

  const onHandleDelete = useCallback(async (data: IPointInterest[]) => {
    try {
      const response = await api.delete(
        `/delivery/pointInterest/${data[0].id}`
      );

      if (response.status === 204) {
        FrontendNotification(
          'Ponto de Interesse removido com sucesso!',
          NotificationType.SUCCESS
        );
      }

      setIsRemove(false);
      setSelectedRows([]);
      setTabs(1);
      setTabs(0);
    } catch {}
  }, []);

  return (
    <CustomModal
      isOpen
      label={t('pointInterest.title')}
      isClose={props.onClear}
      styles={{ minWidth: tabs === 0 ? '90%' : '70%' }}
    >
      <ModalDelete
        isOpen={isRemove}
        onClose={() => setIsRemove(!isRemove)}
        onConfirm={() => onHandleDelete(selectedRows)}
      />
      <Tabs
        selectedIndex={tabs}
        onSelect={(index: number) => {
          setTabs(index);
        }}
      >
        <TabList>
          <Tab>
            <Trans i18nKey="tabs.list" />
          </Tab>
          <Tab>
            <Trans i18nKey="tabs.new" />
          </Tab>
          <Tab disabled>{tabs === 2 && <Trans i18nKey="tabs.edit" />}</Tab>
        </TabList>
        <TabPanel>
          <div className="tw-w-full tw-h-96">
            <Grid<IPointInterest>
              columns={columns}
              filters={[]}
              pagination
              path="/delivery/pointInterest/pageable"
              showCrudButtons={false}
              customCrudButtons
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              onUpdate={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setTabs(2);
              }}
              onDelete={(data: any) => {
                let rows = [];
                rows.push(data);
                setSelectedRows(rows);
                setIsRemove(!isRemove);
              }}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <NewPointInterest onClear={() => setTabs(0)} />
        </TabPanel>
        <TabPanel>
          <NewPointInterest
            onClear={() => setTabs(0)}
            isEdit
            data={selectedRows[0]}
          />
        </TabPanel>
      </Tabs>
    </CustomModal>
  );
};

export default PointInterest;
