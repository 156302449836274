import { VehicleType } from 'components/Vehicles/interfaces/types';
import bicycle from '../../assets/images/typeVehicles/bicycle.svg';
import carreta from '../../assets/images/typeVehicles/cart.svg';
import motorcycle from '../../assets/images/typeVehicles/motorcycle.svg';
import toco from '../../assets/images/typeVehicles/toco.svg';
import tourVehicles from '../../assets/images/typeVehicles/tourVehicles.svg';
import truck from '../../assets/images/typeVehicles/truck.svg';
import van from '../../assets/images/typeVehicles/van.svg';
import vuc from '../../assets/images/typeVehicles/vuc.svg';

export const renderIcons = (value: string) => {
  let urlIcon = vuc;

  switch (value) {
    case VehicleType.VUC:
        urlIcon = vuc;
        break;
      case VehicleType.TOCO:
        urlIcon = toco;
        break;
      case VehicleType.BITRUCK:
        urlIcon = toco;
        break;
      case VehicleType.TRUCK:
        urlIcon = truck;
        break;
      case VehicleType.CARRETA:
        urlIcon = carreta;
        break;
      case VehicleType.VAN:
        urlIcon = van;
        break;
      case VehicleType.VEICULO_PASSEIO:
        urlIcon = tourVehicles;
        break;
      case VehicleType.MOTO:
        urlIcon = motorcycle;
        break;
      case VehicleType.BICICLETA:
        urlIcon = bicycle;
        break;
      case VehicleType.TRUCK_FRIO:
        urlIcon = truck;
        break;
      case VehicleType.CARRETA_FRIO:
        urlIcon = carreta;
        break;
      case VehicleType.TOCO_FRIO:
        urlIcon = toco;
        break;
      case VehicleType.BITRUCK_FRIO:
        urlIcon = toco;
        break;
      case VehicleType.RODOTREM:
        urlIcon = toco;
        break;
      case VehicleType.TRES_QUARTOS:
        urlIcon = carreta;
        break;
      case VehicleType.TRES_QUARTOS_FRIO:
        urlIcon = carreta;
        break;
    default:
      urlIcon = '' // Caso o valor não seja encontrado
  }

  return urlIcon;
};