/* eslint-disable prefer-const */
import React, { useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Modal, Row } from 'reactstrap';
import './styles.scss';

import { FrontendNotification } from 'components/Notification';
import Loading from 'core/common/LoadingRoutering';
import { NotificationType } from 'core/common/Notification';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import { useSelector } from 'react-redux';
import api from 'services/api';
import closeModalIcon from '../../../assets/images/closeIcon.svg';


export default function ConfirmationDriver({
  idRomaneio,
  cpfDriver,
  onClear,
  onConfirm,
  onLoad,
  data,
  dataLoad,
  maxCapacity,
  dataParameters,
  violations
}) {
  const [modal, setModal] = React.useState(true);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const toggle = () => setModal(!modal);

  const getStepId = useSelector(state => state.routeringSimulation.id_step);
  // const toggleRemove = () => setRemoveConfirmation(!removeConfirmation);

  const dataStepsSelected = useSelector(
    (state) => state.routering.data.dataStepsSelected
  );

  const onUpdateCargo = useCallback(async (optmisData, idStep) => {
    try {
      console.log('entrou requisicao');

      let getCargas = sessionStorage.getItem('cargas');

      let getCargaDateScheduling;

      if(getCargas) {
        getCargaDateScheduling = JSON.parse(getCargas);
      }

      const updateSteps = optmisData.steps.slice().sort((a, b) => a.order - b.order);

      const mappingKnowledge = updateSteps.map(
        (itemKnowledge, indexKnowledge) => {
          return {
            id: itemKnowledge.conhecimentoPre.id,
            priority: 0,
          };
        }
      );

      let updateStepsCargo = mappingKnowledge;

      const updateStepsChanged = dataStepsSelected.map((stepSelected) => {
        updateStepsCargo.push({
          id: stepSelected.conhecimentoPre.id,
          priority: 0
        });
      })

      // updateStepsCargo.push({
      //   id: idStep,
      //   priority: 0,
      // });

      const dateScheduling = getCargaDateScheduling.find((item) => item.id === optmisData.id).dataAgendamento;

      const getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
        locale: pt
      });

      const getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
        locale: pt
      });

      const dataSubmit = {
        jobs: updateStepsCargo,
        routeId: optmisData.id,
        vehicles: [
          {
            id: optmisData.vehicle.id,
            roadtrip: optmisData.roadtrip,
            outputForecast: `${getDateScheduling}T${getHoursScheduling}`
          },
        ],
        branchId: optmisData.subsidiary.id,
      };

      const response = await api.post(
        '/route/routing/order',
        dataSubmit
      );

      if(response.data.id_route_simulation !== null) {


        sessionStorage.setItem(
          'idSimulacao',
          JSON.stringify(response.data.id_route_simulation)
          );
        }

      setLoading(false);

      FrontendNotification(
        t('changeVehiclesLoads.messageSuccess'),
        NotificationType.SUCCESS
      );
      setModal(!modal);
      onConfirm();
      // dataKnowledgeRouteringRemoved(knowledgeData);
    } catch (err) {}
  }, []);

  const setChangeSteps = useCallback(async (optmisData, idStep) => {
    try {
      setLoading(true);

      let getCargas = sessionStorage.getItem('cargas');

      let getCargaDateScheduling;

      if(getCargas) {
        getCargaDateScheduling = JSON.parse(getCargas);
      }

      let dataChangeSteps = optmisData.steps.slice().sort((a, b) => a.order - b.order);

      const updateStepsRoutering = dataChangeSteps.filter((stepChanged) => !dataStepsSelected.some((stepSelected) => stepSelected.id === stepChanged.id));

      console.log("Steps 2", updateStepsRoutering);

      // const filterSteps = optmisData.steps.filter((itemFilter, index) => {
      //   return itemFilter.conhecimentoPre.id !== idStep;
      // });

      const mappingKnowledge = updateStepsRoutering.map(
        (itemKnowledge, indexKnowledge) => {
          return {
            id: itemKnowledge.conhecimentoPre.id,
            priority: 0,
          };
        }
      );

      const dateScheduling = getCargaDateScheduling.find((item) => item.id === optmisData.id).dataAgendamento;

      const getDateScheduling = format(new Date(dateScheduling), 'yyyy-MM-dd', {
        locale: pt
      });

      const getHoursScheduling = format(new Date(dateScheduling), 'HH:mm:ss', {
        locale: pt
      });

      const dataSubmit = {
        jobs: mappingKnowledge,
        routeId: optmisData.id,
        vehicles: [
          {
            id: optmisData.vehicle.id,
            roadtrip: optmisData.roadtrip,
            outputForecast: `${getDateScheduling}T${getHoursScheduling}`
          },
        ],
        branchId: optmisData.subsidiary.id,
      };

      const response = await api.patch(
        '/route/routing/order',
        dataSubmit
      );

      const getFilterSteps = optmisData.steps.filter((itemFilter, index) => {
        return itemFilter.conhecimentoPre.id === idStep;
      });

      onUpdateCargo(data, getFilterSteps[0].conhecimentoPre.id);

      // dataKnowledgeRouteringRemoved(knowledgeData);
    } catch (err) {}
  }, []);

  const handleSubmit = useCallback(async maximumCapacity => {
    try {
      // if(dataParameters[0].)
      console.log(dataParameters);
      if (
        dataParameters[0].roteirizacaoUltrapassaQtdAtendimentos === false ||
        dataParameters[0].roteirizacaoUltrapassaPeso === false ||
        dataParameters[0].roteirizacaoUltrapassaCubagem === false ||
        dataParameters[0].roteirizacaoUltrapassaValor === false
      ) {
        if (
          maximumCapacity.porcentageAttendances >= 100 ||
          maximumCapacity.porcentagePrice >= 100 ||
          maximumCapacity.porcentageVolume >= 100 ||
          maximumCapacity.porcentageWeight >= 100 ||
          violations
        ) {
          FrontendNotification(
            t('changeVehiclesLoads.messageMaximumCapacity'),
            NotificationType.WARNING
          );
          onClear();
        } else {
          setChangeSteps(dataLoad, getStepId);
        }
      } else {
        setChangeSteps(dataLoad, getStepId);
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <Loading loading={loading} />
      <Modal
        size="xl"
        isOpen={modal}
        // toggle={toggle}
        // className={className}
        dialogClassName="my-modal"
        style={{ maxWidth: '40%', maxHeight: '90%', zIndex: 9 }}
      >
        <div className="modalWrapperVehicles">
          <div className="iconCloseView">
            <button
              className="buttonWrapper"
              type="button"
              onClick={() => {
                onClear();
                setModal(!modal);
              }}
            >
              <img src={closeModalIcon} alt="" />
            </button>
          </div>
          <p className="title">
            <Trans i18nKey="changeVehicles.title" />
            {/* <Trans i18nKey="phases.smsPhases" /> */}
            {/* <Trans i18nKey="uploadCSV.select" /> */}
          </p>
          <span>
            <Trans i18nKey="changeVehicles.messageConfirmLoadChanged" />
          </span>
          <Row>
            <Col md={6} mt="4">
              <button
                type="button"
                className="buttonDriverCancel"
                onClick={() => {
                  onClear();
                  setModal(!modal);
                }}
              >
                <Trans i18nKey="changeVehicles.buttons.cancel" />
              </button>{' '}
            </Col>
            <Col md={6}>
              <button
                type="button"
                className="buttonDriverConfirm"
                onClick={() => handleSubmit(maxCapacity)}
              >
                <Trans i18nKey="changeVehicles.buttons.confirm" />
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
