import leftArrow from 'assets/images/left-arrow.png';
import rightArrow from 'assets/images/right-arrow.png';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Cargo } from 'pages/ControlTower/ListAttendances/interfaces/types';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';

// import { Container } from './styles';

interface Props {
  data: Cargo[];
}

const Carousel: React.FC<Props> = (props: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onExiting = () => setAnimating(true);
  const onExited = () => setAnimating(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.data.length - 1 ? 0 : activeIndex + 1;

    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? 0 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleMarkerClick = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <div className="tw-flex tw-flex-col tw-h-full tw-w-full">
      <div className="tw-flex tw-w-full tw-h-full tw-items-center tw-justify-around">
        <div className="tw-h-full tw-mr-2" onClick={() => previous()}>
          <img src={leftArrow} alt="" className="tw-w-3 tw-h-3" />
        </div>
        {props.data && props.data.length > 0 && (
          <div className="tw-w-full tw-h-full">
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="routeOptmization.routeSteps.steps.serviceNumber" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].numero}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="routeOptmization.routeSteps.steps.expectedArrival" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].eta !== null
                  ? format(
                      new Date(props.data[activeIndex].eta),
                      'dd/MM/yyyy HH:mm',
                      {
                        locale: pt,
                      }
                    )
                  : '---'}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-start tw-justify-start">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1 tw-w-full">
                <Trans i18nKey="routeOptmization.routeSteps.fields.deliveryAddress" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-text-start tw-font-normal tw-ml-1 tw-w-full">
                {`${props.data[activeIndex].endereco.logradouro}, ${props.data[activeIndex].endereco.numero}, ${props.data[activeIndex].endereco.complemento}`}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="routeOptmization.routeSteps.fields.neighborhood" />
                :
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].endereco.bairro}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                <Trans i18nKey="routeOptmization.routeSteps.fields.city" />:
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].endereco.cidade}
              </span>
            </div>
            <div className="tw-flex tw-w-full tw-items-center tw-justify-start ">
              <span className="tw-text-xs tw-text-[#003049] tw-font-bold tw-ml-1">
                UF:
              </span>
              <span className="tw-text-xs tw-text-[#003049] tw-font-normal tw-ml-1">
                {props.data[activeIndex].endereco.uf}
              </span>
            </div>
          </div>
        )}
        <div
          className="tw-flex tw-justify-end tw-h-full tw-ml-4 tw-cursor-pointer"
          onClick={() => next()}
        >
          <img src={rightArrow} alt="" className="tw-w-3 tw-h-3" />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
