import CustomModal from 'components/ModalCustom';
import Loading from 'core/common/Loading';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap/lib';

interface Props {
  onClear: () => void;
  onConfirm: () => void;
}

const ModalConfirm: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();

  return (
    <div>
      <CustomModal
        isOpen
        label={`${t('searchAddress.title')}?`}
        isClose={() => props.onClear()}
        styles={{ maxWidth: '50%' }}
      >
        <Row>
          <Col md={6} mt="4">
            <button
              type="button"
              className="buttonDriverCancel"
              onClick={() => {
                props.onClear();
              }}
            >
              <Trans i18nKey="searchAddress.actions.no" />
            </button>{' '}
          </Col>
          <Col md={6}>
            <button
              type="button"
              className="buttonDriverConfirm"
              onClick={() => {

                props.onConfirm();
              }}
            >
              <Trans i18nKey="searchAddress.actions.yes" />
            </button>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};

export default ModalConfirm;
